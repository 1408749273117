var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{class:_vm.$style.ModalHomeAdvantages,attrs:{"name":"content-slide-left"},on:{"after-enter":_vm.handleAfterEnter,"after-leave":function($event){return _vm.$emit('after-leave')},"before-leave":_vm.handleBeforeLeave}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],class:[_vm.$style.wrapper, _vm.wrapperClassList]},[(_vm.card)?[_c('div',{class:[
                    _vm.$style.header,
                    { [_vm.$style._shadowTop]: _vm.shadowTop },
                ]},[_c('div',{class:_vm.$style.headerTitle,domProps:{"innerHTML":_vm._s(_vm.card.title)}}),_vm._v(" "),_c('div',{class:_vm.$style.headerClose},[_c('UiCloseButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}})],1)]),_vm._v(" "),_c('div',{ref:"ModalHomeAdvantagesBody",class:_vm.$style.body},[(_vm.card.tags)?_c('HomeAdvantageCardLabels',{class:_vm.$style.labels,attrs:{"card":_vm.card,"color":"dark"}}):_vm._e(),_vm._v(" "),(_vm.card.tags !=='firstblock')?_c('img',{class:_vm.$style.image,attrs:{"src":_vm.card.imageDisplay,"alt":"Фото"}}):_vm._e(),_vm._v(" "),(_vm.card.text)?_c('div',{class:_vm.$style.content,domProps:{"innerHTML":_vm._s(_vm.card.text)}}):_vm._e()],1),_vm._v(" "),_c('div',{class:[
                    _vm.$style.footer,
                    { [_vm.$style._shadowBottom]: _vm.shadowBottom },
                ]},[_c('UiButton',{attrs:{"color":"default","size":"big","full-width":"","disabled":_vm.index === 0},on:{"click":function($event){return _vm.changeContent(_vm.index -= 1)}}},[_vm._v("\n                    Назад\n                ")]),_vm._v(" "),_c('UiButton',{attrs:{"color":"default","size":"big","full-width":"","disabled":_vm.cards[_vm.cards.length - 1].tags === 'linkblock'? _vm.index === _vm.cards.length - 2 : _vm.index === _vm.cards.length - 1},on:{"click":function($event){return _vm.changeContent(_vm.index += 1)}}},[_vm._v("\n                    Далее\n                ")])],1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }