<template>
    <symbol id="icon-chat" viewBox="0 0 20 20">
        <path d="M16.0001 3.00002V10.0001C16.0001 11.6569 14.657 13.0001 13.0001 13.0001H8.33007L6.20005 14.6001L4.60004 15.8001C4.42694 15.93 4.2164 16.0001 4.00003 16.0001C3.84305 16.0029 3.68803 15.965 3.55003 15.8901C3.21375 15.7207 3.00117 15.3767 3.00002 15.0001V13.0001C1.34316 13.0001 0 11.6569 0 10.0001V3.00002C0 1.34316 1.34316 0 3.00002 0H13.0001C14.657 0 16.0001 1.34316 16.0001 3.00002Z"/>
        <path d="M20 7.00002V14.0001C20 15.6569 18.6568 17.0001 16.9999 17.0001V19.0001C16.9988 19.3767 16.7862 19.7207 16.4499 19.8901C16.3119 19.965 16.1569 20.0029 15.9999 20.0001C15.7836 20.0001 15.573 19.93 15.3999 19.8001L11.6699 17.0001H6.99986C6.40834 16.9975 5.8308 16.82 5.33984 16.4901L8.65987 14.0001H12.9999C15.2091 14.0001 16.9999 12.2092 16.9999 10V4C18.6568 4 20 5.34316 20 7.00002Z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconChat',
};
</script>
