export default {
    SET_GRID_VIEW(state, payload) {
        state.gridView = payload;
    },

    SET_OBJECTS(state, payload) {
        state.allObjects = state.allObjects.concat(payload);
    },

    RESET_OBJECTS(state) {
        state.allObjects = [];
        state.currentPage = 1;
        state.otherTypesOnPage = null;

        state.flatsLoadedCount = 0;
        state.flatsTotalCount = 0;
        state.flatsEndCursor = null;

        state.cityHousesLoadedCount = 0;
        state.cityHousesTotalCount = 0;
        state.cityHousesEndCursor = null;

        state.pentHousesLoadedCount = 0;
        state.pentHousesTotalCount = 0;
        state.pentHousesEndCursor = null;

        state.townHousesLoadedCount = 0;
        state.townHousesTotalCount = 0;
        state.townHousesEndCursor = null;

        state.cityUnitsLoadedCount = 0;
        state.cityUnitsTotalCount = 0;
        state.cityUnitsEndCursor = null;
    },

    SET_FLATS_LOADED_COUNT(state, payload) {
        state.flatsLoadedCount += payload;
    },

    SET_FLATS_TOTAL_COUNT(state, payload) {
        state.flatsTotalCount = payload;
    },

    SET_FLATS_END_CURSOR(state, payload) {
        state.flatsEndCursor = payload;
    },

    SET_CITY_HOUSES_LOADED_COUNT(state, payload) {
        state.cityHousesLoadedCount += payload;
    },

    SET_CITY_HOUSES_TOTAL_COUNT(state, payload) {
        state.cityHousesTotalCount = payload;
    },

    SET_CITY_HOUSES_END_CURSOR(state, payload) {
        state.cityHousesEndCursor = payload;
    },

    SET_CITY_UNITS_LOADED_COUNT(state, payload) {
        state.cityUnitsLoadedCount += payload;
    },

    SET_CITY_UNITS_TOTAL_COUNT(state, payload) {
        state.cityUnitsTotalCount = payload;
    },

    SET_CITY_UNITS_END_CURSOR(state, payload) {
        state.cityUnitsEndCursor = payload;
    },

    SET_PENT_HOUSES_LOADED_COUNT(state, payload) {
        state.pentHousesLoadedCount += payload;
    },

    SET_PENT_HOUSES_TOTAL_COUNT(state, payload) {
        state.pentHousesTotalCount = payload;
    },

    SET_PENT_HOUSES_END_CURSOR(state, payload) {
        state.pentHousesEndCursor = payload;
    },

    SET_TOWN_HOUSES_LOADED_COUNT(state, payload) {
        state.townHousesLoadedCount += payload;
    },

    SET_TOWN_HOUSES_TOTAL_COUNT(state, payload) {
        state.townHousesTotalCount = payload;
    },

    SET_TOWN_HOUSES_END_CURSOR(state, payload) {
        state.townHousesEndCursor = payload;
    },

    SET_BANNERS(state, payload) {
        state.banners = payload;
    },

    SET_LOADING(state, payload) {
        state.isLoading = payload;
    },

    SET_RELOADING(state, payload) {
        state.isReloading = payload;
    },

    SET_FILTERS(state, payload) {
        state.filters = { ...state.filters, ...payload };
    },

    SET_HUU_QUERY(state, payload) {
        state.huuQuery = payload;
    },

    SET_LAST_FILTERS_PAYLOAD(state, payload) {
        state.lastFiltersPayload = payload;
    },

    SET_SPECS(state, payload) {
        state.specs = payload;
    },

    SET_FACETS(state, payload) {
        state.facets = payload;
    },

    SET_LAST_SEARCH(state, payload) {
        state.lastSearch = { ...state.lastSearch, ...payload };
    },

    SET_IS_LAST_SEARCH_SHOWN(state, payload) {
        state.isLastSearchShown = payload;
    },

    INCREASE_CURRENT_PAGE(state) {
        state.currentPage += 1;
    },

    SET_OTHER_TYPES_ON_PAGE(state, payload) {
        state.otherTypesOnPage = payload;
    },
};
