// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuProject_Mdton{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MenuProject": "MenuProject_Mdton"
};
module.exports = ___CSS_LOADER_EXPORT___;
