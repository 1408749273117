<template>
    <symbol
        id="icon-walk"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M7.60985 8.70695C7.61412 8.70997 7.61984 8.70993 7.62408 8.70686L10.822 6.384C11.1818 6.12071 11.6194 5.98551 12.065 6C12.6085 6.01355 13.1343 6.19611 13.5692 6.52228C14.0042 6.84846 14.3267 7.30205 14.492 7.82C14.678 8.403 14.848 8.797 15.002 9.002C15.4673 9.6228 16.0709 10.1266 16.7649 10.4734C17.1593 10.6705 17.5766 10.8138 18.0059 10.9007C18.5475 11.0104 19 11.4483 19 12.0009C19 12.5528 18.5507 13.0078 18.0044 12.9298C17.3164 12.8315 16.6451 12.6308 16.0131 12.3329C15.7368 12.2026 15.4701 12.0547 15.2145 11.8904C14.4962 11.4286 13.3693 11.7509 13.221 12.5919L13.0012 13.8396C12.9392 14.1913 13.0695 14.5494 13.3431 14.7791L14.7568 15.9658C14.8912 16.0786 14.9936 16.2248 15.0536 16.3897L16.843 21.3062C17.0319 21.8252 16.7639 22.3992 16.2449 22.5881C15.7259 22.7769 15.1519 22.5094 14.963 21.9905L13.3566 17.5777C13.2966 17.4128 13.1942 17.2666 13.0598 17.1538L9.875 14.481C9.5968 14.2564 9.38391 13.9612 9.25851 13.6264C9.13311 13.2915 9.09978 12.9291 9.162 12.577L9.53433 10.4666C9.58398 10.1852 9.26585 9.98643 9.0347 10.1544C9.00773 10.174 8.98402 10.1977 8.96443 10.2247L7.45488 12.3027C7.13022 12.7496 6.50464 12.8486 6.05781 12.5239C5.61113 12.1992 5.51208 11.5739 5.83665 11.1271L7.59291 8.70975C7.59684 8.70435 7.60439 8.7031 7.60985 8.70695ZM13.5 5.5C12.9696 5.5 12.4609 5.28929 12.0858 4.91421C11.7107 4.53914 11.5 4.03043 11.5 3.5C11.5 2.96957 11.7107 2.46086 12.0858 2.08579C12.4609 1.71071 12.9696 1.5 13.5 1.5C14.0304 1.5 14.5391 1.71071 14.9142 2.08579C15.2893 2.46086 15.5 2.96957 15.5 3.5C15.5 4.03043 15.2893 4.53914 14.9142 4.91421C14.5391 5.28929 14.0304 5.5 13.5 5.5ZM10.5844 18.5574C10.547 18.6394 10.4986 18.716 10.4406 18.7851L7.95651 21.7453C7.60164 22.1682 6.97116 22.2234 6.54819 21.8687C6.12507 21.5138 6.06985 20.883 6.42488 20.46L8.63819 17.8228C8.71738 17.7284 8.77845 17.6202 8.81834 17.5037L9.03242 16.8781C9.25968 16.214 10.0825 15.9845 10.6206 16.4352L10.7112 16.5111C11.0561 16.7999 11.1656 17.2835 10.9789 17.6928L10.5844 18.5574Z"
            fill="currentColor"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconWalk',
};
</script>
