<template>
    <ValidationObserver
        ref="observer"
        v-slot="{ handleSubmit }"
        tag="div"
        :class="[$style.SubscribeForm, {[$style._success]: isSuccess}]"
    >
        <form
            ref="SubscribeForm"
            @submit.prevent="handleSubmit(onSubmit)"
        >
            <div :class="$style.formInputs">
                <UiInput
                    v-model="email"
                    field="email"
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    rules="required|email"
                    :class="$style.input"
                />

                <UiButton
                    color="primary"
                    type="submit"
                    :class="$style.button"
                >
                    Подписаться
                </UiButton>
            </div>

            <div :class="$style.formInfo">
                <FormPolicy v-model="checkboxes" />
            </div>
        </form>

        <transition name="fade">
            <TheFormSuccess
                v-if="isSuccess"
                :class="$style.formSuccess"
                title="Успешно!"
                description="Вы подписались на рассылку обновлений"
                @click="$emit('close')"
            />
        </transition>
    </ValidationObserver>
</template>

<script>
import UiInput from '~/components/ui/home/UiInput.vue';
import UiButton from '~/components/ui/home/UiButton.vue';

import TheFormSuccess from '~/components/common/form/TheFormSuccess.vue';
import FormPolicy from '~/components/common/form/FormPolicy.vue';

export default {
    name: 'SubscribeForm',

    components: {
        FormPolicy,
        UiInput,
        UiButton,
        TheFormSuccess,
    },

    data() {
        return {
            email: '',
            isSuccess: false,
            checkboxes: {
                policy: false,
                marketing: true,
            },
        };
    },

    methods: {
        async onSubmit() {
            try {
                const payload = {
                    email: this.email,
                    ad: this.checkboxes.marketing,
                };

                await this.$axios.$post('/api/subscription/', payload);

                window.mindbox('async', {
                    operation: 'Website.SubscriptionInFooter',
                    data: {
                        customer: {
                            email: this.email,
                        },
                    },
                    onSuccess: function() {
                        console.log('%c Mindbox: All good, заявка отправлена', 'background: #01630b; color: #add7ff');
                    },
                    onError: function(error) {
                        console.log('%c Mindbox: error! Ошибка в отправке заявки', 'background: #ffd400; color: #ff0000', error);
                    },
                });

                this.resetForm();
                this.openSuccessModal();
            } catch (e) {
                this.openFailureModal();
                console.warn('[Subscribe] onSubmit:', e);
            }
        },

        resetForm() {
            this.$refs.SubscribeForm.reset();
            this.email = '';
            this.checkboxes.policy = false;
            this.checkboxes.marketing = true;
            this.$refs.observer.reset();
        },

        openSuccessModal() {
            this.$modal.open('ModalHomeSaleFormSuccess', {
                title: 'Вы успешно подписались на рассылку!',
                description: 'Вступайте в чат-бот и узнавайте новости первыми',
                hasTelegramLink: true,
            });
        },

        openFailureModal() {
            this.$modal.open('ModalHomeSaleFormSuccess', {
                title: 'Произошла ошибка',
                description: 'Попробуйте повторить попытку снова или перезагрузите страницу!',
                hasTelegramLink: false,
                isError: true,
            });
        },
    },
};
</script>

<style lang="scss" module>
    .SubscribeForm {
        flex-grow: 1;

        @include respond-to(sm) {
            padding-top: 1rem;
            padding-bottom: 0;
        }

        @include respond-to(xs) {
            padding-top: 3.2rem;
        }
    }

    .input {
        flex-grow: 1;
        margin-bottom: 2.4rem;
    }

    .button {
        z-index: 1;
        width: 20rem;
        height: 5.6rem;
        margin-left: 1.2rem;

        @include respond-to(xs) {
            width: 100%;
            height: auto;
            margin-left: 0;
        }
    }

    .formInputs {
        display: flex;
        align-items: start;
        width: 100%;

        @include respond-to(xs) {
            display: block;
        }
    }

    .formInfo {
        @include respond-to(xs) {
            margin-top: 1.6rem;
        }
    }
</style>
