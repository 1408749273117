<template>
    <div :class="$style.InfraItemInfo">
        <h3
            :class="[$style.title, $style._mobile, {[$style._noMargin]: isOnlyTitle}]"
            v-html="title"
        >
        </h3>
        <div
            v-if="!isOnlyTitle"
            :class="$style.body"
        >
            <div
                v-if="image"
                :class="$style.image"
            >
                <ImageLazy
                    :origin="image"
                    :preview="preview"
                />
            </div>
            <h3
                :class="[$style.title, $style._desktop]"
                v-html="title"
            >
            </h3>
            <div
                v-if="subtitle"
                :class="$style.subtitle"
                v-html="subtitle"
            >
            </div>
            <div :class="$style.description" v-html="description">
            </div>
        </div>
    </div>
</template>

<script>
import ImageLazy from '../common/ImageLazy';

export default {
    name: 'InfraItemInfo',
    components: { ImageLazy },
    props: {
        image: {
            type: String,
            default: '',
        },

        preview: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        subtitle: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },

        isOnlyTitle: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" module>
    .InfraItemInfo {
        padding: 2.4rem;

        @include respond-to(xs) {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 0;
        }
    }

    .body {
        @include respond-to(xs) {
            overflow-x: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    }

    .image {
        position: relative;
        height: 18.4rem;
        margin: -2.4rem -2.4rem 2.4rem;

        @include respond-to(xs) {
            overflow: hidden;
            margin: 0 0 32px;
            transform: translate3d(0, 0, 0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
    }

    .title {
        margin-bottom: .8rem;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.4;

        @include respond-to(xs) {
            max-width: 80%;
            margin-bottom: 32px;
            font-size: 28px;
            line-height: 1;
        }

        &._mobile {
            display: none;

            @include respond-to(xs) {
                display: block;
            }
        }

        &._desktop {
            @include respond-to(xs) {
                display: none;
            }
        }

        &._noMargin {
            margin-bottom: 0;
        }
    }

    .subtitle {
        font-size: 1.2rem;
        line-height: 1.64;
        color: $base-gray;
    }

    .description {
        margin-top: 1.6rem;
        font-size: 1.2rem;
        line-height: 1.64;
    }
</style>
