import creditCoreGetLowestRateAgendas from '~/queries/dvizh/creditCoreGetLowestRateAgendas.graphql';
import getLoanOffer from '~/queries/dvizh/getLoanOffer.graphql';

export const state = () => ({
    dvizhOffer: {
        agendaId: '',
        agendaName: '',
        payment: 0,
        period: 0,
        price: 0,
        rate: 0,
    },
    minInitialPayment: null,
});

export const mutations = {
    SET_DVIZH_DATA(state, newData) {
        state.dvizhOffer = newData;
    },
    SET_MIN_INITIAL_PAYMENT(state, newData) {
        state.minInitialPayment = newData;
    },
};

export const actions = {
    async loadDvizhOffer({ commit }, payload) {
        try {
            const { data } = await this.$axios.create({
                baseURL: 'https://gql.make.dvizh.io',
            })
                .$post('/gql', {
                    query: creditCoreGetLowestRateAgendas.loc.source.body,
                    variables: {
                        housingComplexUuid: 'b345c76d-97d1-44fa-ae12-1a50810d4ca8',
                        prices: [payload],
                    },
                });

            if (!data || !data?.creditCoreGetLowestRateAgendas) {
                return;
            }

            const mortgageOffer = data?.creditCoreGetLowestRateAgendas[0];            
            commit('SET_DVIZH_DATA', mortgageOffer);

            const { period, price } = mortgageOffer;
            
            const loanOffer = await this.$axios.create({
                baseURL: 'https://gql.make.dvizh.io',
            })
                .$post('/gql', {
                    query: getLoanOffer.loc.source.body,
                    variables: {
                        loanPeriod: period / 12,
                        cost: price * 100,
                        housingComplexUuid: 'b345c76d-97d1-44fa-ae12-1a50810d4ca8',
                        initialPaymentPercent: 30,
                    },
                });

            if (!loanOffer || !loanOffer?.data?.getLoanOffer) {
                return;
            }

            let minInitialPayment = null;
            let loanOfferData = loanOffer.data.getLoanOffer;

            if (loanOfferData) {
                const { agendaId } = mortgageOffer;
                const offer = loanOfferData?.find(offer => offer.id === agendaId);
                
                if (offer) {
                    minInitialPayment = offer.minInitialPayment;
                    commit('SET_MIN_INITIAL_PAYMENT', minInitialPayment);
                } else {
                    loanOfferData = loanOfferData?.map(offer => offer.minInitialPayment);

                    if (loanOfferData && loanOfferData.length > 0) {
                        minInitialPayment = Math.min.apply(Math, loanOfferData);

                        commit('SET_MIN_INITIAL_PAYMENT', minInitialPayment);
                    }
                }
            }
        } catch (e) {
            console.log('[Vuex] dvizh/loadData: Не удалось загрузить', e);
        }
    },
};

export const getters = {
    getDvizhOfferData: state => state.dvizhOffer,
    getMinInitialPaymentData: state => state.minInitialPayment,
};
