// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubscribeModal_9Ahm0{width:97rem}@media(max-width:991.98px){.SubscribeModal_9Ahm0{width:74rem}}@media(max-width:767.98px){.SubscribeModal_9Ahm0{width:100vw}}.body_QYGL-{position:relative;z-index:1;width:100%;margin-left:47.8rem}@media(max-width:991.98px){.body_QYGL-{margin-left:33.8rem}}@media(max-width:767.98px){.body_QYGL-{overflow-y:auto;height:100%;margin-left:0}}.body_QYGL-._success_IHKRw{min-height:54rem}@media(max-width:767.98px){.body_QYGL-._success_IHKRw{min-height:100%}}.body_QYGL-._success_IHKRw>form{visibility:hidden}.title_RYiJK{margin-bottom:1.2rem}.subtitle_k6-V9{margin-bottom:4rem;font-size:1.4rem;font-weight:600;color:#616161}@media(max-width:991.98px){.subtitle_k6-V9{line-height:1.4}}.form_0nEea{margin:-1.6rem 0}.input_Ayllc{margin-bottom:3.2rem}.btn_6V5zH{margin-top:4rem;-webkit-transition:opacity .3s ease;transition:opacity .3s ease}@media(max-width:767.98px){.btn_6V5zH{margin-top:3.2rem}}.policy_O3UpF{overflow:hidden;margin-top:4rem;font-size:1.2rem;font-weight:600;line-height:1.64;color:#c5c5c5}.policy_O3UpF>a{text-decoration:underline}.formSuccess_UqOEa{position:absolute;top:0;left:0;z-index:2;width:100%;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SubscribeModal": "SubscribeModal_9Ahm0",
	"body": "body_QYGL-",
	"_success": "_success_IHKRw",
	"title": "title_RYiJK",
	"subtitle": "subtitle_k6-V9",
	"form": "form_0nEea",
	"input": "input_Ayllc",
	"btn": "btn_6V5zH",
	"policy": "policy_O3UpF",
	"formSuccess": "formSuccess_UqOEa"
};
module.exports = ___CSS_LOADER_EXPORT___;
