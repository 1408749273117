<template>
    <symbol id="icon-info" viewBox="0 0 16 16">
        <path d="M7.9987 1.33301C4.3227 1.33301 1.33203 4.32367 1.33203 7.99967C1.33203 11.6757 4.3227 14.6663 7.9987 14.6663C11.6747 14.6663 14.6654 11.6757 14.6654 7.99967C14.6654 4.32367 11.6747 1.33301 7.9987 1.33301ZM7.9987 13.333C5.05803 13.333 2.66536 10.9403 2.66536 7.99967C2.66536 5.05901 5.05803 2.66634 7.9987 2.66634C10.9394 2.66634 13.332 5.05901 13.332 7.99967C13.332 10.9403 10.9394 13.333 7.9987 13.333Z"/>
        <path d="M7.33203 7.33366H8.66536V11.3337H7.33203V7.33366ZM7.33203 4.66699H8.66536V6.00033H7.33203V4.66699Z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconInfo',
};
</script>
