<template>
    <div :class="$style.MenuBlackFridayBanner" @click="onClick">
        <img
            :src="image"
            fetchpriority="high"
            alt="Чёрная пятница"
            :class="$style.image"
        >
    </div>
</template>

<script>
export default {
    name: 'MenuBlackFridayBanner',

    computed: {
        image() {
            return '/images/pages/black-friday/menu-banner.png';
        },
    },

    methods: {
        onClick() {
            this.$router.replace({
                path: '/flats',
                query: {
                    isBlackFriday: true,
                    orderBy: '-is_black_friday',
                },
            });
        },
    },
};
</script>

<style lang="scss" module>
    .MenuBlackFridayBanner {
        display: flex;
        cursor: pointer;

        .image {
            width: 100%;
        }
    }
</style>
