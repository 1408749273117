<template>
    <transition
        :class="$style.ModalSelect"
        name="content-slide-up"
        @after-enter="handleAfterEnter"
        @after-leave="$emit('after-leave')"
        @before-leave="handleBeforeLeave"
    >
        <div
            v-show="visible"
            :class="[$style.wrapper, wrapperClassList]"
        >
            <UiModalTouchHeader
                v-touch:swipe.bottom="onSwipeBottom"
                :header="data.header"
                :strong="data.strongHeader"
                @close="close"
            />

            <!-- <template v-if="data.type === 'tooltip'">
                <div
                    v-if="data.imageBanner"
                    :class="$style.imageBanner"
                >
                    <ImageLazy
                        :class="$style.image"
                        :absolute="false"
                        :preview="data.imagePreview"
                        :origin="data.imageBanner"
                    />
                </div>
                <div
                    v-if="data.title || data.description"
                    :class="$style.textBlock"
                >
                    <h3 v-if="data.title" :class="$style.title">{{ data.title }}</h3>
                    <p v-if="data.description" :class="$style.description">{{ data.description }}</p>
                </div>
            </template>

            <template v-if="data.type === 'options'">
                <UiModalOptionsList @close="close"/>
            </template>

            <template v-if="data.type === 'officeForm'">
                <OfficeFormMobile/>
            </template> -->

            <template v-if="!data.type && data.component">
                <component
                    :is="data.component"
                    :data="data.componentData"
                    @close="close"
                />
            </template>
        </div>
    </transition>
</template>

<script>

import UiModalTouchHeader from '~/components/ui/UiModalTouchHeader';
// import OfficeFormMobile from '~/components/forms/office/OfficeFormMobile';
// import UiModalOptionsList from '~/components/ui/home/UiModalOptionsList';
// import ShareOptionsMobile from '~/components/common/ShareOptionsMobile';
import ImageLazy from '~/components/common/ImageLazy';

export default {
    name: 'UiModalTouch',

    components: {
        UiModalTouchHeader,
        // OfficeFormMobile,
        // UiModalOptionsList,
        // ShareOptionsMobile,
        ImageLazy,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },

        data: {
            type: Object,
            default: () => ({}),
        },

    },

    data() {
        return {
            active: false,
        };
    },

    computed: {
        wrapperClassList() {
            return {
                [this.$style._active]: this.active,
                [this.$style._about]: this.data.about,
            };
        },
    },

    methods: {

        close() {
            this.$emit('close');
        },

        handleAfterEnter() {
            this.active = true;
        },

        handleBeforeLeave() {
            this.active = false;
        },

        onSwipeBottom() {
            this.$emit('close');
        },

    },
};
</script>

<style lang="scss" module>

    .wrapper {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 100%;
        max-width: 58.4rem;
        background-color: #fff;
        transform: translateX(-50%);
        transition: transform $homeTransition;

        &._active {
            transform: translateX(-50%) translateY(-100%);
        }

        &._about {
            max-width: 60.8rem;
            height: calc(100% - 6.1rem);

            @include respond-to(xs) {
                height: calc(100% - 1.6rem);
            }
        }
    }

    .option {
        @include old-p4;

        padding: 2rem 3.2rem;
        color: $base;
        transition: all $homeTransition;

        &._active {
            background-color: $home-accent-2;
            color: $inversed;
        }
    }

    @include respond-to(xs) {
        .header {
            padding: 2.8rem 1.6rem 2.4rem;
        }
    }

    .imageBanner {
        width: 100%;
        height: 29.4rem;
        margin: 2.4rem 0;
        padding: 0 2.4rem;

        @include respond-to(xs) {
            height: 25rem;
            margin: 1.6rem 0;
            padding: 0 1.6rem;
        }

        .image {
            overflow: hidden;
        }
    }

    .textBlock {
        margin: 2.4rem 0;
        padding: 0 2.4rem;
    }

    .title {
        @include old-h3;

        margin-bottom: 6.4rem;
    }

    .description {
        @include old-p5;
    }
</style>
