<template>
    <symbol id="icon-clock" viewBox="0 0 16 16">
        <path d="M8 14A6 6 0 118 2a6 6 0 010 12zm.6-6V5H7.4v4.2H11V8H8.6z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconClock',
};
</script>
