<template>
    <div :class="$style.modalBody">
        <div
            v-touch:swipe.bottom="closeModal"
            :class="$style.topLine"
        >
        </div>
        <div :class="$style.modalHeader">
            <div :class="$style.title">Наши инвесторы</div>
            <div :class="$style.btn">
                <Close
                    :class="$style.icon"
                    @click="closeModal"
                    @after-leave="$emit('after-leave')"
                />
            </div>
        </div>
        <div :class="$style.partnersContainer">
            <div
                v-for="(image, i) in data"
                :key="image.id"
                :class="$style.imagesContainer"
            >
                <img
                    :src="`/images/pages/home/sponsors/${image.path}`"
                    :style="{'height': i === 1? '20px' : ''}"
                    alt=""
                >
            </div>

        </div>
    </div>
</template>

<script>
import Close from '@/assets/icons/close.svg?inline';

export default {
    name: 'SponsorsModal',
    components: {
        Close,
    },

    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            visible: true,
        };
    },

    methods: {
        closeModal() {
            this.$emit('after-leave');
            this.$emit('close');
            this.visible = false;
        },
    },
};
</script>

<style lang="scss" module>
    .modalBody {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 85%;
        padding: 0 24px 24px 24px;
        border-radius: 1.6rem;
        background-color: #fff;
    }

    .topLine {
        width: 40px;
        height: 10px;
        margin-top: 8px;
        margin-bottom: 16px;
        border-radius: 2px;
        border-top: 4px solid #dfe0e2;
    }

    .modalHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 2.4rem;
        border-bottom: 1px solid #dfe0e2;
    }

    .title {
        font-size: 16px;
        line-height: 1;
        letter-spacing: -.04em;
        color: #8b8d98;
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background-color: #f4f4f5;

        .icon {
            width: 2rem;
            height: 2rem;

            :global(path) {
                stroke: #2f2f2f;
            }
        }
    }

    .partnersContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 28px;

        @include respond-to(xs) {
            grid-template-columns: repeat(2, 1fr);
            justify-content: space-around;
        }

        .imagesContainer {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: auto;
                height: 30px;
                mix-blend-mode: darken;
                filter: grayscale(100%);
                cursor: pointer;
            }
        }
    }

</style>
