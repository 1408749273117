<template>
    <div
        class="radio-btns"
        :class="{ 'radio-btns_mobile': !$device.isDesktop }"
    >
        <TheRadio
            :value="value"
            label="Сейчас"
            class="radio-btns__radio"
            @click="$emit('input', !value)"
        />

        <TheRadio
            :value="!value"
            label="Точное время"
            class="radio-btns__radio"
            @click="$emit('input', !value)"
        />
    </div>
</template>

<script>
import TheRadio from '~/components/landing/components/ui/Radio';

export default {
    name: 'RadioBtns',

    components: {
        TheRadio,
    },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
    .radio-btns {
        display: flex;
        align-items: center;

        &__radio {
            margin-right: 3rem;
        }
    }

    .radio-btns_mobile {
        .radio-btns {
            &__radio {
                margin-right: 30px;
            }
        }
    }
</style>
