// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalHomeAdvantagesVideo_aL8yA{width:100vw;height:100vh}.headerWrapper_65OMG{border-bottom:.1rem solid #292a2c;background-color:#18191c}.header_HD-Jm{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;height:7.2rem;padding-top:1.6rem;padding-bottom:1.6rem}.headerTitle_TwmgV{font-size:2rem;font-weight:700;line-height:2.6rem;letter-spacing:normal;color:#fff}.headerPagination_IAVeC{font-size:1.8rem;line-height:1.32;letter-spacing:normal;color:#4e4e51}.body_sJdch{position:relative;width:100%;height:calc(100vh - 7.3rem);background-color:#18191c}.slide_8kC3Z,.slider_9RpHp{width:100%;height:100%}.buttons_ciPOi{top:50%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:calc(100% - 12.8rem);-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.buttons_ciPOi,.tabs_v\\+3Lj{position:absolute;left:50%}.tabs_v\\+3Lj{bottom:2.4rem;-webkit-transform:translateX(-50%);transform:translateX(-50%)}@media(max-width:767.98px){.vjs-control-bar{padding-right:16px;padding-left:16px}}.video_iy3vP::-webkit-media-controls-fullscreen-button{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalHomeAdvantagesVideo": "ModalHomeAdvantagesVideo_aL8yA",
	"headerWrapper": "headerWrapper_65OMG",
	"header": "header_HD-Jm",
	"headerTitle": "headerTitle_TwmgV",
	"headerPagination": "headerPagination_IAVeC",
	"body": "body_sJdch",
	"slide": "slide_8kC3Z",
	"slider": "slider_9RpHp",
	"buttons": "buttons_ciPOi",
	"tabs": "tabs_v+3Lj",
	"video": "video_iy3vP"
};
module.exports = ___CSS_LOADER_EXPORT___;
