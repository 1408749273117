<template>
    <transition
        name="expand"
        @enter="enter"
        @after-enter="afterEnter"
        @leave="leave"
        @after-leave="afterLeave"
    >
        <slot :opened="opened"></slot>
    </transition>
</template>

<script>
export default {
    name: 'ExpandBlock',

    data() {
        return {
            opened: false,
        };
    },

    methods: {
        enter(element) {
            const width = getComputedStyle(element).width;

            element.style.width = width;
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';

            const height = getComputedStyle(element).height;

            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;

            // Force repaint to make sure the
            // animation is triggered correctly.
            // eslint-disable-next-line no-unused-expressions
            getComputedStyle(element).height;

            // Trigger the animation.
            // We use `setTimeout` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            setTimeout(() => {
                element.style.height = height;
            });
        },

        afterEnter(element) {
            element.style.height = 'auto';
            this.opened = true;
            this.$emit('after');
        },

        leave(element) {
            element.style.height = getComputedStyle(element).height;

            // Force repaint to make sure the
            // animation is triggered correctly.
            // eslint-disable-next-line no-unused-expressions
            getComputedStyle(element).height;

            setTimeout(() => {
                element.style.height = 0;
                this.$emit('after');
            });
        },

        afterLeave() {
            this.opened = false;
        },
    },
};
</script>
