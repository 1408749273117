<template>
    <div
        :class="[
            $style.menu,
            { [$style.menu_opened]: isOpened }
        ]"
        @mouseenter="$emit('mouseenter')"
        @mouseleave="$emit('mouseleave')"
    >
        <div
            :class="$style.menu__wrap"
            class="container-home"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderMenu',

    props: {
        isOpened: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" module>
    .menu {
        position: absolute;
        top: -48rem;
        overflow: hidden;
        width: 100%;
        height: 48rem;
        padding: 2.8rem 0;
        background-color: $inversed;
        transition: 1s ease;

        &__wrap {
            height: 100%;
        }

        &_opened {
            top: 100%;
        }
    }
</style>
