// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".label_HnwfR{margin-right:.8rem}.label_HnwfR:last-child{margin-right:0}.label_HnwfR._withTooltip_zwRzM{position:relative}.tooltip_EpRTz{display:inline-block}.singleTag_tm-N7{background-color:#757d88;color:#fff}.hiddenLabels_XBLDk{position:relative;padding:1.6rem;border:1px solid #dfe0e2;background-color:#f3f3f3}.hiddenLabels_XBLDk:before{left:calc(50% - .85rem);border-top:1rem solid #dfe0e2;border-right:.85rem solid transparent;border-left:.85rem solid transparent}.hiddenLabels_XBLDk:after,.hiddenLabels_XBLDk:before{content:\"\";position:absolute;top:100%;display:block}.hiddenLabels_XBLDk:after{left:calc(50% - .65rem);border-top:.8rem solid #f3f3f3;border-right:.65rem solid transparent;border-left:.65rem solid transparent}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "label_HnwfR",
	"_withTooltip": "_withTooltip_zwRzM",
	"tooltip": "tooltip_EpRTz",
	"singleTag": "singleTag_tm-N7",
	"hiddenLabels": "hiddenLabels_XBLDk"
};
module.exports = ___CSS_LOADER_EXPORT___;
