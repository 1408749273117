// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormPolicy_3Aclp._line_KW-OI .wrapper_r2c7Q{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap}.FormPolicy_3Aclp._dark_prVha .label_mEtqO a{font-weight:700;color:#cfcfcf}.label_mEtqO{display:inline-block;font-size:1.4rem;font-weight:300;line-height:124%;color:#a0a0a0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.label_mEtqO a{color:#2f2f2f}.label_mEtqO a:hover{text-decoration:underline}.label_mEtqO span{color:#e04b4b}.wrapper_r2c7Q{margin:-.6rem -1.2rem}.checkbox_IWhwn{padding:.6rem 1.2rem;text-align:left}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormPolicy": "FormPolicy_3Aclp",
	"_line": "_line_KW-OI",
	"wrapper": "wrapper_r2c7Q",
	"_dark": "_dark_prVha",
	"label": "label_mEtqO",
	"checkbox": "checkbox_IWhwn"
};
module.exports = ___CSS_LOADER_EXPORT___;
