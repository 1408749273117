const webpIsSupported = async () => {
    if (!process.client) {
        return;
    }

    return new Promise(function(resolve) {
        const image = new Image();
        image.onerror = function() {
            return resolve(false);
        };
        image.onload = function() {
            return resolve(image.width === 1);
        };
        image.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
    }).catch(function() {
        return false;
    });
};


export default async (ctx, inject) => {
    const isWebp = await webpIsSupported() || false;

    inject('isWebpSup', isWebp);
    ctx.$isWebpSup = isWebp;
};
