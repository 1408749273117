<template>
    <component
        :is="component"
        v-bind="getAttrs"
        :class="[$style.LogoIda, classes]"
        aria-label="Logo Ida"
    >
        <div :class="$style.wrapper" @mouseenter="onMouseenter">
            <div :class="$style.inner">
                <svg
                    viewBox="0 0 150 42"
                    xmlns="http://www.w3.org/2000/svg"
                    :class="$style.logoIcon"
                >
                    <path 
                        d="M11.6662 0.44849H15.3621V16.3699H11.6662V5.70222L3.6959 16.3699H0V0.44849H3.6959V11.1482L11.6662 0.44849Z" 
                        fill="currentColor"
                    />
                    
                    <path 
                        d="M34.0862 12.846H36.8179V16.3699H33.2506H20.9738H17.4064V12.846H17.7921C18.0492 12.846 18.3063 12.8353 18.5634 12.814C18.8419 12.7713 19.099 12.6752 19.3347 12.5257C19.5918 12.3548 19.8061 12.0879 19.9775 11.7248C20.1489 11.3404 20.256 10.8171 20.2989 10.1551C20.3203 9.77066 20.331 9.19403 20.331 8.42519C20.3524 7.65635 20.3631 6.81277 20.3631 5.89443C20.3846 4.9761 20.3953 4.0364 20.3953 3.07536C20.4167 2.09295 20.4274 1.21733 20.4274 0.44849H34.0862V12.846ZM30.3903 12.846V4.00437H23.9626C23.9412 5.05084 23.9198 6.108 23.8983 7.17583C23.8983 8.2223 23.8769 9.1086 23.8341 9.83473C23.7912 10.4541 23.7269 11.02 23.6412 11.5326C23.5555 12.0238 23.4484 12.4616 23.3199 12.846H30.3903Z" 
                        fill="currentColor"
                    /> 
                    
                    <path 
                        d="M50.8631 0.44849H54.5269V16.3699H50.8631V14.0633H50.7024C50.1668 14.8749 49.4383 15.537 48.517 16.0495C47.6172 16.5621 46.5352 16.8183 45.2711 16.8183C44.1569 16.8397 43.1178 16.6475 42.1536 16.2417C41.1895 15.8359 40.3539 15.27 39.6469 14.5439C38.9398 13.7964 38.3828 12.8994 37.9757 11.8529C37.59 10.8065 37.3972 9.66388 37.3972 8.42519C37.3972 7.18651 37.59 6.05461 37.9757 5.02949C38.3613 3.98301 38.897 3.08603 39.5826 2.33855C40.2896 1.59107 41.1252 1.01444 42.0894 0.608665C43.0535 0.202888 44.1141 0 45.2711 0C46.5137 0.0213564 47.5957 0.277636 48.517 0.768839C49.4383 1.26004 50.1668 1.89006 50.7024 2.6589H50.8631V0.44849ZM46.0102 13.2945C46.7387 13.2945 47.3922 13.1664 47.9707 12.9101C48.5706 12.6538 49.0741 12.3014 49.4812 11.8529C49.9097 11.4044 50.2418 10.8812 50.4775 10.2832C50.7131 9.68523 50.831 9.05521 50.831 8.39316C50.831 7.70974 50.7131 7.06905 50.4775 6.47106C50.2418 5.87307 49.9097 5.36051 49.4812 4.93338C49.0741 4.48489 48.5706 4.13251 47.9707 3.87623C47.3922 3.61995 46.7387 3.49181 46.0102 3.49181C45.2818 3.49181 44.6176 3.61995 44.0177 3.87623C43.4178 4.13251 42.9035 4.48489 42.475 4.93338C42.0465 5.36051 41.7144 5.87307 41.4787 6.47106C41.2431 7.06905 41.1252 7.70974 41.1252 8.39316C41.1252 9.07657 41.2431 9.71727 41.4787 10.3153C41.7144 10.9132 42.0465 11.4365 42.475 11.885C42.9035 12.3121 43.4178 12.6538 44.0177 12.9101C44.6176 13.1664 45.2818 13.2945 46.0102 13.2945Z" 
                        fill="currentColor"
                    />
                    
                    <path 
                        d="M15.0407 37.593H11.3448V25.2275H3.6959V37.593H0V21.6716H15.0407V37.593Z" 
                        fill="currentColor"
                    />
                    
                    <path 
                        d="M27.7617 21.2232C28.9187 21.2232 29.9793 21.426 30.9434 21.8318C31.9076 22.2376 32.7324 22.8142 33.4181 23.5617C34.1251 24.3092 34.6715 25.2062 35.0571 26.2526C35.4428 27.2778 35.6356 28.4097 35.6356 29.6483C35.6356 30.887 35.4321 32.0296 35.025 33.0761C34.6393 34.1226 34.093 35.0195 33.3859 35.767C32.6789 36.4931 31.8433 37.0591 30.8791 37.4649C29.9364 37.8706 28.8973 38.0629 27.7617 38.0415C26.5405 38.0415 25.4585 37.7852 24.5157 37.2727C23.5945 36.7601 22.866 36.098 22.3303 35.2865H22.1697V42H18.5059V21.6716H22.1697V23.8821H22.3303C22.8874 23.1132 23.6266 22.4832 24.5479 21.992C25.4692 21.5008 26.5405 21.2445 27.7617 21.2232ZM27.0225 34.5177C27.751 34.5177 28.4152 34.3895 29.0151 34.1332C29.615 33.877 30.1292 33.5353 30.5577 33.1081C30.9863 32.6596 31.3184 32.1364 31.554 31.5384C31.7897 30.9404 31.9076 30.2997 31.9076 29.6163C31.9076 28.9329 31.7897 28.2922 31.554 27.6942C31.3184 27.0962 30.9863 26.5837 30.5577 26.1565C30.1292 25.708 29.615 25.3557 29.0151 25.0994C28.4152 24.8431 27.751 24.715 27.0225 24.715C26.2941 24.715 25.6299 24.8431 25.03 25.0994C24.4515 25.3557 23.948 25.708 23.5195 26.1565C23.1124 26.5837 22.791 27.0962 22.5553 27.6942C22.3196 28.2922 22.2018 28.9329 22.2018 29.6163C22.2018 30.2784 22.3196 30.9084 22.5553 31.5064C22.791 32.1044 23.1124 32.6276 23.5195 33.0761C23.948 33.5246 24.4515 33.877 25.03 34.1332C25.6299 34.3895 26.2941 34.5177 27.0225 34.5177Z" 
                        fill="currentColor"
                    />
                    
                    <path 
                        d="M45.8219 38.0415C44.515 38.0415 43.3259 37.8279 42.2546 37.4008C41.2047 36.9523 40.3049 36.3543 39.555 35.6068C38.8051 34.838 38.2159 33.941 37.7874 32.9159C37.3803 31.8908 37.1767 30.7909 37.1767 29.6163C37.1767 28.4417 37.3803 27.3418 37.7874 26.3167C38.1944 25.2916 38.7729 24.4053 39.5228 23.6578C40.2941 22.9103 41.2047 22.323 42.2546 21.8959C43.3259 21.4474 44.515 21.2232 45.8219 21.2232C47.1075 21.2232 48.2752 21.4474 49.325 21.8959C50.3749 22.323 51.264 22.9103 51.9925 23.6578C52.7424 24.4053 53.3209 25.2916 53.728 26.3167C54.135 27.3418 54.3386 28.4417 54.3386 29.6163C54.3386 30.7909 54.135 31.8908 53.728 32.9159C53.3209 33.941 52.7424 34.838 51.9925 35.6068C51.2426 36.3543 50.3427 36.9523 49.2929 37.4008C48.2644 37.8279 47.1075 38.0415 45.8219 38.0415ZM45.8219 34.5177C46.5504 34.5177 47.2039 34.3895 47.7824 34.1332C48.3823 33.877 48.8858 33.5246 49.2929 33.0761C49.7214 32.6276 50.0535 32.115 50.2892 31.5384C50.5248 30.9404 50.6427 30.3104 50.6427 29.6483C50.6427 28.9649 50.5248 28.3349 50.2892 27.7583C50.0535 27.1603 49.7214 26.6477 49.2929 26.2206C48.8858 25.7721 48.3823 25.4197 47.7824 25.1635C47.2039 24.9072 46.5504 24.779 45.8219 24.779C45.072 24.779 44.3971 24.9072 43.7972 25.1635C43.1973 25.4197 42.6831 25.7721 42.2546 26.2206C41.8261 26.6477 41.494 27.1603 41.2583 27.7583C41.0226 28.3349 40.9048 28.9649 40.9048 29.6483C40.9048 30.3104 41.0226 30.9404 41.2583 31.5384C41.494 32.1364 41.8261 32.6596 42.2546 33.1081C42.6831 33.5353 43.1973 33.877 43.7972 34.1332C44.4186 34.3895 45.0935 34.5177 45.8219 34.5177Z" 
                        fill="currentColor"
                    />
                    
                    <path 
                        d="M71.757 34.0692H74.4888V37.593H70.9214H58.6446H55.0773V34.0692H55.4629C55.72 34.0692 55.9771 34.0585 56.2342 34.0371C56.5128 33.9944 56.7699 33.8983 57.0056 33.7488C57.2627 33.578 57.4769 33.311 57.6483 32.9479C57.8197 32.5635 57.9269 32.0403 57.9697 31.3782C57.9911 30.9938 58.0018 30.4172 58.0018 29.6483C58.0233 28.8795 58.034 28.0359 58.034 27.1176C58.0554 26.1992 58.0661 25.2596 58.0661 24.2985C58.0875 23.3161 58.0983 22.4405 58.0983 21.6716H71.757V34.0692ZM68.0611 34.0692V25.2275H61.6335C61.612 26.274 61.5906 27.3312 61.5692 28.399C61.5692 29.4455 61.5478 30.3318 61.5049 31.0579C61.4621 31.6772 61.3978 32.2432 61.3121 32.7557C61.2264 33.2469 61.1193 33.6847 60.9907 34.0692H68.0611Z" 
                        fill="currentColor"
                    /> 
                    
                    <path 
                        d="M94.9159 27.9505C95.9658 28.0359 96.8014 28.399 97.4227 29.0397C98.0441 29.6804 98.3547 30.5774 98.3547 31.7306V37.593H94.6588V32.5956C94.6588 32.1684 94.5303 31.8374 94.2732 31.6025C94.0161 31.3462 93.6626 31.2181 93.2126 31.2181H89.1311V37.593H85.4351V31.2181H81.3214C80.3787 31.2181 79.9074 31.6772 79.9074 32.5956V37.593H76.2114V31.7306C76.2114 30.5774 76.5114 29.6804 77.1113 29.0397C77.7327 28.399 78.5683 28.0359 79.6181 27.9505L75.9222 21.6716H79.9074L83.539 27.9185H85.4351V21.6716H89.1311V27.9185H91.0272L94.6588 21.6716H98.6118L94.9159 27.9505Z" 
                        fill="currentColor"
                    />
                    
                    <path 
                        d="M108.519 21.2232C109.848 21.2232 111.037 21.4688 112.087 21.96C113.158 22.4512 114.047 23.1346 114.754 24.0102C115.483 24.8645 116.018 25.8896 116.361 27.0855C116.704 28.2602 116.811 29.5309 116.682 30.8977H104.052C104.202 31.9228 104.673 32.7984 105.466 33.5246C106.28 34.2293 107.319 34.6031 108.584 34.6458C109.483 34.6672 110.298 34.4963 111.026 34.1332C111.754 33.7488 112.279 33.2363 112.601 32.5956H116.425C116.168 33.4498 115.782 34.2187 115.268 34.9021C114.775 35.5855 114.186 36.1621 113.501 36.632C112.815 37.0805 112.044 37.4222 111.187 37.6571C110.351 37.9134 109.462 38.0415 108.519 38.0415C107.212 38.0415 106.045 37.8173 105.016 37.3688C103.988 36.9203 103.12 36.3116 102.413 35.5428C101.706 34.7739 101.16 33.8876 100.774 32.8839C100.41 31.8588 100.228 30.7802 100.228 29.6483C100.228 28.4737 100.431 27.3739 100.838 26.3487C101.245 25.3236 101.813 24.4373 102.542 23.6898C103.27 22.921 104.138 22.323 105.145 21.8959C106.173 21.4474 107.298 21.2232 108.519 21.2232ZM108.455 24.6189C107.341 24.6189 106.398 24.9178 105.627 25.5158C104.855 26.1138 104.352 26.904 104.116 27.8864H112.858C112.622 26.8827 112.108 26.0925 111.315 25.5158C110.544 24.9178 109.591 24.6189 108.455 24.6189Z" 
                        fill="currentColor"
                    />
                    
                    <path 
                        d="M130.543 27.9505C131.571 28.0573 132.385 28.431 132.985 29.0717C133.585 29.6911 133.885 30.5774 133.885 31.7306V37.593H130.189V32.5956C130.189 32.1684 130.06 31.8374 129.803 31.6025C129.546 31.3462 129.193 31.2181 128.743 31.2181H122.797V37.593H119.101V21.6716H122.797V27.9185H126.557L130.125 21.6716H134.142L130.543 27.9505Z" 
                        fill="currentColor"
                    />
                    
                    <path 
                        d="M150 25.2275H144.569V37.593H140.873V25.2275H135.409V21.6716H150V25.2275Z" 
                        fill="currentColor"
                    />
                </svg>

                <div :class="$style.rectangleTop">
                    <p ref="rectangleTop"></p>
                </div>
                
                <div :class="$style.rectangleBottom">
                    <p ref="rectangleBottom"></p>
                </div>
            </div>
            
            <p
                class="l3"
                :class="$style.text"
                v-html="text"
            >
            </p>
        </div>
    </component>
</template>

<script>
import gsap from 'gsap';

export default {
    name: 'LogoIda',

    props: {
        color: {
            type: String,
            default: 'gray',
            validator: value => [
                'black',
                'gray',
                'white',
            ].includes(value),
        },

        text: {
            type: String,
            default: 'лучшие цифровые <br /> продукты для недвижимости',
        },

        isTouch: {
            type: Boolean,
            default: false,
            required: true,
        },

        href: {
            type: String,
            default: 'https://idaproject.com/',
        },

        to: {
            type: String,
            default: '',
        },

        target: {
            type: String,
            default: '_blank',
        },
    },

    data() {
        return {
            isHoverLogo: false,
        };
    },

    computed: {
        classes() {
            return [{
                [this.$style[`_${this.color}`]]: this.color,
            }];
        },

        component() {
            return this.to ? 'nuxt-link' : 'a';
        },

        getAttrs() {
            const attrs = {};

            if (this.href) {
                attrs.href = this.href;
                attrs.target = this.target;
            } else if (this.to) {
                attrs.to = this.to;
            }

            return attrs;
        },
    },

    methods: {
        onMouseenter() {
            if (this.isHoverLogo || this.isTouch) {
                return;
            }

            this.isHoverLogo = true;

            const animationElements = [this.$refs.rectangleTop, this.$refs.rectangleBottom];
            this.initAnimation(animationElements);
        },

        finishHoverLogo() {
            this.isHoverLogo = false;
        },

        initAnimation(animationElements) {
            if (!animationElements.length) {
                return;
            }

            const tl = gsap.timeline({
                onComplete: () => {
                    this.finishHoverLogo();
                },
            });

            tl
                .to(animationElements, {
                    width: '100%',
                    left: 0,
                    ease: 'power1.inOut',
                    duration: 0.6,
                    stagger: 0,
                })
                .to(animationElements, {
                    width: 0,
                    left: 'unset',
                    right: 0,
                    ease: 'power2.inOut',
                    duration: 0.8,
                    delay: 1,
                    stagger: 0,
                });
        },
    },
};
</script>

<style lang="scss" module>
    .LogoIda {
        overflow: hidden;
        cursor: pointer;

        &._black {
            color: $base-900;
        }

        &._gray {
            color: $base-500;
        }

        &._white {
            color: $base-0;
        }
    }

    .wrapper {
        width: 100%;
    }

    .inner {
        position: relative;
        overflow: hidden;
        width: 15rem;
        height: 4.4rem;
    }

    .rectangleTop,
    .rectangleBottom {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: 200% 100%;
        transition: background-position .6s ease, width .6s ease;

        & > p {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 0;
            height: 100%;
            background-color: $base-500;
        }
    }

    .rectangleTop {
        width: 39%;
        height: 50%;
        background-position: 100% 0;
    }

    .rectangleBottom {
        top: 50%;
        width: 100%;
        height: calc(50% + 1px);
        background-position: 100% 0;
    }

    .logoIcon {
        width: 100%;
        height: 100%;
        transform: translate3d(0, 0, 0);
    }

    .text {
        margin-top: 1.6rem;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 124%;
    }
</style>
