// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MediaSlider_LuDc3{position:relative}.btn_8KfxK{position:absolute;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}.btn_8KfxK._prev_Q4QDI{left:2.4rem}.btn_8KfxK._next_z1ZX3{right:2.4rem}.btn_8KfxK._reverse_VbKvt{-webkit-transform:translateY(-50%) rotate(180deg);transform:translateY(-50%) rotate(180deg)}.slide_u3JoB,.slider_Cr2o6{width:100%;height:100%}.image_\\+A7ti{background-position:50%;background-repeat:no-repeat;background-size:cover}.image_\\+A7ti,.image_\\+A7ti:before{position:absolute;top:0;left:0;width:100%;height:100%}.image_\\+A7ti:before{content:\"\";display:block;background-color:rgba(0,0,0,.1)}.slide_u3JoB{position:relative}.slide_u3JoB:before{content:\"\";display:block;width:100%;padding-top:59.5238095238%}.slide_u3JoB>.aspect-content_eJGze{top:0;height:100%}.pagination_AgQ-5,.slide_u3JoB>.aspect-content_eJGze{position:absolute;left:0;width:100%}.pagination_AgQ-5{bottom:2rem;z-index:2;text-align:center}.dot_ywANq{display:inline-block;width:.4rem;height:.4rem;margin-right:.4rem;margin-left:.4rem;border-radius:50%;background-color:#fff;opacity:.44;-webkit-transition:all .3s;transition:all .3s}.dot_ywANq._active_WEiby{opacity:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MediaSlider": "MediaSlider_LuDc3",
	"btn": "btn_8KfxK",
	"_prev": "_prev_Q4QDI",
	"_next": "_next_z1ZX3",
	"_reverse": "_reverse_VbKvt",
	"slide": "slide_u3JoB",
	"slider": "slider_Cr2o6",
	"image": "image_+A7ti",
	"aspect-content": "aspect-content_eJGze",
	"pagination": "pagination_AgQ-5",
	"dot": "dot_ywANq",
	"_active": "_active_WEiby"
};
module.exports = ___CSS_LOADER_EXPORT___;
