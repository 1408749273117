<template>
    <div
        id="app"
        :class="$style.DigitalLayout"
    >
        <DigitalHeader />
        <main :class="$style.main">
            <nuxt
                keep-alive
                :keep-alive-props="{ max: 20 }"
            />
        </main>
        <TheModalMobileSlideUp />
        <UniversalModal />
        <FullScreenModal />
        <TheModal />
        <TheSprite />
        <TheModalNew :style="{zIndex: 100}" />
        <PageTransition />
        <div
            :class="[
                $style.overlay,
                {[$style._shown]: isOverlayShown},
            ]"
            @click="onOveylayClick"
        />
    </div>
</template>

<script>
// utils
import { calcVhInit } from '~/assets/js/utils';

// components
import DigitalHeader from '~/components/digital/DigitalHeader.vue';
import TheModalMobileSlideUp from '~/components/layout/TheModalMobileSlideUp.vue';
import UniversalModal from '~/components/common/modals/universal-modal/UniversalModal.vue';
import FullScreenModal from '~/components/common/modals/FullScreenModal.vue';
import { mapActions } from 'vuex';
import PageTransition from '~/components/common/PageTransition.vue';
import TheModalNew from '~/components/layout/TheModalNew.vue';
import TheSprite from '~/components/layout/TheSprite.vue';
import TheModal from '~/components/layout/TheModal.vue';

export default {
    name: 'DigitalLayout',
    components: {
        TheModal,
        TheSprite,
        TheModalNew,
        PageTransition,
        FullScreenModal,
        UniversalModal,
        TheModalMobileSlideUp,
        DigitalHeader,
    },

    data() {
        return {
            isOverlayShown: false,
        };
    },

    mounted() {
        this.$root.$on('showLayoutOverlay', this.showOverlay);
        this.$root.$on('hideLayoutOverlay', this.hideOverlay);
        this.$nextTick(() => {
            calcVhInit();
            const touchSupported = 'ontouchstart' in window ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0;

            this.setTouchSupport(touchSupported);
        });
    },

    beforeDestroy() {
        this.$root.$off('showLayoutOverlay', this.showOverlay);
        this.$root.$off('hideLayoutOverlay', this.hideOverlay);
    },

    methods: {
        ...mapActions({
            setTouchSupport: 'device/changeIsTouch',
        }),

        showOverlay() {
            this.isOverlayShown = true;
        },

        hideOverlay() {
            this.isOverlayShown = false;
        },

        onOveylayClick() {
            this.$root.$emit('onLayoutOverlayClick');
        },
    },
};
</script>

<style
    lang="scss"
    module
>
    .DigitalLayout {
        position: relative;
        min-height: 100vh;
        /* stylelint-disable */
        min-height: 100dvh;
        /* stylelint-enable */
    }

    .main {
        flex-grow: 1;
        width: 100%;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 16;
        visibility: hidden;
        width: 100%;
        height: 100%;
        background-color: $home-base-1;
        opacity: 0;
        transition: 1s ease;

        &._shown {
            visibility: visible;
            opacity: .64;
        }
    }
</style>
