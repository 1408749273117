import allFlats from '~/queries/flats/allFlats.graphql';
import allFlatsSpecs from '~/queries/flats/allFlatsSpecs.graphql';
import allFlatsFacets from '~/queries/flats/allFlatsFacets.graphql';
import allBannersRealty from '~/queries/flats/allBannersRealty.graphql';
import allFlatsScenarios from '~/queries/flats/allFlatsScenarios.graphql';
import allFlatsUrbanBlocks from '~/queries/flats/allFlatsUrbanBlocks.graphql';

import { DEFAULT_FILTERS, SPECS_ADDITIONAL_INFORMATION } from '~/assets/js/constants.js';

import { getNodeArray } from '~/assets/js/utils.js';
import { excludeQuery, updateQueryUseHistory } from '~/assets/js/utils/queryUtils.js';
import { doSort } from '~/assets/js/utils/flatsUtils.js';
import { prepareDataForRequest, parseDataFromResponse } from '~/assets/js/utils/apiUtils.js';

export default {
    // axios запрос по текущему фильтру и по доп. параметрам payload (newTypeFlat, first, after)
    async fetchObjects({ state }, payload) {
        const { data } = await this.$axios.$post('/graphql/', {
            query: allFlats.loc.source.body,
            variables: {
                ...prepareDataForRequest(state.filters),
                ...prepareDataForRequest(payload),
            },
        }, { progress: false });

        return data.allFlats;
    },

    // загружаем квартиры, делаем нужные коммиты, возвращаем квартиры для сортировки
    async loadFlats({ state, getters, commit, dispatch }) {
        const response = await dispatch('fetchObjects', {
            newTypeFlat: ['209'],
            first: getters.flatsPerPage,
            after: state.flatsEndCursor,
        });

        const flats = response.edges.map(item => item.node);
        commit('SET_FLATS_LOADED_COUNT', flats.length);
        commit('SET_FLATS_TOTAL_COUNT', response.totalCount);
        commit('SET_FLATS_END_CURSOR', response.pageInfo.endCursor);

        return flats;
    },

    // загружаем ситихаусы, делаем нужные коммиты, возвращаем ситихаусы для сортировки
    async loadCityHouses({ state, getters, commit, dispatch }) {
        const response = await dispatch('fetchObjects', {
            newTypeFlat: ['210'],
            first: getters.cityHousesPerPage,
            after: state.cityHousesEndCursor,
        });

        const cityHouses = response.edges.map(item => item.node);
        commit('SET_CITY_HOUSES_LOADED_COUNT', cityHouses.length);
        commit('SET_CITY_HOUSES_TOTAL_COUNT', response.totalCount);
        commit('SET_CITY_HOUSES_END_CURSOR', response.pageInfo.endCursor);

        return cityHouses;
    },

    // загружаем пентхаусы, делаем нужные коммиты, возвращаем пентхаусы для сортировки
    async loadPentHouses({ state, getters, commit, dispatch }) {
        const response = await dispatch('fetchObjects', {
            newTypeFlat: ['211'],
            first: getters.pentHousesPerPage,
            after: state.pentHousesEndCursor,
        });

        const pentHouses = response.edges.map(item => item.node);
        commit('SET_PENT_HOUSES_LOADED_COUNT', pentHouses.length);
        commit('SET_PENT_HOUSES_TOTAL_COUNT', response.totalCount);
        commit('SET_PENT_HOUSES_END_CURSOR', response.pageInfo.endCursor);

        return pentHouses;
    },

    async loadCityUnits({ state, getters, commit, dispatch }) {
        const response = await dispatch('fetchObjects', {
            newTypeFlat: ['212'],
            first: getters.cityUnitsPerPage,
            after: state.cityUnitsEndCursor,
        });

        const cityUnits = response.edges.map(item => item.node);
        commit('SET_CITY_UNITS_LOADED_COUNT', cityUnits.length);
        commit('SET_CITY_UNITS_TOTAL_COUNT', response.totalCount);
        commit('SET_CITY_UNITS_END_CURSOR', response.pageInfo.endCursor);

        return cityUnits;
    },

    // загружаем таунхаусы, делаем нужные коммиты, возвращаем таунхаусы для сортировки
    async loadTownHouses({ state, getters, commit, dispatch }) {
        const response = await dispatch('fetchObjects', {
            newTypeFlat: ['280'],
            first: getters.townHousesPerPage,
            after: state.townHousesEndCursor,
        });

        const townHouses = response.edges.map(item => item.node);
        commit('SET_TOWN_HOUSES_LOADED_COUNT', townHouses.length);
        commit('SET_TOWN_HOUSES_TOTAL_COUNT', response.totalCount);
        commit('SET_TOWN_HOUSES_END_CURSOR', response.pageInfo.endCursor);

        return townHouses;
    },

    // формируем perPage для каждого типа объектов (но не квартир) для каждой страницы
    async preLoadObject({ state, commit, dispatch }) {
        const hasPentHouses = !state.filters.newTypeFlat.length || state.filters.newTypeFlat.includes('211');
        const hasCityHouses = !state.filters.newTypeFlat.length || state.filters.newTypeFlat.includes('210');
        const hasTownHouses = !state.filters.newTypeFlat.length || state.filters.newTypeFlat.includes('280');
        const hasCityUnits = !state.filters.newTypeFlat.length || state.filters.newTypeFlat.includes('212');

        const [pentHouses, cityHouses, townHouses, cityUnits] = await Promise.all([
            hasPentHouses ? dispatch('fetchObjects', { newTypeFlat: ['211'], first: 0 }) : null,
            hasCityHouses ? dispatch('fetchObjects', { newTypeFlat: ['210'], first: 0 }) : null,
            hasTownHouses ? dispatch('fetchObjects', { newTypeFlat: ['280'], first: 0 }) : null,
            hasCityUnits ? dispatch('fetchObjects', { newTypeFlat: ['212'], first: 0 }) : null,
        ]);

        const arrayOfPentHouses = [];
        const arrayOfCityHouses = [];
        const arrayOfTownHouses = [];
        const arrayOfCityUnits = [];

        if (hasPentHouses) {
            for (let i = 0; i < pentHouses.totalCount; i += 1) {
                arrayOfPentHouses.push('pentHouse');
            }
        }
        if (hasCityHouses) {
            for (let i = 0; i < cityHouses.totalCount; i += 1) {
                arrayOfCityHouses.push('cityHouse');
            }
        }
        if (hasTownHouses) {
            for (let i = 0; i < townHouses.totalCount; i += 1) {
                arrayOfTownHouses.push('townHouse');
            }
        }
        if (hasCityUnits) {
            for (let i = 0; i < cityUnits.totalCount; i += 1) {
                arrayOfCityUnits.push('cityUnit');
            }
        }

        const sortedArrayTypes = [...arrayOfPentHouses, ...arrayOfTownHouses, ...arrayOfCityHouses, ...arrayOfCityUnits];

        const arrayOfPages = [];
        for (let i = 0; i < sortedArrayTypes.length; i += state.otherTypesPerPage) {
            arrayOfPages.push(sortedArrayTypes.slice(i, i + state.otherTypesPerPage));
        }

        const result = {
            pentHouses: [],
            cityHouses: [],
            townHouses: [],
            cityUnits: [],
        };

        for (let i = 0; i < arrayOfPages.length; i += 1) {
            for (let j = 0; j < arrayOfPages[i].length; j += 1) {
                if (arrayOfPages[i][j] === 'pentHouse') {
                    if (!result.pentHouses[i]) {
                        result.pentHouses[i] = 0;
                    }
                    result.pentHouses[i] += 1;
                }
                if (arrayOfPages[i][j] === 'cityHouse') {
                    if (!result.cityHouses[i]) {
                        result.cityHouses[i] = 0;
                    }
                    result.cityHouses[i] += 1;
                }
                if (arrayOfPages[i][j] === 'townHouse') {
                    if (!result.townHouses[i]) {
                        result.townHouses[i] = 0;
                    }
                    result.townHouses[i] += 1;
                }
                if (arrayOfPages[i][j] === 'cityUnit') {
                    if (!result.cityUnits[i]) {
                        result.cityUnits[i] = 0;
                    }
                    result.cityUnits[i] += 1;
                }
            }
        }

        commit('SET_OTHER_TYPES_ON_PAGE', result);
    },

    // загрузка первой страницы объектов (в зависимости от newTypeFlat 1,2 или 3 запроса)
    async loadObjects({ state, commit, getters, dispatch }) {
        if (state.isReloading) {
            return;
        }
        commit('SET_RELOADING', true);
        commit('RESET_OBJECTS');

        try {
            let flats = [];
            let cityHouses = [];
            let pentHouses = [];
            let townHouses = [];
            let cityUnits = [];

            await dispatch('preLoadObject');

            // если тип объекта не выбран, запрашиваем всё
            if (!state.filters.newTypeFlat.length) {
                [flats, cityHouses, pentHouses, townHouses, cityUnits] = await Promise.all([
                    dispatch('loadFlats'),
                    dispatch('loadCityHouses'),
                    dispatch('loadPentHouses'),
                    dispatch('loadTownHouses'),
                    dispatch('loadCityUnits'),
                ]);
            } else { // иначе запрашиваем только выбранные типы объектов
                [flats, cityHouses, pentHouses, townHouses, cityUnits] = await Promise.all([
                    state.filters.newTypeFlat.includes('209') ? dispatch('loadFlats') : null,
                    state.filters.newTypeFlat.includes('210') ? dispatch('loadCityHouses') : null,
                    state.filters.newTypeFlat.includes('211') ? dispatch('loadPentHouses') : null,
                    state.filters.newTypeFlat.includes('280') ? dispatch('loadTownHouses') : null,
                    state.filters.newTypeFlat.includes('212') ? dispatch('loadCityUnits') : null,
                ]);
            }

            // у ростеха только квартиры и баннеры им не нужны
            if (state.filters.sale25 === true || state.filters.sale2 === true) {
                commit('SET_OBJECTS', flats);
            } else {
                const sortedObjects = doSort({
                    flats: flats || [],
                    cityHouses: cityHouses || [],
                    pentHouses: pentHouses || [],
                    townHouses: townHouses || [],
                    cityUnits: cityUnits || [],
                    banners: state.banners,
                    flatType: state.filters.newTypeFlat,
                    isDesktop: this.$device.isDesktop,
                    gridView: state.gridView,
                    flatsPerPage: getters.flatsPerPage,
                });
                commit('SET_OBJECTS', sortedObjects);
            }
        } catch (e) {
            console.warn('[Vuex] flats/loadObjects: ', e);
        }

        commit('SET_RELOADING', false);
    },

    // подгрузка объектов, в зависимости от HasNextPage делаем 1 или 2 запроса
    async loadMoreObjects({ state, getters, commit, dispatch }) {
        if (!state.isLoading) {
            commit('SET_LOADING', true);
            commit('INCREASE_CURRENT_PAGE');

            try {
                const [flats, cityHouses, pentHouses, townHouses, cityUnits] = await Promise.all([
                    getters.flatsHasNextPage ? dispatch('loadFlats') : null,
                    getters.cityHousesHasNextPage ? dispatch('loadCityHouses') : null,
                    getters.pentHousesHasNextPage ? dispatch('loadPentHouses') : null,
                    getters.townHousesHasNextPage ? dispatch('loadTownHouses') : null,
                    getters.cityUnitsHasNextPage ? dispatch('loadCityUnits') : null,
                ]);

                // у ростеха только квартиры и баннеры им не нужны
                if (state.filters.sale25 === true || state.filters.sale2 === true) {
                    commit('SET_OBJECTS', flats);
                } else {
                    const sortedObjects = doSort({
                        flats: flats || [],
                        cityHouses: cityHouses || [],
                        pentHouses: pentHouses || [],
                        townHouses: townHouses || [],
                        cityUnits: cityUnits || [],
                        banners: state.banners,
                        flatType: state.filters.newTypeFlat,
                        isDesktop: this.$device.isDesktop,
                        gridView: state.gridView,
                        flatsPerPage: getters.flatsPerPage,
                    });
                    commit('SET_OBJECTS', sortedObjects);
                }
            } catch (e) {
                console.warn('[Vuex] flats/loadMoreObjects: ', e);
            }

            commit('SET_LOADING', false);
        }
    },

    async fetchBanners({ commit }) {
        try {
            const { data } = await this.$axios.$post('/graphql/', {
                query: allBannersRealty.loc.source.body,
            }, { progress: false });

            const response = data.allBannersRealty.edges.map(item => item.node);
            const banners = response.map(banner => ({
                ...banner,
                type: 'promo',
            }));

            commit('SET_BANNERS', banners);
        } catch (e) {
            console.warn('[Vuex] flats/fetchBanners: ', e);
        }
    },

    async fetchSpecs({ state, commit }) {
        try {
            const specsResponse = await this.$axios.$post('/graphql/', {
                query: allFlatsSpecs.loc.source.body,
            }, { progress: false });

            commit('SET_SPECS', parseDataFromResponse(specsResponse.data.allFlatsSpecs));

            // подмешиваем доп. инфо к сценариям
            const scenariosResponse = await this.$axios.$post('/graphql/', {
                query: allFlatsScenarios.loc.source.body,
            });

            const scenario = getNodeArray(scenariosResponse.data, 'allFlatsScenarios');
            const newScenario = [...state.specs.scenario].map(el => {
                const {
                    hoverDescription,
                    hoverFilter,
                    imageDisplay,
                    imagePreview,
                } = scenario.find(scenario => scenario.id === el.value);

                return {
                    ...el,
                    hoverDescription,
                    hoverFilter,
                    imageDisplay,
                    imagePreview,
                };
            });

            commit('SET_SPECS', { ...state.specs, scenario: newScenario });

            // подмешиваем доп. инфо к подгруппам
            let subgroup = [];
            scenario.forEach(el => {
                subgroup.push(...getNodeArray(el, 'subgroup'));
            });
            // удаляем дубли
            const tmp = {};
            subgroup = subgroup.filter(({ id }) => !tmp[id] && (tmp[id] = 1));

            const newSubgroup = [...state.specs.subgroup].map(el => {
                const {
                    filterDescription,
                    filterTitle,
                } = subgroup.find(subgroup => subgroup.id === el.value);

                return {
                    ...el,
                    filterDescription,
                    filterTitle,
                };
            });

            commit('SET_SPECS', { ...state.specs, subgroup: newSubgroup });

            // подмешиваем доп. инфо к УБ
            const urbanBlocksResponse = await this.$axios.$post('/graphql/', {
                query: allFlatsUrbanBlocks.loc.source.body,
            });

            const urbanBlock = getNodeArray(urbanBlocksResponse.data, 'allUrbanBlocks');
            const building = [];
            const newUrbanBlock = [...state.specs.urban_block].map(el => {
                const {
                    buildings,
                    imageFlatFilterDisplay,
                    imageFlatFilterPreview,
                    textFlatFilter,
                } = urbanBlock.find(urbanBlock => urbanBlock.id === el.value);

                building.push(...buildings);

                return {
                    ...el,
                    imageFlatFilterDisplay,
                    imageFlatFilterPreview,
                    textFlatFilter,
                };
            });

            commit('SET_SPECS', { ...state.specs, urban_block: newUrbanBlock });

            // подмешиваем доп. инфо к корпусам
            const newBuilding = [...state.specs.building].map(el => {
                const {
                    imageFlatFilterDisplay,
                    imageFlatFilterPreview,
                    textFlatFilter,
                } = building.find(building => building.id === el.value);

                return {
                    ...el,
                    imageFlatFilterDisplay,
                    imageFlatFilterPreview,
                    textFlatFilter,
                };
            });

            commit('SET_SPECS', { ...state.specs, building: newBuilding });

            // подмешиваем доп. инфо к комнатности
            const newLayout = [...state.specs.layout].map(el => {
                let description = null;
                if (SPECS_ADDITIONAL_INFORMATION.layout[el.value]) {
                    description = SPECS_ADDITIONAL_INFORMATION.layout[el.value].description || null;
                }

                return {
                    ...el,
                    description,
                };
            });

            commit('SET_SPECS', { ...state.specs, layout: newLayout });

            // подмешиваем доп. инфо к типу лота
            const newTypeFlat = [...state.specs.new_type_flat].map(el => {
                let tooltip = null;
                if (SPECS_ADDITIONAL_INFORMATION.newTypeFlat[el.value]) {
                    tooltip = SPECS_ADDITIONAL_INFORMATION.newTypeFlat[el.value].tooltip || null;
                }

                return {
                    ...el,
                    tooltip,
                };
            });

            commit('SET_SPECS', { ...state.specs, new_type_flat: newTypeFlat });
        } catch (e) {
            console.warn('[Vuex] flats/fetchSpecs: ', e);
        }
    },

    async fetchFacets({ state, commit }) {
        try {
            const { data } = await this.$axios.$post('/graphql/', {
                query: allFlatsFacets.loc.source.body,
                variables: {
                    ...prepareDataForRequest(state.filters),
                },
            }, { progress: false });

            commit('SET_FACETS', parseDataFromResponse(data.allFlatsFacets));
        } catch (e) {
            console.warn('[Vuex] flats/fetchFacets: ', e);
        }
    },

    async setGridView({ state, commit, dispatch }, payload) {
        if (!state.isReloading) {
            commit('SET_GRID_VIEW', payload);
            // скроллим страницу вверх
            window.scrollTo({ top: 0, behavior: 'smooth' });

            await Promise.all([
                dispatch('loadObjects'),
                dispatch('fetchFacets'),
            ]);
        }
    },

    async changeFilters({ state, getters, dispatch }, payload) {
        if (!state.isReloading) {
            if (getters.totalCount) {
                // если выдача не пустая, сохраняем фильтры перед новым запросом
                dispatch('setLastSearch', state.filters);
            }
            // скрываем кнопку "восстановить"
            dispatch('setIsLastSearchShown', false);
            // сетаем фильтры
            dispatch('setFilters', payload);
            // обновляем квери
            let filters = state.filters;
            if (getters.getHuuQueryKeys.length) {
                filters = excludeQuery(filters, getters.getHuuQuery);
            }
            updateQueryUseHistory(filters);

            // Убрал скролл к началу страницы, так как появился баннер черной пятницы для выборщика
            // window.scrollTo({ top: 0, behavior: 'smooth' });

            await Promise.all([
                dispatch('loadObjects'),
                dispatch('fetchFacets'),
            ]);

            if (getters.totalCount) {
                // если выдача не пустая, сохраняем актуальные фильтры
                dispatch('setLastSearch', state.filters);
            }
        }
    },

    setFilters({ commit }, payload) {
        // а тут в сложных ui компонентах по разному отдает число
        if (payload.areaMax) {
            payload.areaMax = String(payload.areaMax);
        }
        if (payload.areaMin) {
            payload.areaMin = String(payload.areaMin);
        }
        if (payload.floorMax) {
            payload.floorMax = String(payload.floorMax);
        }
        if (payload.floorMin) {
            payload.floorMin = String(payload.floorMin);
        }
        if (payload.priceMax) {
            payload.priceMax = String(payload.priceMax);
        }
        if (payload.priceMin) {
            payload.priceMin = String(payload.priceMin);
        }
        if (payload.subgroup) {
            payload.subgroup = String(payload.subgroup);
        }
        commit('SET_LAST_FILTERS_PAYLOAD', payload);
        commit('SET_FILTERS', payload);
    },

    async resetFilters({ state, dispatch }) {
        if (JSON.stringify(state.filters) !== JSON.stringify(DEFAULT_FILTERS)) {
            dispatch('setHuuQuery', {});
            await dispatch('changeFilters', DEFAULT_FILTERS);
        }
    },

    setHuuQuery({ commit }, payload) {
        commit('SET_HUU_QUERY', payload);
    },

    updateQueryParams({ state }) {
        updateQueryUseHistory(state.filters);
    },

    setLastSearch({ state, commit }, payload) {
        commit('SET_LAST_SEARCH', payload);
        localStorage.setItem('flatsLastSearch', JSON.stringify(state.lastSearch));
    },

    loadLastSearchFromlocalStorage({ state, dispatch }) {
        if (localStorage.getItem('flatsLastSearch')) {
            const localStorageFilters = JSON.parse(localStorage.getItem('flatsLastSearch'));
            Object.keys(localStorageFilters).forEach(key => { // Кастыль по удалению пустых ключей, т.к. каким-то образом там был пустой ключ с пустым значением. Не нашел, откуда он там взялся
                if (localStorageFilters[key] === '') {
                    delete localStorageFilters[key];
                }
            });

            dispatch('setLastSearch', localStorageFilters);

            if (JSON.stringify(state.lastSearch) !== JSON.stringify(state.filters) && JSON.stringify(state.lastSearch) !== JSON.stringify(DEFAULT_FILTERS)) {
                dispatch('setIsLastSearchShown', true);
            }
        }
    },

    saveLastSearchBeforeLeavePage({ state, getters, dispatch }) {
        if (getters.totalCount && JSON.stringify(state.filters) !== JSON.stringify(DEFAULT_FILTERS)) {
            dispatch('setLastSearch', state.filters);
        }

        dispatch('setIsLastSearchShown', false);
    },

    deleteLastSearch({ dispatch }) {
        dispatch('setLastSearch', DEFAULT_FILTERS);
        dispatch('setIsLastSearchShown', false);
    },

    deleteValueFromLastSearch({ state, dispatch }, filter) {
        const payload = {};

        if (filter.value) {
            payload[filter.name] = state.lastSearch[filter.name].filter(item => item !== filter.value);
        } else {
            const names = filter.name.split(',');
            names.forEach(name => {
                payload[name] = '';
            });
        }

        dispatch('setLastSearch', payload);

        if (JSON.stringify(state.lastSearch) === JSON.stringify(DEFAULT_FILTERS)) {
            dispatch('setIsLastSearchShown', false);
        }
    },

    setIsLastSearchShown({ commit }, payload) {
        commit('SET_IS_LAST_SEARCH_SHOWN', payload);
    },
};
