<template>
    <div :class="[$style.TheWidgetButtons, 'TheWidgetButtons']">
        <button
            :class="[$style.button, $style._email, {[$style._active]: showButtons}]"
            @click="openSubModal"
        >
            <svg :class="$style.svg">
                <use xlink:href="#icon-mail"/>
            </svg>
        </button>

        <button
            :class="[$style.button, $style._phone, {[$style._active]: showButtons}]"
            type="button"
            @click="handleClick"
        >
            <svg :class="$style.svg">
                <use xlink:href="#icon-phone-calltouch"/>
            </svg>
        </button>

        <button
            :class="[$style.button, $style._main, {[$style._active]: showButtons}]"
            type="button"
            @click="buttonClick"
        >
            <svg :class="[$style.svg, {[$style._active]: !showButtons}]">
                <use xlink:href="#icon-phone-calltouch"/>
            </svg>

            <svg :class="[$style.svg, {[$style._active]: showButtons}]">
                <use xlink:href="#icon-cross-calltouch"/>
            </svg>
        </button>
    </div>
</template>

<script>
export default {
    name: 'TheWidgetButtons',

    data() {
        return {
            showButtons: false,
        };
    },

    methods: {
        buttonClick() {
            if (this.showButtons) {
                this.closeButtons();
            } else {
                this.showButtons = true;
            }
        },

        closeButtons() {
            this.showButtons = false;
        },

        openSubModal() {
            this.$modal.open('SubscribeModal');
        },

        handleClick() {
            if (window.ct) {
                window.ct('modules', 'widgets', 'openExternal', 'promotion');
            }
        },
    },
};
</script>

<style lang="scss" module>
    .TheWidgetButtons {
        position: fixed;
        right: calc(2.4rem + var(--lock-offset, 0px));
        bottom: 2.4rem;
        z-index: 17;
        transition: opacity .3s;

        @include respond-to(sm) {
            right: 1.6rem;
            bottom: 1.6rem;
        }
    }

    .button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        margin-top: 8px;
        border-radius: 50%;
        border: none;
        background-color: $home-accent-3;
        transition: all .3s ease-out;
        cursor: pointer;
        box-shadow: 0 12px 24px rgba($home-accent-3, .15);

        &:hover,
        &:focus,
        &:checked {
            border: 0;
            outline: 0;
        }

        @include hover {
            &:hover {
                background-color: $home-accent-1;
            }
        }

        @include respond-to(sm) {
            &:hover {
                background-color: $home-accent-1;
            }
        }

        &._main {
            animation: pulse 2s infinite;
            box-shadow: 0 0 0 0 rgba($home-accent-2, 1);

            .svg {
                position: absolute;
                visibility: hidden;
                //top: 50%;
                //left: 50%;
                //margin-top: -10px;
                //margin-left: -10px;
                opacity: 0;
                transition: all .3s ease-out;

                &._active {
                    visibility: visible;
                    opacity: 1;
                    animation: none;
                }
            }

            &._active {
                animation: none;
            }
        }

        &._phone {
            position: absolute;
            top: 0;
            visibility: hidden;
            opacity: 0;

            &._active {
                top: -136px;
                visibility: visible;
                opacity: 1;
            }
        }

        &._consultation {
            position: absolute;
            top: 0;
            visibility: hidden;
            opacity: 0;

            &._active {
                top: -204px;
                visibility: visible;
                opacity: 1;
            }
        }

        &._email {
            position: absolute;
            top: 0;
            visibility: hidden;
            opacity: 0;

            &._active {
                top: -68px;
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .svg {
        fill: $base-inverse;
        width: 20px;
        height: 20px;
    }

    @keyframes pulse {
        0% {
            transform: scale(.95);
            box-shadow: 0 0 0 0 rgba($home-accent-2, .7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba($home-accent-2, 0);
        }

        100% {
            transform: scale(.95);
            box-shadow: 0 0 0 0 rgba($home-accent-2, 0);
        }
    }
</style>
