<template>
    <symbol id="icon-heart-line" viewBox="0 0 18 17">
        <path d="M15.8446 8.49335L15.7338 8.62251L8.95444 15.4019L2.17509 8.62251L2.0643 8.49335C1.33899 7.64777 0.960028 6.55932 1.00334 5.44612C1.04666 4.33291 1.50906 3.2772 2.29788 2.49053C3.0867 1.70386 4.14367 1.24434 5.25698 1.20406C6.3703 1.16378 7.45771 1.54572 8.30131 2.27333L8.95444 2.83665L9.60757 2.27333C10.4512 1.54572 11.5386 1.16378 12.6519 1.20406C13.7652 1.24434 14.8222 1.70386 15.611 2.49053C16.3998 3.2772 16.8622 4.33291 16.9055 5.44612C16.9489 6.55932 16.5699 7.64777 15.8446 8.49335Z" stroke-width="2"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconHeartLine',
};
</script>
