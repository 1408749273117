<template>
    <symbol
        id="icon-arrow-down-stroke"
        viewBox="0 0 10 6"
        fill="none"
    >
        <path d="M4.99952 2.21855L1.69952 5.51856L0.756853 4.57589L4.99952 0.333221L9.24219 4.57589L8.29952 5.51855L4.99952 2.21855Z" fill="currentColor"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconArrowDownStroke',
};
</script>
