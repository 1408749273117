import Vue from 'vue';

import {
    ValidationProvider,
    ValidationObserver,
    extend,
    configure,
    setInteractionMode,
} from 'vee-validate';

import * as rules from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
setInteractionMode('eager');

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule],
    });
});

const messages = {
    alpha: ({ _field_ }) => `Поле ${_field_} может содержать только буквенные символы`,
    alpha_num: ({ _field_ }) => `Поле ${_field_} может содержать только буквенно-цифровые символы`,
    alpha_dash: ({ _field_ }) => `Поле ${_field_} может содержать буквенно-цифровые символы, а также тире и подчеркивания`,
    alpha_spaces: ({ _field_ }) => `Поле ${_field_} может содержать только буквенные символы, а также пробелы`,
    between: ({ _field_, min, max }) => `Поле ${_field_} должно находиться в диапазоне от ${min} до ${max}`,
    confirmed: ({ _field_ }) => `Поле ${_field_} заполнено неверно`,
    digits: ({ _field_, length }) => `Поле ${_field_} должно быть числовым и точно содержать ${length} цифр`,
    dimensions: ({ _field_, width, height }) => `Поле ${_field_} должно быть размером ${width} пикселей на ${height} пикселей`,
    email: ({ _field_ }) => `Поле ${_field_} должно быть действительным адресом электронной почты`,
    excluded: ({ _field_ }) => `Поле ${_field_} содержит невалидное значение`,
    ext: ({ _field_ }) => `Поле ${_field_} содержит невалидный файл`,
    is_not: ({ _field_ }) => `Поле ${_field_} недопустимо`,
    image: ({ _field_ }) => `Поле ${_field_} должно быть изображением`,
    integer: ({ _field_ }) => `Поле ${_field_} должно быть целым числом`,
    length: ({ _field_, length }) => `Поле ${_field_} должно быть длиной ${length} символов`,
    max_value: ({ _field_, max }) => `Поле ${_field_} должно быть длиной ${max} символов или меньше`,
    max: ({ _field_, length }) => `Поле ${_field_} не может превышать ${length} символов`,
    mimes: ({ _field_ }) => `Поле ${_field_} должно иметь допустимый тип файла`,
    min_value: ({ _field_, min }) => `Поле ${_field_} должно быть длиной ${min} символов или больше`,
    min: ({ _field_, length }) => `Поле ${_field_} должно содержать не менее ${length} символов`,
    numeric: ({ _field_ }) => `Поле ${_field_} может содержать только числовые символы`,
    oneOf: ({ _field_ }) => `Поле ${_field_} не является допустимым значением`,
    regex: ({ _field_ }) => `Формат поля ${_field_} недопустим`,
    required_if: ({ _field_ }) => `Поле ${_field_} обязательно для заполнения`,
    required: ({ _field_ }) => `Поле ${_field_} обязательно для заполнения`,
    size: ({ _field_, size }) => `Размер поля ${_field_} должен быть меньше ${size} Килобайт`,
    decimal: ({ _field_ }) => `Поле ${_field_} должно содержать только десятичные значения`,
    double: ({ _field_ }) => `Поле ${_field_} должно быть допустимым десятичным числом`,
    phone: ({ _field_ }) => `Поле ${_field_} заполнено неверно`,
    cyrillic: ({ _field_ }) => `Поле ${_field_} должно содержать только кириллицу`,
    map_route: ({ _field_ }) => `Поле ${_field_} не должно содержать спец. символы и латинские буквы`,
    latinEmail: () => 'Поле должно содержать действительный email без русских символов.',
};

export default () => {
    configure({
        defaultMessage: (_field_, values) => messages[values._rule_](values),
    });
};

extend('phone', {
    validate: value => {
        // eslint-disable-next-line
        const regex = /^(\+7|7|8|\+8)[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
        return {
            valid: regex.test(value),
        };
    },
});

extend('cyrillic', {
    validate: value => {
        const regex = /^[\p{Script=Cyrl}\s]*$/u;
        return {
            valid: regex.test(value),
        };
    },
});

extend('map_route', {
    validate: value => {
        const regex = /[A-Za-z@%!"#?¨'_]/gi;
        return {
            valid: !regex.test(value) && value.length < 200,
        };
    },
});

extend('latinEmail', {
    ...rules['email'],
    validate: value => {
        // Проверка на наличие русских символов в email
        const cirilicRegex = /[а-яА-Я]/;
        return !cirilicRegex.test(value) && rules['email'].validate(value);
    },
});
