// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LogoIda_RpKz2{overflow:hidden;cursor:pointer}.LogoIda_RpKz2._black_Hkk8F{color:#2f2f2f}.LogoIda_RpKz2._gray_Le3Ok{color:#717171}.LogoIda_RpKz2._white_WOklq{color:#fff}.wrapper_GRz0M{width:100%}.inner_9XKKr{position:relative;overflow:hidden;width:15rem;height:4.4rem}.rectangleBottom_\\+55N4,.rectangleTop_acVyN{content:\"\";position:absolute;top:0;right:0;bottom:0;left:0;background-size:200% 100%;-webkit-transition:background-position .6s ease,width .6s ease;transition:background-position .6s ease,width .6s ease}.rectangleBottom_\\+55N4>p,.rectangleTop_acVyN>p{position:absolute;top:0;bottom:0;width:0;height:100%;background-color:#717171}.rectangleTop_acVyN{width:39%;height:50%;background-position:100% 0}.rectangleBottom_\\+55N4{top:50%;width:100%;height:calc(50% + 1px);background-position:100% 0}.logoIcon_EpKSg{width:100%;height:100%;-webkit-transform:translateZ(0);transform:translateZ(0)}.text_4iRyb{margin-top:1.6rem;font-size:1.2rem;font-weight:400;line-height:124%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LogoIda": "LogoIda_RpKz2",
	"_black": "_black_Hkk8F",
	"_gray": "_gray_Le3Ok",
	"_white": "_white_WOklq",
	"wrapper": "wrapper_GRz0M",
	"inner": "inner_9XKKr",
	"rectangleBottom": "rectangleBottom_+55N4",
	"rectangleTop": "rectangleTop_acVyN",
	"logoIcon": "logoIcon_EpKSg",
	"text": "text_4iRyb"
};
module.exports = ___CSS_LOADER_EXPORT___;
