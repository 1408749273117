// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DigitalMenu_rwEeT .menu-wrapper_TKnJv{position:fixed;top:8.8rem;right:0;bottom:0;left:0;z-index:-1;overflow-x:hidden;overflow-y:auto;background-color:#fff;-webkit-transition:all .3s ease;transition:all .3s ease}.DigitalMenu_rwEeT .menu-wrapper_TKnJv .navs-container_1ffZp{height:-webkit-max-content;height:-moz-max-content;height:max-content;padding:4rem 5.4rem}.DigitalMenu_rwEeT .menu-wrapper_TKnJv .navs_5wTjR{display:-ms-grid;display:grid;-ms-grid-columns:(1fr)[3];grid-template-columns:repeat(3,1fr);-ms-grid-rows:auto;grid-template-rows:auto;-webkit-box-align:stretch;-ms-flex-align:stretch;align-items:stretch;width:calc(100% + 2rem);margin:-1rem;background-color:#fff}.DigitalMenu_rwEeT .menu-wrapper_TKnJv .nav_nRoQc{display:block;max-width:100%;height:33rem;margin:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DigitalMenu": "DigitalMenu_rwEeT",
	"menu-wrapper": "menu-wrapper_TKnJv",
	"navs-container": "navs-container_1ffZp",
	"navs": "navs_5wTjR",
	"nav": "nav_nRoQc"
};
module.exports = ___CSS_LOADER_EXPORT___;
