<template>
    <transition
        name="content-appear"
        @after-leave="$emit('after-leave')"
    >
        <div v-if="visible" :class="$style.ModalOfficeForm">
            <OfficeForm
                :data="data"
                @close="onClose"
            />
        </div>
    </transition>
</template>

<script>
import OfficeForm from '~/components/forms/office/OfficeForm';
export default {
    name: 'ModalOfficeForm',

    components: {
        OfficeForm,
    },

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onClose() {
            this.$emit('close');
        },
    },

};
</script>

<style lang="scss" module>
    .ModalOfficeForm {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
</style>
