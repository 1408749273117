<template>
    <div :class="$style.icon">
        <HeartIcon :class="$style.icon__img" />

        <div
            v-if="notEmpty"
            :class="$style.icon__circle"
        />
    </div>
</template>

<script>
import HeartIcon from '~/assets/icons/home/heart.svg?inline';

export default {
    name: 'FavouritesIcon',

    components: {
        HeartIcon,
    },

    props: {
        notEmpty: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" module>
    .icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        min-width: 2rem;
        height: 2rem;
        cursor: pointer;

        &:hover {
            .icon__img {
                color: $home-accent-2;
            }
        }

        &__img {
            width: 100%;
            height: 100%;
            color: $gray-4;
            transition: $homeTransition;
        }

        &__circle {
            position: absolute;
            top: .1rem;
            right: .1rem;
            width: .6rem;
            height: .6rem;
            border-radius: 50%;
            background-color: $home-accent-2;
        }
    }
</style>
