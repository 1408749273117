<template>
    <div>
        <Subscribe />

        <div :class="$style.NewFooter">
            <div class="container-home">
                <div :class="$style.firstLine">
                    <div :class="$style.firstRow">
                        <div :class="$style.contactsAndSocials">
                            <div :class="$style.contacts">
                                <div :class="$style.tel">
                                    <a
                                        href="tel:+74951220000"
                                    >
                                        +7 495 122 00 00
                                    </a>
                                </div>

                                <div :class="$style.address">
                                    Москва, район&nbsp;Покровское-Стрешнево, м.&nbsp;"Тушинская", Волоколамское шоссе, вл.&nbsp;73А<br />
                                    Пн–Вс с 9:00 до 21:00
                                </div>
                            </div>

                            <div
                                v-if="!$device.isDesktop"
                                :class="$style.socialsContainer"
                            >
                                <div :class="$style.socialsTitle">
                                    Присоединяйтесь к&nbsp;нам в&nbsp;соцсетях
                                </div>

                                <SocialLinks />
                            </div>
                        </div>

                        <div
                            v-if="$device.isDesktop"
                            :class="$style.socialsContainer"
                        >
                            <div :class="$style.socialsTitle">
                                Присоединяйтесь к&nbsp;нам в&nbsp;соцсетях
                            </div>

                            <SocialLinks />
                        </div>
                    </div>

                    <div
                        v-if="$device.isDesktop"
                        :class="$style.ulContainer"
                    >
                        <ul
                            v-for="(list, idx) in lists"
                            :key="list.title + idx"
                            :class="$style.list"
                        >
                            <template v-if="list.isHeadings && list.links && list.links.length">
                                <li
                                    v-for="link in list.links"
                                    :key="link.label"
                                    :class="$style.headingTitle"
                                >
                                    <nuxt-link :to="link.link">{{ link.label }}</nuxt-link>
                                </li>
                            </template>
                            <template v-else>
                                <li v-if="list.title" :class="$style.title">
                                    <nuxt-link
                                        v-if="list.link"
                                        :to="list.link"
                                    >
                                        {{ list.title }}
                                    </nuxt-link>
                                    <template v-else>
                                        {{ list.title }}
                                    </template>
                                </li>


                                <li
                                    v-for="(link, index) in list.links"
                                    :key="link.label"
                                    :class="$style.link"
                                >
                                    <template v-if="!list.hiddenIndex">
                                        <a
                                            v-if="link.isRedirect"
                                            :key="index"
                                            :href="link.link"
                                            target="_blank"
                                            :class="$style.link"
                                        >
                                            {{ link.label }}
                                        </a>

                                        <nuxt-link
                                            v-else-if="!link.callback"
                                            :key="index"
                                            :to="link.link"
                                            :class="$style.link"
                                        >
                                            {{ link.label }}
                                        </nuxt-link>

                                        <div
                                            v-else
                                            :key="index"
                                            :class="$style.link"
                                            @click="link.callback() || null"
                                        >
                                            {{ link.label }}
                                        </div>
                                    </template>

                                    <template v-else>
                                        <div v-if="index < list.hiddenIndex" :key="index">
                                            <a
                                                v-if="link.isRedirect"
                                                :href="link.link"
                                                target="_blank"
                                                :class="$style.link"
                                            >
                                                {{ link.label }}
                                            </a>

                                            <nuxt-link
                                                v-else-if="!link.callback"
                                                :to="link.link"
                                                :class="$style.link"
                                            >
                                                {{ link.label }}
                                            </nuxt-link>

                                            <div
                                                v-else
                                                :class="$style.link"
                                                @click="link.callback() || null"
                                            >
                                                {{ link.label }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="index === list.hiddenIndex"
                                            :key="index + 1"
                                            :class="$style.showMore"
                                            @click="handleShowMore(idx)"
                                        >
                                            Показать ещё
                                        </div>
                                    </template>
                                </li>

                                <li v-if="list.hiddenIndex === list.links.length + 1">
                                    <div
                                        :class="$style.showMore"
                                        @click="resetShowMore"
                                    >
                                        Скрыть
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="container-home">
                <div :class="$style.secondRow">
                    <div
                        v-if="$device.isMobileOrTablet"
                        :class="$style.accordionContainer"
                    >
                        <template v-for="(list, idx) in lists">
                            <template v-if="list.isHeadings && list.links && list.links.length">
                                <nuxt-link
                                    v-for="link in list.links"
                                    :key="link.label"
                                    :to="link.link"
                                    :class="$style.title"
                                >
                                    {{ link.label }}
                                </nuxt-link>
                            </template>
                            <template v-else>
                                <UiAccordion
                                    v-if="!list.link"
                                    :key="list.title + idx"
                                    :list="list"
                                    :class="$style.accordion"
                                />
                                <nuxt-link
                                    v-else
                                    :key="list.title + idx"
                                    :to="list.link"
                                    :class="$style.titleLink"
                                >
                                    {{ list.title }}
                                </nuxt-link>
                            </template>
                        </template>
                    </div>

                    <div :class="$style.rate">
                        <div
                            v-if="$device.isMobileOrTablet"
                            :class="$style.title"
                        >
                            Удобно ли вам пользоваться сайтом?
                        </div>

                        <UiButton
                            v-if="$device.isMobileOrTablet"
                            :class="$style.socialsBtn"
                            color="primary"
                            @click="openModalAdaptive"
                        >
                            Оцените сайт
                        </UiButton>
                    </div>

                    <div v-if="$device.isDesktop" :class="$style.footerBottom">
                        <div
                            :class="$style.satisfactionForm"
                        >
                            <div :class="$style.ratingTitle">
                                Удобно ли вам<br>
                                пользоваться сайтом?
                            </div>

                            <div :class="$style.ratingStars">
                                <div
                                    v-for="star in stars"
                                    :key="star.id"
                                    :class="[$style.star, {[$style._active]: star.colored}]"
                                    @mouseenter="addBackground(star.id)"
                                    @mouseleave="resetBackground"
                                    @click="openModal(star.id)"
                                >
                                    <FooterStar
                                        v-if="!star.colored"
                                    />

                                    <FooterStarActive
                                        v-if="star.colored"
                                    />
                                </div>
                            </div>
                        </div>
                        <div :class="$style.logos">
                            <div :class="[$style.logoItem, $style._asterus]">
                                <a
                                    href="https://asterus-development.com/"
                                    target="_blank"
                                    rel="nofollow"
                                >
                                    <AsterusLogo :class="$style.logoImage" />
                                    <p :class="$style.text">девелопер проекта</p>
                                </a>
                            </div>

                            <div :class="[$style.logoItem, $style._ida]">
                                <NewLogoIda
                                    :is-touch="false"
                                    href="https://idaproject.com/"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="$style.description">
                <div :class="$style.containerDescription">
                    <div
                        v-if="$device.isDesktop"
                        :class="$style.text"
                    >
                        Любая информация, представленная на данном сайте (в том числе изображения, планировки, схемы расположения объектов социальной, транспортной, инженерной инфраструктуры, цены объектов), — носит исключительно информационный характер, является ориентировочной и может быть изменена, ни при каких условиях не является публичной офертой, определяемой положениями статей 435, 437 Гражданского кодекса Российской Федерации. Актуальная информация размещена на сайте Единой информационной системы жилищного строительства.
                    </div>

                    <div
                        v-if="$device.isDesktopOrTablet"
                        :class="$style.rights"
                    >
                        <div
                            v-if="$device.isTablet"
                            :class="[$style.logoItem, $style._asterus]"
                        >
                            <a
                                href="https://asterus-development.com/"
                                target="_blank"
                                rel="nofollow"
                            >
                                <AsterusLogo :class="$style.logoImage" />

                                <p :class="$style.text">девелопер проекта</p>
                            </a>
                        </div>

                        <div :class="$style.rightsLeft">
                            © 2024 Жилой район ÁLIA. Все права защищены.
                        </div>

                        <a
                            :class="$style.rightsRight"
                            href="/policy/"
                            target="_blank"
                        >
                            Политика конфиденциальности
                        </a>

                        <div
                            v-if="$device.isMobileOrTablet"
                            :class="$style.logoItem"
                        >
                            <NewLogoIda
                                :is-touch="false"
                                href="https://idaproject.com/"
                            />
                        </div>
                    </div>

                    <div
                        v-if="$device.isMobile"
                        :class="$style.rightsMobile"
                    >
                        <div :class="[$style.logoContainerMobile, $style._asterus]">
                            <a href="https://asterus-development.com/" target="_blank">
                                <AsterusLogo :class="$style.logoImage" />

                                <p :class="$style.text">девелопер проекта</p>
                            </a>
                        </div>

                        <div :class="[$style.logoContainerMobile, $style._ida]">
                            <NewLogoIda
                                :is-touch="false"
                                href="https://idaproject.com/"
                            />
                        </div>

                        <div :class="$style.rightsLeft">
                            © ÁLIA 2024
                            <br>
                            Все права защищены
                        </div>

                        <a
                            :class="$style.rightsRight"
                            href="/policy/"
                            target="_blank"
                        >
                            Политика конфиденциальности
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

// components
import FooterStar from '@/assets/icons/footer-star.svg?inline';
import FooterStarActive from '@/assets/icons/footer-star-active.svg?inline';
import UiButton from '~/components/ui/home/UiButton';
import UiAccordion from '~/components/ui/home/UiAccordion';
import Subscribe from '~/components/forms/subscribe/Subscribe';
import NewLogoIda from '~/components/common/NewLogoIda';
import SocialLinks from '~/components/layout/SocialLinks';

// icons
import AsterusLogo from '@/assets/icons/home/logos/asterus-logo.svg?inline';
import { BUILDING_STREAM_LINKS, PURCHASE_PAGE_LINKS } from '~/assets/js/constants';

export default {
    name: 'NewFooter',
    components: {
        FooterStar,
        FooterStarActive,
        UiButton,
        UiAccordion,
        SocialLinks,
        Subscribe,
        NewLogoIda,
        AsterusLogo,
    },

    data() {
        return {
            stars: [
                {
                    id: '1',
                    colored: false,
                },

                {
                    id: '2',
                    colored: false,
                },

                {
                    id: '3',
                    colored: false,
                },

                {
                    id: '4',
                    colored: false,
                },

                {
                    id: '5',
                    colored: false,
                },
            ],

            showMore: null,
        };
    },

    computed: {
        ...mapState('header', [
            'urbanBlocks',
        ]),

        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
        }),

        lists() {
            const urbanBlocks = this.urbanBlocks.map(item => ({
                label: `Урбан-блок ${item.name}`,
                link: `/project/${item.slug}/`,
            }));

            // let adaptiveMenu = [];

            // if (this.$device.isMobileOrTablet || this.isIpadPro) {
            //     adaptiveMenu = [{
            //         title: 'О проекте',
            //         links: [
            //             {
            //                 label: 'О проекте',
            //                 link: '/project/',
            //             },
            //             ...urbanBlocks,
            //         ],
            //     }];
            // }

            const result = [
                {
                    title: 'Купить',
                    hiddenIndex: null,
                    links: {
                        first: {
                            label: 'Квартиры',
                            link: '/flats/?newTypeFlat=209',
                        },

                        second: {
                            label: 'Ситихаусы',
                            link: '/flats/?newTypeFlat=210',
                        },

                        third: {
                            label: 'Пентхаусы',
                            link: '/flats/?newTypeFlat=211',
                        },

                        fourth: {
                            label: 'Таунхаусы',
                            link: '/flats/?newTypeFlat=280',
                        },

                        fifth: {
                            label: 'Сити-юниты',
                            link: '/flats/?newTypeFlat=212',
                        },

                        sixth: {
                            label: 'Машино-места',
                            link: '/parking/',
                        },

                        seventh: {
                            label: 'Кладовые',
                            link: '/storage/',
                        },
                    },
                },

                {

                    title: 'О проекте',
                    hiddenIndex: 6,
                    links: [
                        {
                            label: 'О проекте',
                            link: '/project/',
                        },
                        ...urbanBlocks,
                    ],
                },

                // ...adaptiveMenu,

                {
                    title: 'Покупателям',
                    hiddenIndex: null,
                    links: {
                        first: {
                            label: 'Акции',
                            link: '/center/#promotion',
                        },

                        second: {
                            label: 'Новости',
                            link: '/center/#news',
                        },

                        third: {
                            label: 'Ход строительства',
                            link: '/center/#photo',
                        },

                        fourth: {
                            label: 'Live-стройка',
                            link: '',
                            callback: () => {
                                this.$modal.open('VideoModal', {
                                    src: BUILDING_STREAM_LINKS,
                                    aeroModal: false,
                                    lazy: true,
                                });
                            },
                        },
                    },
                },

                {
                    title: 'Ипотека и рассрочка',
                    hiddenIndex: null,
                    links: {
                        first: {
                            label: 'Ипотека',
                            link: PURCHASE_PAGE_LINKS.mortgage,
                        },

                        third: {
                            label: 'Рассрочка',
                            link: PURCHASE_PAGE_LINKS.installment,
                        },

                        fourth: {
                            label: 'Онлайн-бронирование',
                            link: PURCHASE_PAGE_LINKS.booking,
                        },

                        // fifth: {
                        //     label: 'Trade-In',
                        //     link: 'trade-in/online-eval/',
                        // },
                    },
                },

                {
                    title: 'Документы',
                    hiddenIndex: null,
                    links: {
                        first: {
                            isRedirect: true,
                            label: 'I очередь',
                            link: 'https://xn--80az8a.xn--d1aqf.xn--p1ai/%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B/%D0%BA%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3-%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA/%D0%BE%D0%B1%D1%8A%D0%B5%D0%BA%D1%82/32896',
                        },

                        second: {
                            isRedirect: true,
                            label: 'II очередь',
                            link: 'https://xn--80az8a.xn--d1aqf.xn--p1ai/%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B/%D0%BA%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3-%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA/%D0%BE%D0%B1%D1%8A%D0%B5%D0%BA%D1%82/32897',
                        },

                        third: {
                            isRedirect: true,
                            label: 'III очередь',
                            link: 'https://xn--80az8a.xn--d1aqf.xn--p1ai/%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B/%D0%BA%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3-%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA/%D0%BE%D0%B1%D1%8A%D0%B5%D0%BA%D1%82/51218',
                        },
                    },
                },

                {
                    title: '',
                    isHeadings: true,
                    hiddenIndex: null,
                    links: [
                        { label: 'О компании', link: '/about/' },
                        { label: 'Коммерция', link: '/commercial/' },
                        { label: 'Контакты', link: '/contacts/' },
                        { label: 'Собственникам', link: '/owners/' },
                        { label: 'Брокерам', link: '/brokers/' },
                    ],
                },
            ];

            if (this.showMore) {
                const idx = this.showMore;
                result[idx].hiddenIndex = result[idx].links.length + 1;

                return result;
            } else {
                return result;
            }
        },
    },

    methods: {
        addBackground(id) {
            this.stars.forEach(el => {
                if (Number(el.id) <= Number(id)) {
                    el.colored = true;
                }
            });
        },

        resetBackground() {
            this.stars.forEach(el => {
                el.colored = false;
            });
        },

        openModal(id) {
            this.$emit('open', id);
        },

        openModalAdaptive() {
            this.$emit('open-adaptive');
        },

        handleShowMore(idx) {
            this.showMore = idx;
        },

        resetShowMore() {
            this.showMore = null;
        },
    },
};
</script>

<style lang="scss" module>
    .NewFooter {
        position: relative;
        z-index: 4;
        width: 100%;
        padding: 5.6rem 0;
        background-color: $base-0;

        @include respond-to(sm) {
            padding: 3.6rem 0;
        }
    }

    .firstLine {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 6.4rem;
        width: 100%;
    }

    .firstRow {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 23.5%;
        max-width: 28rem;
        height: 100%;

        @include respond-to(sm) {
            width: 100%;
            max-width: 100%;
        }
    }

    .contactsAndSocials {
        width: 100%;

        @include respond-to(sm) {
            border-bottom: 1px solid $base-100;
        }
    }

    .contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;

        @include respond-to(sm) {
            flex-direction: row;
        }

        @include respond-to(xs) {
            flex-direction: column;
        }

        .tel {
            min-width: 28rem;
            margin-bottom: 1.6rem;
            text-transform: uppercase;
            font-size: 2.4rem;
            font-weight: 700;
            line-height: 132%;
            color: $base-900;
            opacity: .9;

            @include respond-to(sm) {
                min-width: 20rem;
                font-size: 2.4rem;
                line-height: 2.4rem;
                letter-spacing: -.04em;
                color: $base-900;
                opacity: .9;
            }
        }

        .address {
            font-size: 1.6rem;
            font-weight: 300;
            line-height: 124%;
            color: $base-500;

            @include respond-to(sm) {
                text-align: end;
            }

            @include respond-to(xs) {
                margin-bottom: 1.6rem;
                text-align: start;
            }
        }
    }

    .satisfactionForm {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        .star {
            width: 2.6rem;
            height: 2.4rem;
            cursor: pointer;
        }

        .ratingStars {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 1.4rem;
            margin-top: 1.6rem;
        }
    }

    .ulContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-column-gap: 6.4rem;
        grid-row-gap: 5.6rem;
        width: 100%;
        max-width: 76.5%;
    }

    .list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: .76rem;
    }

    .ratingTitle,
    .headingTitle,
    .title {
        margin-bottom: .6rem;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1;

        @include respond-to(sm) {
            margin-bottom: 1.6rem;
            line-height: 1.32;
        }
    }

    .accordion {
        > div[class*="title"] {
            @include respond-to(xxs) {
                font-size: 1.6rem;
            }
        }
    }

    .title + .link {
        margin-top: .6rem;
    }

    .ratingTitle {
        line-height: 1.32;
    }

    .headingTitle {
        margin-bottom: 1.2rem;
        line-height: 1.32;
    }

    .titleLink {
        margin-bottom: 1.6rem;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.32;

        @include respond-to(xxs) {
            font-size: 1.4rem;
        }
    }

    .showMore,
    .link {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 124%;
        color: $base-500;
        transition: $homeTransition;
        cursor: pointer;

        &:hover {
            color: $base-900;
        }
    }

    .showMore {
        color: $base-900;
    }

    .secondRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @include respond-to(sm) {
            flex-direction: column;
            align-items: flex-start;
        }

        @include respond-to(xs) {
            margin-top: 1.6rem;
        }
    }

    .accordionContainer {
        display: grid;
        grid-template-columns: 1.2fr 1fr;
        grid-row-gap: 2.4rem;
        width: 100%;
        margin-bottom: 3.6rem;
        border-bottom: 1px solid $base-100;

        @include respond-to(sm) {
            margin-top: 1.6rem;
            padding-bottom: 1.6rem;
        }

        @include respond-to(xs) {
            grid-template-columns: 1fr;
        }
    }

    .socialsContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 2.8rem;
        margin-bottom: 130px;

        & > :global(div[class*="socialsTitle"]) {
            display: none;

            @include respond-to(sm) {
                display: block;
            }
        }

        @include respond-to(sm) {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-top: 4px;
            margin-bottom: 32px;
        }

        @include respond-to(xs) {
            flex-direction: column;
            margin-top: 12px;
            margin-bottom: 24px;
        }
    }

    .socialsTitle {
        max-width: 200px;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.32;

        @include respond-to(xxs) {
            font-size: 1.4rem;
        }
    }

    .rate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 12px;

        @include respond-to(sm) {
            .title {
                margin: 0;
                padding: 0;
            }
        }

        @include respond-to(xs) {
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;

            .title {
                font-size: 16px;
            }

            .social {
                padding: 1.3rem 1.6rem;
            }

            .socialsBtn {
                width: 100%;
            }
        }
    }

    .socialItem {
        path {
            width: 26px;
            transition: all $homeTransition;
        }

        @include respond-to-min(sm) {
            path {
                width: 40px;
            }
        }

        @include respond-to-min(xs) {
            path {
                width: 26px;
            }
        }

        &:hover {
            @include respond-to-min(md) {
                path {
                    fill: $home-accent-3;
                }
            }
        }
    }

    .footerBottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 3.2rem;
    }

    .logos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 6.4rem;
        min-width: calc(50% - (6.4rem / 2));
        max-width: calc(50% - (6.4rem / 2));
    }

    .logoItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: calc(100% / 2);
        height: 100%;

        p {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 124%;
            color: $base-400;
        }

        &._asterus {
            color: $base-500;

            .text {
                margin-top: 2.4rem;
            }

            &:hover {
                color: $base-900;
            }
        }
    }

    .logoImage {
        svg,
        path,
        g {
            transition: color .3s ease-in;
        }
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-top: 5.6rem;
        padding: 3.6rem 0;
        border-top: 1px solid $base-100;

        @include respond-to(sm) {
            align-items: flex-end;
            width: calc(100% - 48px);
            height: 65px;
            margin-top: 2.6rem;
            margin-right: auto;
            margin-left: auto;
            padding: 2.6rem 0;
        }

        @include respond-to(xs) {
            align-items: flex-end;
            width: calc(100% - 32px);
            height: auto;
            margin-top: 1.6rem;
            margin-right: auto;
            margin-left: auto;
            padding-bottom: 0;
        }
    }

    .containerDescription {
        width: 100%;
        max-width: 144rem;
        margin: 0 auto;
        padding: 0 6.4rem;

        @include respond-to(sm) {
            width: 100%;
            padding: 0;
        }
    }

    .rights {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-top: 1.6rem;

        @include respond-to(sm) {
            align-items: flex-end;
            margin-top: 0;
        }
    }

    .text,
    .rightsLeft,
    .rightsRight {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 124%;
        color: $base-400;
    }

    .rightsRight {
        margin-right: 1.6rem;
    }

    .rightsMobile {
        display: grid;
        grid-template-columns: 1fr 1fr;

        a {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 124%;
            color: $base-500;
        }
    }

    .logoContainerMobile {
        margin-bottom: 2.4rem;

        p {
            font-size: 1.2rem;
            line-height: 124%;
            color: $base-400;
        }

        &._asterus {
            width: 13rem;

            .logoImage {
                color: $base-500;
            }

            .text {
                margin-top: 2rem;
            }
        }

        &._ida {
            color: $base-500;
        }

        .asterusText {
            margin-top: 2.4rem;
            margin-left: .8rem;
        }
    }
</style>
