<template>
    <div :class="$style.MenuProject">
        <MenuLeft :links="links" />

        <MenuRight
            v-show="banners.length"
            :cards="banners"
        />
    </div>
</template>

<script>
import MenuLeft from './MenuLeft';
import MenuRight from './MenuRight';
import { mapState } from 'vuex';

import GridIcon from '~/assets/icons/home/grid.svg?inline';
import CityIcon from '~/assets/icons/home/city.svg?inline';
import TreeIcon from '~/assets/icons/home/tree.svg?inline';

export default {
    name: 'MenuProject',

    components: {
        MenuLeft,
        MenuRight,
    },

    props: {
        banners: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        ...mapState('header', [
            'urbanBlocks',
        ]),

        links() {
            const urbanBlocks = this.urbanBlocks.map(item => ({
                title: `Урбан-блок ${item.name}`,
                url: `/project/${item.slug}/`,
                // todo: костыль, перенести добавление иконки в админку
                icon: item.name.toLowerCase().includes('city') ? CityIcon : TreeIcon,
            }));

            return [{
                title: 'О проекте',
                url: '/project/',
                icon: GridIcon,
            }, ...urbanBlocks];
        },
    },
};
</script>

<style lang="scss" module>
    .MenuProject {
        display: flex;
        justify-content: space-between;
        height: 100%;
    }
</style>
