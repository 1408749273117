// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error_jCBiZ{position:relative;overflow-x:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:100%;max-width:100vw;height:100%;min-height:calc(100vh - 8.8rem)}@media(max-width:767.98px){.error_jCBiZ{padding:0 2.8rem}}.error_jCBiZ:before{content:\"\";position:absolute;top:0;bottom:0;z-index:-1;width:42.3rem;background-color:#f1e56d;-webkit-transform:skew(-24.4deg,0);transform:skew(-24.4deg,0)}@media(max-width:767.98px){.error_jCBiZ:before{width:21.7rem}}.svg_YnbC4{width:100%;max-width:86.7rem}.text_5NilG{margin-top:6.4rem;font-size:1.6rem;line-height:2.4rem;letter-spacing:normal;color:#616161;text-align:center;font-weight:300;color:#717171}.code_nwOG4{font-size:48rem;font-weight:700;line-height:36rem;color:#2f2f2f}@media(max-width:991.98px){.code_nwOG4{font-size:32rem}}@media(max-width:767.98px){.code_nwOG4{font-size:17rem}}.button_gwZR6{margin-top:2.4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "error_jCBiZ",
	"svg": "svg_YnbC4",
	"text": "text_5NilG",
	"code": "code_nwOG4",
	"button": "button_gwZR6"
};
module.exports = ___CSS_LOADER_EXPORT___;
