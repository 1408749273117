import { getCookieFromString } from '../assets/js/utils';

export const state = () => ({
    favoritesCommercial: [],
    favorites_current: [],
});

export const actions = {
    readFavoritesFromCookies({ state, commit }, { req }) {
        try {
            const savedFavorites = getCookieFromString(req?.headers.cookie, 'favoritesCommercial', false);
            if (savedFavorites) {
                commit('ADD_FAVORITES', JSON.parse(decodeURIComponent(savedFavorites)));
            }
        } catch (e) {
            console.log('[Vuex] commercial/readFavoritesFromCookies: Не удалось загрузить', e);
        }
    },

    changeFavoriteState({ state, commit }, commercialId) {
        if (!state.favoritesCommercial.includes(commercialId)) {
            commit('ADD_TO_FAVORITES', commercialId);
        } else {
            commit('REMOVE_FROM_FAVORITES', commercialId);
        }

        const encodedFavorites = encodeURIComponent(JSON.stringify(state.favoritesCommercial));
        document.cookie = `favoritesCommercial=${encodedFavorites};path=/;`;
    },

    removeCurrentFavorites({ commit }) {
        commit('REMOVE_FAVORITES_CURRENT');
    },
};

export const mutations = {

    ADD_FAVORITES(state, payload) {
        state.favoritesCommercial = payload;
    },

    ADD_TO_FAVORITES(state, payload) {
        state.favoritesCommercial.push(payload);
        state.favorites_current.push(payload);
    },

    REMOVE_FROM_FAVORITES(state, payload) {
        state.favoritesCommercial.splice(state.favoritesCommercial.indexOf(payload), 1);
        state.favorites_current.splice(state.favorites_current.indexOf(payload), 1);
    },

    REMOVE_FAVORITES_CURRENT(state) {
        state.favorites_current = [];
    },
};
