var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        _vm.$style.Default,
        {[_vm.$style._tablet]: !_vm.$device.isDesktop},
        {[_vm.$style._mobile]: _vm.$device.isMobile},
    ],attrs:{"id":"app"}},[_c('div',{class:[
            _vm.$style.overlay,
            {[_vm.$style._shown]: _vm.isMenuOpened || _vm.isOverlayShown},
        ],on:{"click":_vm.onOveylayClick}}),_vm._v(" "),_c('client-only',[_c('portal-target',{attrs:{"name":"header-slot"}})],1),_vm._v(" "),_c('HeaderBanner',{class:[
            _vm.$style.banner,
            {[_vm.$style._shown]: _vm.isBannerShown},
            {[_vm.$style._hidden]: _vm.isBannerHidden,
             'HeaderBanner__hidden': _vm.isBannerHidden},
        ]}),_vm._v(" "),_c('TheHeader',{class:[
            _vm.$style.header,
            {[_vm.$style._bottom]: _vm.isBannerShown},
            {[_vm.$style._top]: _vm.isBannerHidden},
        ],attrs:{"data-scroll-lock-fill-gap":"","is-banner-hidden":_vm.isBannerHidden,"menu-banners":_vm.menuBanners},model:{value:(_vm.isMenuOpened),callback:function ($$v) {_vm.isMenuOpened=$$v},expression:"isMenuOpened"}}),_vm._v(" "),_c('main',{on:{"click":_vm.closeModals}},[_c('nuxt')],1),_vm._v(" "),_c('span',{ref:"visibleWidgetPoint"}),_vm._v(" "),_c('NewFooter',{on:{"open":_vm.openModal,"open-adaptive":_vm.openModalAdaptive}}),_vm._v(" "),_c('TheSprite'),_vm._v(" "),_c('TheModal'),_vm._v(" "),_c('TheModalNew'),_vm._v(" "),_c('TheOverlay'),_vm._v(" "),_c('TheModalMobileSlideUp'),_vm._v(" "),_c('UniversalModal'),_vm._v(" "),_c('FullScreenModal'),_vm._v(" "),_c('PageTransition'),_vm._v(" "),_c('TheWidgetButtons',{directives:[{name:"show",rawName:"v-show",value:(_vm.widgetOptions.isShow),expression:"widgetOptions.isShow"}],ref:"widgetButtons",class:[_vm.$style.widget, _vm.widgetOptions.classList]})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }