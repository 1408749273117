<template>
    <div
        :class="[
            $style.burger,
            { [$style.burger_opened]: value }
        ]"
        @click="$emit('click')"
    >
        <div :class="$style.burger__wrap">
            <div :class="$style.burger__line" />
            <div :class="$style.burger__line" />
            <div :class="$style.burger__line" />
            <div :class="$style.burger__line" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TheBurger',

    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style lang="scss" module>
    .burger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.4rem;
        height: 2.4rem;
        cursor: pointer;

        &_opened {
            .burger__line {
                &:nth-child(1) {
                    top: -20%;
                }

                &:nth-child(2) {
                    background-color: $home-accent-3;
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    background-color: $home-accent-3;
                    transform: rotate(-45deg);
                }

                &:nth-child(4) {
                    bottom: -20%;
                }
            }
        }

        &__wrap {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.8rem;
            height: 1.8rem;
        }

        &__line {
            position: absolute;
            left: 0;
            width: 100%;
            height: .15rem;
            border-radius: .075rem;
            background-color: $base;
            transition: $homeTransition;

            &:nth-child(1) {
                top: 20%;
            }

            &:nth-child(4) {
                bottom: 20%;
            }
        }
    }
</style>
