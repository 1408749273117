<template>
    <transition
        :class="$style.ModalHomeAdvantagesGallery"
        name="fade"
        @after-leave="$emit('after-leave')"
    >
        <div
            v-show="visible"
            :class="$style.wrapper"
        >
            <div :class="$style.headerWrapper">
                <div
                    :class="$style.header"
                    class="container-home"
                >
                    <div
                        v-if="$device.isDesktop"
                        :class="$style.headerTitle"
                    >
                        Галерея
                    </div>

                    <div v-else>
                        <div
                            :class="$style.select"
                            @click="handleCategorySelect"
                        >
                            {{ activeAlbumTitle }}

                            <IconArrowDown :class="$style.selectArrow" />
                        </div>
                    </div>

                    <div
                        v-if="$device.isDesktop"
                        :class="$style.pagination"
                    >
                        <div
                            ref="pagination"
                            :class="$style.paginaton"
                        >
                        </div>
                    </div>

                    <div :class="$style.headerClose">
                        <UiCloseButton
                            color="primary-inversed"
                            @click="$emit('close')"
                        />
                    </div>
                </div>
            </div>

            <div :class="$style.body">
                <div
                    ref="slider"
                    :class="$style.slider"
                    class="swiper-container"
                >
                    <div
                        :class="$style.sliderWrapper"
                        class="swiper-wrapper"
                    >
                        <div
                            v-for="image in proxySlides"
                            :key="image.id"
                            :class="$style.slide"
                            class="swiper-slide"
                        >
                            <ImageLazy
                                :origin="image.imageDisplay"
                                :preview="image.imagePreview"
                                :absolute="false"
                                contain-image
                            />
                        </div>
                    </div>
                </div>

                <div :class="$style.buttons">
                    <UiArrowButton
                        ref="prev"
                        rotate
                        enlarged-click-area
                        :disabled="activeIndex <= 1"
                    />

                    <UiArrowButton
                        ref="next"
                        enlarged-click-area
                        :disabled="activeIndex >= slidesLength"
                    />
                </div>

                <div :class="$style.footer">
                    <UiTabs
                        v-if="$device.isDesktop"
                        v-model="activeTab"
                        :tabs="tabs"
                        dark
                    />

                    <div
                        v-else
                        :class="$style.pagination"
                    >
                        <div
                            ref="pagination"
                            :class="$style.paginaton"
                        >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Swiper from 'swiper/js/swiper.min.js';

import { renderFraction } from '~/assets/js/utils/sliderUtils';

import UiCloseButton from '~/components/ui/home/UiCloseButton';
import UiArrowButton from '~/components/ui/home/UiArrowButton';
import UiTabs from '~/components/ui/home/UiTabs';
import ImageLazy from '~/components/common/ImageLazy';
import IconArrowDown from '~/assets/icons/arrow-down.svg?inline';

export default {
    name: 'ModalHomeAdvantagesGallery',

    components: {
        UiCloseButton,
        UiArrowButton,
        UiTabs,
        ImageLazy,
        IconArrowDown,
    },

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            slider: null,
            activeTab: '',
        };
    },

    computed: {
        ...mapState('home/gallery', ['albums', 'activeAlbum']),

        ...mapGetters({
            isWebpSup: 'device/getIsWebpSupported',
        }),

        activeAlbumTitle() {
            return this.activeAlbum?.name || '';
        },

        activeAlbumSlides() {
            return this.activeAlbum?.slides || [];
        },

        proxySlides() {
            const ex = this.isWebpSup ? 'webp' : 'png';
            const noRelativeArray = [];
            for (let i = 0; i < this.activeAlbumSlides.length; i++) {
                noRelativeArray.push(Object.assign({}, this.activeAlbumSlides[i]));
            }
            return noRelativeArray.map(el => {
                el.imageDisplay = this.$image.gen(el.imageDisplay, { q: 60, fit: false, ex: ex });
                el.imagePreview = this.$image.gen(el.imagePreview, { q: 50, fit: false, ex: ex });
                return el;
            });
        },

        tabs() {
            return this.albums.map(album => ({
                value: album.id,
                label: album.label,
            }));
        },

        slidesLength() {
            return this.slider?.slides?.length || 0;
        },

        activeIndex() {
            return this.slider?.activeIndex + 1 || 0;
        },
    },

    watch: {
        activeTab(val) {
            this.handleTabChange(val);
        },

        activeAlbum(val) {
            this.handleTabChange(val.activeId);
        },
    },

    mounted() {
        this.activeTab = this.data.activeId;
    },

    methods: {
        ...mapActions('home/gallery', ['setActiveGalleryAlbum']),

        handleTabChange(id) {
            this.setActiveGalleryAlbum(id);
            if (this.slider) {
                this.initSlider();
            } else {
                setTimeout(() => {
                    this.initSlider();
                }, 500);
            }
        },

        initSlider() {
            if (this.slider) {
                this.slider.destroy();
                this.slider = null;
            }

            const options = {
                slidesPerView: 1,
            };

            if (this.$refs.prev && this.$refs.next) {
                options.navigation = {
                    prevEl: this.$refs.prev.$el,
                    nextEl: this.$refs.next.$el,
                };
            }

            if (this.$refs.pagination) {
                options.pagination = {
                    el: this.$refs.pagination,
                    type: 'fraction',
                    renderFraction: renderFraction,
                };
            }

            this.$nextTick(() => {
                this.slider = new Swiper(this.$refs.slider, options);
            });
        },

        handleCategorySelect() {
            this.$modal.open('ModalHomeAdvantagesGalleryAlbums');
        },
    },
};
</script>

<style lang="scss" module>
    .ModalHomeAdvantagesGallery {
        width: 100vw;
        height: 100vh;
    }

    .headerWrapper {
        border-bottom: .1rem solid #292a2c; // TODO: добавить цвет в кит или поменять
        background-color: $home-base-1;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 7.2rem;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }

    .headerTitle {
        @include old-h4;

        width: 10rem;
        color: $inversed;
    }

    .pagination {
        @include old-p1;

        color: #4e4e51; // TODO: добавить цвет в кит или поменять
    }

    .headerClose {
        display: flex;
        justify-content: flex-end;
        width: 10rem;
    }

    .body {
        position: relative;
        width: 100%;
        height: calc(100vh - 7.2rem - .1rem);
        background-color: $home-base-1;
    }

    .slider {
        width: 100%;
        height: 100%;
    }

    .sliderWrapper {
        //
    }

    .slide {
        width: 100%;
        height: 100%;
    }

    .buttons {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: calc(100% - $homeDesktopPadding * 2);
        transform: translate(-50%, -50%);
    }

    .footer {
        position: absolute;
        bottom: 2.4rem;
        left: 50%;
        transform: translateX(-50%);
    }

    .select {
        @include old-h4;

        position: relative;
        padding-right: 32px;
        color: $inversed;
    }

    .selectArrow {
        position: absolute;
        top: calc(50% - 8px);
        right: 0;
        width: 16px;
        height: 16px;
        color: $inversed;
    }

    @include respond-to(sm) {
        .headerTitle {
            width: auto;
        }

        .headerClose {
            width: auto;
        }

        .buttons {
            width: calc(100% - 32px * 2);
        }

        .pagination {
            @include old-p3;

            color: $inversed;
        }
    }

    @include respond-to(sm) {
        .buttons {
            width: calc(100% - 24px * 2);
        }

        .footer {
            bottom: 8px;
        }
    }
</style>
