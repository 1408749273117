// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_qOsRS{position:absolute;top:100%;left:50%;width:100%;max-width:584px;border-top-left-radius:8px;border-top-right-radius:8px;background-color:#fff;-webkit-transform:translateX(-50%);transform:translateX(-50%);-webkit-transition:-webkit-transform .3s ease;transition:-webkit-transform .3s ease;transition:transform .3s ease;transition:transform .3s ease,-webkit-transform .3s ease}.wrapper_qOsRS._active_o6oS5{-webkit-transform:translateX(-50%) translateY(-100%);transform:translateX(-50%) translateY(-100%)}.header_u7e\\+G{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;padding:28px 24px 24px;border-bottom:1px solid #dfe0e2}.headerBar_l1sEU{position:absolute;top:8px;left:calc(50% - 20px);width:40px;height:4px;background-color:#dfe0e2}.headerTitle_2cYQO{font-size:1.6rem;font-weight:700;line-height:1.6rem;letter-spacing:-.064rem;color:#8b8d98}.album_4ECTb{font-size:1.4rem;font-weight:700;line-height:1;letter-spacing:normal;padding:20px 32px;color:#000;-webkit-transition:all .3s ease;transition:all .3s ease}.album_4ECTb._active_o6oS5{background-color:#2f2f2f;color:#fff}@media(max-width:767.98px){.header_u7e\\+G{padding:28px 16px 24px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_qOsRS",
	"_active": "_active_o6oS5",
	"header": "header_u7e+G",
	"headerBar": "headerBar_l1sEU",
	"headerTitle": "headerTitle_2cYQO",
	"album": "album_4ECTb"
};
module.exports = ___CSS_LOADER_EXPORT___;
