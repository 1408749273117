<template>
    <button
        :class="[$style.UiCloseButton, classList]"
        :disabled="disabled"
        type="button"
        @click="$emit('click')"
    >
        <CloseIcon :class="$style.icon" />
    </button>
</template>

<script>
// TODO использовать вместо этого компонента, компонент ui/buttons/UiCircleButton
import CloseIcon from '@/assets/icons/home/close.svg?inline';

export default {
    name: 'UiCloseButton',

    components: {
        CloseIcon,
    },

    props: {
        color: {
            type: String,
            default: 'default',
            validator: value => ['default', 'primary', 'primary-inversed', 'light', 'gray'].includes(value),
        },

        isRounded: {
            type: Boolean,
            default: false,
        },

        shadow: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return {
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style['_shadow']]: this.shadow,
                [this.$style['_isRounded']]: this.isRounded,
            };
        },
    },
};
</script>

<style lang="scss" module>
    .UiCloseButton {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        padding: 0;
        border: none;
        background-clip: padding-box;
        transition: $homeTransition;
        cursor: pointer;

        &._isRounded {
            border-radius: 50%;
        }

        &._shadow {
            box-shadow: 0 0 1.2rem rgba(0, 0, 0, .15);
        }

        &._default {
            background-color: $home-accent-2;
            color: $inversed;

            &:hover {
                background-color: $home-accent-3;
                color: $inversed;
            }

            &:active {
                background-color: $home-accent-2;
                color: $inversed;
            }
        }

        &._light {
            background-color: $base-50;
            color: $base-900;

            &:hover {
                background-color: $base-0;
                color: $base-500;
            }

            &:active {
                background-color: $base-50;
                color: $base-500;
            }
        }

        &._primary {
            background-color: $home-accent-6;
            color: $home-accent-2;

            &:hover {
                background-color: $home-accent-6;
                color: $home-accent-4;
            }

            &:active {
                background-color: $home-accent-6;
                color: $home-accent-2;
            }
        }

        &._gray {
            background-color: $base-100;
            color: $base-900;

            &:hover {
                background-color: $base-200;
            }

            &:active {
                background-color: $base-100;
                color: $base-800;
            }
        }

        &._primary-inversed {
            background-color: $inversed;
            color: $home-accent-2;

            &:hover {
                background-color: $inversed;
                color: $home-accent-4;
            }

            &:active {
                background-color: $inversed;
                color: $home-accent-2;
            }
        }

        &:focus {
            outline: none;
        }

        &:disabled {
            opacity: .44;
            pointer-events: none;
            user-select: none;
        }
    }

    .icon {
        width: 2.4rem;
        height: 2.4rem;

        @include respond-to(sm) {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
</style>
