<template>
    <symbol id="icon-heart" viewBox="0 0 18 18">
        <path d="M15.89 9.01l-.11.13L9 15.92 2.22 9.14l-.11-.13a4.417 4.417 0 016.237-6.22L9 3.355l.653-.563a4.417 4.417 0 016.237 6.22z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconHeart',
};
</script>
