<template>
    <section
        class="form"
        :class="{
            'form_tablet': isTablet,
            'form_mobile': isMobile,
        }"
    >
        <div class="form__title">
            Ответим на&nbsp;вопросы и&nbsp;поможем выбрать планировку
        </div>

        <TheInput
            v-model="phone"
            :is-error="phoneError"
            class="form__input"
            @clearError="phoneError = false"
        />

        <RadioBtns
            v-model="now"
            class="form__radio"
            :class="{ 'form__radio_disabled': isCalltoutchDisabled }"
        />

        <TimeSelect
            v-show="!now"
            v-model="scheduleTime"
            class="form__select"
            :hour-options="hourOptions"
            :callback-info="callbackInfo"
            @update="findWorkDay"
        />

        <TheCheckbox
            v-model="agreement"
            label="Согласие на обработку персональных данных"
            :is-error="agreementError"
            class="form__checkbox"
            @clearError="agreementError = false"
        />

        <TheButton
            label="Жду звонка"
            class="form__btn"
            @click="onSubmit"
        />

        <TheSocial />
    </section>
</template>

<script>
import TheCheckbox from '~/components/landing/components/ui/Checkbox';
import RadioBtns from '~/components/landing/components/CallbackForm/RadioBtns';
import TimeSelect from '~/components/landing/components/CallbackForm/TimeSelect';
import TheInput from '~/components/landing/components/ui/Input';
import TheButton from '~/components/landing/components/ui/Button';
import TheSocial from '~/components/landing/components/CallbackForm/Social';

export default {
    name: 'CallbackForm',

    components: {
        TheCheckbox,
        RadioBtns,
        TimeSelect,
        TheInput,
        TheButton,
        TheSocial,
    },

    data() {
        return {
            phone: '',
            phoneError: false, // ошибка в наборе телефона
            now: true, // радио
            agreement: false,
            agreementError: false, // не нажали на чекбокс
            requestType: 'landing_form',
            isCalltoutchDisabled: false, // если данные не получены с ctw, то будет true
            callbackDate: '', // день.месяц обратного звонка
            timezone: 0, // временной пояс calltoutch
            hours: [], // часы работы calltoutch
            hourOptions: [], // отфильтрованные часы работы сегодня или завтра
            scheduleTime: '', // время, выбранное пользователем
        };
    },

    computed: {
        isDesktop() {
            return this.$device.isDesktop;
        },

        isTablet() {
            return !this.$device.isDesktop;
        },

        isMobile() {
            return this.$device.isMobile;
        },

        formattedPhone() {
            if (this.phone) {
                return this.phone.replace(/[^+\d]+/g, '').slice(1);
            }
            return '';
        },

        // Информация о времени обратного звонка в нужном формате
        callbackInfo() {
            let minutes = '00';
            if (new Date().getHours() <= this.hourOptions[0]?.label) {
                minutes = new Date().getMinutes() > 54 ? 0 : new Date().getMinutes();
                minutes = Math.ceil((minutes + 1) / 5) * 5;
                minutes = minutes >= 10 ? minutes : `0${minutes}`;
            }
            const from = `${this.hourOptions[0]?.label}:${minutes}`;
            const to = `${this.hourOptions[this.hourOptions.length - 1]?.label}:55`;
            return `${this.callbackDate} c ${from} до ${to}`;
        },
    },

    mounted() {
        // TODO нужно как-то отслеживать, когда ctw будет загружен
        setTimeout(() => {
            this.getRouteKeyData();
        }, 3000);
    },

    methods: {
        getRouteKeyData() {
            if (window.ctw) {
                window.ctw.getRouteKeyData(this.requestType, (success, data) => {
                    if (success) {
                        if (data.widgetFound) {
                            const info = data.widgetData;
                            if (info.callCenterWorkingMode === 'working_hours' || info.collectNonWorkingRequests) {
                                this.timezone = Number(info.workingSchedule.utcTimezone.slice(3, 6));
                                this.hours = info.workingSchedule.hours;
                                this.findWorkDay();
                            } else {
                                // В данный момент не принимают заявок
                                this.isCalltoutchDisabled = true;
                            }
                        } else {
                            // Виджет отключен
                            this.isCalltoutchDisabled = true;
                        }
                    } else {
                        // Ошибка получения данных
                        this.isCalltoutchDisabled = true;
                        console.warn('[landing/getRouteKeyData]:', data, window.ctw);
                    }
                });
            } else {
                // ctw не найден
                this.isCalltoutchDisabled = true;
            }
        },

        // Фильтруем часы обратного звонка с учетом часового пояса, сегодня или завтра будет звонок и еще формируем "день.месяц" обратного звонка
        findWorkDay() {
            if (window.ctw) {
                const today = new Date();
                const hourNow = today.getMinutes() > 54 ? today.getHours() + 1 : today.getHours();
                const timezone = today.getTimezoneOffset() / 60;
                const offsetZone = -timezone - this.timezone;
                const dayOfWeek = today.getDay();
                this.hourOptions = this.hours
                    .filter(el => el.dayOfWeek === dayOfWeek)
                    .map(el => ({
                        label: el.hour + offsetZone,
                        value: el.hour,
                    }))
                    .filter(el => el.label < 24 && el.label >= hourNow);

                if (this.hourOptions.length) {
                    const day = today.getDate() >= 10 ? today.getDate() : `0${today.getDate()}`;
                    const month = today.getMonth() >= 9 ? today.getMonth() + 1 : `0${today.getMonth() + 1}`;
                    this.callbackDate = `${day}.${month}`;
                } else {
                    const nextDay = dayOfWeek + 1 > 7 ? 1 : dayOfWeek + 1;
                    this.hourOptions = this.hours
                        .filter(el => el.dayOfWeek === nextDay)
                        .map(el => ({
                            label: el.hour + offsetZone,
                            value: el.hour,
                        }))
                        .filter(el => el.label < 24);

                    const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
                    const day = tomorrow.getDate() >= 10 ? tomorrow.getDate() : `0${tomorrow.getDate()}`;
                    const month = tomorrow.getMonth() >= 9 ? tomorrow.getMonth() + 1 : `0${tomorrow.getMonth() + 1}`;
                    this.callbackDate = `${day}.${month}`;
                }
            }
        },

        onSubmit() {
            if (this.phone.length < 18 || this.phone && this.phone[17] === '_') {
                this.phoneError = true;
                return;
            }

            if (!this.agreement) {
                this.agreementError = true;
                return;
            }

            if (this.isCalltoutchDisabled) {
                this.$modal.open('CallbackFailModal');
                return;
            }

            if (window.ctw) {
                // Отправляем заявку на обратный звонок в calltoutch
                const date = `${new Date().getFullYear()}-${this.callbackDate.slice(-2)}-${this.callbackDate.slice(0, 2)} ${this.scheduleTime}:00`;

                const scheduleTime = this.now ? null : date;
                window.ctw.createRequest(this.requestType, this.formattedPhone, [], this.ctwCallback, scheduleTime);
            }
        },

        ctwCallback(success, data) {
            if (success) {
                this.$modal.open('CallbackSuccessModal');
                const payload = new FormData();
                payload.append('phone', `+${this.formattedPhone}`);
                payload.append('request_type', 'image_page');
                payload.append('url', window.location.href);
                payload.append('request_time', this.now ? 'Сейчас' : `${this.callbackDate} ${this.scheduleTime}`);

                try {
                    this.$axios.$post('/api/request/', payload);
                } catch (e) {
                    console.warn('[landing/createRequest]:', e?.response?.data);
                }
            } else {
                console.warn('[landing/ctwCallback]:', data, window.ctw);
                this.$modal.open('CallbackFailModal');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .form {
        width: 100%;

        &__title {
            margin-bottom: 3.2rem;
            text-transform: uppercase;
            font-family: 'Aeroport', sans-serif;
            font-size: 2.4rem;
            font-weight: 700;
            line-height: 3rem;
            color: #2a2c2b;
        }

        &__input {
            margin-bottom: 1.4rem;
        }

        &__radio {
            margin-bottom: 3.2rem;

            &_disabled {
                pointer-events: none;
            }
        }

        &__select {
            margin-bottom: 3.2rem;
        }

        &__checkbox {
            margin-bottom: 1.4rem;
        }

        &__btn {
            margin-bottom: 3.2rem;
        }
    }

    .form_tablet {
        .form {
            &__title {
                margin-bottom: 32px;
                font-size: 24px;
                line-height: 30px;
            }

            &__input {
                margin-bottom: 14px;
            }

            &__radio {
                margin-bottom: 32px;
            }

            &__select {
                margin-bottom: 32px;
            }

            &__checkbox {
                margin-bottom: 14px;
            }

            &__btn {
                margin-bottom: 32px;
            }
        }
    }

    .form_mobile {
        .form {
            &__title {
                margin-bottom: 30px;
                font-size: 18px;
                line-height: 23px;
            }

            &__input {
                margin-bottom: 12px;
            }

            &__radio {
                margin-bottom: 30px;
            }

            &__select {
                margin-bottom: 30px;
            }

            &__checkbox {
                margin-bottom: 12px;
            }

            &__btn {
                margin-bottom: 30px;
            }
        }
    }
</style>
