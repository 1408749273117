// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Plan_6f8ri{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.image_KHr1D,.Plan_6f8ri{width:100%;height:100%}.image_KHr1D{display:block}.placeholder_cQYf3{width:100%;height:100%;background-image:url(/assets/flats/plan-placeholder.svg);background-position:50%;background-repeat:no-repeat;background-size:contain}.rooms_WHVWN{width:100%;height:100%;pointer-events:none}.roomArea_8WV\\+x,.rooms_WHVWN{position:absolute;top:0;left:0}.roomArea_8WV\\+x{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:2px;font-size:1rem;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}@media(max-width:767.98px){.roomArea_8WV\\+x{font-size:8px}}.roomArea_8WV\\+x span:not(:first-child){margin-top:2px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Plan": "Plan_6f8ri",
	"image": "image_KHr1D",
	"placeholder": "placeholder_cQYf3",
	"rooms": "rooms_WHVWN",
	"roomArea": "roomArea_8WV+x"
};
module.exports = ___CSS_LOADER_EXPORT___;
