<template>
    <form
        action="#"
        method="post"
        :class="$style.TheForm"
        @submit.prevent="$emit('submit', $event)"
    >
        <slot></slot>


        <UiLink
            :class="$style.btn"
            type="submit"
            :disabled="!valid || !buttonActive"
        >
            {{ buttonLabel }}
        </UiLink>

        <slot name="after"></slot>

    </form>
</template>

<script>
// components


import UiLink from '../ui/UiLink';

export default {
    name: 'TheForm',

    components: {
        UiLink,
    },

    props: {
        errors: {
            type: Array,
            default() {
                return [];
            },
        },

        buttonLabel: {
            type: String,
            default: 'Отправить',
        },

        buttonActive: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            valid: true,
        };
    },
};
</script>

<style lang="scss" module>
    .TheForm {
        margin: -1.6rem 0;
    }

    .btn {
        margin-top: 2.4rem;
        transition: opacity .3s;

        @include respond-to(xs) {
            margin-top: 16px;
        }

        &:disabled {
            opacity: .5;
            pointer-events: none;
        }
    }
</style>
