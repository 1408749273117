<template>
    <div :class="$style.MenuMortgage">
        <MenuLeft :links="links" />

        <MenuRight
            v-show="banners.length"
            :cards="banners"
        />
    </div>
</template>

<script>
import MenuLeft from './MenuLeft';
import MenuRight from './MenuRight';
import { PURCHASE_PAGE_LINKS } from '~/assets/js/constants';
// import BusinessIcon from '~/assets/icons/home/business.svg?inline';
// import UsersIcon from '~/assets/icons/home/users.svg?inline';

export default {
    name: 'MenuMortgage',

    components: {
        MenuLeft,
        MenuRight,
    },

    props: {
        banners: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            links: [{
                title: 'Ипотека',
                url: PURCHASE_PAGE_LINKS.mortgage,
            }, {
                title: 'Рассрочка',
                url: PURCHASE_PAGE_LINKS.installment,
            }, {
                title: 'Онлайн-бронирование',
                url: PURCHASE_PAGE_LINKS.booking,
            }],
            // {
            //     title: 'Trade-in',
            //     url: '/trade-in/online-eval/',
            // }
        };
    },
};
</script>

<style lang="scss" module>
    .MenuMortgage {
        display: flex;
        justify-content: space-between;
        height: 100%;
    }
</style>
