<template>
    <symbol
        id="icon-accordion-new"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path
            d="M13.3337 5.33344H2.66699"
            stroke="black"
            stroke-linecap="round"
        />
        <path
            d="M10.667 7.33337H2.66699"
            stroke="black"
            stroke-linecap="round"
        />
        <path
            d="M8.00033 9.33337H2.66699"
            stroke="black"
            stroke-linecap="round"
        />
        <path
            d="M6.00033 11.3334H2.66699"
            stroke="black"
            stroke-linecap="round"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconAccordionNew',
};
</script>
