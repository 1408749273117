<template>
    <transition name="overlay-appear">
        <div
            v-show="isOverlayVisible"
            :class="$style.TheModalMobileSlideUp"
            @click.self="onClose"
        >
            <div
                :class="[$style.wrap, {[$style._visible]: isContentVisible}]"
                :style="styleWrap"
            >
                <component
                    :is="header"
                    v-if="header"
                    v-bind="options"
                    @close="onClose"
                />

                <div
                    v-if="!header && options?.title !== 'Генплан'"
                    :class="$style.header"
                >
                    <div :class="$style.title">
                        {{ options ? options.title : '' }}
                    </div>

                    <UiCloseButton
                        color="primary"
                        @click="onClose"
                    />
                </div>

                <component
                    :is="component"
                    v-bind="options"
                    @close="onClose"
                />

                <component
                    :is="footer"
                    v-if="footer"
                    v-bind="options"
                    @close="onClose"
                />

                <div
                    v-touch:swipe.bottom="onClose"
                    :class="$style.bar"
                />
            </div>
        </div>
    </transition>
</template>

<script>
import { lockBody, unlockBody } from '@/assets/js/utils';

import UiCloseButton from '~/components/ui/home/UiCloseButton';

export default {
    name: 'TheModalMobileSlideUp',

    components: {
        UiCloseButton,
    },

    data() {
        return {
            component: null,
            header: null,
            footer: null,
            options: null,
            isOverlayVisible: false,
            isContentVisible: false,
        };
    },

    computed: {
        styleWrap() {
            return {
                maxWidth: this.options?.maxWidth ?? '584px',
            };
        },
    },

    watch: {
        '$route.name'() {
            this.onClose();
        },
    },

    beforeMount() {
        this.$modalMobileSlideUp.event.$on('open', this.onOpen);
        this.$modalMobileSlideUp.event.$on('close', this.onClose);
    },

    beforeDestroy() {
        this.$modalMobileSlideUp.event.$off('open', this.onOpen);
        this.$modalMobileSlideUp.event.$off('close', this.onClose);
    },

    methods: {
        onOpen(data) {
            lockBody();
            this.isOverlayVisible = true;
            this.component = data.component;
            this.options = data.options;
            if (data.header) {
                this.header = data.header;
            }
            if (data.footer) {
                this.footer = data.footer;
            }

            setTimeout(() => {
                this.isContentVisible = true;
            }, 500);
        },

        onClose() {
            this.isContentVisible = false;

            setTimeout(() => {
                this.options = null;
                this.component = null;
                this.header = null;
                this.footer = null;
                this.isOverlayVisible = false;
            }, 500);

            unlockBody();

            if (this.options?.beforeClose && typeof this.options?.beforeClose === 'function') {
                this.options.beforeClose();
            }
        },
    },
};
</script>

<style lang="scss" module>
    .TheModalMobileSlideUp {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: rgba($home-base-1, .88);

        .wrap {
            position: absolute;
            top: 100%;
            left: 50%;
            width: 100%;
            background-color: $inversed;
            transform: translateX(-50%);
            transition: transform $homeTransition;

            &._visible {
                transform: translateX(-50%) translateY(-100%);
            }
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2.8rem 2.4rem 2.4rem;
            border-bottom: 1px solid $line-200;
        }

        .bar {
            position: absolute;
            top: .8rem;
            left: 50%;
            width: 4rem;
            height: .4rem;
            background-clip: padding-box;
            background-color: $line-200;
            transform: translateX(-50%);

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 8rem;
                height: 4rem;
                border-radius: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .title {
            @include old-p2;

            color: $home-accent-5;
        }
    }

    @include respond-to(xs) {
        .TheModalMobileSlideUp {
            background-color: rgba($home-base-1, .64);

            .header {
                padding: 2.8rem 1.6rem 2.4rem;
            }
        }
    }
</style>
