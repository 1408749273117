<template>
    <symbol id="icon-video" viewBox="0 0 48 48">
        <path d="M24 44C12.954 44 4 35.046 4 24S12.954 4 24 4s20 8.954 20 20-8.954 20-20 20zm-2.756-27.17a.8.8 0 00-1.244.664v13.012a.8.8 0 001.244.664l9.758-6.504a.8.8 0 000-1.332l-9.76-6.504h.002z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconVideo',
};
</script>
