<template>
    <ModalContainer
        :class="$style.SubscribeModal"
        :visible="visible"
        is-subscribe-modal
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <ValidationObserver
            ref="observer"
            v-slot="{ handleSubmit }"
            tag="div"
            :class="[$style.body, {[$style._success]: isSuccess}]"
        >
            <h3 :class="[$style.title, 'h2 _base']">
                Подписаться<br>на рассылку
            </h3>

            <h4 :class="$style.subtitle">
                Будьте в курсе новостей<br>и специальных предложений раньше остальных!
            </h4>

            <form
                ref="SubscribeModalForm"
                action=""
                :class="$style.form"
                @submit.prevent="handleSubmit(onSubmit)"
            >
                <UiInput
                    v-model="name"
                    field="имя"
                    name="name"
                    placeholder="Имя"
                    color="old"
                    rules="required|cyrillic|min:2|alpha_spaces"
                    :class="$style.input"
                />

                <UiInput
                    v-model="email"
                    field="email"
                    type="email"
                    name="email"
                    placeholder="Email"
                    color="old"
                    rules="required|email"
                    :class="$style.input"
                />

                <UiLink
                    :class="$style.btn"
                    type="submit"
                >
                    Подписаться
                </UiLink>
            </form>

            <div :class="$style.policy">
                <FormPolicy v-model="checkboxes" />
            </div>

            <transition name="fade">
                <TheFormSuccess
                    v-if="isSuccess"
                    :class="$style.formSuccess"
                    title="Успешно!"
                    description="Вы подписались на рассылку обновлений"
                    @click="$emit('close')"
                />
            </transition>
        </ValidationObserver>
    </ModalContainer>
</template>

<script>
import ModalContainer from '~/components/common/modals/ModalContainer';
import UiInput from '~/components/ui/home/UiInput';
import UiLink from '~/components/ui/UiLink';
import TheFormSuccess from '~/components/common/form/TheFormSuccess';
import FormPolicy from '~/components/common/form/FormPolicy.vue';

export default {
    name: 'SubscribeModal',

    components: {
        FormPolicy,
        TheFormSuccess,
        UiInput,
        UiLink,
        ModalContainer,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            name: '',
            email: '',
            checkboxes: {
                policy: false,
                marketing: true,
            },

            isSuccess: false,
        };
    },

    methods: {
        async onSubmit() {
            try {
                const payload = {
                    name: this.name,
                    email: this.email,
                    ad: this.checkboxes.marketing,
                };

                await this.$axios.$post('/api/subscription/', payload);

                window.mindbox('async', {
                    operation: 'Website.SubscriptionInFooter',
                    data: {
                        customer: {
                            email: this.email,
                            firstName: this.name,
                        },
                    },
                    onSuccess: function() {
                        console.log('%c Mindbox: All good, заявка отправлена', 'background: #01630b; color: #add7ff');
                    },
                    onError: function(error) {
                        console.log('%c Mindbox: error! Ошибка в отправке заявки', 'background: #ffd400; color: #ff0000', error);
                    },
                });

                this.resetForm();
                this.isSuccess = true;
            } catch (e) {
                console.warn('[SubscribeModal] onSubmit:', e);
            }
        },

        resetForm() {
            this.$refs.SubscribeModalForm.reset();
            this.name = '';
            this.email = '';
            this.checkboxes.policy = false;
            this.checkboxes.marketing = true;
            this.$refs.observer.reset();
        },
    },
};
</script>

<style lang="scss" module>
    .SubscribeModal {
        width: 97rem;

        @include respond-to(sm) {
            width: 74rem;
        }

        @include respond-to(xs) {
            width: 100vw;
        }
    }

    .body {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-left: 47.8rem;

        @include respond-to(sm) {
            margin-left: 33.8rem;
        }

        @include respond-to(xs) {
            overflow-y: auto;
            height: 100%;
            margin-left: 0;
        }

        &._success {
            min-height: 54rem;

            @include respond-to(xs) {
                min-height: 100%;
            }

            & > form {
                visibility: hidden;
            }
        }
    }

    .title {
        margin-bottom: 1.2rem;
    }

    .subtitle {
        margin-bottom: 4rem;
        font-size: 1.4rem;
        font-weight: 600;
        color: $base-old-600;

        @include respond-to(sm) {
            line-height: 1.4;
        }
    }

    .form {
        margin: -1.6rem 0;
    }

    .input {
        margin-bottom: 3.2rem;
    }

    .btn {
        margin-top: 4rem;
        transition: opacity $homeTransition;

        @include respond-to(xs) {
            margin-top: 3.2rem;
        }
    }

    .policy {
        overflow: hidden;
        margin-top: 4rem;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.64;
        color: $base-gray;

        & > a {
            text-decoration: underline;
        }
    }

    .formSuccess {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }
</style>
