<template>
    <transition
        :class="$style.ModalHomeAdvantages"
        name="content-slide-left"
        @after-enter="handleAfterEnter"
        @after-leave="$emit('after-leave')"
        @before-leave="handleBeforeLeave"
    >
        <div
            v-show="visible"
            :class="[$style.wrapper, wrapperClassList]"
        >
            <template v-if="card">
                <div
                    :class="[
                        $style.header,
                        { [$style._shadowTop]: shadowTop },
                    ]"
                >
                    <div
                        :class="$style.headerTitle"
                        v-html="card.title"
                    />

                    <div :class="$style.headerClose">
                        <UiCloseButton
                            color="primary"
                            @click="$emit('close')"
                        />
                    </div>
                </div>

                <div
                    ref="ModalHomeAdvantagesBody"
                    :class="$style.body"
                >
                    <HomeAdvantageCardLabels
                        v-if="card.tags"
                        :card="card"
                        color="dark"
                        :class="$style.labels"
                    />

                    <img
                        v-if="card.tags !=='firstblock'"
                        :src="card.imageDisplay"
                        alt="Фото"
                        :class="$style.image"
                    >

                    <div
                        v-if="card.text"
                        :class="$style.content"
                        v-html="card.text"
                    />
                </div>

                <div
                    :class="[
                        $style.footer,
                        { [$style._shadowBottom]: shadowBottom },
                    ]"
                >
                    <UiButton
                        color="default"
                        size="big"
                        full-width
                        :disabled="index === 0"
                        @click="changeContent(index -= 1)"
                    >
                        Назад
                    </UiButton>

                    <UiButton
                        color="default"
                        size="big"
                        full-width
                        :disabled="cards[cards.length - 1].tags === 'linkblock'? index === cards.length - 2 : index === cards.length - 1"
                        @click="changeContent(index += 1)"
                    >
                        Далее
                    </UiButton>
                </div>
            </template>
        </div>
    </transition>
</template>

<script>

import UiCloseButton from '~/components/ui/home/UiCloseButton';
import UiButton from '~/components/ui/home/UiButton';
import HomeAdvantageCardLabels from '~/components/common/card/advantage-cards/AdvantageCardLabels';
import { mapGetters } from 'vuex';

export default {
    name: 'ModalHomeAdvantages',

    components: {
        UiCloseButton,
        UiButton,
        HomeAdvantageCardLabels,
    },

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            active: false,
            card: null,
            shadowTop: false,
            shadowBottom: false,
            index: null,
        };
    },

    computed: {
        ...mapGetters({
            cards: 'home/gallery/filteredCards',
        }),

        wrapperClassList() {
            return {
                [this.$style._active]: this.active,
            };
        },
    },

    mounted() {
        this.card = this.cards.find(card => card.id === this.data.id);
        this.index = this.cards.findIndex(card => card.id === this.data.id);

        this.$nextTick(() => {
            this.onScroll();
            this.$refs.ModalHomeAdvantagesBody.addEventListener('scroll', this.onScroll);
        });
    },

    beforeDestroy() {
        this.$refs.ModalHomeAdvantagesBody.removeEventListener('scroll', this.onScroll);
    },

    methods: {
        onScroll() {
            const el = this.$refs.ModalHomeAdvantagesBody;

            if (!el) {
                return;
            }

            if (el.scrollTop === 0) {
                this.shadowTop = false;
            } else {
                this.shadowTop = true;
            }

            if (el.scrollHeight - el.clientHeight === el.scrollTop) {
                this.shadowBottom = false;
            } else {
                this.shadowBottom = true;
            }
        },

        changeContent(index) {
            this.card = this.cards[index];
            this.$refs.ModalHomeAdvantagesBody.scrollTop = 0;
        },

        handleAfterEnter() {
            this.active = true;
        },

        handleBeforeLeave() {
            this.active = false;
        },
    },
};
</script>

<style lang="scss" module>
    .ModalHomeAdvantages {
        max-height: 100vh;
    }

    .wrapper {
        position: absolute;
        top: 0;
        left: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 65.6rem;
        height: 100%;
        padding: 2.8rem;
        background-color: $inversed;
        border-top-left-radius: .4rem;
        border-bottom-left-radius: .4rem;
        transition: all $homeTransition;

        &._active {
            transform: translateX(-100%);
        }
    }

    .header {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1.6rem;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            display: block;
            width: 100%;
            height: 11.6rem;
            background: linear-gradient(180deg, $inversed 74.53%, rgba($base, 0) 100.54%);
            opacity: 0;
            transition: $homeTransition;
        }

        &._shadowTop:before {
            opacity: 1;
        }
    }

    .headerTitle {
        @include old-h2;
    }

    .body {
        overflow: auto;
        display: flex;
        flex-direction: column;
        width: calc(100% + 2.4rem);
        height: 100%;
        padding-right: 2rem;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            border-radius: 8px;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #bbb;
        }

        scrollbar-color: #bbb transparent;
        scrollbar-width: thin;
    }

    .labels {
        margin-bottom: 2.8rem;
    }

    .image {
        width: 100%;
        margin-bottom: 2.8rem;
    }

    .content {
        @include old-p3;

        margin-bottom: 2.6rem;
        color: $home-base-3;
    }

    .footer {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 0 1.2rem;
        width: 100%;
        margin-top: auto;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
            display: block;
            width: 100%;
            height: 8.8rem;
            background: linear-gradient(0deg, $inversed 75%, rgba($base, 0) 123.12%);
            opacity: 0;
            transition: $homeTransition;
        }

        &._shadowBottom:before {
            opacity: 1;
        }
    }

    @include respond-to(sm) {
        .wrapper {
            width: 100%;
        }
    }
</style>
