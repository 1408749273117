// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_1SuyD{max-width:128rem}.content_tebF5{height:100%}@media(max-width:991.98px){.content_tebF5{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}}.title_Hh3-N{margin-bottom:4rem}@media(max-width:767.98px){.title_Hh3-N{margin-bottom:32px}}.logo_katU\\+{width:8rem;height:3rem;margin-bottom:3.2rem;background-position:50%;background-repeat:no-repeat;background-size:contain}@media(max-width:767.98px){.logo_katU\\+{width:80px;height:29px}}.row_ffoc4{display:-webkit-box;display:-ms-flexbox;display:flex;margin:0 -3.2rem}@media(max-width:991.98px){.row_ffoc4{overflow-x:hidden;overflow-y:auto;height:100%;margin:-8px 0;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-overflow-scrolling:touch}}.cell_xsf7K{width:33.33%;padding:0 3.2rem}@media(max-width:991.98px){.cell_xsf7K{width:100%;padding:8px 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_1SuyD",
	"content": "content_tebF5",
	"title": "title_Hh3-N",
	"logo": "logo_katU+",
	"row": "row_ffoc4",
	"cell": "cell_xsf7K"
};
module.exports = ___CSS_LOADER_EXPORT___;
