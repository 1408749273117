<template>
    <symbol id="icon-project-logo" viewBox="0 0 120 44">
        <path d="M16.111 13.8h-4.445L0 44h6.258l2.322-6.052h9.655l1.982-5.057h-9.684l5.578-14.501L25.965 44h6.258L20.557 13.8H16.11zM103.917 13.8H99.472L87.776 44h6.286l2.294-6.052h9.655l1.982-5.057h-9.683l5.578-14.501L113.742 44H120l-11.666-30.2h-4.417zM16.989 0l-2.945 7.718h6.145L23.133 0H16.99zM46.833 13.77h-5.917V44h23.473v-6.052H46.833V13.77zM78.83 13.77h-5.494V44h5.494V13.77z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconProjectLogo',
};
</script>
