// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LikeButton_B8rkJ{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-negative:0;flex-shrink:0;font-size:1.2rem;line-height:1.88;color:#b61e1e;-webkit-transition:opacity .3s linear;transition:opacity .3s linear}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.LikeButton_B8rkJ:hover{opacity:.64}}.LikeButton_B8rkJ._active_BVKvh .icon_WtCJE{fill:#b61e1e;-webkit-animation:heartbeat_OKsoi 1s linear;animation:heartbeat_OKsoi 1s linear}@-webkit-keyframes heartbeat_OKsoi{0%{-webkit-transform:scale(1);transform:scale(1)}20%{-webkit-transform:scale(1.2);transform:scale(1.2)}40%{-webkit-transform:scale(1);transform:scale(1)}60%{-webkit-transform:scale(1.2);transform:scale(1.2)}80%{-webkit-transform:scale(1);transform:scale(1)}to{-webkit-transform:scale(1);transform:scale(1)}}@keyframes heartbeat_OKsoi{0%{-webkit-transform:scale(1);transform:scale(1)}20%{-webkit-transform:scale(1.2);transform:scale(1.2)}40%{-webkit-transform:scale(1);transform:scale(1)}60%{-webkit-transform:scale(1.2);transform:scale(1.2)}80%{-webkit-transform:scale(1);transform:scale(1)}to{-webkit-transform:scale(1);transform:scale(1)}}.icon_WtCJE{width:1.8rem;height:1.8rem;margin-right:1rem;fill:transparent;stroke:#b61e1e;stroke-width:2;will-change:transform;-webkit-transition:fill .3s linear;transition:fill .3s linear}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LikeButton": "LikeButton_B8rkJ",
	"_active": "_active_BVKvh",
	"icon": "icon_WtCJE",
	"heartbeat": "heartbeat_OKsoi"
};
module.exports = ___CSS_LOADER_EXPORT___;
