<template>
    <div
        class="input"
        :class="{ 'input_mobile': !$device.isDesktop }"
    >
        <div class="input__label">
            Ваш номер телефона*
        </div>

        <MaskedInput
            :value="value"
            :mask="mask"
            placeholder="+7 (___) ___-__-__"
            type="tel"
            name="phone"
            class="input__input"
            :class="{ 'input__input_error': isError }"
            @input="$emit('input', $event)"
            @focus="$emit('clearError')"
        />

        <div class="input__error">
            {{ isError ? '*Обязательное поле для заполнения' : '' }}
        </div>
    </div>
</template>

<script>
import MaskedInput from 'vue-text-mask';

export default {
    name: 'TheInput',

    components: {
        MaskedInput,
    },

    props: {
        isError: {
            type: Boolean,
            default: false,
        },

        value: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            mask: ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
        };
    },
};
</script>

<style lang="scss" scoped>
    .input {
        display: flex;
        flex-direction: column;

        &__label {
            margin-bottom: .8rem;
            font-family: 'Aeroport', sans-serif;
            font-size: 1.2rem;
            line-height: 1;
            color: #2a2c2b;
        }

        &__input {
            display: flex;
            align-items: center;
            width: 100%;
            height: 4.5rem;
            padding: 0 2rem;
            border-radius: .4rem;
            border: 1px solid #eef0ef;
            background-color: #eef0ef;
            font-family: 'Aeroport', sans-serif;
            font-size: 1.6rem;
            line-height: 1;
            color: #2a2c2b;

            &_error {
                border: 1px solid #b61e1e;
                color: #b61e1e;
            }
        }

        &__error {
            height: 1.2rem;
            margin-top: .6rem;
            font-family: 'Aeroport', sans-serif;
            font-size: 1.2rem;
            line-height: 1;
            color: #b61e1e;
        }
    }

    .input_mobile {
        .input {
            &__label {
                margin-bottom: 8px;
                font-size: 12px;
            }

            &__input {
                height: 45px;
                padding: 0 20px;
                border-radius: 4px;
                font-size: 16px;
            }

            &__error {
                height: 12px;
                margin-top: 6px;
                font-size: 12px;
            }
        }
    }
</style>
