// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuBlackFridayBanner_kb\\+p1{display:-webkit-box;display:-ms-flexbox;display:flex;cursor:pointer}.MenuBlackFridayBanner_kb\\+p1 .image_TeTZr{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MenuBlackFridayBanner": "MenuBlackFridayBanner_kb+p1",
	"image": "image_TeTZr"
};
module.exports = ___CSS_LOADER_EXPORT___;
