<template>
    <div
        class="checkbox"
        :class="{
            'checkbox_mobile': !$device.isDesktop,
            'checkbox_error': isError,
        }"
        @click="toggle"
    >
        <div class="checkbox__wrap">
            <div class="checkbox__element">
                <img
                    v-if="value"
                    src="/images/pages/landing/icons/check.svg"
                    alt="Иконка"
                    class="checkbox__icon"
                >
            </div>

            <div class="checkbox__label">
                {{ label }}
            </div>
        </div>

        <div class="checkbox__error">
            {{ isError ? '*Обязательное поле для заполнения' : '' }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'TheCheckbox',

    props: {
        value: {
            type: Boolean,
            default: false,
        },

        label: {
            type: String,
            default: '',
        },

        isError: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        toggle() {
            this.$emit('clearError');
            this.$emit('input', !this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
    .checkbox {
        &_error {
            .checkbox__element {
                border: 1px solid #b61e1e;
            }
        }

        &__wrap {
            display: flex;
            align-items: center;
            width: fit-content;
            cursor: pointer;
            user-select: none;
        }

        &__element {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.6rem;
            height: 1.6rem;
            border: 1px solid #2a2c2b;
        }

        &__icon {
            width: 100%;
        }

        &__label {
            margin-left: 1rem;
            font-family: 'Aeroport', sans-serif;
            font-size: 1.2rem;
            line-height: 1;
            color: #2a2c2b;
        }

        &__error {
            height: 1.2rem;
            margin-top: .6rem;
            font-family: 'Aeroport', sans-serif;
            font-size: 1.2rem;
            line-height: 1;
            color: #b61e1e;
        }
    }

    .checkbox_mobile {
        .checkbox {
            &__element {
                width: 16px;
                height: 16px;
            }

            &__label {
                margin-left: 10px;
                font-size: 12px;
            }

            &__error {
                height: 12px;
                margin-top: 6px;
                font-size: 12px;
            }
        }
    }
</style>
