<template>
    <div
        :class="[
            $style.link,
            { [$style.link_active]: isActive },
            { [$style.link_disabled]: disabled },
        ]"
        @mouseenter="$emit('mouseenter')"
        @mouseleave="$emit('mouseleave')"
    >
        <div
            v-if="$slots.default"
            :class="$style.link__icon"
            :style="iconColor ? `color: ${iconColor}` : ''"
        >
            <slot />
        </div>

        <div :class="[$style.link__label, linkClass]">
            {{ label }}
        </div>
    </div>
</template>

<script>
const BUY_LINK_LABEL = 'Купить';

export default {
    name: 'HeaderLink',

    props: {
        label: {
            type: String,
            required: true,
        },

        iconColor: {
            type: String,
            default: '',
        },

        isActive: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        linkClass() {
            return {
                [this.$style.link__gray]: this.label === BUY_LINK_LABEL,
            };
        },
    },
};
</script>

<style lang="scss" module>
    .link {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 1.1rem;
        user-select: none;

        &:hover {
            .link__label {
                color: $home-accent-2;
            }
        }

        &_active {
            .link__label {
                color: $base-900;
            }
        }

        &_disabled {
            pointer-events: none;
        }

        &__icon {
            position: relative;
            width: 1.6rem;
            min-width: 1.6rem;
            height: 1.6rem;
            margin-right: .8rem;
            color: $home-accent-2;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 1.6rem;
                height: auto;
                transform: translate(-50%, -50%);
            }
        }

        &__label {
            @include old-p4;

            text-transform: uppercase;
            font-size: 1.2rem;
            color: $base-500;
            transition: $homeTransition;
        }

        &__gray {
            padding: 1.3rem 2rem 1.3rem 2rem;
            background-color: $base-50;
            color: $base-900;
        }
    }
</style>
