// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu_d9l9v{position:absolute;top:100%;overflow:auto;width:100%;height:calc(100vh - 100%);height:calc(var(--vh, 1vh)*100 - 100%);background-color:#fff;-webkit-transform:translateY(-100%);transform:translateY(-100%);-webkit-transition:1s ease;transition:1s ease}@supports(height:100dvh){.menu_d9l9v{height:calc(100dvh - 100%)}}.menu_withBanner_rfLvo{height:calc(100vh - 100% - 4.8rem);height:calc(var(--vh, 1vh)*100 - 100% - 4.8rem)}@supports(height:100dvh){.menu_withBanner_rfLvo{height:calc(100dvh - 100% - 4.8rem)}}.menu_opened_wNP7g{-webkit-transform:translateY(0);transform:translateY(0)}.menu__wrap_trFwW{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;min-height:100%;padding-top:2.4rem;padding-bottom:2.4rem;background-color:#fff}.menu__top_Lbk4G{-ms-grid-columns:(1fr)[2];grid-template-columns:repeat(2,1fr);grid-gap:1.2rem;gap:1.2rem;margin-bottom:4rem}.menu__middle_zmdni,.menu__top_Lbk4G{display:-ms-grid;display:grid}.menu__middle_zmdni{-ms-grid-columns:(1fr)[2];grid-template-columns:repeat(2,1fr);grid-gap:2.1rem 1.2rem;gap:2.1rem 1.2rem;margin-bottom:3.2rem}.menu__dropdowns_ntTmn{display:-ms-grid;display:grid;grid-gap:2.1rem;gap:2.1rem;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.menu__social_J0kBc{margin-top:auto}.menu_mobile_\\+XE6R .menu__top_Lbk4G{grid-gap:.8rem;gap:.8rem;margin-bottom:3.2rem}.menu_mobile_\\+XE6R .menu__middle_zmdni{-ms-grid-columns:1fr;grid-template-columns:1fr;margin-bottom:2.4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "menu_d9l9v",
	"menu_withBanner": "menu_withBanner_rfLvo",
	"menu_opened": "menu_opened_wNP7g",
	"menu__wrap": "menu__wrap_trFwW",
	"menu__top": "menu__top_Lbk4G",
	"menu__middle": "menu__middle_zmdni",
	"menu__dropdowns": "menu__dropdowns_ntTmn",
	"menu__social": "menu__social_J0kBc",
	"menu_mobile": "menu_mobile_+XE6R"
};
module.exports = ___CSS_LOADER_EXPORT___;
