import { DEFAULT_FILTERS } from '~/assets/js/constants.js';

export default () => ({
    gridView: 'cards', // тип отображения объектов (cards, rows)

    allObjects: [], // массив отображаемых объектов (квартиры, ситихаусы, пентхаусы, баннеры)
    banners: [], // массив карточек-баннеров
    currentPage: 1, // текущая "страница", увеличивается, когда подгружаем еще объекты
    otherTypesOnPage: null, // количество разных типов объектов на каждой странице (1 пентхаус + 3 ситихауса или 0 пентхаусов + 4 ситихауса) и т.д., интерфейс объекта можно посмотреть в actions preLoadObject
    otherTypesPerPage: 4, // количество объектов других типов на одной странице

    flatsLoadedCount: 0, // количество загруженных квартир
    flatsTotalCount: 0, // количество квартир всего
    flatsEndCursor: null, // идентификатор последней загруженной квартиры

    cityHousesLoadedCount: 0, // количество загруженных ситихаусов
    cityHousesTotalCount: 0, // количество ситихаусов всего
    cityHousesEndCursor: null, // идентификатор последнего загруженного ситихауса

    pentHousesLoadedCount: 0, // количество загруженных пентхаусов
    pentHousesTotalCount: 0, // количество пентхаусов всего
    pentHousesEndCursor: null, // идентификатор последнего загруженного пентхауса

    townHousesLoadedCount: 0,
    townHousesTotalCount: 0,
    townHousesEndCursor: null,

    cityUnitsLoadedCount: 0,
    cityUnitsTotalCount: 0,
    cityUnitsEndCursor: null,

    isReloading: false, // отвечает за ожидание ответа от loadObjects
    isLoading: false, // отвечает за ожидание ответа от loadMoreObjects

    // фильтры, спеки и фасеты
    filters: DEFAULT_FILTERS,
    huuQuery: {}, // это фильтр ЧПУ страницы выборщика
    facets: {},
    specs: {},
    lastFiltersPayload: {},

    lastSearch: DEFAULT_FILTERS, // фильтр по прошлому поиску
    isLastSearchShown: false, // показывать или нет "Восстановить" прошлый поиск
});
