// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiLabel_C28vq{position:relative;display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding:1.2rem;background-color:#f3f3f3;text-transform:uppercase;font-size:1.2rem;font-weight:700;line-height:1;color:#18191c}.UiLabel_C28vq._dark_nPizp{background-color:#18191c;color:#fff}.UiLabel_C28vq._dark-transparent_mSpMa{background-color:#757d88;color:#fff}.UiLabel_C28vq._default-transparent_6gnbV{background-color:hsla(0,0%,100%,.24);color:#fff}.UiLabelIconAfter_vvTTg{width:1.2rem;height:1.2rem;margin-left:.4rem}.UiLabelIconBefore_A0RX-{width:1.2rem;height:1.2rem;margin-right:.4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiLabel": "UiLabel_C28vq",
	"_dark": "_dark_nPizp",
	"_dark-transparent": "_dark-transparent_mSpMa",
	"_default-transparent": "_default-transparent_6gnbV",
	"UiLabelIconAfter": "UiLabelIconAfter_vvTTg",
	"UiLabelIconBefore": "UiLabelIconBefore_A0RX-"
};
module.exports = ___CSS_LOADER_EXPORT___;
