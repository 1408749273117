// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".live_Z9caD{width:100%;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"live": "live_Z9caD"
};
module.exports = ___CSS_LOADER_EXPORT___;
