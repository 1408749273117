<template>
    <symbol id="icon-filter" viewBox="0 0 18 18">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.082 7C9.97746 7 9.08203 6.10457 9.08203 5C9.08203 3.89543 9.97746 3 11.082 3C12.1866 3 13.082 3.89543 13.082 5C13.082 6.10457 12.1866 7 11.082 7ZM7.57236 5.00001C7.57236 5.38534 7.63446 5.75617 7.7492 6.10303L0.0703125 6.10303L0.0703125 4.10303L7.68803 4.10303C7.61256 4.38935 7.57236 4.68998 7.57236 5.00001ZM17.9298 6.10303L14.4149 6.10303C14.5296 5.75617 14.5917 5.38534 14.5917 5.00001C14.5917 4.68998 14.5515 4.38935 14.476 4.10303L17.9298 4.10303V6.10303ZM3.67153 11.4454C3.67153 11.8027 3.72499 12.1475 3.82436 12.4723H0.0718521L0.0718521 10.4723H3.8084C3.71928 10.7813 3.67153 11.1078 3.67153 11.4454ZM17.9313 12.4723L10.5283 12.4723C10.6277 12.1475 10.6812 11.8027 10.6812 11.4454C10.6812 11.1078 10.6334 10.7813 10.5443 10.4723L17.9313 10.4723V12.4723ZM5.17676 11.4454C5.17676 12.55 6.07219 13.4454 7.17676 13.4454C8.28133 13.4454 9.17676 12.55 9.17676 11.4454C9.17676 10.3409 8.28133 9.44544 7.17676 9.44544C6.07219 9.44544 5.17676 10.3409 5.17676 11.4454Z"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconFilter',
};
</script>
