<template>
    <ValidationProvider
        v-slot="{ errors }"
        tag="div"
        :name="field"
        :rules="rules"
        :class="$style.UiTextarea"
    >
        <textarea
            :value="value"
            :name="name"
            :placeholder="placeholder"
            :disabled="disabled"
            :class="[
                $style.textarea,
                { [$style._old]: color === 'old' },
                { [$style._error]: errors && errors.length },
            ]"
            @input="onInput"
        />

        <div
            v-if="errors && errors.length"
            :class="$style.error"
        >
            {{ errors[0] }}
        </div>
    </ValidationProvider>
</template>

<script>
export default {
    name: 'UiTextarea',

    props: {
        value: {
            type: [String, Number],
            default: '',
        },

        color: {
            type: String,
            default: 'default',
            validator: value => ['default', 'old'].includes(value),
        },

        placeholder: {
            type: String,
            default: '',
        },

        field: {
            type: String,
            description: 'Имя поля обязательно для валидации',
            required: true,
        },

        name: {
            type: String,
            default: '',
        },

        rules: {
            type: [String, Array, Object],
            description: 'Правила валидации vee-validate',
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onInput(event) {
            this.$emit('input', event.target.value);
        },
    },
};
</script>

<style lang="scss" module>
    .UiTextarea {
        display: flex;
        flex-direction: column;
    }

    .textarea {
        @include old-p4;

        display: flex;
        align-items: center;
        height: 24rem;
        padding: 2rem;
        border: none;
        background-color: $home-accent-6;
        outline: none;
        text-transform: uppercase;
        color: $home-accent-2;
        transition: $homeTransition;

        &::placeholder {
            color: $base-400;
        }

        &:disabled {
            opacity: .56;
            pointer-events: none;
        }

        &._error {
            color: $danger;
        }

        &._old {
            border: 1px solid $base-ease-gray;
            background-color: $inversed;
        }

        @include respond-to(sm) {
            font-size: 16px;
        }
    }

    .error {
        @include old-p6;

        margin-top: .4rem;
        color: $danger;
    }
</style>
