export function prepareDataForRequest(data) {
    const preparedData = { ...data };

    // TODO это кастыль для старого компонента фильтра на странице паркингов
    if (preparedData.features) {
        preparedData.features = preparedData.features.join(',');
    }

    for (const property in preparedData) {
        if (preparedData[property] === null
            || preparedData[property] === ''
            || Array.isArray(preparedData[property]) && preparedData[property].length === 0) {
            delete preparedData[property];
        }
    }

    return preparedData;
}

export function parseDataFromResponse(data) {
    const parsedData = {};

    data.forEach(item => {
        if (item.choices) {
            parsedData[item.name] = [ ...item.choices ];
        }

        if (item.range) {
            parsedData[item.name] = { ...item.range };
        }

        if (item.objects) {
            parsedData[item.name] = [ ...item.objects ];
        }
    });

    return parsedData;
}
