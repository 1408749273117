<template>
    <symbol id="icon-arrow-play" viewBox="0 0 36 36">
        <path d="M0 36V0" fill="none" />
        <path d="M15.6 24.3L24 18l-8.4-6.3v12.6z" />
        <path
            d="M18 0C8 0 0 8 0 18s8 18 18 18 18-8 18-18S27.9 0 18 0zm0 33.6c-8.6 0-15.6-7-15.6-15.6S9.4 2.4 18 2.4s15.6 7 15.6 15.6-7 15.6-15.6 15.6z"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconArrowPlay',
};
</script>
