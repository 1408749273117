<template>
    <symbol id="icon-appstore" viewBox="0 0 32 32">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.8 24.6a8.8 8.8 0 100-17.6 8.8 8.8 0 000 17.6zm.414-10.777l1-1.778-.871-.49-.555.985-.526-.977-.88.474.826 1.535-2.21 3.928H11.3v1h1.136l-.594 1.055.872.49.87-1.545h2.607v-1h-2.045l2.068-3.677zm-.11 1.414l2.582 4.8.88-.474-.572-1.063H20.3v-1h-1.844l-1.472-2.737-.88.474z"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconAppstore',
};
</script>
