<template>
    <button
        class="btn-reset btn"
        :class="{ 'btn_mobile': !$device.isDesktop }"
        @click="$emit('click')"
    >
        {{ label }}
    </button>
</template>

<script>
export default {
    name: 'TheButton',

    props: {
        label: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
    .btn {
        width: fit-content;
        padding: 1.9rem 4rem;
        border-radius: 2.9rem;
        background-color: #2a2c2b;
        text-transform: uppercase;
        font-family: 'Aeroport', sans-serif;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.2;
        color: #fff;
        transition: .3s ease-in-out;

        &:hover {
            background-color: #151515;
        }
    }

    .btn_mobile {
        padding: 19px 40px;
        border-radius: 29px;
        font-size: 16px;
    }
</style>
