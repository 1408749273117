<template>
    <symbol id="icon-tile" viewBox="0 0 16 17">
        <path d="M4 9h3v3H4zM9 9h3v3H9zM4 4h3v3H4zM9 4h3v3H9z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconTile',
};
</script>
