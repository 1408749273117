<template>
    <div :class="[$style.menuRight, {[$style._responsive]: responsive}]">
        <component
            :is="card.link ? 'NuxtLink' : 'div'"
            v-for="(card, i) in cards"
            :key="i"
            :to="card.link"
            :prefetch="Boolean(card.prefetch)"
            :class="$style.card"
            @click.prevent
        >
            <MenuCard
                :title="card.title"
                :text="card.subtitle"
                :color="card.color"
                :text-size="card.textSize"
                :icon="card.icon"
                :icon-component="card.iconComponent"
                @click.native.prevent.stop="card.callback ? card.callback() : onClickLink(card.link)"
            >
                <template v-if="card.iconComponent" #icon>
                    <component :is="card.iconComponent" />
                </template>
            </MenuCard>
        </component>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { applyQuery, queryToObject } from '~/assets/js/utils/queryUtils';
import scrollToSection from '~/assets/js/utils/scrollToSection';
import { DEFAULT_FILTERS } from '~/assets/js/constants';

import MenuCard from './MenuCard';

export default {
    name: 'MenuRight',

    components: {
        MenuCard,
    },

    props: {
        cards: {
            type: Array,
            default: () => [],
        },

        responsive: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        ...mapActions({
            changeFilters: 'flats/changeFilters',
            resetFilters: 'flats/resetFilters',
        }),

        async onClickLink(link) {
            const hashSplit = link.split('#');
            const linkHash = hashSplit.length > 1 ? hashSplit[1] : '';
            if (this.$route.fullPath === link) {
                if (linkHash) {
                    scrollToSection(linkHash);
                } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' }); // то скроллим страницу вверх
                }
            } else if (
                this.$route.path === '/flats/' && // проверяем что находимся на выборщике
                link.includes('/flats/') // и то что пользователь кликнул на меню ссылка которого ссылается на выборщик
            ) {
                await this.resetFilters();
                // и если есть query параметры то тогда применяем их к выборщику
                if (link.split('?').length === 2) {
                    const query = queryToObject(link.split('?')[1]);
                    const filterFromQuery = applyQuery(DEFAULT_FILTERS, query);
                    await this.changeFilters(filterFromQuery);
                }
            } else if (this.$route.path !== '/' && link.includes(this.$route.path) && linkHash) {
                history.replaceState({}, '', `#${linkHash}`);
                scrollToSection(linkHash);
            } else {
                await this.$router.push(link);
            }
        },
    },
};
</script>

<style lang="scss" module>
    .menuRight {
        display: flex;
        align-items: stretch;
        height: 100%;

        &._responsive {
            .card {
                flex: 1;
                width: auto;
            }
        }
    }

    .card {
        width: 32rem;

        &:not(:first-child) {
            margin-left: 2rem;
        }
    }
</style>
