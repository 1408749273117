<template>
    <div
        ref="HeaderMenuMobile"
        :class="[
            $style.menu,
            { [$style.menu_opened]: isOpenedLazy },
            { [$style.menu_withBanner]: !isBannerHidden },
            { [$style.menu_mobile]: $device.isMobile },
        ]"
    >
        <div
            :class="$style.menu__wrap"
            class="container-home"
        >
            <div :class="$style.menu__top">
                <UiButton @click="handleLogIn">
                    <template slot="iconBefore">
                        <UserIcon />
                    </template>

                    <template slot="default">
                        Войти
                    </template>
                </UiButton>

                <UiButton :link="'/favorites/'">
                    <template slot="iconBefore">
                        <HeartIcon />
                    </template>

                    <template slot="default">
                        Избранное
                    </template>
                </UiButton>
            </div>

            <div :class="$style.menu__middle">
                <div :class="$style.menu__dropdowns">
                    <MenuDropdown
                        v-for="link in leftColumn"
                        :key="link.title"
                        v-bind="link"
                        :is-opened="openedDropdown === link.title"
                        @click="toggleDropdown(link.title)"
                        @click-on-link="isOpenedLazy = false"
                    />
                </div>

                <div :class="$style.menu__dropdowns">
                    <MenuDropdown
                        v-for="link in rightColumn"
                        :key="link.title"
                        v-bind="link"
                        :is-opened="openedDropdown === link.title"
                        @click="toggleDropdown(link.title)"
                        @click-on-link="isOpenedLazy = false"
                    />
                </div>
            </div>

            <SocialLinks
                :is-header="true"
                full-width
                :class="$style.menu__social"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import UiButton from '~/components/ui/home/UiButton.vue';
import MenuDropdown from '~/components/layout/header/menu/MenuDropdown.vue';
import SocialLinks from '~/components/layout/SocialLinks.vue';

import UserIcon from '~/assets/icons/home/user.svg?inline';
import HeartIcon from '~/assets/icons/home/heart.svg?inline';
import { BUILDING_STREAM_LINKS, PURCHASE_PAGE_LINKS } from '~/assets/js/constants';

export default {
    name: 'HeaderMenuMobile',

    components: {
        UiButton,
        MenuDropdown,
        SocialLinks,
        UserIcon,
        HeartIcon,
    },

    props: {
        isOpened: {
            type: Boolean,
            default: false,
        },

        isBannerHidden: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            rightColumn: [{
                title: 'Акции и новости',
                url: '/center/',
            }, {
                title: 'Коммерция',
                url: '/commercial/',
            }, {
                title: 'Собственникам',
                url: '/owners/',
            }, {
                title: 'Контакты',
                url: '/contacts/',
            }, {
                title: 'Live',
                items: [{
                    label: 'Онлайн-камеры',
                    callback: () => {
                        this.$modal.open('VideoModal', {
                            src: BUILDING_STREAM_LINKS,
                            aeroModal: false,
                            lazy: true,
                        });
                    },
                }, {
                    label: 'Панорама проекта',
                    callback: () => {
                        this.$modal.open('VideoModal', {
                            src: ['https://tour.virtualland.ru/svl/alia/'],
                            aeroModal: true,
                            lazy: false,
                            withoutPadding: true,
                            isVirtualLand: true,
                        });
                    },
                }, {
                    label: 'Отчёт по ходу строительства',
                    url: '/center/?tab=3',
                }],
            }],

            openedDropdown: null,
            isOpenedLazy: this.isOpened,
        };
    },

    computed: {
        ...mapState('header', [
            'flatCount',
            'cityhouseCount',
            'townhouseCount',
            'cityunitCount',
            'parkingCount',
            'storageCount',
            'penthouseCount',
            'urbanBlocks',
        ]),

        leftColumn() {
            const urbanBlocks = this.urbanBlocks.map(item => ({
                label: `Урбан-блок ${item.name}`,
                url: `/project/${item.slug}/`,
            }));

            const leftColumn = [{
                title: 'Ипотека и рассрочка',
                items: [{
                    label: 'Ипотека',
                    url: PURCHASE_PAGE_LINKS.mortgage,
                }, {
                    label: 'Рассрочка',
                    url: PURCHASE_PAGE_LINKS.installment,
                }, {
                    label: 'Онлайн–бронирование',
                    url: PURCHASE_PAGE_LINKS.booking,
                }],
                // {
                //     label: 'Trade-in',
                //     url: '/trade-in/online-eval/',
                // }
            }, {
                title: 'О проекте',
                items: [{
                    label: 'О проекте',
                    url: '/project/',
                }, ...urbanBlocks],
            }, {
                title: 'О компании',
                url: '/about/',
            }];

            const flats = {
                title: 'Купить',
                items: [],
            };

            if (this.flatCount && this.flatCount !== '0') {
                flats.items.push({
                    label: 'Квартиры',
                    url: '/flats/?newTypeFlat=209',
                });
            }

            if (this.cityhouseCount && this.cityhouseCount !== '0') {
                flats.items.push({
                    label: 'Ситихаусы',
                    url: '/flats/?newTypeFlat=210',
                });
            }

            if (this.townhouseCount && this.townhouseCount !== '0') {
                flats.items.push({
                    label: 'Таунхаусы',
                    url: '/flats/?newTypeFlat=280',
                });
            }

            if (this.penthouseCount && this.penthouseCount !== '0') {
                flats.items.push({
                    label: 'Пентхаусы',
                    url: '/flats/?newTypeFlat=211',
                });
            }
            if (this.cityunitCount && this.cityunitCount !== '0') {
                flats.items.push({
                    label: 'Сити-юниты',
                    url: '/flats/?newTypeFlat=212',
                });
            }

            if (this.parkingCount && this.parkingCount !== '0') {
                flats.items.push({
                    label: 'Машино-места',
                    url: '/parking/',
                });
            }

            if (this.storageCount && this.storageCount !== '0') {
                flats.items.push({
                    label: 'Кладовые',
                    url: '/storage/',
                });
            }

            if (flats.items.length) {
                leftColumn.unshift(flats);
            }

            return leftColumn;
        },
    },

    watch: {
        isOpenedLazy(opened) {
            if (opened) {
                this.openedDropdown = null;
                this.$refs.HeaderMenuMobile.scrollTop = 0;
                disablePageScroll(this.$refs.HeaderMenuMobile);
            } else {
                enablePageScroll();
            }
            this.$emit('change-is-opened', opened);
        },

        isOpened(newVal) {
            this.isOpenedLazy = newVal;
        },
    },

    methods: {
        toggleDropdown(value) {
            if (this.openedDropdown === value) {
                this.openedDropdown = null;
            } else {
                this.openedDropdown = value;
            }
        },

        handleLogIn() {
            if (window?.iflatWidget) {
                window.iflatWidget.show();
            }
        },
    },
};
</script>

<style lang="scss" module>
    .menu {
        position: absolute;
        top: 100%;
        overflow: auto;
        width: 100%;
        height: calc(calc(100 * var(--vh, 1vh)) - 100%);
        background-color: $inversed;
        transform: translateY(-100%);
        transition: 1s ease;

        @supports (height: 100dvh) {
            /* stylelint-disable */
            height: calc(100dvh - 100%);
            /* stylelint-enable */
        }

        &_withBanner {
            height: calc(calc(100 * var(--vh, 1vh)) - 100% - #{$headerBannerHeight});

            @supports (height: 100dvh) {
                /* stylelint-disable */
                height: calc(100dvh - 100% - #{$headerBannerHeight});
                /* stylelint-enable */
            }
        }

        &_opened {
            transform: translateY(0);
        }

        &__wrap {
            display: flex;
            flex-direction: column;
            min-height: 100%;
            padding-top: 2.4rem;
            padding-bottom: 2.4rem;
            background-color: $inversed;
        }

        &__top {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.2rem;
            margin-bottom: 4rem;
        }

        &__middle {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2.1rem 1.2rem;
            margin-bottom: 3.2rem;
        }

        &__dropdowns {
            display: grid;
            gap: 2.1rem;
            height: fit-content;
        }

        &__social {
            margin-top: auto;
        }
    }

    .menu_mobile {
        .menu {
            &__top {
                gap: .8rem;
                margin-bottom: 3.2rem;
            }

            &__middle {
                grid-template-columns: 1fr;
                margin-bottom: 2.4rem;
            }
        }
    }
</style>
