<template>
    <div
        :class="[$style.HeaderBanner, 'HeaderBanner']"
        @mouseenter="sliderApp.autoplay.stop()"
        @mouseleave="sliderApp.autoplay.start()"
    >
        <div :class="['container-home', $style.container]">
            <div :class="$style.wrap">
                <div
                    ref="slider"
                    :class="['swiper-container', $style.slider]"
                >
                    <ul
                        ref="wrapper"
                        class="swiper-wrapper"
                    >
                        <li
                            v-for="(banner, i) in banners"
                            :key="i"
                            :class="$style.slide"
                            class="swiper-slide"
                            @click="onOpenModal"
                        >
                            <transition name="fade">
                                <div
                                    :key="activeIndex"
                                    :class="$style.content"
                                >
                                    <div
                                        v-if="banner.blackFridayDiscount"
                                        :class="$style.blackFridayDiscount"
                                        v-html="banner.blackFridayDiscount"
                                    />

                                    <div
                                        :class="$style.title"
                                        v-html="banner.title"
                                    />

                                    <div
                                        v-if="timerItem && banner.id === timerItem.id"
                                        :class="$style.timer"
                                    >
                                        <div
                                            v-for="(item, j) in parseTimerNumbers"
                                            :key="j"
                                            :class="$style.time"
                                        >
                                            {{ item }}
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </li>
                    </ul>
                </div>

                <div
                    ref="pagination"
                    :class="[$style.pagination, {[$style._hide]: banners.length <= 1}]"
                />

                <transition name="fade">
                    <div
                        v-if="activeItem && activeItem.category === 'PROMO' && $route.path !== '/center/' || activeItem && activeItem.category === 'TIMER' && $route.path !== '/flats/'"
                        :key="isTimerShown"
                        :class="$style.link"
                        @mouseenter="isBtnHover = true"
                        @mouseleave="isBtnHover = false"
                        @click.prevent="onClickMore"
                    >
                        <div :class="$style.text">
                            {{ btnText }}
                        </div>

                        <div :class="$style.arrow">
                            <UiArrowButton
                                size="small"
                                :hovered="isBtnHover"
                            />
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Swiper from 'swiper/js/swiper.min.js';

import UiArrowButton from '~/components/ui/home/UiArrowButton.vue';

export default {
    name: 'HeaderBanner',

    components: {
        UiArrowButton,
    },

    data() {
        return {
            sliderApp: null,

            isBtnHover: false,

            second: 1000,
            minute: 1000 * 60,
            hour: 1000 * 60 * 60,
        };
    },

    computed: {
        ...mapGetters({
            bannerData: 'HeaderBanner/getBannerData',
            timerItem: 'HeaderBanner/getTimerItem',
            isIpadPro: 'device/getIsIpadPro',
        }),

        btnText() {
            if (this.isTimerShown || this.activeItem.blackFridayDiscount) {
                if ((this.$device.isTablet || this.isIpadPro) && this.activeItem.blackFridayDiscount || this.$device.isMobile) {
                    return 'Квартиры';
                } else {
                    return 'Выбрать квартиру';
                }
            }

            return 'Подробнее';
        },

        banners() {
            // if (this.$route.path === '/' || this.$route.path === '/flats/') {
            //     if (this.$route.path === '/flats/') {
            //         return this.bannerData.filter(item => !item.blackFridayDiscount);
            //     }

            //     return this.bannerData;
            // }

            return this.bannerData.filter(item => item.category === 'PROMO');
        },

        activeIndex() {
            return this.sliderApp?.realIndex || 0;
        },

        activeItem() {
            return this.banners[this.activeIndex];
        },

        isTimerShown() {
            return this.activeItem?.id === this.timerItem?.id;
        },

        timerNumbers: {
            get() {
                if (this.timerItem) {
                    let date = Number(this.timerItem.timerEndAt);

                    const hours = Math.floor(date / this.hour);
                    date %= this.hour;

                    const minutes = Math.floor(date / this.minute);
                    date %= this.minute;

                    const seconds = Math.floor(date / this.second);
                    return [hours, minutes, seconds];
                }

                return [];
            },

            set(payload) {
                this.setTime(payload);
            },
        },

        parseTimerNumbers() {
            if (this.timerNumbers.length) {
                return this.timerNumbers.map(str => str < 10 ? `0${str}` : str);
            }

            return null;
        },
    },

    watch: {
        banners() {
            this.destroySlider();

            this.$nextTick(() => {
                this.createSlider();

                if (this.timerNumbers.length) {
                    this.createTimer();
                }
            });
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.createSlider();

            if (this.timerNumbers.length) {
                this.createTimer();
            }
        });
    },

    beforeDestroy() {
        this.destroySlider();
        this.destroyTimer();
    },

    methods: {
        ...mapActions({
            setTime: 'HeaderBanner/setTime',
            deleteTimer: 'HeaderBanner/deleteTimer',
        }),

        destroySlider() {
            if (this.sliderApp) {
                this.sliderApp.destroy();
                this.sliderApp = null;
            }
        },

        createSlider() {
            const options = {
                autoplay: {
                    delay: 5000,
                },

                speed: 1000,

                effect: this.$device.isMobile ? 'fade' : 'slide',
                fadeEffect: {
                    crossFade: true,
                },

                slidesPerView: 1,
                allowTouchMove: true,

                pagination: {
                    el: this.$refs.pagination,
                    bulletClass: this.$style.dot,
                    bulletActiveClass: this.$style._active,
                    clickable: true,
                },
            };

            this.sliderApp = new Swiper(this.$refs.slider, options);
        },

        onOpenModal() {
            this.$modal.open('MortgageModal', {
                bannerData: this.banners,
                activeIndex: this.activeIndex,
            });
        },

        updateTimer() {
            let [hours, minutes, seconds] = this.timerNumbers;

            seconds -=1;
            if (seconds < 0) {
                minutes -= 1;
                seconds = 59;
                if (minutes < 0) {
                    hours -= 1;
                    minutes = 59;
                    if (hours < 0) {
                        this.destroyTimer();
                        this.deleteTimer();
                        return;
                    }
                }
            }

            this.timerNumbers = String(hours * this.hour + minutes * this.minute + seconds * this.second);
        },

        createTimer() {
            this.destroyTimer();

            this.timer = setInterval(this.updateTimer, 1000);
        },

        destroyTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },

        onClickMore() {
            if (this.activeItem && this.activeItem.slug) {
                this.$router.push(this.activeItem.slug);
            } else {
                this.onOpenModal();
            }
        },
    },
};
</script>

<style lang="scss" module>
    .HeaderBanner {
        overflow: hidden;
        width: 100%;
        height: $headerBannerHeight;
        background-color: $base-900;
        cursor: pointer;

        .container {
            height: 100%;
        }

        .wrap {
            position: relative;
            height: 100%;
            padding: 0 12rem;

            @include respond-to(xs) {
                padding: 0 12rem 0 3.6rem;
            }
        }

        .slider {
            height: 100%;
        }

        .slide {
            width: 100%;
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            @include respond-to(xs) {
                justify-content: flex-start;
            }
        }

        .blackFridayDiscount {
            @include l3;

            margin-right: 1.2rem;
            padding: .4rem;
            background-color: #f1e56d;
            white-space: nowrap;

            @include respond-to(xs) {
                @include l5;
            }
        }

        .title {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2rem;
            color: $base-0;
            user-select: none;

            @include respond-to(xs) {
                @include l6;

                color: $base-0;
            }
        }

        .timer {
            display: flex;
            align-items: center;
            margin-left: 1.6rem;

            @include respond-to(xs) {
                margin-left: .8rem;
            }
        }

        .time {
            @include l3;

            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 3.2rem;
            height: 3.2rem;
            background-color: $base-800;
            color: $base-0;

            &:not(:last-child) {
                margin-right: 1.2rem;

                &:before {
                    @include l3;

                    content: ':';
                    position: absolute;
                    top: 50%;
                    left: calc(100% + .4rem);
                    width: .4rem;
                    color: $base-0;
                    transform: translateY(-50%);
                }
            }

            @include respond-to(xs) {
                @include l5;

                min-width: 2.4rem;
                height: 2.4rem;
                color: $base-0;

                &:not(:last-child) {
                    &:before {
                        @include l3;

                        color: $base-0;
                    }
                }
            }
        }

        .link {
            position: absolute;
            top: 50%;
            right: 0;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 1.2rem;
            transform: translateY(-50%);
        }

        .text {
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1;
            color: $base-0;
        }

        .arrow {
            display: flex;
            margin-left: .8rem;
            padding: .4rem;
        }

        .pagination {
            position: absolute;
            top: 50%;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $base-0;
            transform: translateY(-50%);

            &._hide {
                display: none;
            }
        }

        .dot {
            position: relative;
            width: 1.6rem;
            height: 1.6rem;
            margin-right: .2rem;
            margin-left: .2rem;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                width: 100%;
                height: .2rem;
                background-color: $base-0;
                opacity: .44;
                transform: translateY(-50%);
                transition: $transition;

                @include respond-to(xs) {
                    height: .4rem;
                }
            }

            @include respond-to(xs) {
                width: .4rem;
                height: .4rem;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &._active:before {
                opacity: 1;
            }
        }
    }
</style>
