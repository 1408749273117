<template>
    <a
        :href="link"
        :class="$style.link"
        @click.prevent="onClick(link)"
    >
        <div
            v-if="$slots.default"
            :class="$style.link__icon"
        >
            <slot />
        </div>

        <div :class="[$style.link__text, $style.link__text_count]">
            {{ count }}
        </div>

        <div :class="$style.link__text">
            {{ title }}
        </div>
    </a>
</template>

<script>
import { mapActions } from 'vuex';
import { applyQuery, queryToObject } from '~/assets/js/utils/queryUtils';
import { DEFAULT_FILTERS } from '~/assets/js/constants';

export default {
    name: 'MenuLink',

    props: {
        title: {
            type: String,
            required: true,
        },

        link: {
            type: String,
            required: true,
        },

        count: {
            type: String,
            default: '',
        },
    },

    methods: {
        ...mapActions({
            changeFilters: 'flats/changeFilters',
            resetFilters: 'flats/resetFilters',
        }),

        async onClick(link) {
            if (this.$route.fullPath === link) {
                window.scrollTo({ top: 0, behavior: 'smooth' }); // то скроллим страницу вверх
            } else if (this.$route.path === '/flats/' && // проверяем что находимся на выборщике
                link.includes('/flats/') // и то что пользователь кликнул на меню ссылка которого ссылается на выборщик
            ) {
                await this.resetFilters();
                // и если есть query параметры то тогда применяем их к выборщику
                if (link.split('?').length === 2) {
                    const query = queryToObject(link.split('?')[1]);
                    const filterFromQuery = applyQuery(DEFAULT_FILTERS, query);
                    await this.changeFilters(filterFromQuery);
                }
            } else {
                await this.$router.push(link);
            }
        },
    },
};
</script>

<style lang="scss" module>
    .link {
        display: flex;
        align-items: center;
        width: fit-content;
        margin-bottom: 2.8rem;

        &:last-child {
            margin-bottom: initial;
        }

        &:hover {
            .link {
                &__text {
                    color: $base-900;

                    &_count {
                        color: $base-900;
                    }
                }
            }
        }

        &__icon {
            display: flex;
            width: 2.4rem;
            min-width: 2.4rem;
            height: 2.4rem;
            min-height: 2.4rem;
            margin-right: 1.6rem;
            color: $base-900;
        }

        &__text {
            text-transform: uppercase;
            font-size: 2rem;
            font-weight: 700;
            line-height: 1.4;
            color: $base-300;
            transition: $homeTransition;

            &_count {
                margin-right: .5rem;
            }
        }
    }
</style>
