<template>
    <ModalContainer
        :class="[$style.container]"
        :visible="visible"
        is-park-modal
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <InfraItemInfo
            :title="park.title"
            is-only-title
        />
    </ModalContainer>
</template>

<script>
import ModalContainer from './ModalContainer';
import InfraItemInfo from '@/components/play/InfraItemInfo';

export default {
    name: 'ParkModal',

    components: {
        InfraItemInfo,
        ModalContainer,
    },

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        park() {
            return this.data.park;
        },
    },
};
</script>

<style lang="scss" module>
    .container {
        width: 100%;
        // font-weight: 700;
        // font-size: 28px;
        // line-height: 130%;
    }
</style>
