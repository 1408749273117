// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_eAUwD{position:absolute;top:100%;left:50%;width:100%;max-width:584px;background-color:#fff;-webkit-transform:translateX(-50%);transform:translateX(-50%);-webkit-transition:-webkit-transform .3s ease;transition:-webkit-transform .3s ease;transition:transform .3s ease;transition:transform .3s ease,-webkit-transform .3s ease}.wrapper_eAUwD._active_diTDw{-webkit-transform:translateX(-50%) translateY(-100%);transform:translateX(-50%) translateY(-100%)}.header_-ZP4n{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;padding:2.8rem 2.4rem 2.4rem;border-bottom:1px solid #dfe0e2}.headerBar_Nsy7j{position:absolute;top:8px;left:calc(50% - 20px);width:40px;height:4px;background-color:#dfe0e2}.headerTitle_lUMSq{padding-right:1.6rem;text-transform:uppercase;font-size:1.6rem;font-weight:700;line-height:132%;color:#2f2f2f}.body_rAYlH{padding:1.2rem .8rem 2.4rem}.option_b3HgU{padding:1.6rem 2rem;text-transform:uppercase;font-size:1.2rem;font-weight:700;line-height:100%;color:#000;-webkit-transition:all .3s ease;transition:all .3s ease}.option_b3HgU._active_diTDw{background-color:#f3f3f3}.option_b3HgU:not(:last-child){margin-bottom:.8rem}@media(max-width:519.98px){.header_-ZP4n{padding:2.8rem 1.6rem 2.4rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_eAUwD",
	"_active": "_active_diTDw",
	"header": "header_-ZP4n",
	"headerBar": "headerBar_Nsy7j",
	"headerTitle": "headerTitle_lUMSq",
	"body": "body_rAYlH",
	"option": "option_b3HgU"
};
module.exports = ___CSS_LOADER_EXPORT___;
