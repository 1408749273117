<template>
    <symbol id="icon-infra" viewBox="0 0 16 16">
        <path d="M2 2h6.255a.667.667 0 01.596.369l.482.964h4A.667.667 0 0114 4v7.333a.666.666 0 01-.667.667H9.08a.667.667 0 01-.596-.369L8 10.667H3.333v4H2V2z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconIfra',
};
</script>
