<template>
    <symbol id="icon-comment" viewBox="0 0 18 18">
        <path d="M5.076 16.353l-4.41.98.98-4.409A8.333 8.333 0 1117.334 9a8.333 8.333 0 01-12.257 7.353z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconComment',
};
</script>
