// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubscribeForm_FX2lY{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}@media(max-width:991.98px){.SubscribeForm_FX2lY{padding-top:1rem;padding-bottom:0}}@media(max-width:767.98px){.SubscribeForm_FX2lY{padding-top:3.2rem}}.input_P68W4{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;margin-bottom:2.4rem}.button_ibDAv{z-index:1;width:20rem;height:5.6rem;margin-left:1.2rem}@media(max-width:767.98px){.button_ibDAv{width:100%;height:auto;margin-left:0}}.formInputs_3keYC{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:start;width:100%}@media(max-width:767.98px){.formInputs_3keYC{display:block}}@media(max-width:767.98px){.formInfo_hTG-6{margin-top:1.6rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SubscribeForm": "SubscribeForm_FX2lY",
	"input": "input_P68W4",
	"button": "button_ibDAv",
	"formInputs": "formInputs_3keYC",
	"formInfo": "formInfo_hTG-6"
};
module.exports = ___CSS_LOADER_EXPORT___;
