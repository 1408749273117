<template>
    <div
        class="select"
        :class="{ 'select_mobile': !$device.isDesktop }"
    >
        <SelectValue
            :value="value"
            :is-opened="isOpened"
            @click="toggleDropdown"
        />

        <div
            v-if="isOpened"
            class="select__wrap"
        >
            <div class="select__options">
                <div class="select__scroll">
                    <SelectOption
                        v-for="option in options"
                        :key="option.value"
                        :value="option"
                        :is-active="value && option.value === value.value"
                        @click="selectItem(option)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectValue from './SelectValue';
import SelectOption from './SelectOption';

export default {
    name: 'TheSelect',

    components: {
        SelectValue,
        SelectOption,
    },

    props: {
        value: {
            type: Object,
            default: null,
        },

        options: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            isOpened: false,
        };
    },

    mounted() {
        document.addEventListener('click', this.onClickOutside);
    },

    beforeDestroy() {
        document.removeEventListener('click', this.onClickOutside);
    },

    methods: {
        selectItem(value) {
            this.$emit('input', value);
            this.toggleDropdown();
        },

        toggleDropdown() {
            this.isOpened = !this.isOpened;
            if (this.isOpened) {
                this.$emit('update');
            }
        },

        onClickOutside(e) {
            this.isOpened = this.$el.contains(e.target) && this.isOpened;
        },
    },
};
</script>

<style lang="scss" scoped>
    .select {
        position: relative;

        &__wrap {
            position: absolute;
            top: calc(100% + 1rem);
            left: 0;
            z-index: 5;
            width: 100%;
            padding: .4rem .4rem .4rem 0;
            border-radius: .4rem;
            background: #eef0ef;
        }

        &__options {
            @include scroll-style;

            overflow-x: hidden;
            overflow-y: auto;
        }

        &__scroll {
            height: 100%;
            max-height: 22.5rem;
        }
    }

    .select_mobile {
        .select {
            &__wrap {
                top: calc(100% + 10px);
                padding: 4px 4px 4px 0;
                border-radius: 4px;
            }

            &__scroll {
                max-height: 225px;
            }
        }
    }
</style>
