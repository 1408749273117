import mainPageNew from '~/queries/new-home/mainPageNew.graphql';
import projectNavigationUrbanBlocks from '~/queries/pages/project/navigation/projectNavigationUrbanBlocks.graphql';
import { getNodeArray } from '~/assets/js/utils';

export const state = () => ({
    flatCount: '0',
    cityhouseCount: '0',
    parkingCount: '0',
    storageCount: '0',
    penthouseCount: '0',
    townhouseCount: '0',
    cityunitCount: '0',
    urbanBlocks: [],
});

export const actions = {
    async fetchHeaderInfo({ commit }) {
        try {
            const response = await this.$axios.$post('/graphql/', {
                query: mainPageNew.loc.source.body,
            });

            const { data: UrbanBlocksResponse } = await this.$axios.$post('/graphql/', {
                query: projectNavigationUrbanBlocks.loc.source.body,
            });

            const payload = {
                flatCount: String(response.data.mainPageNew.flatCount),
                cityhouseCount: String(response.data.mainPageNew.cityhouseCount),
                parkingCount: String(response.data.mainPageNew.parkingCount),
                storageCount: String(response.data.mainPageNew.storageCount),
                penthouseCount: String(response.data.mainPageNew.penthouseCount),
                townhouseCount: String(response.data.mainPageNew.townhouseCount),
                cityunitCount: String(response.data.mainPageNew.cityunitCount),
                urbanBlocks: getNodeArray(UrbanBlocksResponse, 'aboutProjectUrbanBlocks'),
            };

            commit('SET_HEADER_INFO', payload);
        } catch (e) {
            console.log('[Vuex] header/setHeaderInfo: Не удалось загрузить', e);
        }
    },
};

export const mutations = {
    SET_HEADER_INFO(state, payload) {
        state.flatCount = payload.flatCount;
        state.cityhouseCount = payload.cityhouseCount;
        state.parkingCount = payload.parkingCount;
        state.storageCount = payload.storageCount;
        state.urbanBlocks = payload.urbanBlocks;
        state.penthouseCount = payload.penthouseCount;
        state.townhouseCount = payload.townhouseCount;
        state.cityunitCount = payload.cityunitCount;
    },
};
