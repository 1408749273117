<template>
    <svg
        :class="$style.SuccessIcon"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 380 348"
    >
        <circle
            :class="$style.circle"
            cx="174"
            cy="174"
            r="173.5"
            stroke="#dbdbdb"
        />
        <mask
            id="a"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="380"
            height="348"
        >
            <path
                d="M174 348c96.098 0 174-77.902 174-174h32V48h-86C262.781 18.258 220.523 0 174 0 77.903 0 0 77.903 0 174c0 96.098 77.903 174 174 174z"
                fill="#dbdbdb"
            />
        </mask>
        <g mask="url(#a)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M379 92L207.162 358H50.838L-31 182H77.162l56.726 121.994L270.838 92H379z"
                fill="#000"
            />
        </g>
    </svg>
</template>

<script>

export default {
    name: 'SuccessIcon',

    data() {
        return {
            animation: null,
        };
    },
};
</script>

<style lang="scss" module>
    .SuccessIcon {
        width: 38rem;
        height: 34.8rem;
        transform-origin: center;
        animation: appear .5s linear forwards;

        @include respond-to(xs) {
            width: 254px;
            height: 232px;
        }
    }

    @keyframes appear {
        0% {
            transform: scale(0);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }

</style>
