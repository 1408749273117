<template>
    <symbol id="icon-arrow-next" viewBox="0 0 25 17">
        <path d="M22.357 8.595l1.06 1.06 1.061-1.06-1.06-1.06-1.061 1.06zM.99 10.095h21.367v-3H.99v3zm22.428-2.56L16.08.196 13.959 2.32l7.337 7.337 2.122-2.122zm-2.122 0l-7.322 7.322 2.121 2.121 7.323-7.322-2.122-2.122z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconArrowNext',
};
</script>
