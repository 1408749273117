<template>
    <ModalContainer
        :class="[$style.container, {[$style._isSubModal]: data.type === 'subscribe'}]"
        :visible="visible"
        :is-subscribe-modal="data.type === 'subscribe'"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <div :class="[$style.body, {[$style._success] : formSuccess || formFailure}, $style.bodyContent]">
            <h3
                :class="[$style.title, 'h2 _base']"
                v-html="data.title"
            >
            </h3>
            
            <h4
                v-if="data.subtitle"
                :class="[$style.subtitle]"
                v-html="data.subtitle"
            />
            
            <ValidationObserver
                v-slot="{ handleSubmit }"
                tag="div"
            >
                <TheForm
                    :class="$style.form"
                    :button-label="data.buttonLabel"
                    @submit="handleSubmit(() => onFormSubmit($event))"
                >

                    <input
                        type="hidden"
                        name="category"
                        :value="data.type"
                    >

                    <input
                        v-if="data.flatId"
                        type="hidden"
                        name="flat"
                        :value="data.flatId"
                    >

                    <input
                        v-if="data.subtype"
                        type="hidden"
                        name="subtype"
                        :value="data.subtype"
                    >

                    <UiInput
                        :class="$style.input"
                        name="name"
                        type="text"
                        :label="nameLabel"
                        is-require
                        :error="getInputError('name')"
                        @input="oninputChange"
                        @change="oninputChange"
                    />

                    <UiInput
                        v-if="data.type !== 'subscribe'"
                        :class="$style.input"
                        name="phone"
                        type="tel"
                        label="Телефон"
                        is-mask
                        is-require
                        :error="getInputError('phone')"
                        @input="oninputChange"
                        @change="oninputChange"
                    />

                    <UiInput
                        v-if="data.type === 'parking-storage-booking' || data.type === 'subscribe'"
                        :class="$style.input"
                        name="email"
                        type="text"
                        label="Email"
                        :error="getInputError('email')"
                        @input="oninputChange"
                        @change="oninputChange"
                    />

                    <div v-if="data.type === 'consultation'" :class="$style.consultation">
                        <UiInput
                            :class="$style.input"
                            name="email"
                            type="text"
                            label="E-mail"
                            is-require
                            :error="getInputError('email')"
                            @input="oninputChange"
                            @change="oninputChange"
                        />

                        <div :class="$style.consultation__radios">
                            <UiRadio
                                :class="$style.consultation__radio"
                                name="time"
                                label="Сейчас"
                                value="now"
                                :checked="true"
                                @input="onRadioChange"
                                @change="onRadioChange"
                            />

                            <UiRadio
                                :class="$style.consultation__radio"
                                name="time"
                                label="Выбор времени"
                                value="time"
                                @input="onRadioChange"
                                @change="onRadioChange"
                            />
                        </div>

                        <transition name="fade">
                            <div v-if="timePicked === 'time'" :class="$style.timepicker">
                                <span :class="$style.timepicker__text">Перезвоним вам в</span>

                                <div :class="$style.timepicker__wrap">
                                    <VueTimepicker
                                        v-model="initialTimeValue"
                                        :minute-interval="5"
                                        :hour-range="[[11, 22]]"
                                        input-width="100%"
                                        :input-class="$style.timepicker__input"
                                        hide-clear-button
                                        hide-disabled-hours
                                        drop-direction="up"
                                        hour-label="Час"
                                        minute-label="Мин."
                                    />
                                </div>
                            </div>
                        </transition>
                    </div>

                    <template #after>
                        <div :class="$style.policy">
                            <FormPolicy />
                        </div>
                    </template>

                </TheForm>

            </ValidationObserver>

            <transition name="fade">
                <TheFormSuccess
                    v-if="formSuccess"
                    :class="$style.formSuccess"
                    :title="data.type === 'subscribe' ? 'Успешно!' : 'Заявка отправлена'"
                    :description="data.type === 'subscribe' ? 'Вы подписались на рассылку обновлений' : 'Спасибо за заявку, наш менеджер свяжется с Вами в ближайшее время.'"
                    @click="$emit('close')"
                />
            </transition>

            <transition name="fade">
                <div
                    v-if="formFailure"
                    :class="[$style.TheFormFailure, $style.formSuccess]"
                >
                    <h3 :class="[$style.title, 'h2 _base']">
                        Заявка
                        не отправлена
                    </h3>
                    <div :class="$style.description">
                        К сожалению данная квартира уже забронирована. Наш менеджер перезвонит Вам для уточнения информации.
                    </div>

                    <UiLink
                        :class="$style.link"
                        type="button"
                        url="/flats/"
                    >
                        Показать похожие квартиры
                    </UiLink>
                </div>
            </transition>
        </div>
    </ModalContainer>
</template>

<script>
// utils
import FormMixin from '@/assets/js/mixins/FormMixin';
// components
import ModalContainer from './ModalContainer';
import TheForm from '../TheForm';
import UiInput from '../../ui/input/UiInput';
import TheFormSuccess from '../form/TheFormSuccess';
import UiRadio from '@/components/ui/input/UiRadio';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import UiLink from '../../ui/UiLink';
import FormPolicy from '~/components/common/form/FormPolicy.vue';

export default {
    name: 'CommonForm',

    components: {
        FormPolicy,
        UiLink,
        UiRadio,
        TheFormSuccess,
        UiInput,
        TheForm,
        ModalContainer,
        VueTimepicker,
    },

    mixins: [FormMixin],

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },

    data() {
        return {
            initialTimeValue: {
                HH: '11',
                mm: '00',
            },

            timePicked: 'now',
        };
    },

    computed: {
        nameLabel() {
            return this.data.type === 'parking-storage-booking' ?
                'ФИО'
                : 'Имя';
        },
    },

    methods: {
        onRadioChange(evt) {
            this.oninputChange();
            this.timePicked = evt.target.value;
        },
    },
};
</script>

<style lang="scss" module>
    .container {
        width: 64rem;

        &._isSubModal {
            width: 97rem;

            .body {
                margin-left: 47.8rem;
            }

            .title {
                margin-bottom: 1.2rem;
            }
        }

        @include respond-to(sm) {
            &._isSubModal {
                width: 74rem;

                .body {
                    margin-left: 33.8rem;
                }
            }
        }

        @include respond-to(xs) {
            width: 100vw;

            &._isSubModal {
                width: 100vw;

                .body {
                    margin-left: 0;
                }
            }
        }
    }

    .body {
        position: relative;
        z-index: 1;
        width: 100%;

        @include respond-to(xs) {
            overflow-y: auto;
            height: 100%;
        }

        &._success {
            min-height: 54rem;

            @include respond-to(xs) {
                min-height: 100%;
            }

            & > form {
                visibility: hidden;
            }
        }
    }

    .info {
        display: flex;
        align-items: center;
        margin-bottom: 4rem;
        cursor: pointer;

        @include hover {
            &:hover {
                .infoIconWrap:after {
                    width: 3.8rem;
                    height: 3.8rem;
                }
            }
        }

        @include respond-to(xs) {
            padding-left: 2px;
        }
    }

    .infoIconWrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.2rem;
        height: 3.2rem;
        margin-right: 1.2rem;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 50%;
            border: 1px solid $base-light-gray;
            transform: translate(-50%, -50%);
            transition: all .3s;
        }

        svg {
            width: 1.6rem;
            height: 1.6rem;
        }
    }

    .policy {
        margin-top: 4rem;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.64;
        color: $base-gray;

        & > a {
            text-decoration: underline;
        }
    }

    .formSuccess {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    .title {
        margin-bottom: 4rem;

        &._booking {
            margin-bottom: 2rem;
        }
    }

    .subtitle {
        margin-bottom: 4rem;
        font-size: 1.4rem;
        font-weight: 600;
        color: #616161;

        @include respond-to(sm) {
            font-size: 14px;
            line-height: 1.4;
        }
    }

    .consultation {
        display: flex;
        flex-direction: column;

        &__radios {
            display: flex;
        }

        &__radio {
            margin-right: 3.6rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .timepicker {
        display: flex;
        align-items: center;
        margin-top: 2rem;

        &__wrap {
            width: 130px;
        }

        &__input {
            border: none !important; //stylelint-disable-line declaration-no-important
            text-align: center;
        }
    }

    .row {
        display: flex;
        justify-content: space-between;

        .input {
            width: calc(50% - 1.6rem);
        }

        @include respond-to(xs) {
            .input {
                width: 100%;
            }
        }
    }

    .TheFormFailure {
        background-color: $base-inverse;
    }

    .description {
        margin-bottom: 4rem;
    }

    .link {
        margin-bottom: 4rem;
    }
</style>
