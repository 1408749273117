export const DEFAULT_FILTERS = {
    priceMin: '',
    priceMax: '',
    areaMin: '',
    areaMax: '',
    floorMin: '',
    floorMax: '',
    layout: [],
    decoration: [],
    usp: [], // TODO устарело, но возможно где-то еще используется
    orderBy: '',
    building: [],
    section: [],
    typeFlat: [], // TODO устарело, но возможно где-то еще используется
    isBlackFriday: '',
    isReady: '',
    isSalesStart: '',
    window_view: [],
    status: '',
    key_distribution: [],
    sale: '',
    sale25: '', // специальное предложение для ростеха
    sale2: '', // новое специальное предложение для ростеха
    urbanBlock: [],
    scenario: '',
    subgroup: '',
    uspLayout: [],
    uspBalcony: [],
    uspLayoutFeatures: [],
    newTypeFlat: [],
};

export const SPECS_ADDITIONAL_INFORMATION = {
    layout: {
        // eslint-disable-next-line no-useless-computed-key
        ['2е']: {
            description: 'Одна спальня + кухня-гостиная',
        },
        // eslint-disable-next-line no-useless-computed-key
        ['3е']: {
            description: 'Две спальни + кухня-гостиная',
        },
        // eslint-disable-next-line no-useless-computed-key
        ['4е']: {
            description: 'Три спальни + кухня-гостиная',
        },
        // eslint-disable-next-line no-useless-computed-key
        ['5е']: {
            description: 'Четыре спальни + кухня-гостиная',
        },
    },

    newTypeFlat: {
        // eslint-disable-next-line no-useless-computed-key
        ['210']: {
            tooltip: 'Ситихаус — двухуровневое помещение на первом этаже жилого корпуса, с отдельным входом, высокими потолками и фасадным остеклением.',
        },
        // eslint-disable-next-line no-useless-computed-key
        ['211']: {
            tooltip: 'Пентхаус — это просторная резиденция на 25 этаже с панорамными окнами, высокими потолками и великолепными видами на четыре стороны света.',
        },
        // eslint-disable-next-line no-useless-computed-key
        ['212']: {
            tooltip: 'Сити-юнит — двухуровневое помещение на первом этаже жилого корпуса, с отдельным входом, высокими потолками и фасадным остеклением.',
        },
    },
};

export const PURCHASE_PAGE_META = {
    purchase: {
        h1: 'Способы покупки',
        metaTitle: 'Способы покупки',
        metaDescription: 'Способы покупки.',
    },
    mortgage: {
        h1: 'Покупка квартиры с помощью ипотеки',
        metaTitle: 'Покупка квартиры с помощью ипотеки',
        metaDescription: 'Покупка квартиры с помощью ипотеки.',
    },
    installment: {
        h1: 'Покупка квартиры в рассрочку',
        metaTitle: 'Покупка квартиры в рассрочку',
        metaDescription: 'Покупка квартиры в рассрочку.',
    },
    booking: {
        h1: 'Бронирование квартиры',
        metaTitle: 'Бронирование квартиры',
        metaDescription: 'Бронирование квартиры.',
    },
};

// словарь статусов для модели коммерческих помещений
export const COMMERCIAL_STATUSES = {
    // eslint-disable-next-line quote-props
    'Продана': '0',
    'В продаже': '1',
    // eslint-disable-next-line quote-props
    'Бронь': '2',
    // eslint-disable-next-line quote-props
    'Оценка': '3',
    'Страт. резерв': '4',
};

// Категории баннеров для раскрывающегося меню
export const MENU_BANNER_TYPES = {
    BUY: 'Купить',
    WAY_TO_BUY: 'Способы покупки',
    ABOUT_PROJECT: 'О проекте',
};

export const ERROR_MESSAGES = {
    SERVER_ERROR: 'Internal Server Error',
    PAGE_NOT_FOUND: 'This page could not be found',
    TRANSLATED_SERVER_ERROR: 'Внутренняя ошибка сервера',
    TRANSLATED_PAGE_NOT_FOUND: 'Что-то пошло не так... Страница не найдена',
};

// Ссылки на стримы, пока реализации их загрузки нет в админке вынес в константу, потому что используется в футере и хедере
export const BUILDING_STREAM_LINKS = [
    // 'https://watcher.teslatel.ru/vsaas/embed/dahua1-e7eedc12c3?dvr=false&token=2.A4CkXNeJAAEABeHzoDrhWRtXwkobrLEMmBs_nZxCgnMq23Vx',
    'https://watcher.teslatel.ru/vsaas/embed/hik1-ed1fd4d491?dvr=false&token=3.gVZ5-cvNAAAAAAAAAAwABiUPHqlsakBo065OlzjEqGGSSg8AtgUp-C19',
    'https://watcher.teslatel.ru/vsaas/embed/hik3-fd3e5d8049?dvr=false&token=3.gVZ5-cvNAAAAAAAAAAwABiUPHqlsai7V26sSnTfzp-cva3nwT-Bp_iC0',
    // 'https://watcher.teslatel.ru/vsaas/embed/hik1-ed1fd4d491?dvr=false&token=2.A4CkXNeJAAEABeHzoDrhWbTz2pc7ps0ykndoPc3QUi1lbRfL',
];

export const PURCHASE_PAGE_LINKS = {
        mortgage: '/lgotnay-ipoteka/',
        installment: '/rassrochka/',
        booking: '/bronirovanie/',
}



export const BANNERS_TEXT_CONTENT = {
    BLACK_FRIDAY_ALT: "Черная пятница",
    BLACK_FRIDAY_BUTTON: "Узнать подробнее",
    RIVER_PROJECT_ALT: "Старт бронирования дома на набережной Москвы-реки",
    FLATS_TITLE: "Скидка до",
    FLATS_SPAN_TEXT: "на квартиры",
    FLATS_BUTTON: "Выбрать квартиру",
    INSTALLMENT_ALT: "0% рассрочка",
    INSTALLMENT_BUTTON: "Узнать подробнее",
    DISCOUNT_TEXT: 'Скидка до 15%',
    DISCOUNT_ITEM_TEXT: 'на квартиры месяца',
};
