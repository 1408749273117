// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiTextarea_vCiiM{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.textarea_PBBUB,.UiTextarea_vCiiM{display:-webkit-box;display:-ms-flexbox;display:flex}.textarea_PBBUB{font-size:1.4rem;font-weight:700;line-height:1;letter-spacing:normal;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:24rem;padding:2rem;border:none;background-color:#f4f4f5;outline:none;text-transform:uppercase;color:#2f2f2f;-webkit-transition:.3s ease;transition:.3s ease}.textarea_PBBUB::-webkit-input-placeholder{color:#a0a0a0}.textarea_PBBUB::-moz-placeholder{color:#a0a0a0}.textarea_PBBUB:-ms-input-placeholder{color:#a0a0a0}.textarea_PBBUB::-ms-input-placeholder{color:#a0a0a0}.textarea_PBBUB::placeholder{color:#a0a0a0}.textarea_PBBUB:disabled{opacity:.56;pointer-events:none}.textarea_PBBUB._error_uQZnb{color:#e04b4b}.textarea_PBBUB._old_Tr0OA{border:1px solid #dbdbdb;background-color:#fff}@media(max-width:991.98px){.textarea_PBBUB{font-size:16px}}.error_nQ6UQ{font-size:1.2rem;line-height:1.5;letter-spacing:normal;margin-top:.4rem;color:#e04b4b}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiTextarea": "UiTextarea_vCiiM",
	"textarea": "textarea_PBBUB",
	"_error": "_error_uQZnb",
	"_old": "_old_Tr0OA",
	"error": "error_nQ6UQ"
};
module.exports = ___CSS_LOADER_EXPORT___;
