// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuDropdown_Ba-lI{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.header_3S995,.MenuDropdown_Ba-lI{display:-webkit-box;display:-ms-flexbox;display:flex}.header_3S995{-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:.4rem}.title_AdKlP{font-size:2rem;font-weight:700;line-height:2.6rem;letter-spacing:normal;text-transform:uppercase;color:#717171;-webkit-transition:.3s ease;transition:.3s ease}.title_AdKlP._opened_c0iam{color:#2f2f2f}.subTitle_fgEeF{font-size:2rem;font-weight:700;line-height:2.6rem;letter-spacing:normal;margin-left:.4rem;color:#757d88}.icon_XvDgw{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:1.6rem;height:1.6rem;margin-left:.8rem;-webkit-transition:.3s ease;transition:.3s ease}.icon_XvDgw._opened_c0iam{-webkit-transform:rotate(180deg);transform:rotate(180deg)}.arrow_ZmIVX{width:1.6rem;height:1.6rem;color:#757d88}.body_qCMZz{overflow:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-transition:.3s ease;transition:.3s ease}.link_R4RGe{font-size:1.4rem;line-height:1.4;letter-spacing:normal;margin-top:1.2rem;text-transform:uppercase;color:#717171}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MenuDropdown": "MenuDropdown_Ba-lI",
	"header": "header_3S995",
	"title": "title_AdKlP",
	"_opened": "_opened_c0iam",
	"subTitle": "subTitle_fgEeF",
	"icon": "icon_XvDgw",
	"arrow": "arrow_ZmIVX",
	"body": "body_qCMZz",
	"link": "link_R4RGe"
};
module.exports = ___CSS_LOADER_EXPORT___;
