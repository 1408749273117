<template>
    <symbol id="icon-facebook" viewBox="0 0 32 32">
        <path d="M16 7.2c-4.86 0-8.8 3.964-8.8 8.854 0 4.418 3.218 8.081 7.425 8.746v-6.188H12.39v-2.558h2.235v-1.95c0-2.22 1.313-3.445 3.324-3.445.963 0 1.97.173 1.97.173v2.178h-1.11c-1.093 0-1.434.682-1.434 1.383v1.66h2.44l-.39 2.56h-2.05V24.8c4.207-.664 7.425-4.329 7.425-8.746 0-4.89-3.94-8.854-8.8-8.854z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconFacebook',
};
</script>
