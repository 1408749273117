<template>
    <div
        class="social"
        :class="{
            'social_tablet': isTablet,
            'social_mobile': isMobile,
        }"
    >
        <div class="social__text">
            Если вы хотите связаться самостоятельно:
        </div>

        <a
            href="tel:+74951220000"
            class="social__wrap social__wrap_1"
        >
            <img
                src="/images/pages/landing/icons/phone.svg"
                alt="Телефон"
                class="social__icon social__icon_tel"
            >

            <div class="social__link">
                +7 495 122 00 00
            </div>
        </a>

        <a
            href="https://t.me/asterus_alia"
            target="_blank"
            class="social__wrap social__wrap_2"
        >
            <img
                src="/images/pages/landing/icons/telegram.svg"
                alt="Телеграм"
                class="social__icon social__icon_telegram"
            >

            <div class="social__link">
                Telegram-бот
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: 'TheSocial',

    computed: {
        isDesktop() {
            return this.$parent.isDesktop;
        },

        isTablet() {
            return this.$parent.isTablet;
        },

        isMobile() {
            return this.$parent.isMobile;
        },
    },
};
</script>

<style lang="scss" scoped>
    .social {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: "text wrap1" "text wrap2";
        grid-row-gap: 2rem;
        grid-column-gap: 5rem;
        padding-top: 2rem;
        border-top: 1px solid #2a2c2b;

        &__text {
            font-family: 'Aeroport', sans-serif;
            font-size: 1.2rem;
            line-height: 1.2;
            color: #2a2c2b;
            grid-area: text;
        }

        &__wrap {
            display: flex;
            align-items: center;
            width: fit-content;

            &_1 {
                grid-area: wrap1;
            }

            &_2 {
                display: none;
                grid-area: wrap2;
            }
        }

        &__icon {
            margin-right: 2rem;

            &_tel {
                width: 1.7rem;
                min-width: 1.7rem;
            }

            &_telegram {
                width: 2.1rem;
                min-width: 2.1rem;
            }
        }

        &__link {
            font-family: 'Aeroport', sans-serif;
            font-size: 1.6rem;
            line-height: 1.4;
            color: #2a2c2b;
        }
    }

    .social_tablet {
        grid-row-gap: 20px;
        grid-column-gap: 50px;
        padding-top: 20px;

        .social {
            &__text {
                font-size: 12px;
            }

            &__icon {
                margin-right: 20px;

                &_tel {
                    width: 17px;
                    min-width: 17px;
                }

                &_telegram {
                    width: 21px;
                    min-width: 21px;
                }
            }

            &__link {
                font-size: 16px;
            }
        }
    }

    .social_mobile {
        grid-template-columns: 1fr;
        grid-template-areas: "text" "wrap1" "wrap2";
        grid-row-gap: 12px;
        grid-column-gap: initial;
        padding-top: 36px;

        .social {
            &__text {
                margin-bottom: 8px;
            }
        }
    }
</style>
