// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menuLeft_ToCaW{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:100%}.menuLeft__links_r-ylK{margin-bottom:2.8rem}.menuLeft__social_kIPrs{margin-top:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuLeft": "menuLeft_ToCaW",
	"menuLeft__links": "menuLeft__links_r-ylK",
	"menuLeft__social": "menuLeft__social_kIPrs"
};
module.exports = ___CSS_LOADER_EXPORT___;
