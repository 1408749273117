<template>
    <ModalContainer
        :visible="visible"
        :class="$style.body"
        @after-leave="$emit('after-leave')"
        @close="visible = false"
    >
        <div :class="$style.modalWrapper">
            <div :class="$style.title">{{ data.title }}</div>
            <div :class="$style.text">Благодарим за регистрацию на экскурсию в жилой район ÁLIA 22 сентября 2022 года! Накануне мы пришлём вам напоминание и организационную информацию. До встречи в ÁLIA! </div>
            <div :class="$style.signature">Команда ASTERUS</div>
            <div :class="$style.imageContainer">
                <img src="/images/pages/rosteh/rosteh-form-logo.png" alt="">
            </div>
        </div>
    </ModalContainer>
</template>

<script>
import ModalContainer from '~/components/common/modals/ModalContainer';
export default {
    name: 'RostehSucceededFormSend',
    components: { ModalContainer },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            visible: true,
        };
    },
};
</script>

<style lang="scss" module>
    .body {
        position: absolute;
        top: calc(76rem - 67%);
        left: calc(32rem + 15%);
        width: 64rem;
        min-height: 76rem;
        padding: 0;
        background-color: #fff;
        font-family: $font-family;
        color: #000;

        @include respond-to(sm) {
            top: 15%;
            left: 15%;
            width: 70%;
            min-height: 70%;
        }

        @include respond-to(xxs) {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 100%;
        }
    }

    .modalWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;

        @include respond-to(xxs) {
            justify-content: flex-start;
        }
    }

    .title {
        margin-bottom: 2rem;
        font-size: 4rem;
        font-weight: 700;
        line-height: 140%;

        @include respond-to(xxs) {
            max-width: 18rem;
            font-size: 2.8rem;
        }
    }

    .text {
        margin-bottom: 3.2rem;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 188%;

        @include respond-to(xxs) {
            font-size: 1.2rem;
        }
    }

    .signature {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 146%;

        @include respond-to(xxs) {
            margin-bottom: 3.7rem;
        }
    }

    .imageContainer {
        align-self: center;
        width: 38rem;
        height: 34.8rem;

        @include respond-to(xxs) {
            position: absolute;
            bottom: 10%;
            width: 25.3rem;
            height: 23.2rem;
            justify-self: flex-end;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
</style>
