// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slider_7Fc7S{position:relative}.slider_7Fc7S:before{content:\"\";display:block;width:100%;padding-top:60.8053691275%}.slider_7Fc7S>.aspect-content_f-yjv{position:absolute;top:0;left:0;width:100%;height:100%}@media(max-width:767.98px){.slider_7Fc7S{position:relative}.slider_7Fc7S:before{content:\"\";display:block;width:100%;padding-top:61.1111111111%}.slider_7Fc7S>.aspect-content_f-yjv{position:absolute;top:0;left:0;width:100%;height:100%}}.container_uM2B5{position:absolute;top:0;left:0}.container_uM2B5,.slide_cDS44{width:100%;height:100%}.slide_cDS44{overflow:hidden;-webkit-transform:translateZ(0);transform:translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden}.image_cid4x{height:100%}.footer_21Ink{position:relative;margin:2rem -10.7rem 0}@media(max-width:767.98px){.footer_21Ink{margin:16px -16px 0}}.thumbs_Ty6E4{padding:0 10.7rem}@media(max-width:767.98px){.thumbs_Ty6E4{padding:0 16px}}.thumbsSlide_K8O4I{overflow:hidden;width:8.6rem;height:5.2rem;cursor:pointer;-webkit-backface-visibility:hidden;backface-visibility:hidden;position:relative}.thumbsSlide_K8O4I,.thumbsSlide_K8O4I:before{-webkit-transform:translateZ(0);transform:translateZ(0)}.thumbsSlide_K8O4I:before{content:\"\";position:absolute;z-index:1;top:0;left:0;display:block;width:100%;height:100%;background-color:rgba(0,0,0,.4);opacity:0;-webkit-transition:background-color .3s linear,opacity .3s linear;transition:background-color .3s linear,opacity .3s linear}.thumbsSlide_K8O4I._active:before{opacity:1}.btn_BOZIz{position:absolute;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}@media(max-width:767.98px){.btn_BOZIz{display:none}}.btn_BOZIz._next_PqmEg{right:3.2rem}.btn_BOZIz._prev_H24SX{left:3.2rem}.paginaton_UasaY{position:absolute;top:50%;left:50%;font-size:1.4rem;font-weight:700;line-height:1.46;color:#fff;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": "slider_7Fc7S",
	"aspect-content": "aspect-content_f-yjv",
	"container": "container_uM2B5",
	"slide": "slide_cDS44",
	"image": "image_cid4x",
	"footer": "footer_21Ink",
	"thumbs": "thumbs_Ty6E4",
	"thumbsSlide": "thumbsSlide_K8O4I",
	"btn": "btn_BOZIz",
	"_next": "_next_PqmEg",
	"_prev": "_prev_H24SX",
	"paginaton": "paginaton_UasaY"
};
module.exports = ___CSS_LOADER_EXPORT___;
