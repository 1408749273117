import { getCookieFromString } from '../assets/js/utils';

export const state = () => ({
    likes_ids: [],
    current_session_likes: [],
});

export const actions = {
    readLikesFromCookies({ state, commit }, { req }) {
        try {
            const savedLikes = getCookieFromString(req?.headers.cookie, 'likes', false);
            if (savedLikes) {
                commit('ADD_LIKES_IDS', JSON.parse(decodeURIComponent(savedLikes)));
            }
        } catch (e) {
            console.log('[Vuex] center/readLikesFromCookies: Не удалось загрузить', e);
        }
    },

    addLike({ state, commit }, postId) {
        commit('ADD_CURRENT_SESSION_LIKES', postId);
        commit('ADD_LIKES_IDS', [...state.likes_ids, postId]);
    },

    setLikesCookies({ state }) {
        const encodedLikes = encodeURIComponent(JSON.stringify(state.likes_ids));
        document.cookie = `likes=${encodedLikes}`;
    },

};

export const mutations = {
    ADD_LIKES_IDS(state, payload) {
        state.likes_ids = payload;
    },

    ADD_CURRENT_SESSION_LIKES(state, payload) {
        state.current_session_likes.push(payload);
    },
};
