<template>
    <transition
        name="overlay-appear"
    >
        <div
            v-show="isOverlayVisible"
            :class="[$style.overlay]"
            @click="onClose"
        >
        </div>
    </transition>
</template>

<script>

export default {
    name: 'TheOverlay',

    data() {
        return {
            isOverlayVisible: false,
        };
    },

    beforeMount() {
        this.$overlay.event.$on('open', this.onOpen);
        this.$overlay.event.$on('close', this.onClose);
    },

    beforeDestroy() {
        this.$overlay.event.$off('open', this.onOpen);
        this.$overlay.event.$off('close', this.onClose);
    },

    methods: {
        onOpen() {
            this.isOverlayVisible = true;
        },

        onClose() {
            this.isOverlayVisible = false;
        },
    },
};
</script>

<style lang="scss" module>
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 98;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: rgba(20, 21, 22, .48);
        // -webkit-overflow-scrolling: touch;

        @include respond-to(sm) {
            background-color: rgba(125, 125, 134, .7);
        }
    }
</style>
