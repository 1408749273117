// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiBurger_0T61M{width:2.4rem;height:2.4rem;padding:.4rem;cursor:pointer}.UiBurger_0T61M._isOpen_a4hvs .lines_mzyKT .line_xzrYe:first-child{top:calc(50% - .2rem);right:50%;-webkit-transform:rotate(45deg) translate(0);transform:rotate(45deg) translate(0)}.UiBurger_0T61M._isOpen_a4hvs .lines_mzyKT .line_xzrYe:nth-child(2){opacity:0;-webkit-transform:translateY(-400%);transform:translateY(-400%)}.UiBurger_0T61M._isOpen_a4hvs .lines_mzyKT .line_xzrYe:last-child{top:calc(50% - .2rem);right:50%;-webkit-transform:rotate(-45deg) translate(0);transform:rotate(-45deg) translate(0)}.UiBurger_0T61M .lines_mzyKT{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%;height:100%}.UiBurger_0T61M .lines_mzyKT .line_xzrYe{position:absolute;right:0;left:0;width:1.6rem;height:.2rem;background-color:#2f2f2f;opacity:1;-webkit-transition:opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;transition:opacity .3s ease,visibility .3s ease,-webkit-transform .3s ease;transition:opacity .3s ease,visibility .3s ease,transform .3s ease;transition:opacity .3s ease,visibility .3s ease,transform .3s ease,-webkit-transform .3s ease}.UiBurger_0T61M .lines_mzyKT .line_xzrYe:first-child{top:0}.UiBurger_0T61M .lines_mzyKT .line_xzrYe:nth-child(2){top:calc(50% - .1rem)}.UiBurger_0T61M .lines_mzyKT .line_xzrYe:last-child{bottom:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiBurger": "UiBurger_0T61M",
	"_isOpen": "_isOpen_a4hvs",
	"lines": "lines_mzyKT",
	"line": "line_xzrYe"
};
module.exports = ___CSS_LOADER_EXPORT___;
