// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheFormSuccess_TBplS{background-color:#fff}.title_OoDAI{margin-bottom:2rem}.description_eAawv,.link_zXykS{margin-bottom:4rem}.success_8ILBe{text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TheFormSuccess": "TheFormSuccess_TBplS",
	"title": "title_OoDAI",
	"description": "description_eAawv",
	"link": "link_zXykS",
	"success": "success_8ILBe"
};
module.exports = ___CSS_LOADER_EXPORT___;
