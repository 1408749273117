export default {
    // общая сумма квартир, ситихаусов и пентхаусов
    totalCount: state => {
        if (state.isReloading) {
            return '...';
        }

        return state.flatsTotalCount + state.cityHousesTotalCount + state.pentHousesTotalCount + state.townHousesTotalCount + state.cityUnitsTotalCount;
    },

    // сколько квартир отображаем на текущей странице
    flatsPerPage: (state, getters) => {
        if (state.filters.sale25 || state.filters.sale2) {
            return 20;
        }

        if ((getters.cityHousesPerPage + getters.pentHousesPerPage + getters.townHousesPerPage + getters.cityUnitsPerPage) % 2) {
            return 20;
        }

        return 22;
    },

    // сколько квартир ожидается на следующей странице
    flatsCountOnNextPage: (state, getters) => {
        if ((getters.cityHousesCountOnNextPage + getters.pentHousesCountOnNextPage + getters.cityUnitsCountOnNextPage) % 2 || state.filters.sale25 || state.filters.sale2) {
            if (state.flatsTotalCount - state.flatsLoadedCount < 20) {
                return state.flatsTotalCount - state.flatsLoadedCount;
            }

            return 20;
        }

        if (state.flatsTotalCount - state.flatsLoadedCount < 22) {
            return state.flatsTotalCount - state.flatsLoadedCount;
        }

        return 22;
    },

    // сколько ситихаусов отображаем на текущей странице
    cityHousesPerPage: state => {
        if (!state.filters.newTypeFlat.length || state.filters.newTypeFlat.includes('210')) {
            return state.otherTypesOnPage?.cityHouses[state.currentPage - 1] || 0;
        }

        return 0;
    },

    // сколько ситихаусов ожидается на следующей странице
    cityHousesCountOnNextPage: state => {
        if (!state.filters.newTypeFlat.length || state.filters.newTypeFlat.includes('210')) {
            return state.otherTypesOnPage?.cityHouses[state.currentPage] || 0;
        }

        return 0;
    },

    // сколько пентхаусов отображаем на текущей странице
    pentHousesPerPage: state => {
        if (!state.filters.newTypeFlat.length || state.filters.newTypeFlat.includes('211')) {
            return state.otherTypesOnPage?.pentHouses[state.currentPage - 1] || 0;
        }

        return 0;
    },

    // сколько пентхаусов ожидается на следующей странице
    pentHousesCountOnNextPage: state => {
        if (!state.filters.newTypeFlat.length || state.filters.newTypeFlat.includes('211')) {
            return state.otherTypesOnPage?.pentHouses[state.currentPage] || 0;
        }

        return 0;
    },

    // сколько сити-юнитов отображаем на текущей странице
    cityUnitsPerPage: state => {
        if (!state.filters.newTypeFlat.length || state.filters.newTypeFlat.includes('212')) {
            return state.otherTypesOnPage?.cityUnits[state.currentPage - 1] || 0;
        }

        return 0;
    },

    // сколько сити-юнитов ожидается на следующей странице
    cityUnitsCountOnNextPage: state => {
        if (!state.filters.newTypeFlat.length || state.filters.newTypeFlat.includes('212')) {
            return state.otherTypesOnPage?.cityUnits[state.currentPage] || 0;
        }

        return 0;
    },

    // сколько таунхаусов отображаем на текущей странице
    townHousesPerPage: state => {
        if (!state.filters.newTypeFlat.length || state.filters.newTypeFlat.includes('280')) {
            return state.otherTypesOnPage?.townHouses[state.currentPage - 1] || 0;
        }

        return 0;
    },

    // сколько таунхаусов ожидается на следующей странице
    townHousesCountOnNextPage: state => {
        if (!state.filters.newTypeFlat.length || state.filters.newTypeFlat.includes('280')) {
            return state.otherTypesOnPage?.townHouses[state.currentPage] || 0;
        }

        return 0;
    },

    // общая сумма ожидаемых объектов
    loadMoreCount: (_state, getters) => getters.flatsCountOnNextPage + getters.cityHousesCountOnNextPage + getters.pentHousesCountOnNextPage + getters.townHousesCountOnNextPage + getters.cityUnitsCountOnNextPage,

    // есть ли еще страница с квартирами
    flatsHasNextPage: state => Boolean(state.flatsTotalCount - state.flatsLoadedCount),

    // есть ли еще страница с ситихаусами
    cityHousesHasNextPage: state => Boolean(state.cityHousesTotalCount - state.cityHousesLoadedCount),

    // есть ли еще страница с таунхаусами
    townHousesHasNextPage: state => Boolean(state.townHousesTotalCount - state.townHousesLoadedCount),

    // есть ли еще страница с сити-юнитами
    cityUnitsHasNextPage: state => Boolean(state.cityUnitsTotalCount - state.cityUnitsLoadedCount),

    // есть ли еще страница с пентхаусами
    pentHousesHasNextPage: state => Boolean(state.pentHousesTotalCount - state.pentHousesLoadedCount),

    lastFiltersPayload: state => state.lastFiltersPayload,

    getHuuQuery: state => state.huuQuery,

    getHuuQueryKeys: state => Object.keys(state.huuQuery),
};
