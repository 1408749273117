<template>
    <div
        class="time-select"
        :class="{
            'time-select_tablet': isTablet,
            'time-select_mobile': isMobile,
        }"
    >
        <div class="time-select__text">
            Перезвоним вам в
        </div>

        <TheSelect
            v-model="hour"
            :options="hourOptions"
            class="time-select__select time-select__select_ml"
            @update="$emit('update')"
        />

        <div class="time-select__colon">
            :
        </div>

        <TheSelect
            v-model="minute"
            :options="minuteOptions"
            class="time-select__select time-select__select_mr"
            @update="$emit('update')"
        />

        <div class="time-select__text time-select__text_nowrap">
            Мы можем позвонить
            <br v-if="!isMobile">
            {{ callbackInfo }}
        </div>
    </div>
</template>

<script>
import TheSelect from '~/components/landing/components/ui/Select';

export default {
    name: 'TimeSelect',

    components: {
        TheSelect,
    },

    props: {
        value: {
            type: String,
            default: '',
        },

        hourOptions: {
            type: Array,
            default: () => [],
        },

        callbackInfo: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            hour: null,

            minute: null,

            minutes: [{
                value: '00',
                label: '00',
            }, {
                value: '05',
                label: '05',
            }, {
                value: '10',
                label: '10',
            }, {
                value: '15',
                label: '15',
            }, {
                value: '20',
                label: '20',
            }, {
                value: '25',
                label: '25',
            }, {
                value: '30',
                label: '30',
            }, {
                value: '35',
                label: '35',
            }, {
                value: '40',
                label: '40',
            }, {
                value: '45',
                label: '45',
            }, {
                value: '50',
                label: '50',
            }, {
                value: '55',
                label: '55',
            }],
        };
    },

    computed: {
        isDesktop() {
            return this.$parent.isDesktop;
        },

        isTablet() {
            return this.$parent.isTablet;
        },

        isMobile() {
            return this.$parent.isMobile;
        },

        minuteOptions() {
            if (this.hour && this.hour.label === new Date().getHours()) {
                return this.minutes.filter(el => el.label > new Date().getMinutes());
            } else {
                return this.minutes;
            }
        },
    },

    watch: {
        hour() {
            if (this.minute && !this.minuteOptions.find(el => el.value === this.minute.value)) {
                this.minute = this.minuteOptions[0];
            }
            this.$emit('input', `${this.hour.value}:${this.minute.value}`);
        },

        minute() {
            this.$emit('input', `${this.hour.value}:${this.minute.value}`);
        },

        hourOptions() {
            if (!this.hour) {
                this.hour = this.hourOptions[0];
                this.minute = this.minuteOptions[0];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .time-select {
        display: flex;
        align-items: center;
        width: min-content;

        &__text {
            font-family: 'Aeroport', sans-serif;
            font-size: 1.2rem;
            line-height: 1.2;
            color: #2a2c2b;

            &_nowrap {
                white-space: nowrap;
            }
        }

        &__select {
            width: 7rem;
            min-width: 7rem;

            &_ml {
                margin-left: 2rem;
            }

            &_mr {
                margin-right: 2rem;
            }
        }

        &__colon {
            margin: 0 .5rem;
            font-family: 'Aeroport', sans-serif;
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 1.9rem;
        }
    }

    .time-select_tablet {
        .time-select {
            &__text {
                font-size: 12px;
            }

            &__select {
                width: 70px;
                min-width: 70px;

                &_ml {
                    margin-left: 20px;
                }

                &_mr {
                    margin-right: 20px;
                }
            }

            &__colon {
                margin: 0 5px;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }

    .time-select_mobile {
        flex-wrap: wrap;
        width: initial;

        .time-select {
            &__text {
                &_nowrap {
                    margin-top: 20px;
                }
            }
        }
    }
</style>
