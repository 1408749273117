<template>
    <div :class="[$style.DigitalMenu, classList]">
        <UiBurger
            :is-open="isOpen"
            :class="$style.burger"
            @toggle-click="handleToggle"
        />

        <transition name="list">
            <div v-show="isOpen" :class="$style['menu-wrapper']">
                <div :class="[$style['navs-container'], 'container-home']">
                    <nav
                        v-if="navs && navs.length"
                        :class="$style.navs"
                    >
                        <UiNavLinkCard
                            v-for="nav in navs"
                            :key="nav.label"
                            :to="nav.to"
                            :label="nav.label"
                            :icon="nav.icon"
                            :image="nav.image"
                            prefetch
                            :class="$style.nav"
                            @link-click="handleLinkClick"
                        />
                    </nav>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
// utils
import { lockBody, unlockBody } from '~/assets/js/utils';

// components
import UiBurger from '~/components/ui/UiBurger.vue';
import UiNavLinkCard from '~/components/ui/UiNavLinkCard.vue';

export default {
    name: 'DigitalMenu',

    components: {
        UiBurger,
        UiNavLinkCard,
    },

    props: {
        /**
         * Массив объектов типа
         * {
         *   to: string,
         *   icon: string,
         *   label: string,
         *   image: string,
         * }
        */
        navs: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            isOpen: false,
        };
    },

    computed: {
        classList() {
            return {
                [this.$style._isOpen]: this.isOpen,
            };
        },
    },

    beforeDestroy() {
        unlockBody();
    },

    methods: {
        handleToggle(value) {
            this.isOpen = value;

            this.isOpen ? lockBody() : unlockBody();
        },

        handleLinkClick() {
            this.$nextTick(() => {
                this.isOpen = false;
                unlockBody();
            });
        },
    },
};
</script>

<style lang="scss" module>
    .DigitalMenu {
        .menu-wrapper {
            position: fixed;
            top: $digital-header-height;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            overflow-x: hidden;
            overflow-y: auto;
            background-color: $base-0;
            transition: all .3s ease;

            .navs-container {
                height: max-content;
                padding: 4rem 5.4rem;
            }

            .navs {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: auto;
                align-items: stretch;
                width: calc(100% + 2rem);
                margin: -1rem;
                background-color: $base-0;
            }

            .nav {
                display: block;
                max-width: 100%;
                height: 33rem;
                margin: 1rem;
            }
        }
    }
</style>
