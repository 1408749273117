<template>
    <symbol id="icon-lock" viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0z" />
        <path
            d="M7 10h13a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1V9a7 7 0 0 1 13.262-3.131l-1.789.894A5 5 0 0 0 7 9v1zm3 5v2h4v-2h-4z"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconLock',
};
</script>
