export default ({ $axios, req, $config, $sentry }) => {
    $axios.defaults.xsrfCookieName = 'csrftoken';
    $axios.defaults.xsrfHeaderName = 'X-CSRFToken';

    const headers = req && req.headers ? Object.assign({}, req.headers) : {};
    $axios.setHeader('X-Forwarded-Host', headers['x-forwarded-host']);
    $axios.setHeader('X-Forwarded-Port', headers['x-forwarded-port']);
    $axios.setHeader('X-Forwarded-Proto', headers['x-forwarded-proto']);

    // Сгенерированный хэш из логина и пароля - для basic авторизации (только для локального запуска)
    // https://www.blitter.se/utils/basic-authentication-header-generator/ - тут генератор
    $axios.setHeader('Authorization', 'Basic aWRhcHJvamVjdDoyMjMzMjI=');

    let endpoint;
    if (process.env.PROXY_URL) {
        endpoint = process.env.PROXY_URL;
    } else if (process.env.BACKEND_HOST) {
        endpoint = `http://${process.env.BACKEND_HOST}:8000/`;
    } else {
        endpoint = 'http://backend:8000/';
    }

    if (process.client) {
        endpoint = `${location.origin}/`;
    }
    $axios.setBaseURL(endpoint);
    $axios.onError(error => {
        if ($config.SENTRY_DSN_FRONTEND && !$config.IS_DEV) {
            $sentry.captureException(`${error}:
            ${JSON.stringify(error.response)}`);
        }
    });
};
