<template>
    <div
        :class="[$style.UiBurger, classList]"
        @click.stop="onClick"
    >
        <div :class="$style.lines">
            <span :class="$style.line" />
            <span :class="$style.line" />
            <span :class="$style.line" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiBurger',

    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return {
                [this.$style._isOpen]: this.isOpen,
            };
        },
    },

    methods: {
        onClick() {
            this.$emit('toggle-click', !this.isOpen);
        },
    },
};
</script>

<style lang="scss" module>
    .UiBurger {
        width: 2.4rem;
        height: 2.4rem;
        padding: .4rem;
        cursor: pointer;

        &._isOpen {
            .lines {
                .line {
                    &:first-child {
                        top: calc(50% - .2rem);
                        right: 50%;
                        transform: rotate(45deg) translate(0);
                    }

                    &:nth-child(2) {
                        opacity: 0;
                        transform: translateY(-400%);
                    }

                    &:last-child {
                        top: calc(50% - .2rem);
                        right: 50%;
                        transform: rotate(-45deg) translate(0);
                    }
                }
            }
        }

        .lines {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;

            .line {
                position: absolute;
                right: 0;
                left: 0;
                width: 1.6rem;
                height: .2rem;
                background-color: $base-900;
                opacity: 1;
                transition: opacity .3s ease, visibility .3s ease, transform .3s ease;

                &:first-child {
                    top: 0;
                }

                &:nth-child(2) {
                    top: calc(50% - .1rem);
                }

                &:last-child {
                    bottom: 0;
                }
            }
        }
    }
</style>
