<template>
    <ModalContainer
        :class="$style.container"
        :visible="visible"
        @after-leave="$emit('after-leave')"
        @close="data.card ? $modal.open('MediaModal', {card: data.card, classList: 'is-article'}) : $emit('close')"
    >
        <div :class="[$style.body, {[$style._success] : formSuccess}]">
            <h3 :class="[$style.title, 'h2 _base']">
                Оставить<br>комментарий
            </h3>
            <TheForm
                :class="$style.form"
                button-label="Оставить комментарий"
                @submit="onFormSubmit"
            >
                <input
                    type="hidden"
                    name="category"
                    value="comment"
                >
                <input
                    type="hidden"
                    name="post"
                    :value="data.postId"
                >
                <UiInput
                    :class="$style.input"
                    name="name"
                    type="text"
                    label="Имя"
                    is-require
                    :error="getInputError('name')"
                    @input="oninputChange"
                    @change="oninputChange"
                />

                <UiInput
                    :class="$style.input"
                    name="email"
                    type="email"
                    label="Email"
                    is-require
                    :error="getInputError('email')"
                    @input="oninputChange"
                    @change="oninputChange"
                />

                <UiInput
                    :class="$style.textarea"
                    tag="textarea"
                    name="text"
                    label="Комментарий"
                    is-require
                    textarea
                    :error="getInputError('comment')"
                    @input="oninputChange"
                    @change="oninputChange"
                />
            </TheForm>

            <transition name="fade">
                <TheFormSuccess
                    v-if="formSuccess"
                    :class="$style.formSuccess"
                    @click="$emit('close')"
                />
            </transition>
        </div>
    </ModalContainer>
</template>

<script>
// utils
import FormMixin from '@/assets/js/mixins/FormMixin';
// components
import ModalContainer from './ModalContainer';
import TheForm from '../TheForm';
import UiInput from '../../ui/input/UiInput';
import TheFormSuccess from '../form/TheFormSuccess';

export default {
    name: 'CommentModal',

    components: {
        TheFormSuccess,
        UiInput,
        TheForm,
        ModalContainer,
    },

    mixins: [FormMixin],

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },
};
</script>

<style lang="scss" module>
    .container {
        width: 64rem;

        @include respond-to(xs) {
            width: 100vw;
        }
    }

    .body {
        position: relative;
        z-index: 1;
        width: 100%;

        @include respond-to(xs) {
            overflow-y: auto;
            height: 100%;
        }

        &._success {
            & > form {
                visibility: hidden;
            }
        }
    }

    .formSuccess {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    .title {
        margin-bottom: 4rem;
    }
</style>
