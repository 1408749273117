<template>
    <div :class="$style.error">
        <span :class="$style.code">
            {{ statusCode }}
        </span>

        <p :class="$style.text">
            {{ customError }}
        </p>

        <UiButton
            :class="$style.button"
            link="/"
            color="primary"
        >
            перейти на главную
        </UiButton>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import UiButton from '~/components/ui/home/UiButton.vue';
import { ERROR_MESSAGES } from '~/assets/js/constants';

export default {
    name: 'NuxtError',
    components: { UiButton },
    props: {
        error: {
            type: Object,
            default: null,
        },
    },

    head() {
        return {
            title: this.message,
            meta: [
                {
                    name: 'viewport',
                    content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
                },
            ],
        };
    },

    computed: {
        ...mapState('PageTransition', [
            'changing',
        ]),

        statusCode() {
            return this.error && this.error.statusCode || 500;
        },

        customError() {
            switch (this.error.message) {
                case ERROR_MESSAGES.SERVER_ERROR:
                    return ERROR_MESSAGES.TRANSLATED_SERVER_ERROR;

                case ERROR_MESSAGES.PAGE_NOT_FOUND:
                    return ERROR_MESSAGES.TRANSLATED_PAGE_NOT_FOUND;

                default:
                    return this.error.message;
            }
        },
    },

    mounted() {
        // TODO тут баг. Из-за redirect store сбрасывается и черный экран всё закрывает, если обновить 404 страницу. Надо полностью переделывать логику PageTransition компонента
        // this.animate();
    },

    methods: {
        ...mapActions({
            animate: 'PageTransition/animate',
        }),
    },
};
</script>

<style lang="scss" module>
    .error {
        position: relative;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100vw;
        height: 100%;
        min-height: calc(100vh - $digital-header-height);

        @include respond-to(xs) {
            padding: 0 2.8rem;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: -1;
            width: 42.3rem;
            background-color: $yellow-light-2;
            transform: skew(-24.4deg, 0);

            @include respond-to(xs) {
                width: 21.7rem;
            }
        }
    }

    .svg {
        width: 100%;
        max-width: 86.7rem;
    }

    .text {
        margin-top: 6.4rem;

        @include p2;

        text-align: center;
        font-weight: 300;
        color: $gray-5;
    }

    .code {
        font-size: 48rem;
        font-weight: 700;
        line-height: 36rem;
        color: $base-900;

        @include respond-to(sm) {
            font-size: 32rem;
        }

        @include respond-to(xs) {
            font-size: 17rem;
        }
    }

    .button {
        margin-top: 2.4rem;
    }
</style>
