// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Default_tWGqc{position:relative;padding-top:13.8rem}.Default_tWGqc._tablet_R9BHT{padding-top:12.8rem}.Default_tWGqc._mobile_r6SY4{padding-top:11.2rem}.Default_tWGqc .overlay_HL1dl{position:absolute;top:0;left:0;z-index:16;visibility:hidden;width:100%;height:100%;background-color:#18191c;opacity:0;-webkit-transition:1s ease;transition:1s ease}.Default_tWGqc .overlay_HL1dl._shown_3UdlA{visibility:visible;opacity:.64}.Default_tWGqc .banner_JxWEv{position:fixed;top:0;left:0;z-index:20;visibility:hidden;-webkit-transform:translateY(-100%);transform:translateY(-100%);-webkit-transition:.3s ease;transition:.3s ease}.Default_tWGqc .banner_JxWEv._shown_3UdlA{visibility:visible;-webkit-transform:translateY(0);transform:translateY(0)}.Default_tWGqc .banner_JxWEv._hidden_QjBmH{-webkit-transform:translateY(-100%);transform:translateY(-100%)}.Default_tWGqc .header_HGOzh{position:fixed;top:4.8rem;left:0;z-index:19;-webkit-transform:translateY(-4.8rem);transform:translateY(-4.8rem);-webkit-transition:-webkit-transform .3s ease;transition:-webkit-transform .3s ease;transition:transform .3s ease;transition:transform .3s ease,-webkit-transform .3s ease}.Default_tWGqc .header_HGOzh._bottom_qr9S8{-webkit-transform:translateY(0);transform:translateY(0)}.Default_tWGqc .header_HGOzh._top_xrpVc{-webkit-transform:translateY(-4.8rem);transform:translateY(-4.8rem)}@media(max-width:991.98px){.Default_tWGqc .widget_5-3vB._flatsPage_ibKcB{bottom:8.6rem!important}}.Default_tWGqc .widget_5-3vB._hidden_QjBmH{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Default": "Default_tWGqc",
	"_tablet": "_tablet_R9BHT",
	"_mobile": "_mobile_r6SY4",
	"overlay": "overlay_HL1dl",
	"_shown": "_shown_3UdlA",
	"banner": "banner_JxWEv",
	"_hidden": "_hidden_QjBmH",
	"header": "header_HGOzh",
	"_bottom": "_bottom_qr9S8",
	"_top": "_top_xrpVc",
	"widget": "widget_5-3vB",
	"_flatsPage": "_flatsPage_ibKcB"
};
module.exports = ___CSS_LOADER_EXPORT___;
