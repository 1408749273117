<template>
    <ModalContainer
        :visible="visible"
        class="modal-container"
        :class="{
            'modal-container_tablet': isTablet,
            'modal-container_mobile': isMobile,
        }"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <CallbackForm class="modal-container__form" />
    </ModalContainer>
</template>

<script>
import ModalContainer from './ModalContainer';
import CallbackForm from '~/components/landing/components/CallbackForm';

export default {
    name: 'CallbackModal',

    components: {
        ModalContainer,
        CallbackForm,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isDesktop() {
            return this.$device.isDesktop;
        },

        isTablet() {
            return !this.$device.isDesktop;
        },

        isMobile() {
            return this.$device.isMobile;
        },
    },
};
</script>

<style lang="scss" scoped>
    .modal-container {
        &__form {
            max-width: 65rem;
            min-height: 65rem;
            padding: 10rem 12rem;
        }
    }

    .modal-container_tablet {
        .modal-container {
            &__form {
                max-width: 510px;
                min-height: 670px;
                padding: 60px 60px 0 60px;
            }
        }
    }

    .modal-container_mobile {
        .modal-container {
            &__form {
                min-height: 100vh;
                padding: 40px 20px;
            }
        }
    }
</style>
