// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfraItemInfo_ns80e{padding:2.4rem}@media(max-width:767.98px){.InfraItemInfo_ns80e{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:100%;padding:0}}@media(max-width:767.98px){.body_aNv5-{overflow-x:hidden;overflow-y:auto;-webkit-overflow-scrolling:touch}}.image_dGZYr{position:relative;height:18.4rem;margin:-2.4rem -2.4rem 2.4rem}@media(max-width:767.98px){.image_dGZYr{overflow:hidden;margin:0 0 32px;-webkit-transform:translateZ(0);transform:translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden}}.title_w9VBn{margin-bottom:.8rem;font-size:2rem;font-weight:700;line-height:1.4}@media(max-width:767.98px){.title_w9VBn{max-width:80%;margin-bottom:32px;font-size:28px;line-height:1}}.title_w9VBn._mobile_MNyZ7{display:none}@media(max-width:767.98px){.title_w9VBn._mobile_MNyZ7{display:block}}@media(max-width:767.98px){.title_w9VBn._desktop_50zul{display:none}}.title_w9VBn._noMargin_9pMrs{margin-bottom:0}.subtitle_I2TDD{color:#c5c5c5}.description_ZzihU,.subtitle_I2TDD{font-size:1.2rem;line-height:1.64}.description_ZzihU{margin-top:1.6rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InfraItemInfo": "InfraItemInfo_ns80e",
	"body": "body_aNv5-",
	"image": "image_dGZYr",
	"title": "title_w9VBn",
	"_mobile": "_mobile_MNyZ7",
	"_desktop": "_desktop_50zul",
	"_noMargin": "_noMargin_9pMrs",
	"subtitle": "subtitle_I2TDD",
	"description": "description_ZzihU"
};
module.exports = ___CSS_LOADER_EXPORT___;
