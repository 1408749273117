import { prepareDataForRequest } from '@/assets/js/utils/apiUtils';

import flatSimilar from '@/queries/flat/flatSimilar.graphql';

export const state = () => ({
    similarFlats: [],
    similarFlatsEndCursor: '',
    similarFlatsHasNextPage: false,
    similarFlatsTotalCount: 0,
    similarFlatsLeft: 0,
    showStep: 0,
});

export const actions = {
    async fetchSimilarFlats({ state, dispatch }, values) {
        try {
            const { data } = await this.$axios.$post('/graphql/', {
                query: flatSimilar.loc.source.body,
                variables: {
                    ...prepareDataForRequest(values),
                },
            }, { progress: false });

            dispatch('setSimilarFlatsEndCursor', data.allFlats.pageInfo.endCursor);
            dispatch('setSimilarFlatsHasNextPage', data.allFlats.pageInfo.hasNextPage);
            dispatch('setSimilarFlatsTotalCount', data.allFlats.totalCount);

            return data.allFlats.edges.map(item => item.node);
        } catch (e) {
            console.warn('[Vuex] flat/fetchSimilarFlats: ', e);
        }
    },

    appendSimilarFlats({ commit }, payload) {
        commit('APPEND_SIMILAR_FLATS', payload);
    },

    setSimilarFlats({ commit }, payload) {
        commit('SET_SIMILAR_FLATS', payload);
    },

    setSimilarFlatsEndCursor({ commit }, payload) {
        commit('SET_SIMILAR_FLATS_END_CURSOR', payload);
    },

    setSimilarFlatsHasNextPage({ commit }, payload) {
        commit('SET_SIMILAR_FLATS_HAS_NEXT_PAGE', payload);
    },

    setSimilarFlatsTotalCount({ commit }, payload) {
        commit('SET_SIMILAR_FLATS_TOTAL_COUNT', payload);
    },

    setShowStep({ commit }, payload) {
        commit('SET_SHOW_STEP', payload);
    },
};

export const mutations = {
    APPEND_SIMILAR_FLATS(state, payload) {
        state.similarFlats = state.similarFlats.concat(payload);
    },

    SET_SIMILAR_FLATS(state, payload) {
        state.similarFlats = payload;
    },

    SET_SIMILAR_FLATS_END_CURSOR(state, payload) {
        state.similarFlatsEndCursor = payload;
    },

    SET_SIMILAR_FLATS_HAS_NEXT_PAGE(state, payload) {
        state.similarFlatsHasNextPage = payload;
    },

    SET_SIMILAR_FLATS_TOTAL_COUNT(state, payload) {
        state.similarFlatsTotalCount = payload;
    },

    SET_SHOW_STEP(state, payload) {
        state.showStep = payload;
    },
};
