<template>
    <component
        :is="buttonComponent"
        :class="[$style.UiButton, classList]"
        v-bind="elementAttriutes"
        @click="$emit('click')"
    >
        <span
            v-if="$slots.iconBefore"
            :class="$style.UiButtonIconBefore"
        >
            <slot name="iconBefore" />
        </span>

        <slot />
    </component>
</template>

<script>
export default {
    name: 'UiButton',

    props: {
        link: {
            type: String,
            default: '',
        },

        external: {
            type: Boolean,
            default: false,
        },

        blank: {
            type: Boolean,
            default: false,
        },

        type: {
            type: String,
            default: 'button',
            validator: value => ['button', 'submit'].includes(value),
        },

        size: {
            type: String,
            default: '',
            validator: value => ['', 'big', 'large'].includes(value),
        },

        color: {
            type: String,
            default: 'default',
            validator: value => ['default', 'primary', 'primary-inversed', 'social', 'social-inversed', 'bordered', 'tab-primary', 'yellow'].includes(value),
        },

        fullWidth: {
            type: Boolean,
            default: false,
        },

        active: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        buttonComponent() {
            if (this.link) {
                if (this.external || this.blank) {
                    return 'a';
                } else {
                    return 'nuxt-link';
                }
            } else {
                return 'button';
            }
        },

        elementAttriutes() {
            return {
                type: this.link ? null : this.type,
                to: this.external || this.blank ? null : this.link ? this.link : null,
                href: this.external || this.blank ? this.link : null,
                target: this.blank ? '_blank' : null,
                rel: this.blank ? 'noreferrer noopener nofollow' : null,
                disabled: !this.link && this.disabled ? true : null,
            };
        },

        classList() {
            return {
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style['_fullWidth']]: this.fullWidth,
                [this.$style['_active']]: this.active,
                [this.$style['_disabled']]: this.link && this.disabled,
                [this.$style['_withIcon']]: this.$slots.iconBefore,
            };
        },
    },
};
</script>

<style lang="scss" module>
    .UiButton {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 1.3rem 2rem;
        border: none;
        background-clip: padding-box;
        background-color: $home-accent-6;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1;
        color: $home-accent-2;
        transition: all .3s ease;
        cursor: pointer;

        &._default {
            background-color: $home-accent-6;
            color: $home-accent-2;

            &._active {
                background-color: $home-accent-6;
                color: $home-accent-4;
            }

            &:hover {
                background-color: $home-accent-6;
                color: $home-accent-4;
            }

            &:active {
                background-color: $home-accent-6;
                color: $home-accent-2;
            }
        }

        &._primary {
            background-color: $home-accent-2;
            color: $inversed;

            &._active {
                background-color: $home-accent-2;
                color: $inversed;
            }

            &:hover {
                background-color: $home-accent-3;
                color: $inversed;
            }

            &:active {
                background-color: $home-accent-2;
                color: $inversed;
            }
        }

        &._primary-inversed {
            background-color: $inversed;
            color: $home-accent-2;

            &._active {
                background-color: $inversed;
                color: $home-accent-2;
            }

            &:hover {
                background-color: $inversed;
                color: $home-accent-4;
            }

            &:active {
                background-color: $inversed;
                color: $home-accent-2;
            }
        }

        &._social {
            background-color: $home-accent-6;
            color: $home-accent-4;

            &._active {
                background-color: $home-accent-3;
                color: $inversed;
            }

            &:hover {
                background-color: $home-accent-3;
                color: $inversed;
            }

            &:active {
                background-color: $home-accent-3;
                color: $inversed;
            }
        }

        &._social-inversed {
            background-color: $inversed;
            color: $home-accent-5;

            &._active {
                background-color: $home-accent-3;
                color: $inversed;
            }

            &:hover {
                background-color: $home-accent-3;
                color: $inversed;
            }

            &:active {
                background-color: $home-accent-3;
                color: $inversed;
            }
        }

        &._tab-primary {
            background-color: $home-accent-2;
            color: rgba($inversed, .64);

            &._active {
                background-color: $home-accent-2;
                color: $inversed;
            }

            &:hover {
                background-color: #48494b;
                color: $inversed;
            }

            &:active {
                background-color: $home-accent-2;
                color: $inversed;
            }
        }

        &._yellow {
            background-color: $new-accent;
            color: $base-900;

            &._active {
                background-color: $new-accent;
                color: $base-900;
            }

            @include hover {
                &:hover {
                    background-color: $new-accent;
                    color: $base-900;
                }
            }

            &:active {
                background-color: $new-accent;
                color: $base-900;
            }
        }

        &._bordered {
            border: 1px solid $line-200;
            background-color: transparent;
            color: $home-accent-2;

            &._active {
                color: $home-accent-2;
            }

            &:hover {
                color: #757d88;
            }

            &:active {
                color: $home-accent-2;
            }
        }

        &._fullWidth {
            display: flex;
            width: 100%;
        }

        &._big {
            padding: 2.1rem 2rem;
        }

        &._withIcon {
            padding: 1.2rem 2rem;
        }

        &._big._withIcon {
            padding: 2rem;
        }

        &._large {
            height: 5.6rem;
        }

        &:focus {
            outline: none;
        }

        &._disabled,
        &:disabled {
            opacity: .44;
            pointer-events: none;
            user-select: none;
        }
    }

    .UiButtonIconBefore {
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: .8rem;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
</style>
