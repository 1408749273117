// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiTabs_whEBM{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;grid-gap:.8rem;gap:.8rem;padding:.8rem;background-color:#f3f3f3}.UiTabs_whEBM._dark_RaZm1{background-color:#303030}.UiTabs_whEBM._dark_RaZm1 .tab_QONLv{color:#fff}.UiTabs_whEBM._dark_RaZm1 .tab_QONLv:hover{background-color:hsla(0,0%,100%,.16);color:#fff}.UiTabs_whEBM._dark_RaZm1 .tab_QONLv._active_\\+\\+yi9,.UiTabs_whEBM._dark_RaZm1 .tab_QONLv:active{background-color:#fff;color:#000}.tab_QONLv{position:relative;padding:1.2rem 1.6rem;border:none;background-clip:padding-box;background-color:transparent;text-transform:uppercase;white-space:nowrap;font-size:1.2rem;font-weight:700;color:#8b8d98;opacity:.64;-webkit-transition:all .3s ease;transition:all .3s ease;cursor:pointer}.tab_QONLv:hover{background-color:hsla(0,0%,100%,.66);color:#000;opacity:1}.tab_QONLv._active_\\+\\+yi9,.tab_QONLv:active{background-color:#fff;color:#000;opacity:1}.tab_QONLv:focus{outline:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiTabs": "UiTabs_whEBM",
	"_dark": "_dark_RaZm1",
	"tab": "tab_QONLv",
	"_active": "_active_++yi9"
};
module.exports = ___CSS_LOADER_EXPORT___;
