<template>
    <symbol id="icon-instagram" viewBox="0 0 32 32">
        <path d="M16 7.2c2.39 0 2.69.009 3.627.053.938.044 1.576.19 2.137.409a4.29 4.29 0 011.56 1.015c.447.44.793.972 1.014 1.559.217.56.365 1.2.41 2.137.04.938.052 1.236.052 3.627s-.009 2.69-.053 3.627c-.044.938-.192 1.576-.41 2.137a4.296 4.296 0 01-1.014 1.56c-.44.447-.972.793-1.56 1.014-.56.217-1.199.365-2.136.41-.938.04-1.236.052-3.627.052s-2.69-.009-3.627-.053c-.938-.044-1.576-.192-2.137-.409a4.304 4.304 0 01-1.56-1.015 4.316 4.316 0 01-1.014-1.559c-.218-.56-.365-1.2-.41-2.137C7.213 18.69 7.2 18.391 7.2 16c0-2.39.009-2.69.053-3.627.044-.938.19-1.575.409-2.137.22-.588.567-1.12 1.015-1.56a4.31 4.31 0 011.559-1.014c.561-.218 1.198-.365 2.137-.41.938-.04 1.236-.052 3.627-.052zm0 4.4a4.4 4.4 0 100 8.8 4.4 4.4 0 000-8.8zm5.72-.22a1.1 1.1 0 10-2.2 0 1.1 1.1 0 002.2 0zM16 13.36a2.64 2.64 0 110 5.28 2.64 2.64 0 010-5.28z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconInstagram',
};
</script>
