<template>
    <button
        :class="[$style.UiCircleButton, classList]"
        type="button"
        @click="$emit('click')"
        @mouseenter="$emit('on-mouseenter')"
        @mouseleave="$emit('on-mouseleave')"
    >
        <span :class="$style.content">
            <slot />
        </span>
    </button>
</template>

<script>
// Компонент ориентирован на ALIA-UI (KIT)
export default {
    name: 'UiCircleButton',

    props: {
        color: {
            type: String,
            default: 'gray',
            validator: value => ['gray', 'black', 'bordered', 'white'].includes(value),
        },

        size: {
            type: String,
            default: 'normal',
            validator: value => ['normal', 'big', 'small', 'moderate'].includes(value),
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        hovered: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return {
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style._big]: this.size === 'big',
                [this.$style._small]: this.size === 'small',
                [this.$style._moderate]: this.size === 'moderate',
                [this.$style._mobile]: !this.$device.isDesktop,
                [this.$style._disabled]: this.disabled,
                [this.$style._hovered]: this.hovered,
            };
        },
    },
};
</script>

<style lang="scss" module>
    .UiCircleButton {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        min-width: 4rem;
        height: 4rem;
        min-height: 4rem;
        padding: 0;
        border-radius: 50%;
        border: none;
        outline: none;
        transition: $transition;
        cursor: pointer;
        user-select: none;

        &._disabled {
            pointer-events: none;
        }

        .content {
            width: 1.6rem;
            height: 1.6rem;
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 1.6rem;
            letter-spacing: -.04em;

            & > svg {
                width: 100%;
                height: auto;
            }
        }
    }

    /* sizes */
    .UiCircleButton._big {
        width: 4.8rem;
        height: 4.8rem;

        .content {
            width: 2rem;
            height: 2rem;
            font-size: 2rem;
            line-height: 2rem;
        }
    }

    .UiCircleButton._small {
        width: 3.2rem;
        min-width: 3.2rem;
        height: 3.2rem;
        min-height: 3.2rem;

        .content {
            width: 1.2rem;
            height: 1.2rem;
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
    }

    .UiCircleButton._moderate {
        width: 3.6rem;
        min-width: 3.6rem;
        height: 3.6rem;
        min-height: 3.6rem;

        .content {
            width: 1.6rem;
            height: 1.6rem;
            font-size: 1.6rem;
            line-height: 1.6rem;
        }
    }

    /* colors */
    .UiCircleButton._gray {
        background-color: $base-50;
        color: $base-900;

        &._mobile {
            &:hover,
            &._hovered {
                color: $base-900;
            }

            &:active {
                color: $base-600;
            }
        }

        &:hover,
        &._hovered {
            color: $base-600;
        }

        &:active {
            color: $base-900;
        }

        &._disabled {
            color: $base-400 !important; //stylelint-disable-line declaration-no-important
        }
    }

    .UiCircleButton._black {
        background-color: $base-900;
        color: $base-0;

        &._mobile {
            &:hover,
            &._hovered {
                background-color: $base-900;
            }

            &:active {
                background-color: $base-700;
            }
        }

        &:hover,
        &._hovered {
            background-color: $base-700;
        }

        &:active {
            background-color: $base-900;
        }

        &._disabled {
            background-color: $base-300 !important; //stylelint-disable-line declaration-no-important
        }
    }

    .UiCircleButton._bordered {
        border: 1px solid $base-200;
        background-color: transparent;
        color: $base-900;

        &._mobile {
            &:hover,
            &._hovered {
                color: $base-900;
            }

            &:active {
                color: $base-600;
            }
        }

        &:hover,
        &._hovered {
            color: $base-600;
        }

        &:active {
            color: $base-900;
        }

        &._disabled {
            color: $base-400 !important; //stylelint-disable-line declaration-no-important
        }
    }

    .UiCircleButton._white {
        background-color: $base-0;
        color: $base-900;

        &._mobile {
            &:hover,
            &._hovered {
                color: $base-900;
            }

            &:active {
                color: $base-600;
            }
        }

        &:hover,
        &._hovered {
            color: $base-600;
        }

        &:active {
            color: $base-900;
        }

        &._disabled {
            background-color: $base-300 !important; //stylelint-disable-line declaration-no-important
            color: $base-0 !important; //stylelint-disable-line declaration-no-important
        }
    }
</style>
