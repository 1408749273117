<template>
    <ModalContainer
        :class="$style.container"
        :visible="visible"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <div :class="$style.slider">
            <div
                ref="slider"
                class="swiper-container"
            >
                <!--SliderButtons-->
                <div
                    v-show="isButton"
                    :class="$style.sliderButtons"
                >
                    <SliderButton
                        ref="prev"
                        direction="_prev"
                    />

                    <SliderButton
                        ref="next"
                        direction="_next"
                    />
                </div>

                <ul
                    ref="wrapper"
                    :class="$style.wrapper"
                    class="swiper-wrapper"
                >
                    <li
                        v-for="card in data.bannerData"
                        :key="card.id"
                        :class="$style.slide"
                        class="swiper-slide"
                    >
                        <div :class="$style.body">
                            <div :class="$style.header">
                                <h3
                                    v-if="card.titleModal"
                                    :class="[$style.title, 'h2 _base']"
                                    v-html="card.titleModal"
                                />
                            </div>

                            <div :class="$style.text">
                                <div :class="$style.inner">
                                    <div
                                        v-if="card.introModal"
                                        :class="[$style.topText, 'text']"
                                        v-html="card.introModal"
                                    />

                                    <div
                                        v-if="card.description"
                                        :class="[$style.topText, 'text']"
                                        v-html="card.description"
                                    />
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </ModalContainer>
</template>

<script>
import Swiper from 'swiper/js/swiper.min.js';

import ModalContainer from '~/components/common/modals/ModalContainer.vue';
import SliderButton from '~/components/common/slider/SliderButton.vue';

export default {
    name: 'MortgageModal',

    components: {
        ModalContainer,
        SliderButton,
    },

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },

    data() {
        return {
            sliderApp: undefined,
        };
    },

    computed: {
        isButton() {
            return this.data.bannerData.length > 1;
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.initApp();

            this.sliderApp.on('slideChange', () => {
                this.scrollToTop();
            });
        });
    },

    updated() {
        if (this.sliderApp) {
            this.sliderApp.update();
        }
    },

    beforeDestroy() {
        if (this.sliderApp) {
            this.sliderApp.destroy();
        }
    },

    methods: {
        initApp() {
            const options = {
                slidesPerView: 1,
                allowTouchMove: true,
                spaceBetween: 20,
                initialSlide: this.data.activeIndex,
                autoHeight: true,
                navigation: {
                    prevEl: this.$refs.prev.$el,
                    nextEl: this.$refs.next.$el,
                    hiddenClass: '_hidden',
                    disabledClass: '_disabled',
                },
            };

            this.sliderApp = new Swiper(this.$refs.slider, options);
        },

        scrollToTop() {
            document.querySelector('#scroll').scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style lang="scss" module>
    .container {
        width: 96rem;

        @include respond-to(sm) {
            width: 100%;
            height: auto;
        }
    }

    .body {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @include respond-to(xs) {
            padding-bottom: 22px;
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 4rem;

        @include respond-to(xs) {
            margin-bottom: 0;
        }
    }

    .interactive {
        @include respond-to(xs) {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .title {
        width: 85%;
        margin-bottom: .8rem;
    }

    .video {
        width: 100%;
    }

    .gallery {
        @include respond-to(xs) {
            margin: auto 0;
        }
    }

    .text {
        position: relative;

        @include respond-to(xs) {
            overflow: hidden;
            height: 100%;
            padding: 32px 0;
        }
    }

    .inner {
        @include respond-to(xs) {
            position: relative;
            z-index: 1;
            overflow-y: auto;
            height: 100%;
        }

        .topText {
            p {
                padding-bottom: 0;
            }
        }
    }

    .topText {
        margin-bottom: 1.14em;

        a {
            text-decoration: underline;

            &:hover {
                opacity: .7;
            }
        }
    }

    .image {
        overflow: hidden;
        margin: 2.4rem 0;
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        @include aspect-ratio(746, 457);

        @include respond-to(xs) {
            @include aspect-ratio(288, 176);
        }
    }

    .sliderButtons {
        position: fixed;
        top: 50%;
        left: 49.5%;
        display: flex;
        justify-content: space-between;
        width: 90rem;
        transform: translate(-50%);

        @include respond-to(sm) {
            width: 95%;
        }
    }

    .slide {
        width: 100%;
        padding: 0 1.6rem;
    }

    .slider {
        width: 100%;
        height: auto;
    }

    .wrapper {
        align-items: flex-start;
    }
</style>
