<template>
    <symbol id="icon-telegram-pdf" viewBox="0 0 18 18">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.78977 8.84192C6.16258 6.93676 9.07847 5.68077 10.5374 5.07393C14.7031 3.3413 15.5687 3.04032 16.1329 3.03038C16.2569 3.02819 16.5344 3.05895 16.7141 3.20477C16.8659 3.32791 16.9076 3.49425 16.9276 3.61099C16.9476 3.72774 16.9725 3.99369 16.9527 4.20149C16.7269 6.57334 15.7502 12.3292 15.2532 14.9857C15.043 16.1098 14.629 16.4867 14.2281 16.5236C13.3571 16.6037 12.6956 15.9479 11.8519 15.3948C10.5317 14.5294 9.7859 13.9907 8.50442 13.1463C7.02345 12.1703 7.9835 11.6339 8.82751 10.7573C9.04838 10.5279 12.8864 7.03696 12.9607 6.72028C12.9699 6.68067 12.9786 6.53303 12.8909 6.45508C12.8032 6.37712 12.6737 6.40378 12.5803 6.42498C12.4479 6.45503 10.3389 7.84902 6.25321 10.6069C5.65457 11.018 5.11234 11.2183 4.62652 11.2078C4.09094 11.1962 3.0607 10.905 2.29483 10.656C1.35545 10.3507 0.608856 10.1892 0.673868 9.67065C0.70773 9.40054 1.0797 9.12429 1.78977 8.84192Z"
        />
    </symbol>


</template>

<script>
export default {
    name: 'IconTelegramPdf',
};
</script>
