var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.MenuDropdown},[_c(_vm.url ? 'a' : 'button',{tag:"component",staticClass:"btn-reset",class:_vm.$style.header,attrs:{"href":_vm.url || null},on:{"click":function($event){$event.preventDefault();_vm.url ? _vm.onClick(_vm.url) : _vm.$emit('click')}}},[_c('div',{class:[
                _vm.$style.title,
                { [_vm.$style._opened]: _vm.isOpened },
            ]},[_vm._v("\n            "+_vm._s(_vm.title)+"\n        ")]),_vm._v(" "),(_vm.subTitle)?_c('div',{class:_vm.$style.subTitle},[_vm._v("\n            "+_vm._s(_vm.subTitle)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.items.length)?_c('div',{class:[
                _vm.$style.icon,
                { [_vm.$style._opened]: _vm.isOpened },
            ]},[_c('ArrowIcon',{class:_vm.$style.arrow})],1):_vm._e()]),_vm._v(" "),_c('ExpandBlock',[(_vm.items.length && _vm.isOpened)?_c('div',{class:_vm.$style.body},_vm._l((_vm.items),function(item,i){return _c('a',{key:i,class:_vm.$style.link,attrs:{"href":item.url ? item.url : '#'},on:{"click":function($event){$event.preventDefault();item.callback ? item.callback() : _vm.onClick(item.url)}}},[_vm._v("\n                "+_vm._s(item.label)+"\n            ")])}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }