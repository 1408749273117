<template>
    <symbol id="icon-accordion-arrow" viewBox="0 0 16 17">
        <path d="M12 7l-4 4-4-4h8z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconAccordionArrow',
};
</script>
