export const state = () => ({
    albums: [],
    tabs: [],
    activeAlbum: {},
    activeTab: {},
});

export const getters = {
    cards: state => state.tabs.find(tab => tab.value === state.activeTab)?.features || [],
    filteredCards: state => state.tabs.find(tab => tab.value === state.activeTab)?.features.filter(el => el.text.length > 0) || [],
};

export const actions = {
    setAlbums({ commit }, payload) {
        const albums = payload.map(album => ({ ...album, label: album.name }));

        commit('SET_ALBUMS', albums);
    },

    setTabs({ commit }, payload) {
        const tabs = [];
        payload.forEach((el, idx) => {
            tabs.push({
                value: idx,
                label: el.name,
                features: el.features,
            });
        });
        commit('SET_TABS', tabs);
        commit('SET_ACTIVE_TAB', tabs[0].value);
    },

    setActiveGalleryAlbum({ state, commit }, payload) {
        const newActiveAlbum = state.albums.find(album => album.id == payload);

        if (newActiveAlbum) {
            state.albums.forEach(album => album.isActive = false);
            newActiveAlbum.isActive = true;

            commit('SET_ACTIVE_GALLERY_ALBUM', newActiveAlbum);
        }
    },

    setActiveTab({ state, commit }, payload) {
        commit('SET_ACTIVE_TAB', payload);
    },
};

export const mutations = {
    SET_ALBUMS(state, payload) {
        state.albums = payload;
    },

    SET_TABS(state, payload) {
        state.tabs = payload;
    },

    SET_ACTIVE_GALLERY_ALBUM(state, payload) {
        state.activeAlbum = payload;
    },

    SET_ACTIVE_TAB(state, payload) {
        state.activeTab = payload;
    },
};
