// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MediaInfo_OwMdt{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;min-width:16%;font-size:1.2rem;line-height:1.64;color:#c5c5c5}.icon_tXW6Z{display:inline-block;width:.4rem;min-width:4px;height:.4rem;min-height:4px;margin:0 .8rem;border-radius:50%;background-color:#dbdbdb;vertical-align:middle}.adds_UamK5{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.svg_qTdJj{width:1.6rem;height:1.6rem;margin-right:.6rem;fill:#a0a0a0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MediaInfo": "MediaInfo_OwMdt",
	"icon": "icon_tXW6Z",
	"adds": "adds_UamK5",
	"svg": "svg_qTdJj"
};
module.exports = ___CSS_LOADER_EXPORT___;
