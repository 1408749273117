<template>
    <!-- <symbol id="icon-copy" viewBox="0 0 18 18">
        <path
            d="M7.66699 9.66697C7.95329 10.0497 8.31856 10.3664 8.73803 10.5956C9.15749 10.8248 9.62133 10.961 10.0981 10.9952C10.5749 11.0293 11.0534 10.9605 11.5012 10.7935C11.9491 10.6264 12.3557 10.365 12.6937 10.027L14.6937 8.02697C15.3009 7.3983 15.6368 6.55629 15.6292 5.6823C15.6216 4.80831 15.2711 3.97227 14.6531 3.35424C14.035 2.73621 13.199 2.38565 12.325 2.37806C11.451 2.37046 10.609 2.70644 9.98033 3.31364L8.83366 4.45364"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.3335 8.33283C10.0472 7.95008 9.68189 7.63338 9.26243 7.40421C8.84297 7.17503 8.37913 7.03875 7.90236 7.00461C7.4256 6.97047 6.94707 7.03925 6.49924 7.20631C6.0514 7.37337 5.64472 7.63479 5.3068 7.97283L3.3068 9.97283C2.69961 10.6015 2.36363 11.4435 2.37122 12.3175C2.37881 13.1915 2.72938 14.0275 3.3474 14.6456C3.96543 15.2636 4.80147 15.6142 5.67546 15.6217C6.54945 15.6293 7.39146 15.2934 8.02013 14.6862L9.16013 13.5462"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </symbol> -->
    <symbol id="icon-copy" viewBox="0 0 18 18">
        <path
            d="M13.5 6H7.5C6.67157 6 6 6.67157 6 7.5V13.5C6 14.3284 6.67157 15 7.5 15H13.5C14.3284 15 15 14.3284 15 13.5V7.5C15 6.67157 14.3284 6 13.5 6Z"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M12 6V4.5C12 4.10218 11.842 3.72064 11.5607 3.43934C11.2794 3.15804 10.8978 3 10.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V10.5C3 10.8978 3.15804 11.2794 3.43934 11.5607C3.72064 11.842 4.10218 12 4.5 12H6"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconCopy',
};
</script>
