export const state = () => ({
    domainUrl: null, // определяется автоматически, не менять
    prodDomain: 'alia.moscow',
    fullPath: '',
});

export const getters = {
    getDomainAddress(state) {
        return `https://${state.domainUrl}`;
    },

    getIsTestStage(state) {
        return state.prodDomain !== state.domainUrl;
    },

    getFullPath(state) {
        return state.fullPath;
    },
};

export const actions = {
    async init({ dispatch, state }, context) {
        if (context?.req?.headers['x-forwarded-host']) {
            dispatch('changeActiveDomain', context.req.headers['x-forwarded-host']);
        } else if (process.env.PROXY_URL) {
            const regex = /.*:\/\/(?:www.)?([^/]+)/g;
            const array = [...process.env.PROXY_URL.matchAll(regex)];
            const found = array.map(m => m[1]);
            const domain = found[0];

            if (domain) {
                dispatch('changeActiveDomain', domain);
            }
        } else {
            console.warn('[store domain/actions/init Error] domain address is not set');
        }

        if (context) {
            const {
                req,
            } = context;

            let protocol = 'http';

            if (req.headers['x-forwarded-proto']) {
                protocol = req.headers['x-forwarded-proto'];
            } else if (req.connection.encrypted) {
                protocol = 'https';
            }

            const host = req.headers['x-forwarded-host'] || req.headers.host;
            const url = req.url;

            if (protocol && host && url) {
                dispatch('changeFullPath', `${protocol}://${host}${req.url}`);
            }
        }
    },

    changeActiveDomain({ commit }, payload) {
        commit('SET_DOMAIN', payload);
    },

    changeFullPath({ commit }, payload) {
        commit('SET_FULL_PATH', payload);
    },
};

export const mutations = {
    SET_DOMAIN(state, domain) {
        state.domainUrl = domain;
    },

    SET_FULL_PATH(state, fullPath) {
        state.fullPath = fullPath;
    },
};
