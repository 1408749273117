export function queryToObject(qs) {
    let obj = {};

    if (qs) {
        if (qs.startsWith("?")) {
            qs = qs.substring(1);
        }

        let params = qs.split('&');

        params.forEach(param => {
            let name = param.split('=')[0];
            let value = param.split('=')[1];
            if (name && value) {
                if (Object.prototype.hasOwnProperty.call(obj, name)) {
                    if (Array.isArray(obj[name])) {
                        obj[name].push(value);
                    } else {
                        obj[name] = [obj[name], value];
                    }
                } else {
                    obj[name] = value;
                }
            }
        });
    }
    return obj;
}

export function objectToQuery(obj) {
    let qs = '';
    for (let name in obj) {
        if (obj[name]) {
            if (Array.isArray(obj[name])) {
                obj[name].forEach(val => {
                    if (val) {
                        qs += `${name}=${val}&`;
                    }
                });
            } else {
                qs += `${name}=${obj[name]}&`;
            }
        }
    }
    return qs.slice(0, -1);
}

export function updateQuery(values, additionalParams = {}) {
    let query = {};

    Object.keys(values).forEach(key => {
        if (values[key] !== '') {
            query[key] = values[key];
        }
    });

    $nuxt.$router.replace({
        query: { ...query, ...additionalParams},
    });
}

export function updateQueryUseHistory(values, additionalParams = {}) {
    let query = {};
    Object.keys(values).forEach(key => {
        if (values[key] !== '') {
            query[key] = values[key];
        }
    });
    const updatedQuery = objectToQuery({ ...query, ...additionalParams});
    let newUrl = window.location.pathname;
    if (Object.keys(updatedQuery).length) {
        newUrl = newUrl + '?' + updatedQuery;
    }

    window.history.replaceState({}, '', newUrl);
}

export function applyQuery(defaulValues, queryValues) {
    let values = JSON.parse(JSON.stringify(defaulValues));

    if (typeof defaulValues !== 'object') {
        console.warn('defaulValues must be an object');
        return;
    }
    if (typeof queryValues !== 'object') {
        console.warn('queryValues must be an object');
        return;
    }

    if (!Object.keys(queryValues).length) {
        return values;
    }

    for (let name in queryValues) {
        if (Object.prototype.hasOwnProperty.call(values, name)) {
            if (Array.isArray(values[name])) {
                if (Array.isArray(queryValues[name])) {
                    values[name] = queryValues[name].map(i => {
                        if (i === 'true') {
                            return true;
                        } else if (i === 'false') {
                            return false;
                        } else return i;
                    });
                } else {
                    values[name] = [queryValues[name] === 'true' ? true : queryValues[name] === 'false' ? false : queryValues[name]];
                }
            } else {
                if (Array.isArray(queryValues[name])) {
                    values[name] = queryValues[name][0] === 'true' ? true : queryValues[name][0] === 'false' ? false : queryValues[name][0];
                } else {
                    values[name] = queryValues[name] === 'true' ? true : queryValues[name] === 'false' ? false : queryValues[name];
                }
            }
        }
    }

    return values;
}

/**
 * Исключает из объекта queryValue те значения множественного выбора которые указаны в excludeValue
 * @param queryValues
 * @param excludeValues
 */
export function excludeQuery(queryValues, excludeValues) {
    if (Object.prototype.toString.call(queryValues) !== '[object Object]') {
        console.warn('utils excludeQuery: queryValue must be an object');
        return queryValues;
    }
    if (Object.prototype.toString.call(queryValues) !== '[object Object]') {
        console.warn('utils excludeQuery: queryValue must be an object');
        return queryValues;
    }

    if (Object.keys(excludeValues).length === 0) {
        return queryValues;
    }

    const query = JSON.parse(JSON.stringify(queryValues));
    for (let excludeKey in excludeValues) {
        if (!Object.prototype.hasOwnProperty.call(query, excludeKey)) {
            continue;
        }

        if (!Array.isArray(query[excludeKey])) {
            continue;
        }

        let excludeArr = excludeValues[excludeKey];
        if (!Array.isArray(excludeArr)) {
            excludeArr = [excludeValues[excludeKey]];
        }

        query[excludeKey] = query[excludeKey].filter(queryValue => !excludeArr.includes(queryValue));
    }

    return query;
}
