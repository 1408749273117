<template>
    <div :class="$style.ImageModal">
        <transition
            :enter-active-class="$style.contentEnterActive"
            :leave-active-class="$style.contentLeaveActive"
            :enter-class="$style.contentEnter"
            :leave-to-class="$style.contentLeaveTo"
            @after-leave="$emit('after-leave')"
        >
            <div
                v-show="visible"
                :class="[$style.container, responsiveClass]"
            >
                <div v-if="data?.imageDisplay" :class="$style.image">
                    <ImageLazy
                        :origin="data.imageDisplay"
                        :preview="data?.imagePreview || ''"
                        cover-image
                    />
                </div>
                <div :class="[$style.modal, responsiveClass]">
                    <div :class="[$style.header, responsiveClass]">
                        <div :class="$style.title">
                            {{ data?.title || '' }}
                        </div>
                        <UiCircleButton :class="[$style.close, responsiveClass]" @click="$emit('close')">
                            <CloseIcon />
                        </UiCircleButton>
                    </div>
                    <template v-if="data?.component">
                        <component
                            :is="data.component"
                            v-bind="getProps"
                        />
                    </template>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import ImageLazy from '~/components/common/ImageLazy.vue';
import UiCircleButton from '~/components/ui/buttons/UiCircleButton';
import CloseIcon from '~/assets/icons/home/close.svg?inline';

export default {
    name: 'ImageModal',
    components: { UiCircleButton, ImageLazy, CloseIcon },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },

        data: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        getProps() {
            return { ...this.data.componentOptions, isResponsive: this.data.isResponsive };
        },

        responsiveClass() {
            return { [this.$style._isResponsive]: this.data.isResponsive };
        },
    },
};
</script>

<style lang="scss" module>
    .ImageModal {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 97rem;
        min-height: 100%;
        margin: 0 auto;
        padding: 2.4rem 0;

        @include respond-to(xs) {
            width: 100vw;
            height: 100%;
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
        }

        @include respond-to(sm) {
            width: 74rem;
        }

        @include respond-to(xs) {
            width: 100vw;
        }

        .close {
            position: absolute;
            top: 50%;
            right: 2.8rem;
            border-radius: 0;
            transform: translateY(-50%);

            @include respond-to(xs) {
                &._isResponsive {
                    position: relative;
                    top: unset;
                    right: unset;
                    transform: unset;
                }
            }
        }
    }

    .container {
        display: flex;
        align-items: stretch;
        width: 100%;
        background-color: #fff;

        @include respond-to(xs) {
            &._isResponsive {
                height: 100%;
            }
        }
    }

    .contentEnterActive,
    .contentLeaveActive {
        transition: all .4s ease-out;
    }

    .contentEnter,
    .contentLeaveTo {
        opacity: 0;
    }

    .header {
        position: relative;
        padding: 2.8rem 9.6rem 2.8rem 2.8rem;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.4;

        @include respond-to(xs) {
            &._isResponsive {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 65px;
                padding: 1.2rem 1.6rem;
                border-bottom: 1px solid $base-ease-gray;

                .title {
                    font-size: 1.6rem;
                    font-weight: 700;
                    line-height: 132%;
                }
            }
        }
    }

    .image {
        position: relative;
        flex: 1 0 50%;
        background-color: $base-200;

        @include respond-to(xs) {
            display: none;
        }
    }

    .modal {
        flex: 1 0 50%;

        @include respond-to(xs) {
            &._isResponsive {
                height: 100%;
            }
        }
    }
</style>
