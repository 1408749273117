import PromotionTimerQuery from '../queries/PromotionTimerQuery.graphql';

export const state = () => ({
    timerData: {},
});

export const getters = {
    getTimerDeadline: state => state?.timerData?.timeLeft,
    getTimerTitle: state => state?.timerData?.title,
    getTimerDisabled: state => state?.timerData?.disable,
};

export const actions = {
    async loadData({ commit }) {
        try {
            const { data } = await this.$axios.$post('/graphql/', {
                query: PromotionTimerQuery.loc.source.body,
                variables: {
                    slug: 'main-page',
                },
            });

            if (!data) {
                return;
            }

            commit('SET_TIMER_DATA', data.slug);
        } catch (e) {
            console.log('[Vuex] timer/loadData: Не удалось загрузить', e);
        }
    },
};

export const mutations = {
    SET_TIMER_DATA(state, payload) {
        state.timerData = payload;
    },
};
