<template>
    <div :class="$style.MenuDropdown">
        <component
            :is="url ? 'a' : 'button'"
            :href="url || null"
            :class="$style.header"
            class="btn-reset"
            @click.prevent="url ? onClick(url) : $emit('click')"
        >
            <div
                :class="[
                    $style.title,
                    { [$style._opened]: isOpened },
                ]"
            >
                {{ title }}
            </div>

            <div
                v-if="subTitle"
                :class="$style.subTitle"
            >
                {{ subTitle }}
            </div>

            <div
                v-if="items.length"
                :class="[
                    $style.icon,
                    { [$style._opened]: isOpened },
                ]"
            >
                <ArrowIcon :class="$style.arrow" />
            </div>
        </component>
        <ExpandBlock>
            <div
                v-if="items.length && isOpened"
                :class="$style.body"
            >
                <a
                    v-for="(item, i) in items"
                    :key="i"
                    :href="item.url ? item.url : '#'"
                    :class="$style.link"
                    @click.prevent="item.callback ? item.callback() : onClick(item.url)"
                >
                    {{ item.label }}
                </a>
            </div>
        </ExpandBlock>
    </div>
</template>

<script>
import ArrowIcon from '~/assets/icons/arrow-down-solid.svg?inline';
import { mapActions } from 'vuex';
import { applyQuery, queryToObject } from '~/assets/js/utils/queryUtils';
import { DEFAULT_FILTERS } from '~/assets/js/constants';
import ExpandBlock from '~/components/common/ExpandBlock.vue';

export default {
    name: 'MenuDropdown',

    components: {
        ExpandBlock,
        ArrowIcon,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        subTitle: {
            type: String,
            default: '',
        },

        url: {
            type: String,
            default: '',
        },

        items: {
            type: Array,
            default: () => [],
        },

        isOpened: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        ...mapActions({
            changeFilters: 'flats/changeFilters',
            resetFilters: 'flats/resetFilters',
        }),

        async onClick(link) {
            setTimeout(() => { // ios safari fix animation bug
                this.$emit('click-on-link');
            }, 300);

            if (this.$route.fullPath === link) {
                window.scrollTo({ top: 0, behavior: 'smooth' }); // то скроллим страницу вверх
            } else if (this.$route.path === '/flats/' && // проверяем что находимся на выборщике
                link.includes('/flats/') // и то что пользователь кликнул на меню ссылка которого ссылается на выборщик
            ) {
                await this.resetFilters();
                // и если есть query параметры то тогда применяем их к выборщику
                if (link.split('?').length === 2) {
                    const query = queryToObject(link.split('?')[1]);
                    const filterFromQuery = applyQuery(DEFAULT_FILTERS, query);
                    await this.changeFilters(filterFromQuery);
                }
            } else {
                await this.$router.push(link);
            }
        },
    },
};
</script>

<style lang="scss" module>
    .MenuDropdown {
        display: flex;
        flex-direction: column;
        height: fit-content;
    }

    .header {
        display: flex;
        align-items: center;
        margin-bottom: .4rem;
    }

    .title {
        @include old-h4;

        text-transform: uppercase;
        color: $base-500;
        transition: $homeTransition;

        &._opened {
            color: $base-900;
        }
    }

    .subTitle {
        @include old-h4;

        margin-left: .4rem;
        color: #757d88;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.6rem;
        height: 1.6rem;
        margin-left: .8rem;
        transition: $homeTransition;

        &._opened {
            transform: rotate(180deg);
        }
    }

    .arrow {
        width: 1.6rem;
        height: 1.6rem;
        color: #757d88;
    }

    .body {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        transition: $homeTransition;
    }

    .link {
        @include old-p5;

        margin-top: 1.2rem;
        text-transform: uppercase;
        color: $base-500;
    }
</style>
