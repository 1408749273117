<template>
    <component
        :is="buttonComponent"
        :class="[$style.UiButton, classList]"
        v-bind="elementAttributes"
        @click="$emit('click')"
        @mouseenter="$emit('mouseenter')"
        @mouseleave="$emit('mouseleave')"
    >
        <span
            v-if="$scopedSlots.iconBefore"
            :class="$style.iconBefore"
        >
            <slot name="iconBefore" :active="isActive" />
        </span>

        <slot />

        <span
            v-if="$scopedSlots.iconAfter"
            :class="$style.iconAfter"
        >
            <slot name="iconAfter" :active="isActive" />
        </span>

        <transition
            :enter-active-class="$style.loaderEnterActive"
            :leave-active-class="$style.loaderLeaveActive"
            :enter-class="$style.loaderEnter"
            :leave-to-class="$style.loaderLeaveTo"
            :duration="600"
        >
            <span v-if="loading" :class="$style.loader"></span>
        </transition>
    </component>
</template>

<script>
// Компонент ориентирован на ALIA-UI (KIT)
import { kebabToCamelCase } from '~/components/ui/utils/helpers';

export default {
    name: 'UiButton',

    inject: {
        ButtonGroup: {
            default: null,
        },
    },

    props: {
        value: {
            type: [String, Number, Array, Object],
            required: false,
            default: () => undefined,
        },

        link: {
            type: String,
            default: '',
        },

        external: {
            type: Boolean,
            default: false,
        },

        blank: {
            type: Boolean,
            default: false,
        },

        type: {
            type: String,
            default: 'button',
        },

        size: {
            type: String,
            default: 'normal',
            validator: value => ['normal', 'big', 'medium', 'moderate', 'small', 'superSmall'].includes(value),
        },

        color: {
            type: String,
            default: 'gray',
            validator: value => ['gray', 'black', 'bordered', 'white', 'gray-and-white', 'yellow'].includes(value),
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        inverse: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isActive: false,
        };
    },

    computed: {
        buttonComponent() {
            return this.link ? this.external || this.blank ? 'a' : 'nuxt-link' : 'button';
        },

        elementAttributes() {
            return {
                type: this.link ? null : this.type,
                to: this.external || this.blank ? null : this.link ? this.link : null,
                href: this.external || this.blank ? this.link : null,
                target: this.blank ? '_blank' : null,
                rel: this.blank ? 'noreferrer noopener' : null,
            };
        },

        classList() {
            return {
                [this.$style[`_${kebabToCamelCase(this.color)}`]]: this.color,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style._inverse]: this.inverse,
                [this.$style._active]: this.isActive,
                [this.$style._mobile]: !this.$device.isDesktop,
                [this.$style._disabled]: this.disabled || (this.blank || this.external) && !this.link,
                [this.$style._loading]: this.loading,
                [this.$style._withoutText]: !this.$slots.default,
            };
        },
    },

    created() {
        if (this.ButtonGroup) {
            this.ButtonGroup.registerButton(this);
        }
    },

    beforeDestroy() {
        if (this.ButtonGroup) {
            this.ButtonGroup.unregisterButton(this);
        }
    },
};
</script>

<style lang="scss" module>
    .UiButton {
        @include l4;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 2rem;
        border: none;
        outline: none;
        text-transform: uppercase;
        font-size: 1.2rem;
        transition: $transition, color .3s ease;
        cursor: pointer;
        user-select: none;

        &._disabled,
        &._loading {
            cursor: not-allowed;
            pointer-events: none;
        }

        &._loading {
            color: rgba(0, 0, 0, 0);
            transition: $transition, color .3s ease;
        }

        &._withoutText {
            padding: initial;

            .iconBefore {
                margin-right: initial;
            }

            .iconAfter {
                margin-left: initial;
            }
        }

        .iconBefore,
        .iconAfter {
            display: flex;
            width: 1.6rem;
            height: 1.6rem;
        }

        .iconBefore {
            margin-right: .8rem;
        }

        .iconAfter {
            margin-left: .8rem;
        }

        .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            box-sizing: border-box;
            display: inline-block;
            width: 2.6rem;
            height: 2.6rem;
            border-radius: 50%;
            border: .3rem solid;
            transform-origin: 0 0;
            animation: rotation 1s linear infinite;
        }
    }

    /* sizes */
    .UiButton._normal {
        height: 4rem;
        min-height: 4rem;

        &._withoutText {
            width: 4rem;
            min-width: 4rem;
        }
    }

    .UiButton._moderate {
        height: 4.8rem;
        min-height: 4.8rem;

        &._withoutText {
            width: 4.8rem;
            min-width: 4.8rem;
        }
    }

    .UiButton._medium {
        height: 5.2rem;
        min-height: 5.2rem;

        &._withoutText {
            width: 5.2rem;
            min-width: 5.2rem;
        }
    }

    .UiButton._big {
        height: 5.6rem;
        min-height: 5.6rem;

        &._withoutText {
            width: 5.6rem;
            min-width: 5.6rem;
        }
    }

    .UiButton._small {
        @include l5;

        height: 3.6rem;
        min-height: 3.6rem;

        &._withoutText {
            width: 3.6rem;
            min-width: 3.6rem;
        }
    }

    .UiButton._superSmall {
        @include l5;

        height: 3.2rem;
        min-height: 3.2rem;

        &._withoutText {
            width: 3.2rem;
            min-width: 3.2rem;
        }
    }

    /* colors */
    .UiButton._gray {
        background-color: $base-50;
        color: $base-900;

        &._mobile {
            &:active {
                color: $base-600;
            }
        }

        @include hover {
            &:hover {
                color: $base-600;
            }
        }

        &:active {
            color: $base-900;
        }

        &._active {
            background-color: $base-900;
            color: $base-0;
        }

        &._disabled {
            color: $base-400 !important; //stylelint-disable-line declaration-no-important
        }

        .loader {
            border-color: $base-900;
            border-bottom-color: transparent;
        }

        &._loading {
            color: rgba(0, 0, 0, 0);
        }
    }

    .UiButton._black {
        background-color: $base-900;
        color: $base-0;

        &._mobile {
            &:hover {
                background-color: $base-900;
            }

            &:active {
                background-color: $base-700;
            }

            &._active {
                background-color: $base-0;
                color: $base-900;
            }
        }

        @include hover {
            &:hover {
                background-color: $base-700;
            }

            &:active {
                background-color: $base-900;
            }
        }

        &._active {
            background-color: $base-0;
            color: $base-900;
        }

        &._disabled {
            background-color: $base-300 !important; //stylelint-disable-line declaration-no-important
        }

        .loader {
            border-color: $base-0;
            border-bottom-color: transparent;
        }

        &._loading {
            color: rgba(0, 0, 0, 0);
        }
    }

    .UiButton._bordered {
        border: 1px solid $base-200;
        background-color: transparent;
        color: $base-900;

        &._mobile {
            &:hover {
                color: $base-900;
            }

            &:active {
                color: $base-600;
            }
        }

        &:hover {
            color: $base-600;
        }

        &:active {
            color: $base-900;
        }

        &._disabled {
            color: $base-400 !important; //stylelint-disable-line declaration-no-important
        }

        &._inverse {
            border-color: $base-700;
            color: $base-500;

            &:hover {
                color: $base-0;
            }

            &:active,
            &._active {
                border-color: $base-600;
                background-color: $base-600;
                color: $base-0;
            }

            .loader {
                border-color: $base-500;
                border-bottom-color: transparent;
            }
        }

        .loader {
            border-color: $base-900;
            border-bottom-color: transparent;
        }

        &._loading {
            color: rgba(0, 0, 0, 0);
        }
    }

    .UiButton._white {
        background-color: $base-0;
        color: $base-900;

        &._mobile {
            &:hover {
                color: $base-900;
            }

            &:active {
                color: $base-600;
            }
        }

        &:hover {
            color: $base-600;
        }

        &:active {
            color: $base-900;
        }

        &._disabled {
            background-color: $base-300 !important; //stylelint-disable-line declaration-no-important
            color: $base-0 !important; //stylelint-disable-line declaration-no-important
        }

        &._active {
            background-color: $base-900;
            color: $base-0;

            &:hover {
                color: $base-0;
            }
        }

        .loader {
            border-color: $base-900;
            border-bottom-color: transparent;
        }

        &._loading {
            color: rgba(0, 0, 0, 0);
        }
    }

    .UiButton._grayAndWhite {
        background-color: $base-50;
        color: $base-400;

        @include hover {
            &:hover {
                background-color: $base-0;
                color: $base-500;
            }
        }

        &:active,
        &._active {
            background-color: $base-0;
            color: $base-900;
        }

        &._disabled {
            color: $base-300;
        }

        .loader {
            border-color: $base-600;
            border-bottom-color: transparent;
        }

        &._loading {
            color: rgba(0, 0, 0, 0);
        }
    }

    .UiButton._yellow {
        background-color: #faee7c;
        color: $base-900;

        &._mobile {
            &:hover {
                background-color: #faee7c;
                color: $base-900;
            }

            &:active {
                background-color: #f1e56d;
                color: $base-900;
            }
        }

        &:hover {
            background-color: #f1e56d;
            color: $base-900;
        }

        &:active {
            background-color: #f1e56d;
            color: $base-900;
        }
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg) translate(-50%, -50%);
        }

        100% {
            transform: rotate(360deg) translate(-50%, -50%);
        }
    }

    .loaderEnterActive {
        transition: opacity .3s ease;
    }

    .loaderLeaveActive {
        transition: opacity .3s ease;
    }

    .loaderEnter,
    .loaderLeaveTo {
        opacity: 0;
    }
</style>
