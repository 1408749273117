<template>
    <transition name="page-transition">
        <div
            v-if="changing"
            :class="$style.PageTransition"
        />
    </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'PageTransition',

    data() {
        return {
            exceptions: [
                'purchase',
                'mortgage',
                'installment',
                'booking',
            ],
        };
    },

    computed: {
        ...mapState('PageTransition', [
            'changing',
        ]),
    },

    watch: {
        '$route.path': function(prevVal, nextVal) {
            const prev = prevVal.replace(/\//g, '');
            const next = nextVal.replace(/\//g, '');

            if (!(this.exceptions.includes(prev) && this.exceptions.includes(next))) {
                this.animate();
            }
        },
    },

    methods: {
        ...mapActions({
            animate: 'PageTransition/animate',
        }),
    },
};
</script>

<style lang="scss" module>
    .PageTransition {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 21;
        width: 100%;
        height: 100%;
        background: $base;
    }
</style>
