<template>
    <div :class="$style.MenuBuy">
        <MenuLeft :links="links" />

        <MenuRight
            v-show="banners.length"
            :cards="banners"
        />

        <!-- Убрал чёрную пятницу -->
        <MenuBlackFridayBanner
            v-if="false"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { plural } from '~/assets/js/utils';

import MenuLeft from './MenuLeft';
import MenuRight from './MenuRight';
import MenuBlackFridayBanner from './MenuBlackFridayBanner';

import AdditionalIcon from '~/assets/icons/home/additional.svg?inline';
import PenthouseIcon from '~/assets/icons/home/penthouse.svg?inline';
import TownhouseIcon from '~/assets/icons/home/townhouse.svg?inline';
import CityUnitIcon from '~/assets/icons/home/door-open-stroke.svg?inline';
import CarIcon from '~/assets/icons/home/car.svg?inline';
import BoxIcon from '~/assets/icons/home/box.svg?inline';
import BuildingsIcon from '~/assets/icons/buildings.svg?inline';

export default {
    name: 'MenuBuy',

    components: {
        MenuLeft,
        MenuRight,
        MenuBlackFridayBanner,
    },

    props: {
        banners: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        ...mapState('header', [
            'flatCount',
            'cityhouseCount',
            'townhouseCount',
            'parkingCount',
            'storageCount',
            'penthouseCount',
            'cityunitCount',
        ]),

        links() {
            const links = [];
            if (this.flatCount && this.flatCount !== '0') {
                links.push({
                    title: plural(this.flatCount, ['квартира', 'квартиры', 'квартир']),
                    url: '/flats/?newTypeFlat=209',
                    count: this.flatCount,
                    icon: AdditionalIcon,
                });
            }

            if (this.cityhouseCount && this.cityhouseCount !== '0') {
                links.push({
                    title: plural(this.cityhouseCount, ['ситихаус', 'ситихауса', 'ситихаусов']),
                    url: '/flats/?newTypeFlat=210',
                    count: this.cityhouseCount,
                    icon: BuildingsIcon,
                });
            }

            if (this.parkingCount && this.parkingCount !== '0') {
                links.push({
                    title: plural(this.parkingCount, ['машино-место', 'машино-места', 'машино-мест']),
                    url: '/parking/',
                    count: this.parkingCount,
                    icon: CarIcon,
                });
            }

            if (this.storageCount && this.storageCount !== '0') {
                links.push({
                    title: plural(this.storageCount, ['кладовая', 'кладовых', 'кладовых']),
                    url: '/storage/',
                    count: this.storageCount,
                    icon: BoxIcon,
                });
            }

            if (this.penthouseCount && this.penthouseCount !== '0') {
                links.push({
                    title: plural(this.penthouseCount, ['пентхаус', 'пентхауса', 'пентхаусов']),
                    url: '/flats/?newTypeFlat=211',
                    count: this.penthouseCount,
                    icon: PenthouseIcon,
                });
            }

            if (this.townhouseCount && this.townhouseCount !== '0') {
                links.push({
                    title: plural(this.townhouseCount, ['таунхаус', 'таунхауса', 'таунхаусов']),
                    url: '/flats/?newTypeFlat=280',
                    count: this.townhouseCount,
                    icon: TownhouseIcon,
                });
            }

            if (this.cityunitCount && this.cityunitCount !== '0') {
                links.push({
                    title: plural(this.cityunitCount, ['сити-юнит', 'сити-юнита', 'сити-юнитов']),
                    url: '/flats/?newTypeFlat=212',
                    count: this.cityunitCount,
                    icon: CityUnitIcon,
                });
            }

            return links;
        },
    },
};
</script>

<style lang="scss" module>
    .MenuBuy {
        display: flex;
        justify-content: space-between;
        height: 100%;
    }
</style>
