<template>
    <section
        class="value-box"
        :class="{
            'value-box_opened': isOpened,
            'value-box_mobile': !$device.isDesktop,
        }"
        @click="$emit('click')"
    >
        <div class="value-box__title">
            {{ value ? value.label || value.value : '' }}
        </div>

        <img
            src="/images/pages/landing/icons/arrow-down.svg"
            alt="Стрелка"
            class="value-box__arrow"
        >
    </section>
</template>

<script>
export default {
    name: 'SelectValue',

    props: {
        value: {
            type: Object,
            default: null,
        },

        isOpened: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
    .value-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 4.5rem;
        padding: 0 1.5rem;
        border-radius: .4rem;
        background-color: #eef0ef;
        transition: .3s ease-in-out;
        cursor: pointer;

        // &:hover {
        // }

        &_opened {
            .value-box__arrow {
                transform: rotate(180deg);
            }
        }

        &__arrow {
            width: 1rem;
            margin-left: .5rem;
            transition: inherit;
        }

        &__title {
            font-family: 'Aeroport', sans-serif;
            font-size: 1.6rem;
            line-height: 1;
            color: #2a2c2b;
        }
    }

    .value-box_mobile {
        height: 45px;
        padding: 0 15px;
        border-radius: 4px;

        .value-box {
            &__arrow {
                width: 10px;
                margin-left: 5px;
            }

            &__title {
                font-size: 16px;
            }
        }
    }
</style>
