<template>
    <p :class="[$style.inputBlock, {[$style._error]: isError, [$style._focus]: isActive, [$style._textarea]: textarea, [$style._reverseColor]: blockColor === 'black'}]">
        <component
            :is="tag"
            :id="name"
            ref="input"
            v-mask="inputMask"
            :value="inputValue"
            :class="[$style.input, {[$style._inputHome]: isHomeInput, [$style._white]: white}]"
            :type="type"
            :name="name"
            :placeholder="placeholder"
            :required="isRequire"
            :autocomplete="autocomplete"
            @input="onInput"
            @change="onChange"
            @blur="onBlur"
            @focus="onFocus"
        />

        <label
            :class="$style.label"
            :for="name"
        >
            {{ label }}
        </label>
        <template v-if="isError">
            <span
                :class="$style.error"
                v-html="error.messages[0]"
            ></span>
        </template>
        <template v-if="homeError">
            <span
                :class="$style.error"
                v-html="homeError"
            ></span>
        </template>
        <slot></slot>
    </p>
</template>

<script>
import MaskedInput from 'vue-text-mask';

export default {
    name: 'UiInput',

    components: {
        MaskedInput,
    },

    props: {
        tag: {
            type: String,
            default: 'input',
        },

        label: {
            type: String,
            default: null,
            require: true,
        },

        type: {
            type: String,
            default: 'text',
        },

        value: {
            type: [String, Number],
            default: null,
        },

        placeholder: {
            type: String,
            default: null,
        },

        name: {
            type: String,
            default: '',
        },

        isRequire: {
            type: Boolean,
            default: false,
        },

        autocomplete: {
            type: String,
            default: null,
        },

        isMask: {
            type: Boolean,
            default: false,
        },

        maskPattern: {
            type: [Array, Function, undefined],
            default: undefined,
        },

        error: {
            type: Object,
            default: () => ({}),
        },

        textarea: {
            type: Boolean,
            default: false,
        },

        blockColor: {
            type: String,
            // white, black
            default: 'white',
        },

        isHomeInput: {
            type: Boolean,
            default: false,
        },

        white: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            inputValue: '',
            isActive: false,
            homeError: '',
        };
    },

    computed: {
        phoneMask() {
            if (!this.isMask) {
                return null;
            }

            return '+7 (###) ###-##-##';
        },

        inputMask() {
            if (this.maskPattern) {
                return this.maskPattern;
            }
            if (this.phoneMask) {
                return this.phoneMask;
            }

            return '';
        },

        isError() {
            return Boolean(this.error) && Boolean(this.error.messages) && Boolean(this.error.messages.length);
        },
    },

    watch: {
        value(val) {
            this.inputValue = val;
        },
    },

    created() {
        if (this.value) {
            this.inputValue = this.value;
        }
    },

    methods: {
        onInput(evt) {
            if (!evt.target && !evt.target?.value) {
                return;
            }
            this.inputValue = evt.target.value;
            this.$emit('input', evt);
        },

        onChange(evt) {
            this.inputValue = evt.target.value;
            this.$emit('change', evt);
        },

        onBlur(evt) {
            if (!evt.target.value) {
                this.isActive = false;
            }
            if (this.name === 'name' && this.isHomeInput) {
                const cyrillicValidationRegex = /^[\p{Script=Cyrl}\s]*$/u;
                this.inputValue = evt.target.value.match(cyrillicValidationRegex);
                this.inputValue === null? this.inputValue = '' : this.inputValue = this.inputValue[0];
                this.inputValue === ''? this.homeError = 'Имя должно содержать только кириллицу' : this.homeError = '';
            }
            this.$emit('blur', evt);
        },

        onFocus() {
            this.isActive = true;
        },
    },
};
</script>

<style lang="scss" module>
    .inputBlock {
        position: relative;
        height: 9rem;
        padding: 1.6rem 0;
        text-transform: uppercase;
        font-family: inherit;
        font-size: 1.2rem;
        line-height: 1;
        color: $base;

        @include respond-to(sm) {
            text-transform: unset;
            font-size: 16px;
        }

        & ._white {
            border-color: #fff;
            background-color: #fff;
            color: $base;
        }

        &._textarea {
            height: 27.2rem;

            @include respond-to(xs) {
                height: 210px;
            }
        }

        &._error {
            .input {
                border-color: $accent;
            }
        }

        &._focus {
            & .label {
                background-color: $base-inverse;
                color: $base;
                transform: translateY(-4.2rem) scale(.9);
            }

            textarea + .label {
                transform: translateY(-3.4rem) scale(.9);
            }

            .input {
                border-color: $base-gray;
            }
        }

        &._reverseColor._focus {
            & .label {
                background-color: $base;
                color: $base-inverse;
            }

            .input {
                color: $base-inverse;
            }
        }
    }

    .label {
        position: absolute;
        top: 50%;
        left: .8rem;
        z-index: 2;
        display: block;
        padding: 0 .8rem;
        font-size: inherit;
        color: $base-gray;
        transform: translateY(-50%);
        transform-origin: left center;
        transition: transform .3s ease, color .3s ease, background-color .3s ease;
        pointer-events: none;
    }

    textarea + .label {
        top: 3.6rem;
        transform: none;
    }

    .input {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 1.6rem;
        border: 1px solid $base-ease-gray;
        background-color: transparent;
        text-align: left;
        font: inherit;
        line-height: inherit;
        color: inherit;
        transition: border-color .3s ease-in-out;
        resize: none;

        &._inputHome {
            background-color: $base-ease-gray;
        }

        &:focus,
        &:hover {
            border-color: $base-gray;
            outline: none;
        }
    }

    .error {
        position: absolute;
        bottom: -4px;
        left: 0;
        font-size: 1.1rem;
        line-height: 1.6;
        color: $accent;
    }
</style>
