// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheSprite_JFaF1{position:absolute;top:0;left:0;width:0;height:0;opacity:0;pointer-events:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TheSprite": "TheSprite_JFaF1"
};
module.exports = ___CSS_LOADER_EXPORT___;
