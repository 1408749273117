<template>
    <transition name="fade">
        <div
            v-show="isOverlayVisible"
            ref="FullScreenModal"
            :class="$style.FullScreenModal"
            :style="style"
        >
            <keep-alive>
                <component
                    :is="component"
                    v-if="component"
                    :key="componentKey"
                    v-bind="componentOptions"
                    @close="onClose"
                />
            </keep-alive>
            <div ref="sizeBlock" :class="$style.sizeBlock"></div>
        </div>
    </transition>
</template>

<script>
import { debounce, generateUniqueId } from '~/assets/js/utils';
import { lockBody, unlockBody } from '@/assets/js/utils';
import { convertToUnit } from '~/components/ui/utils/helpers';

export default {
    name: 'FullScreenModal',

    data() {
        return {
            component: null,
            componentOptions: null,
            componentKey: null,
            history: null,
            isOverlayVisible: false,
            height: '100vh',
            resizeObserver: null,
            hasHeader: false,
        };
    },

    computed: {
        style() {
            return {
                height: convertToUnit(this.height),
            };
        },
    },

    watch: {
        '$route.name'() {
            this.onClose();
        },
    },

    created() {
        this.debouncedCalculateHeight = debounce(this.calculateHeight, 300);
    },

    beforeMount() {
        this.$fullScreenModal.event.$on('open', this.onOpen);
        this.$fullScreenModal.event.$on('close', this.onClose);
        this.$fullScreenModal.event.$on('changeData', this.onChangeData);
    },

    mounted() {
        this.resizeObserver = new ResizeObserver(this.onResize);
    },

    beforeDestroy() {
        this.$fullScreenModal.event.$off('open', this.onOpen);
        this.$fullScreenModal.event.$off('close', this.onClose);
        this.$fullScreenModal.event.$off('changeData', this.onChangeData);

        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },

    methods: {
        onOpen({ component, componentOptions, hasHeader }) {
            if (this.history === null) {
                this.history = [];
            } else if (Array.isArray(this.history)) {
                this.history.push({
                    component: this.component,
                    componentOptions: this.componentOptions,
                    componentKey: this.componentKey,
                });
            }

            this.hasHeader = Boolean(hasHeader);
            if (this.resizeObserver) {
                this.resizeObserver.observe(this.$refs.sizeBlock);
            }
            window.addEventListener('resize', this.debouncedCalculateHeight);
            window.addEventListener('scroll', this.debouncedCalculateHeight);
            setTimeout(() => {
                this.calculateHeight();
            }, 400);

            this.isOverlayVisible = true;

            if (componentOptions) {
                this.componentOptions = componentOptions;
            }
            if (component) {
                this.componentKey = generateUniqueId();
                this.component = component;
            }

            this.$nextTick(() => {
                lockBody();
            });
        },

        onClose() {
            if (Array.isArray(this.history) && this.history.length > 0) {
                const { component, componentOptions, componentKey } = this.history.pop();
                this.component = component;
                this.componentOptions = componentOptions;
                this.componentKey = componentKey;
                return false;
            } else {
                this.history = null;
            }

            this.isOverlayVisible = false;

            setTimeout(() => {
                this.componentOptions = null;
                this.component = null;

                unlockBody();
            }, 500);
            window.removeEventListener('resize', this.debouncedCalculateHeight);
            window.removeEventListener('scroll', this.debouncedCalculateHeight);

            if (this.resizeObserver) {
                this.resizeObserver.unobserve(this.$refs.sizeBlock);
            }
        },

        onChangeData({ component, componentOptions }) {
            if (componentOptions) {
                this.componentOptions = { ...this.componentOptions, ...componentOptions };
            }
            if (component) {
                this.component = component;
            }
        },

        calculateHeight() {
            this.height = this.hasHeader ? window.innerHeight - this.getHeaderHeight() : window.innerHeight;
        },

        getHeaderHeight() {
            let headerHeight = 0;

            const header = document.querySelector('.TheHeader');
            const bannerHeader = document.querySelector('.HeaderBanner:not(.HeaderBanner__hidden)');

            [header, bannerHeader].forEach(el => {
                if (!el) {
                    return false;
                }
                const { height } = el.getBoundingClientRect();
                headerHeight += height;
            });

            return Math.ceil(headerHeight);
        },

        onResize() {
            const { height } = this.$refs.sizeBlock.getBoundingClientRect();
            this.$nextTick(() => {
                this.height = this.hasHeader ? height - this.getHeaderHeight() : height;
            });
        },
    },
};
</script>

<style lang="scss" module>
    .FullScreenModal {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 98;
        overflow: hidden;
        width: 100%;
        height: 100vh;
        overscroll-behavior: contain;
    }

    .sizeBlock {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        /* stylelint-disable */
        height: 100dvh;
        /* stylelint-enable */
        pointer-events: none;
        cursor: default;
    }
</style>
