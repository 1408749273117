<template>
    <div :class="[$style.SocialLinks, {[$style._header]: isHeader}]">
        <a
            href="https://t.me/asterus_alia"
            target="_blank"
            :class="$style.socialItem"
        >
            <TgLogo />
            <span v-if="isHeader">Telegram</span>
        </a>
        <a
            href="https://vk.com/asterus.alia"
            target="_blank"
            :class="$style.socialItem"
        >
            <VkLogo />
            <span v-if="isHeader">ВКонтакте</span>
        </a>
        <!--        <a-->
        <!--            href="https://www.youtube.com/channel/UCIjyJIn6dm4PaBmnFSjeIBw/featured"-->
        <!--            target="_blank"-->
        <!--            :class="$style.socialItem"-->
        <!--        >-->
        <!--            <YtLogo />-->
        <!--            <span v-if="isHeader">YouTube</span>-->
        <!--        </a>-->
    </div>
</template>

<script>
import TgLogo from '@/assets/icons/home/social-tg.svg?inline';
import VkLogo from '@/assets/icons/home/social-vk.svg?inline';
// import YtLogo from '@/assets/icons/home/social-youtube.svg?inline';
export default {
    name: 'SocialLinks',
    components: {
        TgLogo,
        VkLogo,
        // YtLogo,
    },

    props: {
        isHeader: {
            type: Boolean,
            default: false,
        },
    },

};
</script>

<style lang="scss" module>
    .SocialLinks {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 150px;
        gap: 1.6rem;

        @include respond-to(sm) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            max-width: none;
        }

        @include respond-to(xs) {
            justify-content: flex-start;
        }

        &._header {
            @include respond-to(sm) {
                max-width: 100%;
            }

            @include respond-to(xs) {
                justify-content: space-between;
                gap: 0;
            }
        }
    }

    .socialItem {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1;
        color: $base-900;
        gap: 6px;

        span {
            margin-top: 2px;
        }

        svg {
            width: 28px;
            height: 28px;

            @include respond-to(sm) {
                width: 48px;
                height: 48px;
            }

            @include respond-to(xs) {
                width: 32px;
                height: 32px;
            }

            path {
                transition: all $homeTransition;
            }
        }

        &:hover {
            @include respond-to-min(md) {
                path {
                    fill: $home-accent-3;
                }
            }
        }
    }
</style>
