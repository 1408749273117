<template>
    <div id="formElement" :class="$style.Subscribe">
        <div :class="[$style.content, 'container']">
            <div :class="$style.SubscribeCaption">
                <div>
                    <h2 :class="$style.title">
                        <span :class="$style.title__firstline">
                            Подпишитесь
                        </span>
                        на рассылку
                    </h2>

                    <p :class="$style.description">
                        Узнавайте об акциях <br />
                        и событиях проекта первыми
                    </p>
                </div>

                <!-- <div :class="$style.SubscribeCaption__image" /> -->
            </div>

            <SubscribeForm />
        </div>
    </div>
</template>

<script>
import SubscribeForm from '~/components/forms/subscribe/SubscribeForm.vue';
import { getHeaderHeight, scrollTo } from '~/assets/js/utils';

export default {
    name: 'Subscribe',

    components: {
        SubscribeForm,
    },

    async mounted() {
        if (this.$route.hash === '#form') {
            await this.$nextTick();
            this.scrollToForm();
        }
    },

    methods: {
        scrollToForm() {
            const headerHeight = getHeaderHeight(this.$device);
            scrollTo('formElement', headerHeight, false, 1.5);
        },
    },
};
</script>

<style lang="scss" module>
    .Subscribe {
        position: relative;
        z-index: 1;
        border-bottom: 1px solid $base-100;
        background-color: $base-0;
        scroll-margin-top: $headerHeight;

        @include respond-to(sm) {
            scroll-margin-top: $headerHeightTablet;
        }

        @include respond-to(xs) {
            border-bottom: none;
            scroll-margin-top: $headerHeightMobile;
        }
    }

    .SubscribeCaption {
        position: relative;
        display: flex;
        margin-right: 15rem;

        @include respond-to(sm) {
            justify-content: space-between;
            margin-right: 0;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        padding: 5rem 0;

        @include respond-to(sm) {
            flex-direction: column;
            justify-content: start;
            padding: 4rem 2.4rem;
        }

        @include respond-to(xs) {
            padding: 3.2rem 1.6rem;
        }
    }

    .SubscribeCaption__image {
        display: block;
        width: 11.6rem;
        height: 11.6rem;
        margin-top: -2rem;
        background-repeat: no-repeat;
        background-size: cover;

        @include respond-to(xs) {
            position: absolute;
            top: -1rem;
            right: 0;
            width: 9.3rem;
            height: 9.3rem;
        }
    }

    .title {
        margin-bottom: 1.6rem;
        text-transform: uppercase;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 1.32;

        @include respond-to(xs) {
            font-size: 1.8rem;
        }
    }

    .title__firstline {
        display: block;

        @include respond-to(sm) {
            display: inline-block;
        }

        @include respond-to(xs) {
            display: block;
        }
    }

    .description {
        max-width: 30rem;
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 124%;
        color: $base-500;

        @include respond-to(sm) {
            max-width: 100%;
        }
    }
</style>
