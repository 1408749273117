<template>
    <div :class="[$style.FormPolicy, {[$style._line]: line, [$style._dark]: onDark}]">
        <div :class="$style.wrapper">
            <UiCheck
                name="policy"
                :value="checkboxes.policy"
                :rules="{ required: { allowFalse: false, message: 'test' } }"
                :custom-messages="{ required: 'Поле обязательно к заполнению' }"
                :class="$style.checkbox"
                :checked="checkboxes.policy"
                @check="handleCheck('policy')"
            >
                <span :class="$style.label">
                    Я даю согласие на обработку своих
                    <a
                        href="/policy/"
                        target="_blank"
                        @click.stop
                    >персональных данных</a>
                    <span>*</span>
                </span>
            </UiCheck>

            <UiCheck
                name="marketing"
                :value="checkboxes.marketing"
                :class="$style.checkbox"
                :checked="checkboxes.marketing"
                @check="handleCheck('marketing')"
            >
                <span :class="$style.label">
                    {{ templateText.marketing }}
                </span>
            </UiCheck>

            <UiCheck
                v-if="callback"
                name="callback"
                :value="checkboxes.callback"
                :class="$style.checkbox"
                :checked="checkboxes.callback"
                @check="onCallbackCheck()"
            >
                <span :class="$style.label">
                    {{ templateText.callback }}
                </span>
            </UiCheck>
        </div>
    </div>
</template>

<script>
import UiCheck from '~/components/ui/input/UiCheck.vue';

const CALLBACK_TEMPLATE_TEXT = 'Перезвонить мне';
const MARKETING_TEMPLATE_TEXT = ' Я даю согласие на рекламно-информационные коммуникации';

export default {
    name: 'FormPolicy',
    components: { UiCheck },

    props: {
        value: {
            type: Object,
            required: false,
            default: () => ({
                policy: false,
                marketing: true,
                callback: false,
            }),

            validator(value) {
                const requiredKeys = ['policy', 'marketing'];
                const valueKeys = Object.keys(value);
                return requiredKeys.every(key => valueKeys.includes(key));
            },
        },

        line: {
            type: Boolean,
            required: false,
            default: false,
        },

        onDark: {
            type: Boolean,
            required: false,
            default: false,
        },

        callback: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            checkboxes: {
                policy: false,
                marketing: true,
                callback: false,
            },

            templateText: {
                callback: CALLBACK_TEMPLATE_TEXT,
                marketing: MARKETING_TEMPLATE_TEXT,
            },
        };
    },

    watch: {
        value: {
            handler(newVal) {
                this.checkboxes = newVal;
            },

            deep: true,
        },


        checkboxes: {
            handler(newVal) {
                this.$emit('input', newVal);
            },

            deep: true,
        },
    },

    methods: {
        handleCheck(item) {
            this.checkboxes[item] = !this.checkboxes[item];
        },

        onCallbackCheck() {
            this.handleCheck('callback');
            this.$emit('checked', this.checkboxes.callback);
        },
    },
};
</script>

<style lang="scss" module>
    .FormPolicy {
        &._line {
            .wrapper {
                display: flex;
                flex-wrap: wrap;
            }
        }

        &._dark {
            .label {
                a {
                    font-weight: 700;
                    color: $base-300;
                }
            }
        }
    }

    .label {
        display: inline-block;
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 124%;
        color: $base-400;
        user-select: none;

        a {
            color: $base-900;

            &:hover {
                text-decoration: underline;
            }
        }

        span {
            color: $danger;
        }
    }

    .wrapper {
        margin: -.6rem -1.2rem;
    }

    .checkbox {
        padding: .6rem 1.2rem;
        text-align: left;
    }
</style>
