<template>
    <ModalContainer
        ref="BookingModal"
        :class="$style.BookingModal"
        :visible="visible"
        is-booking-modal
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <ValidationObserver
            ref="observer"
            v-slot="{ handleSubmit }"
            tag="div"
            :class="[$style.body, {[$style._success] : formSuccess || formFailure}]"
        >
            <h3 :class="[$style.title, 'h2 _base']">
                Заявка на<br>бронирование
            </h3>

            <div
                ref="accordWrap"
                :class="$style.accordWrap"
            >
                <div
                    ref="accordHeader"
                    :class="$style.accordHeader"
                >
                    <div :class="$style.accordIcon">
                        <svg>
                            <use xlink:href="#icon-info"/>
                        </svg>
                    </div>

                    <span :class="$style.accordText">Вы можете закрепить за собой выбранную квартиру и зафиксировать её стоимость.</span>
                </div>
            </div>

            <form
                ref="BookingModalForm"
                action=""
                @submit.prevent="handleSubmit(onSubmit)"
            >
                <UiInput
                    v-model="lastName"
                    field="фамилия"
                    name="lastName"
                    placeholder="Фамилия"
                    color="old"
                    rules="required|min:2|map_route"
                    :class="$style.input"
                />

                <div :class="$style.row">
                    <UiInput
                        v-model="name"
                        field="имя"
                        name="name"
                        placeholder="Имя"
                        color="old"
                        rules="required|min:2|map_route"
                        :class="$style.input"
                    />

                    <UiInput
                        v-model="middleName"
                        field="отчество"
                        name="middleName"
                        placeholder="Отчество"
                        color="old"
                        rules="map_route|min:2"
                        :class="$style.input"
                    />
                </div>

                <div
                    ref="select"
                    :class="[
                        $style.select,
                        {[$style._noTransition]: disableSelectTransition},
                    ]"
                >
                    <div :class="$style.selectBody">
                        <p :class="$style.selectLable">Выберите способ оплаты</p>

                        <div :class="$style.selectItem">
                            <UiRadio
                                checked
                                :class="$style.selectRadio"
                                name="payment"
                                value="1"
                                :label-style="{alignItems: 'flex-start', paddingTop: '4px'}"
                                @change="handleRadioChange($event, '100% оплата')"
                            >
                                <div :class="$style.selectItemWrap">
                                    <div :class="$style.selectTitle">100% оплата</div>

                                    <div :class="$style.selectRow">
                                        Срок бронирования -  <span>7 дней</span>,
                                    </div>

                                    <div :class="$style.selectRow">
                                        стоимость -  <span>100000 руб</span>
                                    </div>
                                </div>
                            </UiRadio>
                        </div>

                        <div :class="$style.selectItem">
                            <UiRadio
                                :class="$style.selectRadio"
                                name="payment"
                                value="3"
                                :label-style="{alignItems: 'flex-start', paddingTop: '4px'}"
                                @change="handleRadioChange($event, 'Другой способ')"
                            >
                                <div :class="$style.selectItemWrap">
                                    <div :class="$style.selectTitle">Другой способ</div>

                                    <div :class="$style.selectRow">
                                        (ипотека, рассрочка)
                                        <!-- ,trade-in -->
                                    </div>
                                </div>
                            </UiRadio>
                        </div>
                    </div>
                </div>

                <UiInput
                    v-model="phone"
                    is-phone
                    field="телефон"
                    type="tel"
                    name="phone"
                    placeholder="Телефон"
                    color="old"
                    rules="required|phone"
                    :class="$style.input"
                />

                <UiInput
                    v-model="email"
                    field="email"
                    type="email"
                    name="email"
                    placeholder="Email"
                    color="old"
                    rules="required|email"
                    :class="$style.input"
                />

                <UiLink
                    :class="[$style.btn, {[$style._disabled]: !formButtonActive}]"
                    type="submit"
                >
                    Оставить заявку
                </UiLink>
            </form>

            <div :class="$style.policyWrap">
                <UiCheck
                    name="policy"
                    value="policy"
                    :class="$style.policyItem"
                    :checked="checkboxes.policy"
                    @check="handleCheck('policy')"
                >
                    <div :class="$style.policy">
                        Я даю согласие на <a
                            href="/policy/"
                            target="_blank"
                            @click.stop
                        > обработку персональных данных.</a>
                        <span>*</span>
                    </div>
                </UiCheck>

                <UiCheck
                    name="offer"
                    value="offer"
                    :class="$style.policyItem"
                    :checked="checkboxes.offer"
                    @check="handleCheck('offer')"
                >
                    <div :class="$style.policy">
                        Я принимаю условия <a
                            href="/policy/"
                            target="_blank"
                            @click.stop
                        >оферты</a>
                        <span>*</span>
                    </div>
                </UiCheck>

                <UiCheck
                    name="marketing"
                    value="marketing"
                    :class="$style.policyItem"
                    :checked="checkboxes.marketing"
                    @check="handleCheck('marketing')"
                >
                    <div :class="$style.policy">
                        Я даю согласие на рекламно-информационные коммуникации.
                    </div>
                </UiCheck>
            </div>

            <div :class="$style.info">
                <p>
                    Для бронирования объекта недвижимости необходимо провести оплату в течение 3 часов с момента отправки заявки.
                    <br>
                    Если оплата не будет произведена в указанный срок, объект недвижимости вновь станет доступен для бронирования.
                </p>
            </div>

            <transition name="fade">
                <TheFormSuccess
                    v-if="formSuccess"
                    :class="$style.formSuccess"
                    title="Заявка отправлена"
                    description="Спасибо за заявку, наш менеджер свяжется с Вами в ближайшее время."
                    @click="$emit('close')"
                />
            </transition>

            <transition name="fade">
                <div
                    v-if="formFailure"
                    :class="[$style.TheFormFailure, $style.formSuccess]"
                >
                    <h3 :class="[$style.title, 'h2 _base']">
                        Заявка
                        не отправлена
                    </h3>

                    <div :class="$style.description">
                        К сожалению данная квартира уже забронирована. Наш менеджер перезвонит Вам для уточнения информации.
                    </div>

                    <UiLink
                        :class="$style.link"
                        type="button"
                        url="/flats/"
                    >
                        Показать похожие квартиры
                    </UiLink>
                </div>
            </transition>
        </ValidationObserver>
    </ModalContainer>
</template>

<script>
import ModalContainer from '~/components/common/modals/ModalContainer';
import UiInput from '~/components/ui/home/UiInput';
import TheFormSuccess from '~/components/common/form/TheFormSuccess';
import UiLink from '~/components/ui/UiLink';
import UiRadio from '~/components/ui/input/UiRadio';
import UiCheck from '~/components/ui/input/UiCheck';

export default {
    name: 'BookingModal',

    components: {
        UiCheck,
        UiRadio,
        UiLink,
        TheFormSuccess,
        UiInput,
        ModalContainer,
    },

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },

    data() {
        return {
            name: '',
            lastName: '',
            middleName: '',
            payment: '1',
            phone: '',
            email: '',
            formSuccess: false,
            formFailure: false,

            openedInfo: false,
            openedPayment: false,
            checkboxes: {
                policy: false,
                offer: true,
                marketing: true,
            },

            disableSelectTransition: true,
        };
    },

    computed: {
        formButtonActive() {
            return this.checkboxes.policy && this.checkboxes.offer;
        },
    },

    mounted() {
        this.$nextTick(function() {
            setTimeout(() => this.handlerOpenPayment(), 500);
        });
    },

    methods: {
        handlerOpenInfo() {
            this.openedInfo = !this.openedInfo;

            if (this.openedInfo) {
                this.$refs.accordWrap.style.maxHeight = this.$refs.accordWrap.scrollHeight + 'px';
            } else {
                this.$refs.accordWrap.style.maxHeight = this.$refs.accordHeader.scrollHeight + 'px';
            }
        },

        handlerOpenPayment() {
            this.openedPayment = !this.openedPayment;

            if (this.openedPayment) {
                this.$refs.select.style.maxHeight = this.$refs.select.scrollHeight + 'px';
            } else {
                this.$refs.select.style.maxHeight = this.$refs.selectHeader.scrollHeight + 'px';
            }

            if (this.disableSelectTransition) {
                setTimeout(() => this.disableSelectTransition = false);
            }
        },

        handleCheck(item) {
            this.checkboxes[item] = !this.checkboxes[item];
        },

        handleRadioChange(e) {
            this.payment = e.target.value;
        },

        async onSubmit() {
            try {
                if (window.ctw) {
                    window.ctw.createRequest('bronirovanie', this.phone.replace(/[^+\d]+/g, '').slice(1), [
                        {
                            name: 'ФИО',
                            value: this.name,
                        },
                        {
                            name: 'Email',
                            value: this.email,
                        },
                    ], function(success, data) {
                        console.log(success, data);
                    });
                }

                window.mindbox('sync', {
                    operation: 'ZayavkaNaBronirovanie',
                    data: {
                        customer: {
                            mobilePhone: this.phone,
                            lastName: this.lastName,
                            firstName: this.name,
                            middleName: this.middleName,
                            email: this.email,
                        },
                        viewProduct: {
                            product: {
                                ids: {
                                    website: this.data.flatId,
                                },
                            },
                        },
                    },
                    onSuccess: function() {
                        console.log('%c Mindbox: All good, заявка отправлена', 'background: #01630b; color: #add7ff');
                    },
                    onError: function(error) {
                        console.log('%c Mindbox: error! Ошибка в отправке заявки', 'background: #ffd400; color: #ff0000', error);
                    },
                });

                this.formSuccess = true;
                this.resetForm();
                this.$refs.BookingModal.scrollToTop();
            } catch (e) {
                this.formFailure = true;
                this.$refs.BookingModal.scrollToTop();
                console.warn('[BookingModal] onSubmit:', e);
            }
        },

        resetForm() {
            this.$refs.BookingModalForm.reset();
            this.name = '';
            this.middleName = '';
            this.lastName = '';
            this.phone = '';
            this.email = '';
            this.payment = '1';
            this.checkboxes.policy = false;
            this.checkboxes.offer = true;
            this.checkboxes.marketing = true;
            this.$refs.observer.reset();
        },
    },
};
</script>

<style lang="scss" module>
    .BookingModal {
        width: 100%;
        max-width: 61.2rem;
        height: 100%;
        margin: 0 0 0 auto;
        padding: 0;

        @include respond-to(xs) {
            width: 100vw;
            max-width: 100%;

            .form {
                flex-direction: column;
            }

            .input {
                width: 100%;
            }
        }
    }

    .body {
        position: relative;
        z-index: 1;
        width: 100%;

        @include respond-to(xs) {
            overflow-y: auto;
            height: 100%;
        }

        &._success {
            min-height: 54rem;

            @include respond-to(xs) {
                min-height: 100%;
            }

            & > form {
                visibility: hidden;
            }
        }
    }

    .selectBody {
        margin-bottom: 4rem;
    }

    .policyWrap {
        margin-top: 4rem;
    }

    .policyItem {
        margin-top: .8rem;
    }

    .policy {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.64;
        color: $base-gray;

        & > a {
            text-decoration: underline;
        }

        span {
            color: $danger;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    .formSuccess {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    .title {
        margin-bottom: 4rem;
    }

    .accordWrap {
        margin-bottom: 3.2rem;
        background-color: $base-old-100;
    }

    .accordHeader {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1.6rem 2rem;
        user-select: none;
    }

    .accordIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.6rem;

        svg {
            width: 2rem;
            height: 2rem;
        }
    }

    .accordText {
        max-width: 34rem;
        line-height: 1.6;
    }

    .row {
        display: flex;
        justify-content: space-between;

        .input {
            width: calc(50% - 1.6rem);
        }

        @include respond-to(xs) {
            flex-direction: column;

            .input {
                width: 100%;
            }
        }
    }

    .TheFormFailure {
        background-color: $base-inverse;
    }

    .description {
        margin-bottom: 4rem;
    }

    .link {
        margin-bottom: 4rem;
    }

    .select {
        margin: 1.6rem 0;
        transition: max-height .3s;

        &._error {
            border-color: $accent;

            & .selectHeader {
                color: $accent;
            }
        }

        &._noTransition {
            transition: none;

            & .selectHeader {
                transition: none;
            }

            & .selectIcon {
                transition: none;
            }
        }
    }

    .selectHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.6rem 0;
        color: $base-gray;
        transition: .3s;
        cursor: pointer;
        user-select: none;

        &._isActive {
            color: $base;

            .selectIcon {
                transform: rotate(0);
            }
        }
    }

    .selectIcon {
        width: 2rem;
        height: 2rem;
        transform: rotate(180deg);
        transition: transform .3s;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .selectItemWrap {
        display: flex;
        flex-direction: column;
        margin-top: -8px;
    }

    .selectTitle {
        font-weight: bold;
    }

    .selectItem {
        padding: 1.2rem;
        border: 1px solid $base-ease-gray;
        user-select: none;

        &:not(:last-child) {
            margin-bottom: 1.6rem;
        }
    }

    .selectRow {
        color: $base-gray;

        span {
            color: $base;
        }
    }

    .selectLable {
        margin-bottom: .6rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
    }

    .info {
        margin-top: 2rem;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.6rem;
        color: $base-gray;
    }

    .input {
        margin-bottom: 3.2rem;
    }

    .btn {
        margin-top: 4rem;
        transition: opacity $homeTransition;

        @include respond-to(xs) {
            margin-top: 3.2rem;
        }

        &._disabled {
            opacity: .5;
            pointer-events: none;
        }
    }
</style>
