import { isIe } from '@/assets/js/utils';

const GalleryMixin = {
    data() {
        return {
            interleaveOffset: .5
        };
    },

    methods: {
        checkProgress() {
            if (!this.sliderApp || isIe()) return;
            let swiper = this.sliderApp;
            for (let i = 0; i < swiper.slides.length; i++) {
                let slideProgress = swiper.slides[i].progress,
                    innerOffset = swiper.width * this.interleaveOffset,
                    innerTranslate = slideProgress * innerOffset;
                swiper.slides[i].querySelector('.parallax-image').style.transform = `translateX(${innerTranslate}px)`;
            }
        },

        setTransition(speed) {
            if (!this.sliderApp || isIe()) return;
            let swiper = this.sliderApp;
            for (let i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = `${speed}ms`;
                swiper.slides[i].querySelector('.parallax-image').style.transition = `${speed}ms`;
            }
        }
    }
};

export default GalleryMixin;