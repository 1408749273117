<template>
    <symbol
        id="icon-back"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
    >
        <circle
            cx="16"
            cy="16"
            r="15.5"
            transform="rotate(180 16 16)"
            fill="white"
            stroke="#cfcfcf"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.1246 17.2567L15.4082 19.5403L14.394 20.5546L10.3789 16.5395L14.3871 12.5312L15.4014 13.5455L13.1246 15.8223L21.6091 15.8223L21.6091 17.2567L13.1246 17.2567Z"
            fill="black"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconBack',
};
</script>
