// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageLazy_YuSHZ{position:relative;top:0;left:0;width:100%;height:100%;background-position:50%;background-repeat:no-repeat;background-size:cover}.ImageLazy_YuSHZ._absolute_A5Bo5{position:absolute}.ImageLazy_YuSHZ._contain_LzaVU{background-size:contain}.origin_2kJu1{position:absolute;top:0;left:0;width:100%;height:100%;background-position:50%;background-repeat:no-repeat;background-size:cover;opacity:0;-webkit-transform:translateZ(0);transform:translateZ(0);-webkit-transition:opacity .26s ease;transition:opacity .26s ease;will-change:transform}.origin_2kJu1[lazy=loaded]{opacity:1}.origin_2kJu1._contain_LzaVU{background-size:contain}.origin_2kJu1._cover_OBm-m{background-size:cover}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ImageLazy": "ImageLazy_YuSHZ",
	"_absolute": "_absolute_A5Bo5",
	"_contain": "_contain_LzaVU",
	"origin": "origin_2kJu1",
	"_cover": "_cover_OBm-m"
};
module.exports = ___CSS_LOADER_EXPORT___;
