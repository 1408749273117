// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiAccordion_hYHEl{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start}.title_hf8iG,.UiAccordion_hYHEl{display:-webkit-box;display:-ms-flexbox;display:flex}.title_hf8iG{-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:1.6rem;text-transform:uppercase;font-size:1.6rem;font-weight:700;line-height:1.32}@media(max-width:519.98px){.title_hf8iG{font-size:1.4rem}}.icon_W2CIC{width:1.6rem;height:1.6rem;margin-left:1rem;-webkit-transform:rotate(0deg);transform:rotate(0deg);-webkit-transition:all .3s ease-in-out;transition:all .3s ease-in-out}.icon_W2CIC path{fill:#2f2f2f}.icon_W2CIC._active_eDC5j{-webkit-transform:rotate(180deg);transform:rotate(180deg)}.list_Zeij7{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start}.list_Zeij7 .link_Gre76{margin-bottom:1rem;font-size:1.6rem;font-weight:400;line-height:124%;color:#717171}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiAccordion": "UiAccordion_hYHEl",
	"title": "title_hf8iG",
	"icon": "icon_W2CIC",
	"_active": "_active_eDC5j",
	"list": "list_Zeij7",
	"link": "link_Gre76"
};
module.exports = ___CSS_LOADER_EXPORT___;
