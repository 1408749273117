<template>
    <div
        class="radio"
        :class="{
            'radio_mobile': !$device.isDesktop,
            'radio_active': value,
        }"
        @click="click"
    >
        <div
            class="radio__btn"
        >
            <div
                v-if="value"
                class="radio__circle"
            />
        </div>

        <div class="radio__text">
            {{ label }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'TheRadio',

    props: {
        value: {
            type: Boolean,
            default: false,
        },

        label: {
            type: String,
            default: '',
        },
    },

    methods: {
        click() {
            if (!this.value) {
                this.$emit('click');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .radio {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;

        &_active {
            cursor: default;
        }

        &__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 1rem;
            padding: 3px;
            border-radius: .8rem;
            border: 1px solid #2a2c2b;
        }

        &__circle {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #2a2c2b;
        }

        &__text {
            font-family: 'Aeroport', sans-serif;
            font-size: 1.6rem;
            line-height: 1;
            color: #2a2c2b;
        }
    }

    .radio_mobile {
        .radio {
            &__btn {
                width: 16px;
                height: 16px;
                margin-right: 10px;
                border-radius: 8px;
            }

            &__text {
                font-size: 16px;
            }
        }
    }
</style>
