// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".item_Hvc6M{position:relative;padding-top:1.2rem;font-size:1.4rem;line-height:1.88;-webkit-transition:padding-left .3s ease-out;transition:padding-left .3s ease-out}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.item_Hvc6M:hover span{opacity:.64}}.item_Hvc6M._base{color:#000}.item_Hvc6M._inverse{color:#fff}.item_Hvc6M._vertical{padding-top:0;text-align:left}.item_Hvc6M._vertical:after{top:.8rem;left:0}.item_Hvc6M._huge{font-size:2rem;line-height:1.4}.item_Hvc6M._huge._vertical:after{top:.9rem}.item_Hvc6M:after{content:\"\";position:absolute;top:0;left:50%;width:.8rem;height:1rem;background-color:currentColor;opacity:0;-webkit-transform:translate(-50%,-5px) skewX(-20deg);transform:translate(-50%,-5px) skewX(-20deg);-webkit-transition:opacity .3s ease-in,-webkit-transform .3s ease-in;transition:opacity .3s ease-in,-webkit-transform .3s ease-in;transition:opacity .3s ease-in,transform .3s ease-in;transition:opacity .3s ease-in,transform .3s ease-in,-webkit-transform .3s ease-in}@media(max-width:767.98px){.item_Hvc6M:after{top:4px;width:6px;height:8px}}.item_Hvc6M span{display:inline-block;-webkit-transition:opacity .3s ease-in,-webkit-transform .3s ease-in;transition:opacity .3s ease-in,-webkit-transform .3s ease-in;transition:opacity .3s ease-in,transform .3s ease-in;transition:opacity .3s ease-in,transform .3s ease-in,-webkit-transform .3s ease-in}.item_Hvc6M._active span{opacity:.64}.item_Hvc6M._active:after{opacity:1;-webkit-transform:translate(-50%) skewX(-20deg);transform:translate(-50%) skewX(-20deg)}.item_Hvc6M._active._vertical span{-webkit-transform:translateX(2.4rem);transform:translateX(2.4rem)}.item_Hvc6M._disabled{pointer-events:none}.item_Hvc6M._disabled span{opacity:.3}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "item_Hvc6M"
};
module.exports = ___CSS_LOADER_EXPORT___;
