export const state = () => ({
});

export const actions = {
    async nuxtServerInit({ dispatch }, context) {
        await Promise.all([
            dispatch('domain/init', context),
            dispatch('device/init', context),
            dispatch('project/loadData'),
            dispatch('HeaderBanner/loadData'),
            dispatch('header/fetchHeaderInfo'),
        ]);

        dispatch('center/readLikesFromCookies', context);
        dispatch('flat/readFavoritesFromCookies', context);
        dispatch('parking/readFavoritesFromCookies', context);
        dispatch('storage/readFavoritesFromCookies', context);
        dispatch('commercial/readFavoritesFromCookies', context);
    },
};

export const mutations = {};
