import allMortgageHeaders from '~/queries/allMortgageHeaders.graphql';

import { getNodeArray } from '~/assets/js/utils.js';

export const state = () => ({
    bannerData: [],
});

export const getters = {
    getBannerData: state => state.bannerData,
    getTimerItem: state => state.bannerData?.find(item => item.category === 'TIMER'),
};

export const actions = {
    async loadData({ commit }) {
        try {
            const { data } = await this.$axios.$post('/graphql/', {
                query: allMortgageHeaders.loc.source.body,
            });

            const banners = getNodeArray(data, 'allMortgageHeaders');

            commit('SET_BANNER_DATA', banners);
        } catch (e) {
            console.log('[Vuex] HeaderBanner/loadData: Не удалось загрузить', e);
        }
    },

    setTime({ commit }, payload) {
        commit('SET_TIME', payload);
    },

    deleteTimer({ commit }) {
        commit('DELETE_TIMER');
    },
};

export const mutations = {
    SET_BANNER_DATA(state, payload) {
        state.bannerData = payload;
    },

    SET_TIME(state, payload) {
        const banner = state.bannerData?.find(item => item.category === 'TIMER');

        if (banner) {
            banner.timerEndAt = payload;
        }
    },

    DELETE_TIMER(state) {
        state.bannerData = state.bannerData.filter(item => item.category !== 'TIMER');
    },
};
