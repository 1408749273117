<template>
    <symbol
        id="icon-consultation"
        viewBox="0 0 20 24"
        fill="none"
    >
        <path
            d="M10.0035 17.711C14.4194 17.711 17.9991 14.1313 17.9991 9.71539C17.9991 5.29951 14.4194 1.71973 10.0035 1.71973C5.58759 1.71973 2.00781 5.29951 2.00781 9.71539C2.00781 14.1313 5.58759 17.711 10.0035 17.711Z"
            stroke="white"
            stroke-width="2.28447"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.0087 13.142C11.9013 13.142 13.4355 11.6078 13.4355 9.71529C13.4355 7.82276 11.9013 6.28857 10.0087 6.28857C8.11622 6.28857 6.58203 7.82276 6.58203 9.71529C6.58203 11.6078 8.11622 13.142 10.0087 13.142Z"
            stroke="white"
            stroke-width="2.28447"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M5.43757 16.5688L3.04916 20.5507C2.94533 20.724 2.88931 20.9217 2.88681 21.1237C2.88431 21.3256 2.93541 21.5247 3.03492 21.7005C3.13443 21.8763 3.27877 22.0225 3.45324 22.1243C3.62771 22.2261 3.82605 22.2799 4.02805 22.28H15.985C16.187 22.2799 16.3853 22.2261 16.5598 22.1243C16.7343 22.0225 16.8786 21.8763 16.9781 21.7005C17.0776 21.5247 17.1287 21.3256 17.1262 21.1237C17.1237 20.9217 17.0677 20.724 16.9639 20.5507L14.5755 16.5688" fill="white"/>
        <path
            d="M5.43757 16.5688L3.04916 20.5507C2.94533 20.724 2.88931 20.9217 2.88681 21.1237C2.88431 21.3256 2.93541 21.5247 3.03492 21.7005C3.13443 21.8763 3.27877 22.0225 3.45324 22.1243C3.62771 22.2261 3.82605 22.2799 4.02805 22.28H15.985C16.187 22.2799 16.3853 22.2261 16.5598 22.1243C16.7343 22.0225 16.8786 21.8763 16.9781 21.7005C17.0776 21.5247 17.1287 21.3256 17.1262 21.1237C17.1237 20.9217 17.0677 20.724 16.9639 20.5507L14.5755 16.5688"
            stroke="white"
            stroke-width="2.28447"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconConsultation',
};
</script>
