<template>
    <ModalContainer
        :class="$style.container"
        :visible="visible"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <div :class="[$style.body, {[$style._success] : formSuccess}]">
            <h3
                :class="[$style.title, 'h2 _base']"
                v-html="data.title"
            >
            </h3>
            <ValidationObserver
                v-slot="{ handleSubmit }"
                tag="div"
            >
                <TheForm
                    :class="$style.form"
                    :button-label="data.buttonLabel"
                    @submit="handleSubmit(() => onFormSubmit($event))"
                >

                    <input
                        type="hidden"
                        name="category"
                        value="sendPdf"
                    >

                    <input
                        v-if="data.flatId"
                        type="hidden"
                        name="flat"
                        :value="data.flatId"
                    >

                    <UiInput
                        v-model="email"
                        field="email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        color="old"
                        rules="required|email"
                        :class="$style.input"
                    />

                    <template #after>
                        <div :class="$style.policy">
                            <FormPolicy />
                        </div>
                    </template>

                </TheForm>
            </ValidationObserver>

            <transition name="fade">
                <TheFormSuccess
                    v-if="formSuccess"
                    :class="$style.formSuccess"
                    title="Письмо отправлено"
                    description="В скором времени вы получите письмо с PDF-страницей квартиры."
                    @click="$emit('close')"
                />
            </transition>
        </div>
    </ModalContainer>
</template>

<script>
// utils
import FormMixin from '@/assets/js/mixins/FormMixin';
// components
import ModalContainer from './ModalContainer';
import TheForm from '../TheForm';
import UiInput from '~/components/ui/home/UiInput';
import TheFormSuccess from '../form/TheFormSuccess';
import FormPolicy from '~/components/common/form/FormPolicy.vue';

export default {
    name: 'SendPdfModal',

    components: {
        FormPolicy,
        TheFormSuccess,
        UiInput,
        TheForm,
        ModalContainer,
    },

    mixins: [FormMixin],

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },

    data() {
        return {
            email: '',
        };
    },
};
</script>

<style lang="scss" module>
    .container {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 64rem;
        min-height: auto;
        margin: auto;
        padding: 0;
        background-color: $base-0;
        transform: translate(-50%, -50%);

        @include respond-to(xs) {
            width: 100vw;
        }
    }

    .body {
        position: relative;
        z-index: 1;
        width: 100%;

        @include respond-to(xs) {
            height: 100%;
            padding-top: 4rem;
        }

        &._success {
            min-height: 54rem;

            @include respond-to(xs) {
                min-height: 100%;
            }

            & > form {
                visibility: hidden;
            }
        }
    }

    .policy {
        margin-top: 4rem;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.64;
        color: $base-gray;

        & > a {
            text-decoration: underline;
        }
    }

    .formSuccess {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    .title {
        margin-bottom: 4rem;
    }

    .consultation {
        display: flex;
        flex-direction: column;

        &__radios {
            display: flex;
        }

        &__radio {
            margin-right: 3.6rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .timepicker {
        display: flex;
        align-items: center;
        margin-top: 2rem;

        &__wrap {
            width: 130px;
        }

        &__input {
            border: none !important; //stylelint-disable-line declaration-no-important
            text-align: center;
        }
    }
</style>
