<template>
    <symbol id="icon-asterus" viewBox="0 0 112 20">
        <path d="M104.968 19.963c3.981 0 7.032-2.39 7.032-5.856 0-6.014-9.574-5.738-9.574-9.32 0-1.079.849-1.873 2.413-1.873 1.568 0 2.542.798 2.542 2.192h3.559c0-3.027-2.542-5.102-6.101-5.102-3.602 0-6.183 2.111-6.183 5.062 0 5.617 9.574 5.22 9.574 9.402 0 1.516-1.271 2.59-3.387 2.59-2.201 0-3.429-1.155-3.429-3.15H97.73c.379 3.583 3.257 6.056 7.238 6.056zM87.94 20c3.981 0 6.692-2.59 6.692-6.375V.32H91.03v13.31c0 2.192-1.142 3.424-3.094 3.424-1.905 0-3.008-1.236-3.008-3.425V.32h-3.602v13.467C81.331 17.451 84.002 20 87.94 20zm-23.805-.316h3.602V3.226h1.482c3.137 0 4.83 1.354 4.83 3.506 0 2.269-1.568 3.983-4.83 4.98l5.382 7.968h4.153l-4.998-7.372c2.542-1.155 3.981-3.226 3.981-5.739 0-3.704-3.261-6.257-7.88-6.257h-5.718v19.372h-.004zm-15.464 0h11.353v-2.91h-7.751v-5.897h6.355v-2.91h-6.355V3.227h7.751V.32H48.671v19.364zm-11.733 0h3.602V3.226h4.744V.32h-13.09v2.91h4.744v16.454zm-12.874.28c3.981 0 7.032-2.392 7.032-5.857 0-6.014-9.574-5.738-9.574-9.32 0-1.079.849-1.873 2.417-1.873 1.568 0 2.542.798 2.542 2.192h3.56C30.035 2.071 27.493 0 23.934 0c-3.598 0-6.183 2.111-6.183 5.062 0 5.617 9.574 5.22 9.574 9.402 0 1.516-1.271 2.59-3.387 2.59-2.202 0-3.43-1.155-3.43-3.15h-3.684c.375 3.587 3.253 6.06 7.239 6.06zM0 19.683h3.602L7.924 5.062l2.882 8.843H6.101l7.622 5.779h2.629L9.742.32H6.566L0 19.684z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconAsterus',
};
</script>
