// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body_IF71O{position:absolute;top:calc(76rem - 67%);left:calc(32rem + 15%);width:64rem;min-height:76rem;padding:0;background-color:#fff;font-family:\"Aeroport\",\"Arial\",sans-serif;color:#000}@media(max-width:991.98px){.body_IF71O{top:15%;left:15%;width:70%;min-height:70%}}@media(max-width:519.98px){.body_IF71O{top:0;left:0;width:100%;height:100%;min-height:100%}}.modalWrapper_gUmhI{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;height:100%}@media(max-width:519.98px){.modalWrapper_gUmhI{-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start}}.title_rFpNg{margin-bottom:2rem;font-size:4rem;font-weight:700;line-height:140%}@media(max-width:519.98px){.title_rFpNg{max-width:18rem;font-size:2.8rem}}.text_cbXQP{margin-bottom:3.2rem;font-size:1.4rem;font-weight:400;line-height:188%}@media(max-width:519.98px){.text_cbXQP{font-size:1.2rem}}.signature_8Zx6e{font-size:1.4rem;font-weight:700;line-height:146%}@media(max-width:519.98px){.signature_8Zx6e{margin-bottom:3.7rem}}.imageContainer_DVdu8{-ms-flex-item-align:center;-ms-grid-row-align:center;align-self:center;width:38rem;height:34.8rem}@media(max-width:519.98px){.imageContainer_DVdu8{position:absolute;bottom:10%;width:25.3rem;height:23.2rem;justify-self:flex-end}}.imageContainer_DVdu8 img{width:100%;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body_IF71O",
	"modalWrapper": "modalWrapper_gUmhI",
	"title": "title_rFpNg",
	"text": "text_cbXQP",
	"signature": "signature_8Zx6e",
	"imageContainer": "imageContainer_DVdu8"
};
module.exports = ___CSS_LOADER_EXPORT___;
