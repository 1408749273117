<template>
    <section
        class="option-box"
        :class="{ 'option-box_mobile': !$device.isDesktop }"
        @click="$emit('click')"
    >
        <div class="option-box__title">
            {{ value.label || value.value }}
        </div>
    </section>
</template>

<script>
export default {
    name: 'SelectOptions',

    props: {
        value: {
            type: Object,
            required: true,
        },

        isActive: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
    .option-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 4.5rem;
        padding: 0 1.5rem;
        transition: .3s ease-in-out;
        cursor: pointer;

        // &:hover {
        // }

        &__title {
            font-family: 'Aeroport', sans-serif;
            font-size: 1.6rem;
            line-height: 1;
            color: #2a2c2b;
            transition: inherit;
        }
    }

    .option-box_mobile {
        height: 45px;
        padding: 0 15px;

        .option-box {
            &__title {
                font-size: 16px;
            }
        }
    }
</style>
