<template>
    <header
        :class="[
            $style.header,
            { [$style.header_tablet]: !$device.isDesktop },
            { [$style.header_mobile]: $device.isMobile },
            { [$style.header_opened]: value },
            'TheHeader'
        ]"
    >
        <div :class="$style.header__border" />

        <div :class="$style.header__bg">
            <div
                class="container-home"
                :class="[
                    $style.header__wrap,
                    { [$style.header__wrap_simplified]: isSimplifiedHeader },
                ]"
            >
                <HeaderLogo :simplified="isSimplifiedHeader"/>

                <div
                    v-if="$device.isDesktop"
                    :class="$style.header__links"
                >
                    <component
                        :is="link.url ? 'a' : 'div'"
                        v-for="(link, i) in links"
                        :key="i"
                        :href="link.url"
                        style="cursor: pointer;"
                        @click.prevent="link.callback ? link.callback() : onClickLink(link.url)"
                    >
                        <HeaderLink
                            :label="link.label"
                            :icon-color="link.iconColor"
                            :is-active="link.component === activeComponent"
                            @mouseenter="onMenuLinkMouseEnter(link.component, link.label)"
                            @mouseleave="onMenuLinkMouseLeave(link.component)"
                        >
                            <component
                                :is="link.icon"
                                v-if="link.icon"
                            />
                        </HeaderLink>
                    </component>
                </div>

                <div :class="$style.header__right">
                    <a
                        :class="$style.header__phone"
                        :href="'tel:' + cleanPhone(phone)"
                    >
                        {{ phone }}
                    </a>

                    <div
                        v-if="$device.isDesktop"
                        :class="$style.header__line"
                    />

                    <a
                        href="/favorites/"
                        :class="$style.header__favourites"
                        @click.prevent="onClickLink('/favorites/')"
                    >
                        <FavouritesIcon :not-empty="hasFavorites" />
                    </a>

                    <UiButton
                        v-if="$device.isDesktop"
                        :class="$style.loginButton"
                        @click="handleLogIn"
                    >
                        Войти
                    </UiButton>

                    <TheBurger
                        v-if="!$device.isDesktop"
                        :value="value"
                        @click="$emit('input', !value)"
                    />
                </div>
            </div>
        </div>

        <HeaderMenu
            v-if="$device.isDesktop"
            :is-opened="value"
            :class="$style.header__menu"
            @mouseenter="onMenuMouseEnter"
            @mouseleave="onMenuMouseLeave"
        >
            <transition
                name="fade"
                mode="out-in"
            >
                <component
                    :is="activeComponent"
                    v-if="activeComponent"
                    :banners="activeBanners"
                />
            </transition>
        </HeaderMenu>

        <client-only>
            <portal
                v-if="slot"
                to="header-slot"
                :disabled="$device.isDesktop"
            >
                <component :is="slot" />
            </portal>
        </client-only>


        <HeaderMenuMobile
            v-if="!$device.isDesktop"
            :is-opened="value"
            :is-banner-hidden="isBannerHidden"
            :class="$style.header__menu"
            @change-is-opened="$emit('input', $event)"
            @mouseenter="handleMenuElementActive"
        />
    </header>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { applyQuery, queryToObject } from '~/assets/js/utils/queryUtils';
import { cleanPhone } from '~/assets/js/utils';

import { BUILDING_STREAM_LINKS, DEFAULT_FILTERS, MENU_BANNER_TYPES, PURCHASE_PAGE_LINKS } from '~/assets/js/constants';

import HeaderLogo from '~/components/layout/header/HeaderLogo.vue';
import HeaderLink from '~/components/layout/header/HeaderLink.vue';
import HeaderMenu from '~/components/layout/header/menu/HeaderMenu.vue';
import HeaderMenuMobile from '~/components/layout/header/menu/HeaderMenuMobile.vue';
import FavouritesIcon from '~/components/layout/header/FavouritesIcon.vue';
import TheBurger from '~/components/layout/header/TheBurger.vue';

import UiButton from '~/components/ui/home/UiButton.vue';

import CameraIcon from '~/assets/icons/home/camera.svg?inline';
import MenuBuy from '~/components/layout/header/menu/MenuBuy.vue';
import MenuMortgage from '~/components/layout/header/menu/MenuMortgage.vue';
import MenuLive from '~/components/layout/header/menu/MenuLive.vue';
import MenuProject from '~/components/layout/header/menu/MenuProject';

const MENU_SELECT_DELAY = 300;

export default {
    name: 'TheHeader',

    components: {
        HeaderLogo,
        HeaderLink,
        HeaderMenu,
        MenuProject,
        HeaderMenuMobile,
        FavouritesIcon,
        TheBurger,
        UiButton,
    },

    props: {
        value: {
            type: Boolean,
            default: false,
        },

        isBannerHidden: {
            type: Boolean,
            default: false,
        },

        menuBanners: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            links: [{
                component: MenuBuy,
                label: 'Купить',
                url: '/flats/',
            }, {
                component: MenuMortgage,
                label: 'Ипотека и рассрочка',
                url: PURCHASE_PAGE_LINKS.mortgage,
            }, {
                component: MenuProject,
                label: 'О проекте',
                url: '/project/',
            }, {
                label: 'О компании',
                url: '/about/',
            }, {
                label: 'Новости',
                url: '/center/',
            }, {
                label: 'Коммерция',
                url: '/commercial/',
            }, {
                label: 'Собственникам',
                url: '/owners/',
            }, {
                component: MenuLive,
                label: 'Live',
                icon: CameraIcon,
                iconColor: '#717171',
                callback: this.handleOpenCamerasModal,
            }],

            activeComponent: null,
            slot: null,
            isSimplifiedHeader: false,
            isHeaderFixed: false,
            isMenuHovered: false,
            timer: null,
            lastActiveComponentName: '',
            redirected: false,
            activeBanners: [],

            selectedEnterTimeout: null,
            selectedLeaveTimeout: null,
            selectedMenuLeaveTimeout: null,
        };
    },

    computed: {
        ...mapState('flat', [
            'favorites',
        ]),

        ...mapState('parking', [
            'favoritesParking',
        ]),

        ...mapState('storage', [
            'favoritesStorage',
        ]),

        ...mapState('commercial', [
            'favoritesCommercial',
        ]),

        hasFavorites() {
            return Boolean(this.favorites.length || this.favoritesParking.length || this.favoritesStorage.length || this.favoritesCommercial.length);
        },

        phone() {
            if (this.$route.path === '/owners/') {
                return '+7 495 122-00-33';
            } else {
                return '+7 495 122 00 00';
            }
        },
    },

    watch: {
        $route() {
            this.$emit('input', false);
            // если меню открыто, то запоминаем, что открыто, чтобы на новой странице повторно не открыть меню
            if (this.activeComponent && this.value) {
                this.lastActiveComponentName = this.activeComponent.name;
                this.redirected = true;
            }

            // если кликали внутри меню, а не по ссылкам в хедере, то тут другой сценарий, нежели в функции onMenuLinkMouseLeave
            if (this.isMenuHovered) {
                setTimeout(() => {
                    this.lastActiveComponentName = '';
                    this.redirected = false;
                }, 1000); // transition 1s ease HeaderMenu
            }
        },

        value(val) {
            clearTimeout(this.timer);

            if (!val) {
                this.timer = setTimeout(() => {
                    this.activeComponent = null;
                }, 1000); // transition 1s ease HeaderMenu
            }
        },
    },

    mounted() {
        window.addEventListener('scroll', this.onScroll);

        this.$root.$on('createHeaderSlot', this.createSlot);
        this.$root.$on('destroyHeaderSlot', this.destroySlot);

        if (this.$route.hash === '#cameras' && this.$route.name === 'index') {
            this.handleOpenCamerasModal();
        }
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);

        this.$root.$off('createHeaderSlot', this.createSlot);
        this.$root.$off('destroyHeaderSlot', this.destroySlot);
    },

    methods: {
        ...mapActions({
            changeFilters: 'flats/changeFilters',
            resetFilters: 'flats/resetFilters',
        }),

        cleanPhone,

        createSlot(component) {
            this.slot = component;
        },

        destroySlot() {
            this.slot = null;
        },

        setActiveComponent(component, label) {
            const keys = Object?.keys(this.menuBanners || {}) || [];
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];

                if (keys.length && MENU_BANNER_TYPES[key] === label) {
                    this.activeBanners = this.menuBanners[key] || [];
                    break;
                }
            }

            // проверка на наличие компонента (обязательно) и еще проверка, чтобы после редиректа меню не отрылось
            this.activeComponent = component;
        },

        /**
         * Функция устанавливает активный компонент и эмитит событие input с нужным значением(определяет открыто ли меню)
         * @param component {Object} компонент активного меню
         * @param label {string} label пункта меню
         */
        setActiveComponentAndEmitValue(component, label) {
            if (component && component.name !== this.lastActiveComponentName) {
                this.setActiveComponent(component, label);
                this.$emit('input', true);
            } else {
                this.$emit('input', false);
            }
        },

        /**
         * Отображение активного пункта меню с задержкой MENU_SELECT_DELAY, если меню уже открыто
         * @param component {Object} компонент активного меню
         * @param label {string} label пункта меню
         */
        onMenuLinkMouseEnter(component, label) {
            this.handleDropMenuLeaveTimeout();
            this.handleDropLeaveTimeout();

            if (this.value) {
                this.selectedEnterTimeout = setTimeout(() => {
                    this.setActiveComponentAndEmitValue(component, label);
                }, MENU_SELECT_DELAY);
            } else {
                this.setActiveComponentAndEmitValue(component, label);
            }
        },

        onMenuLinkMouseLeave() {
            this.handleDropEnterTimeout();

            if (!this.isMenuHovered) {
                this.selectedLeaveTimeout = setTimeout(() => {
                    this.$emit('input', false);
                }, MENU_SELECT_DELAY);
            }
        },

        /**
         * Дроп таймаута selectedEnterTimeout, заводимого в методе handleSetSelectedProject
         */
        handleDropEnterTimeout() {
            clearTimeout(this.selectedEnterTimeout);
            this.selectedEnterTimeout = null;
        },

        /**
         * Дроп таймаута selectedLeaveTimeout, заводимого в методе onMenuLinkMouseLeave
         */
        handleDropLeaveTimeout() {
            clearTimeout(this.selectedLeaveTimeout);
            this.selectedLeaveTimeout = null;
        },

        /**
         * Дроп таймаута selectedMenuLeaveTimeout, заводимого в методе onMenuLinkMouseLeave
         */
        handleDropMenuLeaveTimeout() {
            clearTimeout(this.selectedMenuLeaveTimeout);
            this.selectedMenuLeaveTimeout = null;
        },

        onMenuMouseEnter() {
            if (!this.redirected) {
                this.handleDropLeaveTimeout();
                this.isMenuHovered = true;
                this.$emit('input', true);
            }
        },

        onMenuMouseLeave() {
            if (!this.redirected) {
                this.selectedMenuLeaveTimeout = setTimeout(() => {
                    this.isMenuHovered = false;
                    this.$emit('input', false);
                }, MENU_SELECT_DELAY);
            }
        },

        handleLogIn() {
            if (window?.iflatWidget) {
                window.iflatWidget.show();
            }
        },

        onScroll() {
            if (window.scrollY >= 200) {
                this.isSimplifiedHeader = true;
            } else {
                this.isSimplifiedHeader = false;
            }
        },

        async onClickLink(link) {
            if (this.$route.fullPath === link) {
                window.scrollTo({ top: 0, behavior: 'smooth' }); // то скроллим страницу вверх
            } else if (this.$route.path === '/flats/' && // проверяем что находимся на выборщике
                link.includes('/flats/') // и то что пользователь кликнул на меню ссылка которого ссылается на выборщик
            ) {
                await this.resetFilters();
                // и если есть query параметры то тогда применяем их к выборщику
                if (link.split('?').length === 2) {
                    const query = queryToObject(link.split('?')[1]);
                    const filterFromQuery = applyQuery(DEFAULT_FILTERS, query);
                    await this.changeFilters(filterFromQuery);
                }
            } else {
                await this.$router.push(link);
            }
        },

        handleOpenCamerasModal() {
            this.$modal.open('VideoModal', {
                src: BUILDING_STREAM_LINKS,
                aeroModal: false,
                lazy: true,
            });
        },
    },
};
</script>

<style lang="scss" module>
    .header {
        width: 100%;
        width: calc(100% - var(--lock-offset, 0px));
        background-color: $inversed;

        &__bg {
            width: 100%;
            background-color: $inversed;
        }

        &__wrap {
            display: flex;
            align-items: center;
            height: $headerFullHeight;
            transition: $homeTransition;

            &_simplified {
                height: $headerHeight;
            }
        }

        &__links {
            display: flex;
            height: 100%;
            margin: 0 3.8rem;

            @include respond-to-min(xxl) {
                margin: 0 3rem;
            }
        }

        &__right {
            display: grid;
            grid-template-columns: min-content min-content min-content min-content;
            gap: 1.6rem;
            align-items: center;
            margin-left: auto;

            @include respond-to-min(xxl) {
                gap: 1.4rem;
            }
        }

        &__phone {
            @include p3;

            text-transform: uppercase;
            white-space: nowrap;
            font-weight: 700;
            line-height: 1;
            color: $base-900;
            transition: $homeTransition;
        }

        &__line {
            width: 1px;
            height: 1.6rem;
            background-color: $home-base-4;
            transform: rotate(25deg);
            transform-origin: 50% 50%;
        }

        &__favourites {
            transition: $homeTransition;
        }

        &__border {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $gray-2;
        }

        &__menu {
            z-index: -1;
        }

        .loginButton {
            background-color: transparent;
        }
    }

    .header_tablet.header_opened {
        .header {
            &__phone {
                visibility: hidden;
                opacity: 0;
            }

            &__favourites {
                visibility: hidden;
                opacity: 0;
            }
        }
    }

    .header_tablet {
        .header {
            &__wrap {
                height: $headerFullHeightTablet;

                &_simplified {
                    height: $headerHeightTablet;
                }
            }

            &__right {
                grid-template-columns: min-content min-content min-content;
                gap: 2.4rem;
            }

            &__phone {
                @include old-p4;

                color: $home-accent-1;
            }
        }
    }

    .header_mobile {
        .header {
            &__wrap {
                height: $headerFullHeightMobile;

                &_simplified {
                    height: $headerHeightMobile;
                }
            }
        }
    }
</style>
