<template>
    <ValidationProvider
        v-slot="{ errors }"
        tag="div"
        :name="name"
        :mode="mode"
        :rules="rules"
        :custom-messages="customMessages"
    >
        <div
            :class="[
                $style.inputBlock,
                {
                    [$style._error]: (errors && errors.length || propError),
                    [$style._focus]: isActive,
                    [$style._checked]: checked,
                    [$style._disabled]: disabled
                }]"
        >

            <label
                :class="$style.label"
                :for="internalId"
                :style="labelStyle"
                @click.prevent="onClick"
            >
                <input
                    :id="internalId"
                    ref="input"
                    type="checkbox"
                    :checked="checked"
                    :value="inputValue"
                    :class="$style.input"
                    :name="name"
                    :disabled="disabled"
                    @input="onInput"
                    @change="onChange"
                    @blur="onBlur"
                    @focus="onFocus"
                >

                <span :class="$style.radioCheck">
                    <svg
                        :class="$style.icon"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 8 8"
                    >
                        <path d="M7.703 2.14a.77.77 0 1 0-1.252-.895L3.132 5.891 1.467 4.226A.77.77 0 0 0 .379 5.313L2.687 7.62a.77.77 0 0 0 1.17-.097L7.702 2.14Z"/>
                    </svg>
                </span>
                <slot>
                    <span :class="$style.labelText">
                        {{ label }}
                    </span>
                </slot>
            </label>
            <ExpandBlock>
                <div
                    v-if="errors && errors.length || propError"
                    :class="$style.error"
                >
                    {{ errors[0] || propError }}
                </div>
            </ExpandBlock>
        </div>
    </ValidationProvider>
</template>

<script>


import ExpandBlock from '~/components/common/ExpandBlock.vue';
import { generateUniqueId } from '~/assets/js/utils';

export default {
    name: 'UiCheck',
    components: { ExpandBlock },

    props: {
        id: {
            type: [Number, String],
            default: () => null,
        },

        label: {
            type: String,
            default: null,
            require: true,
        },

        value: {
            type: [String, Number, Boolean],
            default: null,
            require: true,
        },

        name: {
            type: String,
            default: '',
        },

        checked: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        rules: {
            type: [String, Array, Object],
            description: 'Правила валидации vee-validate',
            default: '',
        },

        customMessages: {
            type: Object,
            default: function() {
                return {};
            },
        },

        propError: {
            type: String,
            default: '',
        },

        mode: {
            type: String,
            default: 'aggressive',
        },

        labelStyle: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            internalId: this.id,
            inputValue: false,
            isActive: false,
        };
    },

    watch: {
        value(val) {
            this.inputValue = val;
        },
    },

    created() {
        if (this.value) {
            this.inputValue = this.value;
        }

        if (!this.internalId) {
            this.internalId = generateUniqueId();
        }
    },

    methods: {
        onInput(evt) {
            if (!evt.target && !evt.target?.value) {
                return;
            }
            this.inputValue = evt.target.value;
            this.$emit('input', evt);
        },

        onChange(evt) {
            this.inputValue = evt.target.value;
            this.$emit('change', evt);
        },

        onBlur(evt) {
            if (!evt.target.value) {
                this.isActive = false;
            }
            this.$emit('blur', evt);
        },

        onFocus() {
            this.isActive = true;
        },

        onClick() {
            this.$emit('check');
        },
    },
};
</script>

<style lang="scss" module>
    .inputBlock {
        position: relative;
        font-family: inherit;
        font-size: 1.4rem;
        line-height: 1.88;
        color: $base;
        transition: opacity .15s;
        user-select: none;

        &:hover {
            @media (hover: hover) {
                .radioCheck {
                    background-color: $base-50;
                }
            }
        }

        &._disabled {
            opacity: .7;
        }

        &._checked {
            .radioCheck {
                border-color: $base-800;
                background-color: $base-800;
            }

            .icon {
                opacity: 1;
            }
        }

        &._error {
            .radioCheck {
                border-color: $danger;
            }
        }

        &._checked._error {
            .radioCheck {
                background-color: $danger;
            }
        }
    }

    .label {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .input {
        display: none;
    }

    .radioCheck {
        position: relative;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        margin-right: .8rem;
        border: 1px solid $base-200;
        transition: background-color .15s;
    }

    .icon {
        fill: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        width: .8rem;
        height: .8rem;
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: opacity .15s;
    }

    .error {
        @include old-p6;

        font-weight: 400;
        color: $danger;
    }
</style>
