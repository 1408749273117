<template>
    <button
        :class="[$style.UiArrowButton, classList]"
        :disabled="disabled"
        type="button"
        @click="$emit('click')"
    >
        <ArrowIcon v-if="!solidArrow" :class="$style.icon" />
        <span v-else :class="$style.icon">
            <ArrowIconSolid />
        </span>
    </button>
</template>

<script>
import ArrowIcon from '@/assets/icons/home/arrow-circle.svg?inline';
import ArrowIconSolid from '@/assets/icons/home/arrow-solid.svg?inline';

export default {
    name: 'UiArrowButton',

    components: {
        ArrowIcon,
        ArrowIconSolid,
    },

    props: {
        color: {
            type: String,
            default: 'default',
            validator: value => ['default', 'dark', 'primary'].includes(value),
        },

        size: {
            type: String,
            default: 'normal',
            validator: value => ['normal', 'small'].includes(value),
        },

        rotate: {
            type: Boolean,
            default: false,
        },

        absolute: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        hovered: {
            type: Boolean,
            default: false,
        },

        enlargedClickArea: {
            type: Boolean,
            default: false,
        },

        solidArrow: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return {
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style['_rotate']]: this.rotate,
                [this.$style['_enlarged-click-area']]: this.enlargedClickArea,
                [this.$style['_hovered']]: this.hovered,
                [this.$style['_absolute']]: this.absolute,
                [this.$style['_isSolid']]: this.solidArrow,
            };
        },
    },
};
</script>

<style lang="scss" module>
    .UiArrowButton {
        position: relative;
        padding: 0;
        border-radius: 50%;
        border: none;
        background-clip: padding-box;
        background-color: transparent;
        color: $inversed;
        transition: all .3s ease;
        cursor: pointer;

        &._absolute {
            position: absolute;
        }

        &._isSolid {
            &._dark,
            &._primary {
                .icon {
                    border-radius: 50%;
                    background-color: currentColor;

                    & > path {
                        color: $base-0;
                    }
                }
            }

            &._default {
                .icon {
                    border-radius: 50%;
                    background-color: currentColor;

                    & > path {
                        color: $base-900;
                    }
                }
            }
        }

        &._enlarged-click-area {
            &:before {
                content: '';
                position: absolute;
                display: block;
                border-radius: 50%;
            }
        }

        &._rotate {
            .icon {
                transform: rotate(180deg);
            }
        }

        &:focus {
            outline: none;
        }

        &:disabled {
            opacity: .44;
            pointer-events: none;
            user-select: none;
        }

        .icon {
            display: block;
        }
    }

    /* colors */
    .UiArrowButton._default {
        &:hover,
        &._hovered {
            box-shadow: 0 0 0 .4rem #{$inversed + '3d'};
        }

        &:active {
            box-shadow: none;
        }
    }

    .UiArrowButton._dark {
        color: $home-base-1;

        &:hover,
        &._hovered {
            box-shadow: 0 0 0 .4rem #{$base + '3d'};
        }

        &:active {
            box-shadow: none;
        }
    }

    .UiArrowButton._primary {
        color: $home-accent-2;

        &:hover,
        &._hovered {
            box-shadow: 0 0 0 .4rem #{$home-accent-2 + '3d'};
        }

        &:active {
            box-shadow: none;
        }
    }

    /* sizes */
    .UiArrowButton._normal {
        width: 2.4rem;
        height: 2.4rem;

        &._enlarged-click-area {
            &:before {
                top: calc(50% - 2rem);
                left: calc(50% - 2rem);
                width: 4rem;
                height: 4rem;
            }
        }
    }

    .UiArrowButton._small {
        width: 1.6rem;
        height: 1.6rem;

        &._enlarged-click-area {
            &:before {
                top: calc(50% - 1.2rem);
                left: calc(50% - 1.2rem);
                width: 2.4rem;
                height: 2.4rem;
            }
        }

        &._default {
            &:hover,
            &._hovered {
                box-shadow: 0 0 0 .3rem #{$inversed + '3d'};
            }
        }

        &._dark {
            color: $home-base-1;

            &:hover,
            &._hovered {
                box-shadow: 0 0 0 .3rem #{$base + '3d'};
            }
        }

        &._primary {
            color: $home-accent-2;

            &:hover,
            &._hovered {
                box-shadow: 0 0 0 .3rem #{$home-accent-2 + '33'};
            }
        }
    }
</style>
