export const state = () => ({
    options: [],
    activeItem: '',
});

export const getters = {
    getOptionsData: state => state.options,
    getActiveItem: state => state.activeItem,
};

export const actions = {
    setData({ commit }, payload) {
        commit('SET_DATA', payload);
    },

    setActiveItem({ commit }, payload) {
        commit('SET_ACTIVE_ITEM', payload);
    },
};

export const mutations = {
    SET_ACTIVE_ITEM(state, index) {
        if (state.activeItem && index) {
            state.options.forEach(el => {
                el.isActive = false;
            });
            state.options[index].isActive = true;
            state.activeItem = state.options[index].value;
        } else {
            state.activeItem = state.options[0].value;
        }
    },

    SET_DATA(state, payload) {
        payload.forEach((el, i) => {
            const obj = {
                id: i,
                value: el.name,
                icon: el.icon,
                link: el.link,
                callback: el.callback,
                isActive: false,
            };
            state.options.push(obj);
        });
        state.options[0].isActive = true;
    },
};
