<template>
    <div
        :class="$style.AdvantageCardLabels"
    >
        <div
            v-if="false"
            :class="$style.labelWrapper"
        >
            <UiLabel
                v-for="(label, i) in visibleLabels"
                :key="i"
                :color="color || label.color"
                :class="$style.label"
            >
                {{ label.text || card.tags }}
            </UiLabel>

            <UiTooltip
                v-if="isMoreLabelsVisible"
                :class="$style.tooltip"
                top
            >
                <template #activator>
                    <UiLabel
                        :color="color || card.labels[0].color"
                        :class="[$style.label, $style._withTooltip]"
                    >
                        +{{ card.labels.length }}
                    </UiLabel>
                </template>

                <div :class="$style.hiddenLabels">
                    <UiLabel
                        v-for="(label, i) in hiddenLabels"
                        :key="i"
                        :color="color || label.color"
                        :class="$style.label"
                    >
                        {{ label.text }}
                    </UiLabel>
                </div>
            </UiTooltip>
        </div>
        <UiLabel v-else-if="card.tags.length !== 0 && card.tags !== 'firstblock'" :class="$style.singleTag">
            {{ card.tags }}
        </UiLabel>
    </div>
</template>

<script>
import UiLabel from '~/components/ui/home/UiLabel';
import UiTooltip from '~/components/ui/home/UiTooltip';

export default {
    name: 'AdvantageCardLabels',

    components: {
        UiLabel,
        UiTooltip,
    },

    props: {
        card: {
            type: Object,
            default: () => ({}),
        },

        color: {
            type: String,
            default: '',
        },
    },

    computed: {
    //     visibleLabels() {
    //         if (this.card.tags?.length === 0) {
    //             return [];
    //         }
    //
    //         if (this.card.tags?.length >= 2 && this.isFirstTwoLabelsTooLong) {
    //             return this.tags.labels.slice(0, 2);
    //         } else if (this.tags.labels?.length >= 3) {
    //             return this.tags.labels.slice(0, 3);
    //         } else {
    //             return this.tags.labels;
    //         }
    //     },
    //
    //     hiddenLabels() {
    //         if (this.card.labels?.length === 0) {
    //             return [];
    //         }
    //
    //         if (this.card.labels?.length === 2 && this.isFirstTwoLabelsTooLong) {
    //             return this.card.labels.slice(2);
    //         } else if (this.card.labels?.length >= 3) {
    //             return this.card.labels.slice(2);
    //         } else {
    //             return [];
    //         }
    //     },
    //
    //     isMoreLabelsVisible() {
    //         return this.card.labels?.length >= 3 && this.isFirstTwoLabelsTooLong;
    //     },
    //
    //     isFirstTwoLabelsTooLong() {
    //         if (Array.isArray(this.card.tags)) {}
    //         if (this.card.tags?.length < 2) {
    //             return false;
    //         }
    //
    //         if (this.card.tags[0]?.length + this.card.tags[1]?.length > 40) {
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     },
    },
};
</script>

<style lang="scss" module>
    .AdvantageCardLabels {
        //
    }

    .label {
        margin-right: .8rem;

        &:last-child {
            margin-right: 0;
        }

        &._withTooltip {
            position: relative;
        }
    }

    .tooltip {
        display: inline-block;
    }

    .singleTag {
        background-color: $home-accent-4;
        color: #fff;
    }

    .hiddenLabels {
        position: relative;
        padding: 1.6rem;
        border: 1px solid $home-base-4;
        background-color: $base-50;

        &:before {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(50% - .85rem);
            display: block;
            border-top: 1rem solid $home-base-4;
            border-right: .85rem solid transparent;
            border-left: .85rem solid transparent;
        }

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(50% - .65rem);
            display: block;
            border-top: .8rem solid $base-50;
            border-right: .65rem solid transparent;
            border-left: .65rem solid transparent;
        }
    }
</style>
