<template>
    <transition
        name="fade"
        @after-leave="$emit('after-leave')"
    >
        <div
            v-show="visible"
            class="modal"
            :class="{
                'modal_tablet': isTablet,
                'modal_mobile': isMobile,
            }"
            @mousedown.self="$emit('close')"
        >
            <div class="modal__body">
                <button
                    class="modal__btn btn-reset"
                    @click="$emit('close')"
                >
                    <img
                        src="/images/pages/landing/icons/close.svg"
                        alt="Закрыть"
                        class="modal__close"
                    >
                </button>
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ModalContainer',

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isDesktop() {
            return this.$device.isDesktop;
        },

        isTablet() {
            return !this.$device.isDesktop;
        },

        isMobile() {
            return this.$device.isMobile;
        },
    },
};
</script>

<style lang="scss" scoped>
    .modal {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        margin: 0 auto;

        &__body {
            position: relative;
            border-radius: 4px;
            background-color: $base-inverse;
        }

        &__btn {
            position: absolute;
            top: 24px;
            right: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            cursor: pointer;
        }

        &__close {
            width: 20px;
            height: 20px;
        }
    }

    .modal_mobile {
        .modal {
            &__body {
                border-radius: 0;
            }
        }
    }
</style>
