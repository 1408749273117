// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiNavLinkCard_wnfXv .link_Wwg4W{display:block;height:100%;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.UiNavLinkCard_wnfXv .card_5jXpG{position:relative;z-index:2;overflow:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%;height:100%;padding:2.8rem;color:#fff}.UiNavLinkCard_wnfXv .card_5jXpG:after{content:\"\";position:absolute;top:0;right:0;bottom:0;left:0;background-color:rgba(0,0,0,.4);-webkit-transition:background-color .3s ease;transition:background-color .3s ease}.UiNavLinkCard_wnfXv .card_5jXpG .bg_DiKdI{position:absolute;top:0;right:0;bottom:0;left:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:100%;height:100%;background-position:50%;background-size:cover;-webkit-transition:-webkit-transform .3s ease-in-out;transition:-webkit-transform .3s ease-in-out;transition:transform .3s ease-in-out;transition:transform .3s ease-in-out,-webkit-transform .3s ease-in-out}.UiNavLinkCard_wnfXv .card_5jXpG:hover .bg_DiKdI{-webkit-transform:scale(1.05);transform:scale(1.05)}.UiNavLinkCard_wnfXv .card_5jXpG:hover:after{background-color:transparent}.UiNavLinkCard_wnfXv .card_5jXpG .label_PNLJX{position:relative;z-index:4;text-transform:uppercase;font-size:2.4rem;font-weight:700;line-height:1.32}.UiNavLinkCard_wnfXv .card_5jXpG .icon_xnETl{position:relative;z-index:4;width:3.2rem;height:3.2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiNavLinkCard": "UiNavLinkCard_wnfXv",
	"link": "link_Wwg4W",
	"card": "card_5jXpG",
	"bg": "bg_DiKdI",
	"label": "label_PNLJX",
	"icon": "icon_xnETl"
};
module.exports = ___CSS_LOADER_EXPORT___;
