// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DigitalHeader_GDtq\\+{position:relative;height:8.8rem}.DigitalHeader_GDtq\\+ nav{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.DigitalHeader_GDtq\\+ nav a:not(:first-child){margin-left:1.8rem}.header_fKbOl{position:fixed;z-index:20;width:100%;height:8.8rem}.header_fKbOl,.wrapper_rahr6{background-color:#fff}.wrapper_rahr6{position:relative;z-index:2;height:100%;border-bottom:.1rem solid #e7e7e7}.container_OU\\+7O{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;height:100%;padding-top:1.2rem;padding-bottom:1.2rem}.container_OU\\+7O,.logoLink_iSbae{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.logoLink_iSbae{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DigitalHeader": "DigitalHeader_GDtq+",
	"header": "header_fKbOl",
	"wrapper": "wrapper_rahr6",
	"container": "container_OU+7O",
	"logoLink": "logoLink_iSbae"
};
module.exports = ___CSS_LOADER_EXPORT___;
