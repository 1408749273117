// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SocialLinks_A7DaE{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;max-width:150px;grid-gap:1.6rem;gap:1.6rem}@media(max-width:991.98px){.SocialLinks_A7DaE{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;max-width:none}}@media(max-width:767.98px){.SocialLinks_A7DaE{-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start}}@media(max-width:991.98px){.SocialLinks_A7DaE._header_VJz29{max-width:100%}}@media(max-width:767.98px){.SocialLinks_A7DaE._header_VJz29{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;grid-gap:0;gap:0}}.socialItem_H0f8N{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;text-transform:uppercase;font-size:1.2rem;font-weight:700;line-height:1;color:#2f2f2f;grid-gap:6px;gap:6px}.socialItem_H0f8N span{margin-top:2px}.socialItem_H0f8N svg{width:28px;height:28px}@media(max-width:991.98px){.socialItem_H0f8N svg{width:48px;height:48px}}@media(max-width:767.98px){.socialItem_H0f8N svg{width:32px;height:32px}}.socialItem_H0f8N svg path{-webkit-transition:all .3s ease;transition:all .3s ease}@media(min-width:992px){.socialItem_H0f8N:hover path{fill:#303236}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SocialLinks": "SocialLinks_A7DaE",
	"_header": "_header_VJz29",
	"socialItem": "socialItem_H0f8N"
};
module.exports = ___CSS_LOADER_EXPORT___;
