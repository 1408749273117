// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SliderButton_0guiN{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:2.8rem;height:2.8rem;will-change:transform,opacity;-webkit-transition:opacity .3s ease-in,-webkit-transform .3s linear;transition:opacity .3s ease-in,-webkit-transform .3s linear;transition:opacity .3s ease-in,transform .3s linear;transition:opacity .3s ease-in,transform .3s linear,-webkit-transform .3s linear}@media(max-width:767.98px){.SliderButton_0guiN{width:18px;height:18px}}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.SliderButton_0guiN:active,.SliderButton_0guiN:focus{outline:none}.SliderButton_0guiN:hover{-webkit-transform:scale(1.2);transform:scale(1.2)}}.SliderButton_0guiN._disabled{opacity:.3;pointer-events:none}.SliderButton_0guiN svg{display:block;width:100%;height:1.9rem;-webkit-transition:-webkit-transform .3s linear;transition:-webkit-transform .3s linear;transition:transform .3s linear;transition:transform .3s linear,-webkit-transform .3s linear}.SliderButton_0guiN._inverse svg{fill:#fff}.SliderButton_0guiN._base svg{fill:#000}.SliderButton_0guiN._prev svg{-webkit-transform:rotate(180deg);transform:rotate(180deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SliderButton": "SliderButton_0guiN"
};
module.exports = ___CSS_LOADER_EXPORT___;
