// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FullScreenModal_qiqUh{position:fixed;bottom:0;left:0;z-index:98;overflow:hidden;width:100%;height:100vh;-ms-scroll-chaining:none;overscroll-behavior:contain}.sizeBlock_QrtMx{position:absolute;top:0;bottom:0;width:100%;height:100vh;height:100dvh;pointer-events:none;cursor:default}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FullScreenModal": "FullScreenModal_qiqUh",
	"sizeBlock": "sizeBlock_QrtMx"
};
module.exports = ___CSS_LOADER_EXPORT___;
