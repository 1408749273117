<template>
    <symbol id="icon-arrow-top" viewBox="0 0 12 17">
        <path d="M4.76168 4.62181L1.65712 7.72637L0.278264 6.34751L5.73668 0.889097L11.1858 6.33821L9.80694 7.71707L6.71168 4.62181L6.71168 16.1564L4.76169 16.1564L4.76168 4.62181Z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconArrowTop',
};
</script>
