<template>
    <div :class="$style.VirtualLandWrapper">
        <slot></slot>
        <button
            type="button"
            :class="$style.button"
            @click="handlerButtonClick"
        >
            <component :is="icon" :class="$style.icon"></component>
        </button>
    </div>
</template>

<script>
import FullscreenDisabled from '~/assets/icons/fullscreen-disabled.svg?inline';
import FullscreenEnable from '~/assets/icons/fullscreen-enable.svg?inline';
import screenfull from '~/assets/js/utils/fullscreenUtils';
export default {
    name: 'VirtualLandWrapper',

    components: {
        FullscreenDisabled,
        FullscreenEnable,
    },

    data() {
        return {
            isFullWindow: false,
        };
    },

    computed: {
        icon() {
            return this.isFullWindow ? FullscreenDisabled : FullscreenEnable;
        },
    },

    watch: {
        isFullWindow(newVal) {
            this.$emit('change-fullscreen', newVal);
        },
    },

    mounted() {
        this.checkFullScreen();
        screenfull.on('change', this.checkFullScreen);
    },

    beforeDestroy() {
        if (this.isFullWindow) {
            screenfull.exit();
        }
        screenfull.off('change', this.checkFullScreen);
    },

    methods: {
        checkFullScreen() {
            this.isFullWindow = screenfull.isFullscreen;
        },

        handlerButtonClick() {
            if (!this.isFullWindow) {
                screenfull.request();
            } else {
                screenfull.exit();
            }
        },
    },
};
</script>

<style lang="scss" module>
    .VirtualLandWrapper {
        position: relative;
    }

    .button {
        position: absolute;
        right: 30px;
        bottom: 30px;
        z-index: 2;
        width: 50px;
        height: 50px;
        border: none;
        background-color: #131110;
        outline: none;
        cursor: pointer;
        user-select: none;

        &:hover {
            background-color: #2f2f2f;
        }
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 42%;
        height: 42%;
        color: #fff;
        transform: translate(-50%, -50%);
    }
</style>
