// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MediaInteractive_V0Nuv{display:-webkit-box;display:-ms-flexbox;display:flex}.comment_xyshH{margin-left:1.6rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MediaInteractive": "MediaInteractive_V0Nuv",
	"comment": "comment_xyshH"
};
module.exports = ___CSS_LOADER_EXPORT___;
