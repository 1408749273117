<template>
    <svg
        :class="$style.TheSprite"
        xmlns="http://www.w3.org/2000/svg"
    >

        <IconProjectLogo />
        <IconProjectLogoDesc />
        <IconAsterus />
        <IconPhone />
        <IconIfra />
        <IconVideo />
        <IconMouse />
        <IconProject />
        <IconClose />
        <IconClock />
        <IconLock />
        <IconFullyLock />
        <IconDownload />
        <IconModal />
        <IconPen />
        <IconChat />
        <IconPhoneCallTouch />
        <IconCrossCallTouch />
        <IconSettings />
        <IconSettingsHorizontal />
        <IconChevron />
        <IconChevronSmall />
        <IconConsultation />
        <IconFlatsLink />
        <IconInfo />
        <IconNewInfo />
        <IconSort />
        <IconFilter />
        <IconFilterNew />
        <IconHeartLine />
        <IconBack />
        <IconUser />
        <IconShare />
        <IconCopy />
        <IconSchool />
        <IconKindergarten />
        <IconLoopFlat />
        <IconWalk />

        <!--social-->
        <IconTelegram />
        <IconTelegramPdf />
        <IconYoutube />
        <IconAppstore />
        <IconFacebook />
        <IconInstagram />
        <IconVimeo />
        <IconVk />
        <IconMailPdf />
        <IconWhatsapp />
        <IconMail />

        <!--arrows-->
        <IconArrowNext />
        <IconArrowPrev />
        <IconAccordionArrow />
        <IconAccordionNew />
        <IconArrowTop />
        <IconArrowDown />
        <IconArrowDownStroke />

        <!--interactive-->
        <IconHeart />
        <IconComment />
        <IconQuestion />

        <!--view-->
        <IconList />
        <IconTile />
        <IconBooked />
        <IconArrowRound />
        <IconArrowOnlyRound />
        <IconArrowPlay />
        <IconArrowFill />
    </svg>
</template>

<script>
import IconProjectLogo from './SpriteSet/IconProjectLogo';
import IconProjectLogoDesc from './SpriteSet/IconProjectLogoDesc';
import IconPhone from './SpriteSet/IconPhone';
import IconAsterus from './SpriteSet/IconAsterus';
import IconArrowTop from './SpriteSet/IconArrowTop';
import IconTelegram from './SpriteSet/IconTelegram';
import IconTelegramPdf from './SpriteSet/IconTelegramPdf';
import IconYoutube from './SpriteSet/IconYotube';
import IconAppstore from './SpriteSet/IconAppstore';
import IconFacebook from './SpriteSet/IconFacebook';
import IconInstagram from './SpriteSet/IconInstagram';
import IconVimeo from './SpriteSet/IconVimeo';
import IconArrowNext from './SpriteSet/IconArrowNext';
import IconArrowDown from './SpriteSet/IconArrowDown';
import IconArrowDownStroke from './SpriteSet/IconArrowDownStroke';
import IconIfra from './SpriteSet/IconIfra';
import IconVideo from './SpriteSet/IconVideo';
import IconAccordionArrow from './SpriteSet/IconAccordionArrow';
import IconAccordionNew from './SpriteSet/IconAccordionNew';
import IconMouse from './SpriteSet/IconMouse';
import IconProject from './SpriteSet/IconProject';
import IconClose from './SpriteSet/IconClose';
import IconHeart from './SpriteSet/IconHeart';
import IconComment from './SpriteSet/IconComment';
import IconClock from './SpriteSet/IconClock';
import IconList from './SpriteSet/IconList';
import IconTile from './SpriteSet/IconTile';
import IconBooked from './SpriteSet/IconBooked';
import IconVk from './SpriteSet/IconVk';
import IconLock from './SpriteSet/IconLock';
import IconFullyLock from './SpriteSet/IconFullyLock';
import IconDownload from './SpriteSet/IconDownload';
import IconModal from './SpriteSet/IconModal';
import IconPen from './SpriteSet/IconPen';
import IconChat from './SpriteSet/IconChat';
import IconPhoneCallTouch from './SpriteSet/IconPhoneCallTouch';
import IconCrossCallTouch from './SpriteSet/IconCrossCallTouch';
import IconSettings from './SpriteSet/IconSettings';
import IconArrowPlay from './SpriteSet/IconArrowPlay';
import IconArrowRound from './SpriteSet/IconArrowRound';
import IconSettingsHorizontal from './SpriteSet/IconSettingsHorizontal';
import IconChevron from '@/components/layout/SpriteSet/IconChevron';
import IconArrowPrev from '@/components/layout/SpriteSet/IconArrowPrev';
import IconChevronSmall from '@/components/layout/SpriteSet/IconChevronSmall';
import IconConsultation from '@/components/layout/SpriteSet/IconConsultation';
import IconFlatsLink from '@/components/layout/SpriteSet/IconFlatsLink';
import IconInfo from '@/components/layout/SpriteSet/IconInfo';
import IconNewInfo from '@/components/layout/SpriteSet/IconNewInfo';
import IconSort from '@/components/layout/SpriteSet/IconSort';
import IconFilter from '@/components/layout/SpriteSet/IconFilter';
import IconFilterNew from '@/components/layout/SpriteSet/IconFilterNew';
import IconHeartLine from '@/components/layout/SpriteSet/IconHeartLine';
import IconBack from '@/components/layout/SpriteSet/IconBack';
import IconUser from '@/components/layout/SpriteSet/IconUser';
import IconMailPdf from './SpriteSet/IconMailPdf';
import IconShare from './SpriteSet/IconShare';
import IconWhatsapp from './SpriteSet/IconWhatsapp';
import IconCopy from './SpriteSet/IconCopy';
import IconArrowFill from '@/components/layout/SpriteSet/IconArrowFill';
import IconArrowOnlyRound from '@/components/layout/SpriteSet/IconArrowOnlyRound';
import IconQuestion from '@/components/layout/SpriteSet/IconQuestion';
import IconMail from './SpriteSet/IconMail';
import IconSchool from './SpriteSet/IconSchool';
import IconKindergarten from './SpriteSet/IconKindergarten';
import IconLoopFlat from './SpriteSet/IconLoopFlat';
import IconWalk from '~/components/layout/SpriteSet/IconWalk.vue';

export default {
    name: 'TheSprite',
    components: {
        IconWalk,
        IconMailPdf,
        IconArrowOnlyRound,
        IconArrowFill,
        IconQuestion,
        IconMail,
        IconFilter,
        IconFilterNew,
        IconSort,
        IconInfo,
        IconNewInfo,
        IconChevronSmall,
        IconArrowPrev,
        IconChevron,
        IconSettingsHorizontal,
        IconArrowRound,
        IconArrowPlay,
        IconArrowTop,
        IconSettings,
        IconPen,
        IconModal,
        IconDownload,
        IconVk,
        IconChat,
        IconPhoneCallTouch,
        IconCrossCallTouch,
        IconBooked,
        IconTile,
        IconList,
        IconClock,
        IconComment,
        IconHeart,
        IconHeartLine,
        IconClose,
        IconProject,
        IconMouse,
        IconAccordionArrow,
        IconAccordionNew,
        IconVideo,
        IconIfra,
        IconArrowNext,
        IconArrowDown,
        IconArrowDownStroke,
        IconVimeo,
        IconInstagram,
        IconFacebook,
        IconAppstore,
        IconYoutube,
        IconTelegram,
        IconTelegramPdf,
        IconAsterus,
        IconPhone,
        IconProjectLogo,
        IconProjectLogoDesc,
        IconLock,
        IconFullyLock,
        IconConsultation,
        IconFlatsLink,
        IconBack,
        IconUser,
        IconShare,
        IconWhatsapp,
        IconCopy,
        IconSchool,
        IconKindergarten,
        IconLoopFlat,
    },
};
</script>

<style lang="scss" module>
    .TheSprite {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        opacity: 0;
        pointer-events: none;
    }
</style>
