export default function({ redirect, route }) {
    const match = route.path.match(/(.+)\/$/g);

    if (!match && route.path !== '/') {
        redirect(route.path + '/', {
            ...route.query,
        });
    }

    return true;
}
