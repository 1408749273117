<template>
    <ModalContainer
        :class="$style.container"
        :visible="visible"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <div :class="$style.content">
            <h2
                :class="[$style.title, 'h2 _base']"
                v-html="data.title"
            >
            </h2>
            <img
                v-if="data.logo"
                :class="$style.logo"
                :src="data.logo"
            />
            <div :class="$style.row">
                <div
                    :class="$style.cell"
                    v-html="data.columnLeft"
                >
                </div>
                <div
                    :class="$style.cell"
                    v-html="data.columnCenter"
                >
                </div>
                <div
                    :class="$style.cell"
                    v-html="data.columnRight"
                >
                </div>
            </div>
        </div>
    </ModalContainer>
</template>

<script>
import ModalContainer from './ModalContainer';

export default {
    name: 'TextModal',

    components: {
        ModalContainer,
    },

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },

    computed: {
        styles() {
            if (!this.data.logo) {
                return;
            }
            return { backgroundImage: `url(${this.data.logo})` };
        },
    },
};
</script>

<style lang="scss" module>
    .container {
        max-width: $container-w;
    }

    .content {
        height: 100%;

        @include respond-to(sm) {
            display: flex;
            flex-direction: column;
        }
    }

    .title {
        margin-bottom: 4rem;

        @include respond-to(xs) {
            margin-bottom: 32px;
        }
    }

    .logo {
        width: 8rem;
        height: 3rem;
        margin-bottom: 3.2rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        @include respond-to(xs) {
            width: 80px;
            height: 29px;
        }
    }

    .row {
        display: flex;
        margin: 0 -3.2rem;

        @include respond-to(sm) {
            overflow-x: hidden;
            overflow-y: auto;
            height: 100%;
            margin: -8px 0;
            flex-direction: column;
            -webkit-overflow-scrolling: touch;
        }
    }

    .cell {
        width: 33.33%;
        padding: 0 3.2rem;

        @include respond-to(sm) {
            width: 100%;
            padding: 8px 0;
        }
    }
</style>
