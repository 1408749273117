<template>
    <transition name="overlay-appear">
        <div
            v-show="isOverlayVisible"
            :class="[
                $style.UniversalModal,
                {[$style._tablet]: isTablet || isIpadPro},
                {[$style._mobile]: $device.isMobile},
                {[$style._iPad]: isIpadPro},
                {[$style._autoWidth]: autoWidth},
            ]"
            @click.self="onClose"
        >
            <div :class="[$style.wrap, {[$style._visible]: isContentVisible}]">
                <transition
                    :enter-active-class="$style.universalEnterActive"
                    :leave-active-class="$style.universalLeaveActive"
                    :enter-class="$style.universalEnter"
                    :leave-to-class="$style.universalLeaveTo"
                    @leave="leave"
                >
                    <div :key="key" :class="$style.content">
                        <div
                            v-if="left && $device.isDesktop"
                            :class="$style.left"
                        >
                            <component
                                :is="left"
                                v-bind="leftOptions"
                                @close="onClose"
                            />
                        </div>

                        <div :class="$style.right">
                            <component
                                :is="header"
                                v-if="header"
                                v-bind="headerOptions"
                                @close="onClose"
                            />

                            <div
                                v-if="component"
                                :class="$style.body"
                                data-scroll-lock-scrollable
                            >
                                <component
                                    :is="component"
                                    v-bind="componentOptions"
                                    @close="onClose"
                                />
                            </div>

                            <component
                                :is="footer"
                                v-if="footer"
                                v-bind="footerOptions"
                                @close="onClose"
                            />

                            <div
                                v-touch:swipe.bottom="onClose"
                                :class="$style.bar"
                            />
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </transition>
</template>

<script>
import { lockBody, unlockBody } from '@/assets/js/utils';
import { mapGetters } from 'vuex';

export default {
    name: 'UniversalModal',

    data() {
        return {
            component: null,
            componentOptions: null,

            header: null,
            headerOptions: null,

            footer: null,
            footerOptions: null,

            left: null,
            leftOptions: null,

            isOverlayVisible: false,
            isContentVisible: false,

            key: Math.floor(Math.random() * 1000000000),

            autoWidth: false,

            isTablet: false,
        };
    },

    computed: {
        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
        }),
    },

    watch: {
        '$route.name'() {
            this.onClose();
        },
    },

    mounted() {
        this.isTablet = !this.$device.isDesktop || this.isIpadPro;
    },

    beforeMount() {
        this.$universalModal.event.$on('open', this.onOpen);
        this.$universalModal.event.$on('close', this.onClose);
        this.$universalModal.event.$on('changeData', this.onChangeData);
    },

    beforeDestroy() {
        this.$universalModal.event.$off('open', this.onOpen);
        this.$universalModal.event.$off('close', this.onClose);
        this.$universalModal.event.$off('changeData', this.onChangeData);
    },

    methods: {
        onOpen({ component, header, footer, left, componentOptions, headerOptions, footerOptions, leftOptions, autoWidth, isNewModal }) {
            if (this.isIpadPro) {
                this.isTablet = this.isIpadPro;
            } else {
                this.isTablet = !this.$device.isDesktop;
            }
            lockBody();
            this.isOverlayVisible = true;

            if (componentOptions) {
                this.componentOptions = componentOptions;
            }
            if (headerOptions) {
                this.headerOptions = headerOptions;
            }
            if (footerOptions) {
                this.footerOptions = footerOptions;
            }
            if (leftOptions) {
                this.leftOptions = leftOptions;
            }

            if (component) {
                this.component = component;
            }
            if (header) {
                this.header = header;
            }
            if (footer) {
                this.footer = footer;
            }
            if (left) {
                this.left = left;
            }

            this.isNewModal = isNewModal || false;
            this.autoWidth = autoWidth ? autoWidth : false;

            setTimeout(() => {
                this.isContentVisible = true;
            }, 500);
        },

        onClose() {
            this.isContentVisible = false;

            setTimeout(() => {
                this.componentOptions = null;
                this.headerOptions = null;
                this.footerOptions = null;
                this.leftOptions = null;

                this.component = null;
                this.header = null;
                this.footer = null;
                this.left = null;

                this.isOverlayVisible = false;
            }, 500);

            unlockBody();
        },

        onChangeData({ component, header, footer, left, componentOptions, headerOptions, footerOptions, leftOptions }) {
            if (componentOptions) {
                this.componentOptions = { ...this.componentOptions, ...componentOptions };
            }
            if (headerOptions) {
                this.headerOptions = { ...this.headerOptions, ...headerOptions };
            }
            if (footerOptions) {
                this.footerOptions = { ...this.footerOptions, ...footerOptions };
            }
            if (leftOptions) {
                this.leftOptions = { ...this.leftOptions, ...leftOptions };
            }

            if (component) {
                this.component = component;
            }
            if (header) {
                this.header = header;
            }

            if (footer && footer !== 'blank') {
                this.footer = footer;
            } else if (footer === 'blank') {
                this.footer = false;
            }

            if (left) {
                this.left = left;
            }

            this.key = Math.floor(Math.random() * 1000000000);
        },

        leave(el) {
            el.style.position = 'absolute';
            el.style.top = '0';
            el.style.left = '0';
            el.style.width = '100%';
            el.style.height = '100%';
            el.style.maxHeight = 'none';
        },
    },
};
</script>

<style lang="scss" module>
    .UniversalModal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: $overlayer;
        transform: translateZ(10px);

        &._autoWidth {
            .right {
                width: auto;
            }
        }

        .wrap {
            position: absolute;
            top: 0;
            left: 100%;
            display: flex;
            height: 100%;
            background-color: $base-0;
            transition: transform $transition;

            &._visible {
                transform: translateX(-100%);
            }
        }

        .left,
        .right {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .left {
            width: 51rem;
        }

        .right {
            width: 57.4rem;
        }

        .body {
            // @include scroll-style;

            overflow-y: auto;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .bar {
            display: none;
        }
    }

    .UniversalModal._tablet {
        .content {
            display: flex;
            width: 100%;
        }

        .wrap,
        .content {
            max-height: calc(100% - #{$headerFullHeightTablet});
        }

        .wrap {
            top: 100%;
            left: 50%;
            width: 100%;
            max-width: 60.8rem;
            height: initial;
            // border-top-left-radius: 1.2rem;
            // border-top-right-radius: 1.2rem;
            transform: translateX(-50%);

            &._visible {
                transform: translateX(-50%) translateY(-100%);
            }
        }

        &._iPad {
            .wrap {
                max-width: 70%;
            }
        }

        &._autoWidth {
            .wrap {
                max-width: calc(100% - 4.8rem);
            }
        }

        .left {
            display: none;
        }

        .right {
            width: 100%;
            height: initial;
        }

        .bar {
            position: absolute;
            top: .8rem;
            left: 50%;
            display: block;
            width: 4rem;
            height: .4rem;
            background-clip: padding-box;
            background-color: $base-300;
            transform: translateX(-50%);

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 8rem;
                height: 4rem;
                border-radius: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .UniversalModal._mobile {
        .content {
            display: flex;
        }

        .wrap,
        .content {
            max-height: calc(100% - #{$headerFullHeightMobile});
        }

        &._autoWidth {
            .wrap {
                max-width: 100%;
            }
        }
    }

    .universalEnterActive,
    .universalLeaveActive {
        transition: opacity .8s ease;
    }

    .universalEnter,
    .universalLeaveTo {
        opacity: 0;
    }
</style>
