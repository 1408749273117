// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderLogo_eFJza{position:relative;height:4.8rem}@media(max-width:767.98px){.HeaderLogo_eFJza{height:4rem}}.img_3MU8I{display:block;width:auto;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderLogo": "HeaderLogo_eFJza",
	"img": "img_3MU8I"
};
module.exports = ___CSS_LOADER_EXPORT___;
