<template>
    <div :class="$style.UiAccordion">
        <div
            :class="$style.title"
            @click="handleClick"
        >
            {{ list.title }}
            <ArrowDown :class="[$style.icon, {[$style._active]: isActive}]"/>
        </div>

        <ExpandBlock>
            <ul
                v-show="isActive"
                :class="$style.list"
            >
                <li
                    v-for="link in list.links"
                    :key="link.label"
                >
                    <a
                        v-if="link.isRedirect"
                        :href="link.link"
                        target="_blank"
                        :class="$style.link"
                    >
                        {{ link.label }}
                    </a>

                    <nuxt-link
                        v-else-if="!link.callback"
                        :to="link.link"
                        :class="$style.link"
                    >
                        {{ link.label }}
                    </nuxt-link>

                    <div
                        v-else
                        :class="$style.link"
                        @click="link.callback() || null"
                    >
                        {{ link.label }}
                    </div>
                </li>
            </ul>
        </ExpandBlock>
    </div>
</template>

<script>
import ArrowDown from '@/assets/icons/arrow-down-solid.svg?inline';
import ExpandBlock from '~/components/common/ExpandBlock.vue';
export default {
    name: 'UiAccordion',
    components: {
        ExpandBlock,
        ArrowDown,
    },

    props: {
        list: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            isActive: false,
        };
    },

    methods: {
        handleClick() {
            this.isActive = !this.isActive;
        },
    },


};
</script>

<style lang="scss" module>
    .UiAccordion {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .title {
        display: flex;
        align-items: center;
        margin-bottom: 1.6rem;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.32;

        @include respond-to(xxs) {
            font-size: 1.4rem;
        }
    }

    .icon {
        width: 1.6rem;
        height: 1.6rem;
        margin-left: 1rem;
        transform: rotate(0deg);
        transition: all ease-in-out .3s;

        :global(path) {
            fill: #2f2f2f;
        }

        &._active {
            transform: rotate(180deg);
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .link {
            margin-bottom: 1rem;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 124%;
            color: $base-500;
        }
    }
</style>
