// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheForm_cQoFB{margin:-1.6rem 0}.btn_G0MBq{margin-top:2.4rem;-webkit-transition:opacity .3s;transition:opacity .3s}@media(max-width:767.98px){.btn_G0MBq{margin-top:16px}}.btn_G0MBq:disabled{opacity:.5;pointer-events:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TheForm": "TheForm_cQoFB",
	"btn": "btn_G0MBq"
};
module.exports = ___CSS_LOADER_EXPORT___;
