<template>
    <symbol id="icon-close" viewBox="0 0 16 16">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.174 8.896l4.185 4.185.99-.99-4.185-4.185 3.917-3.916-.99-.99-3.917 3.916L4.258 3l-.99.99 3.917 3.916L3 12.091l.99.99 4.184-4.185z"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconClose',
};
</script>
