<template>
    <symbol
        id="icon-phone"
        viewBox="0 0 25 24"
    >
        <path d="M15.395 13.938c-.392-.387-.882-.387-1.271 0-.298.295-.595.59-.887.89-.08.082-.147.1-.245.044-.192-.105-.397-.19-.581-.304-.862-.542-1.583-1.239-2.223-2.023-.317-.39-.6-.807-.796-1.276-.04-.095-.033-.158.045-.235.297-.287.586-.582.879-.876.407-.41.407-.89-.003-1.301-.232-.235-.464-.465-.697-.7-.24-.24-.477-.481-.719-.719-.392-.382-.881-.382-1.27.003-.3.294-.588.597-.892.886a1.447 1.447 0 00-.455.977c-.047.621.105 1.208.32 1.78.44 1.184 1.109 2.235 1.92 3.199a11.882 11.882 0 003.936 3.079c.689.334 1.403.592 2.18.634.534.03.998-.105 1.37-.522.255-.285.542-.544.812-.816.4-.405.402-.894.005-1.294-.474-.477-.951-.951-1.428-1.426zM14.918 11.948l.921-.157a4.118 4.118 0 00-1.151-2.223 4.096 4.096 0 00-2.347-1.17l-.13.926c.692.097 1.32.41 1.818.906.47.47.776 1.064.889 1.718zM16.359 7.943A6.794 6.794 0 0012.463 6l-.13.926A5.89 5.89 0 0115.7 8.604a5.887 5.887 0 011.65 3.184l.922-.157a6.844 6.844 0 00-1.912-3.688z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconPhone',
};
</script>
