var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"overlay-appear"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOverlayVisible),expression:"isOverlayVisible"}],class:[
            _vm.$style.UniversalModal,
            {[_vm.$style._tablet]: _vm.isTablet || _vm.isIpadPro},
            {[_vm.$style._mobile]: _vm.$device.isMobile},
            {[_vm.$style._iPad]: _vm.isIpadPro},
            {[_vm.$style._autoWidth]: _vm.autoWidth},
        ],on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.onClose.apply(null, arguments)}}},[_c('div',{class:[_vm.$style.wrap, {[_vm.$style._visible]: _vm.isContentVisible}]},[_c('transition',{attrs:{"enter-active-class":_vm.$style.universalEnterActive,"leave-active-class":_vm.$style.universalLeaveActive,"enter-class":_vm.$style.universalEnter,"leave-to-class":_vm.$style.universalLeaveTo},on:{"leave":_vm.leave}},[_c('div',{key:_vm.key,class:_vm.$style.content},[(_vm.left && _vm.$device.isDesktop)?_c('div',{class:_vm.$style.left},[_c(_vm.left,_vm._b({tag:"component",on:{"close":_vm.onClose}},'component',_vm.leftOptions,false))],1):_vm._e(),_vm._v(" "),_c('div',{class:_vm.$style.right},[(_vm.header)?_c(_vm.header,_vm._b({tag:"component",on:{"close":_vm.onClose}},'component',_vm.headerOptions,false)):_vm._e(),_vm._v(" "),(_vm.component)?_c('div',{class:_vm.$style.body,attrs:{"data-scroll-lock-scrollable":""}},[_c(_vm.component,_vm._b({tag:"component",on:{"close":_vm.onClose}},'component',_vm.componentOptions,false))],1):_vm._e(),_vm._v(" "),(_vm.footer)?_c(_vm.footer,_vm._b({tag:"component",on:{"close":_vm.onClose}},'component',_vm.footerOptions,false)):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.bottom",value:(_vm.onClose),expression:"onClose",arg:"swipe",modifiers:{"bottom":true}}],class:_vm.$style.bar})],1)])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }