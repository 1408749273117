// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".link_BdNrQ{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin-bottom:2.8rem}.link_BdNrQ:last-child{margin-bottom:0}.link_BdNrQ:hover .link__text_GiHfK,.link_BdNrQ:hover .link__text_count_CyRmh,.link__icon_7Alnn{color:#2f2f2f}.link__icon_7Alnn{display:-webkit-box;display:-ms-flexbox;display:flex;width:2.4rem;min-width:2.4rem;height:2.4rem;min-height:2.4rem;margin-right:1.6rem}.link__text_GiHfK{text-transform:uppercase;font-size:2rem;font-weight:700;line-height:1.4;color:#cfcfcf;-webkit-transition:.3s ease;transition:.3s ease}.link__text_count_CyRmh{margin-right:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "link_BdNrQ",
	"link__text": "link__text_GiHfK",
	"link__text_count": "link__text_count_CyRmh",
	"link__icon": "link__icon_7Alnn"
};
module.exports = ___CSS_LOADER_EXPORT___;
