/**
 * Эмиттер событий. Нужен для вызова и сохранения отложенных и запомненных функций
 */
const EMITTER = {
    /**
     * События
     */
    events: {},

    /**
     * Сохранения функции в памяти
     */
    on(event, callback) {
        if (!this.events[event]) {
            this.events[event] = [];
        }

        this.events[event].push(callback);
    },

    /**
     * Удаление функции из памяти
     */
    off(event) {
        if (this.events[event]) {
            delete this.events[event];
        }
    },

    /**
     * Вызов функции из памяти
     */
    emit(event, params = undefined) {
        if (!this.events[event]) {
            return;
        }

        this.events[event].forEach(callback => {
            setTimeout(() => callback(params));
        });
    },

    /**
     * Вызов функции из памяти с очисткой
     */
    emitWithOff(event, params = undefined) {
        this.emit(event, params);

        this.off(event);
    },
};

export default EMITTER;
