<template>
    <div class="landing-layout">
        <noscript v-html="gtm"></noscript>

        <nuxt />

        <TheModal />
    </div>
</template>

<script>
import TheModal from '@/components/layout/TheModal';

export default {
    name: 'LandingLayout',

    components: {
        TheModal,
    },

    data() {
        return {
            gtm: '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KWBH4H8" height="0" width="0" style="display:none; visibility:hidden;"></iframe></noscript>',
        };
    },

    head() {
        return {
            __dangerouslyDisableSanitizers: ['script'],

            script: [
                {
                    src: 'https://mod.calltouch.ru/init.js?id=fx3i2gmg',
                },
                // {
                //     innerHTML: `
                //             var ct_cb_style = document.createElement('style'); ct_cb_style.id = 'ct_custom_style';
                //             ct_cb_style.innerHTML = '#CalltouchWidgetFrame{height:0px!important;width:0px!important;}';
                //             document.getElementsByTagName('head')[0].appendChild(ct_cb_style);
                //             var on_show_widget = function(event){
                //                 console.log('widget show: ',event);
                //                 if (!!ct_cb_style && !!ct_cb_style.parentNode){ ct_cb_style.parentNode.removeChild(ct_cb_style); }
                //             }
                //             var on_close_widget = function(event){
                //                 console.log('widget close: ',event);
                //                 if (!document.getElementById('ct_custom_style')){ document.getElementsByTagName('head')[0].appendChild(ct_cb_style); }
                //             }
                //             window.ct('modules','widgets','subscribeToEvent',[
                //                 {object:'form',action:'show',callback:function(event){ on_show_widget(event); }},
                //                 {object:'form',action:'close',callback:function(event){ on_close_widget(event); }},
                //                 {object:'widget-button',action:'show',callback:function(event){ on_close_widget(event); }},
                //                 {object:'request',action:'create',callback:function(event){ setTimeout(function(){ on_close_widget(event); }, 2000); }},
                //                 {object:'request',action:'fail',callback:function(event){ setTimeout(function(){ on_close_widget(event); }, 2000); }}
                //             ]);
                //         `,
                // },
                {
                    innerHTML: 'window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag(\'js\', new Date()); gtag(\'event\', \'conversion\', { \'allow_custom_scripts\': true, \'send_to\': \'DC-9737526/invmedia/2019e0+unique\' });',
                },
                {
                    innerHTML: '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\': new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src= \'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f); })(window,document,\'script\',\'dataLayer\',\'GTM-KWBH4H8\');',
                },
                {
                    src: '//smartcallback.ru/api/SmartCallBack.js?t=aMQNr9gGQRHsiNULVjS9',
                    async: true,
                },
            ],

            noscript: [{
                innerHTML: '<img src="https://ad.doubleclick.net/ddm/activity/src=9737526;type=invmedia;cat=2019e0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=1?" width="1" height="1" alt=""/>',
            }],
        };
    },

    mounted() {
        document.body.classList.add('hideCalltouch');
    },

    beforeDestroy() {
        document.body.classList.remove('hideCalltouch');
    },
};
</script>

<style lang="scss" scoped>
    .landing-layout {
        overflow-x: hidden;
        display: flex;
        min-height: 100vh;
    }
</style>

<style lang="scss">
    body.hideCalltouch #CalltouchWidgetFrame {
        visibility: hidden;
    }
</style>
