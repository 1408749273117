// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalOfficeForm_oeAPl{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:100%;height:100%;pointer-events:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalOfficeForm": "ModalOfficeForm_oeAPl"
};
module.exports = ___CSS_LOADER_EXPORT___;
