<template>
    <transition
        :class="$style.ModalHomeAdvantagesGalleryAlbums"
        name="content-slide-up"
        @after-enter="handleAfterEnter"
        @after-leave="$emit('after-leave')"
        @before-leave="handleBeforeLeave"
    >
        <div
            v-show="visible"
            :class="[$style.wrapper, wrapperClassList]"
        >
            <div :class="$style.header">
                <div :class="$style.headerBar" />

                <div :class="$style.headerTitle">
                    Выбор очереди
                </div>

                <div :class="$style.headerClose">
                    <UiCloseButton
                        color="primary-inversed"
                        @click="$emit('close')"
                    />
                </div>
            </div>

            <div :class="$style.body">
                <div :class="$style.albums">
                    <div
                        v-for="album in albums"
                        :key="album.id"
                        :class="[$style.album, {[$style._active]: album.isActive}]"
                        @click="handleAlbumChange(album.id)"
                        v-html="album.name"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import UiCloseButton from '~/components/ui/home/UiCloseButton';

export default {
    name: 'ModalHomeAdvantagesGalleryAlbums',

    components: {
        UiCloseButton,
    },

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            active: false,
        };
    },

    computed: {
        ...mapState('home/gallery', ['albums']),

        wrapperClassList() {
            return {
                [this.$style._active]: this.active,
            };
        },
    },

    methods: {
        ...mapActions('home/gallery', ['setActiveGalleryAlbum']),

        handleAfterEnter() {
            this.active = true;
        },

        handleBeforeLeave() {
            this.active = false;
        },

        handleAlbumChange(albumId) {
            this.setActiveGalleryAlbum(albumId);
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" module>
    .ModalHomeAdvantagesGalleryAlbums {
        //
    }

    .wrapper {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 100%;
        max-width: 584px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: #fff;
        transform: translateX(-50%);
        transition: transform $homeTransition;

        &._active {
            transform: translateX(-50%) translateY(-100%);
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 28px 24px 24px;
        border-bottom: 1px solid $home-base-4;
    }

    .headerBar {
        position: absolute;
        top: 8px;
        left: calc(50% - 20px);
        width: 40px;
        height: 4px;
        background-color: $home-base-4;
    }

    .headerTitle {
        @include old-p2;

        color: $home-base-3;
    }

    .headerClose {
        //
    }

    .body {
        //
    }

    .albums {
        //
    }

    .album {
        @include old-p4;

        padding: 20px 32px;
        color: $base;
        transition: all $homeTransition;

        &._active {
            background-color: $home-accent-2;
            color: $inversed;
        }
    }

    @include respond-to(xs) {
        .header {
            padding: 28px 16px 24px;
        }
    }
</style>
