// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon_6rvUc{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:2rem;min-width:2rem;height:2rem;cursor:pointer}.icon_6rvUc:hover .icon__img_mMYQ-{color:#2f2f2f}.icon__img_mMYQ-{width:100%;height:100%;color:#a0a0a0;-webkit-transition:.3s ease;transition:.3s ease}.icon__circle_kz5fG{position:absolute;top:.1rem;right:.1rem;width:.6rem;height:.6rem;border-radius:50%;background-color:#2f2f2f}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "icon_6rvUc",
	"icon__img": "icon__img_mMYQ-",
	"icon__circle": "icon__circle_kz5fG"
};
module.exports = ___CSS_LOADER_EXPORT___;
