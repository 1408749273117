<template>
    <div :class="$style.menuLeft">
        <div :class="$style.menuLeft__links">
            <MenuLink
                v-for="(link, i) in links"
                :key="i"
                :title="link.title"
                :link="link.url"
                :count="link.count"
            >
                <component :is="link.icon" />
            </MenuLink>
        </div>

        <SocialLinks
            :is-header="true"
            :class="$style.menuLeft__social"
        />
    </div>
</template>

<script>

import MenuLink from './MenuLink';
import SocialLinks from '~/components/layout/SocialLinks';

export default {
    name: 'MenuLeft',

    components: {
        SocialLinks,
        MenuLink,
    },

    props: {
        links: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" module>
    .menuLeft {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__links {
            margin-bottom: 2.8rem;
        }

        &__social {
            margin-top: auto;
        }
    }
</style>
