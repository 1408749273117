var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModalContainer',{class:[_vm.$style.ModalContainer, {
        [_vm.$style._aeroModal]: _vm.isAero,
        [_vm.$style._fullscreen]: _vm.isFullscreen
    }],attrs:{"visible":_vm.visible,"is-aero-modal":_vm.isAero,"is-full-screen":_vm.isFullscreen,"without-padding":_vm.withoutPadding},on:{"after-leave":function($event){return _vm.$emit('after-leave')},"close":function($event){return _vm.$emit('close')}}},[(!_vm.data.hidden)?[_c('div',{class:[_vm.$style.videoWrap, {
                [_vm.$style._aeroModal]: _vm.isAero,
                [_vm.$style._p0]: _vm.withoutPadding,
                [_vm.$style._fullscreen]: _vm.isFullscreen
            }]},[_vm._l((_vm.links),function(link){return [(!_vm.isVirtualLand)?_c('iframe',_vm._b({key:link,class:[_vm.$style.video, {
                        [_vm.$style._h100]: _vm.withoutPadding,
                        [_vm.$style._active]: _vm.activeLink === link,
                    }],attrs:{"src":link,"allow":"autoplay; encrypted-media","allowfullscreen":""}},'iframe',{loading: _vm.isLazy ? 'lazy' : null},false)):_c('VirtualLandWrapper',{key:link,class:_vm.$style.landWrapper,on:{"change-fullscreen":_vm.onChangeFullscreen}},[_c('iframe',_vm._b({key:link,class:[_vm.$style.video, {[_vm.$style._h100]: _vm.withoutPadding}, {[_vm.$style._active]: _vm.activeLink === link}],attrs:{"src":link,"allow":"autoplay; encrypted-media","allowfullscreen":""}},'iframe',{loading: _vm.isLazy ? 'lazy' : null},false))])]})],2),_vm._v(" "),(_vm.links.length > 1)?_c('div',{class:_vm.$style.tabs},_vm._l((_vm.links),function(link,idx){return _c('UiLink',{key:idx,class:[_vm.$style.tab, {[_vm.$style._active]: _vm.activeLink === link}, '_center'],on:{"click":function($event){_vm.activeLink = link}}},[_vm._v("\n                Камера "+_vm._s(idx + 1)+"\n            ")])}),1):_vm._e()]:_c('div',{class:_vm.$style.hidden},[_vm._v("\n        Уважаемые клиенты, в связи с плановыми техническими работами с 25 по 28 октября приостановлена работа камеры на территории строительства. Мы уведомим вас, как только работы будут выполнены."),_c('br'),_c('br'),_vm._v("Приносим извинения за предоставленные неудобства.\n    ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }