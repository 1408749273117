<template>
    <div
        :class="$style.UiModalTouchHeader"
    >
        <div :class="$style.headerBar" />

        <h4 :class="[$style.headerTitle, classes]">
            {{ header }}
        </h4>

        <div
            :class="$style.headerClose"
            @click="$emit('close')"
        >
            <CloseIcon />
        </div>
    </div>
</template>

<script>
import CloseIcon from '@/assets/icons/close.svg?inline';

export default {
    name: 'UiModalTouchHeader',

    components: {
        CloseIcon,
    },

    props: {
        header: {
            type: String,
            default: '',
        },

        strong: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                [this.$style.strong]: this.strong,
            };
        },
    },

};
</script>

<style lang="scss" module>
    .UiModalTouchHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 9.6rem;
        padding: 2.4rem;
        padding-top: 2.8rem;
        border-bottom: 1px solid $home-base-4;
    }

    .headerBar {
        position: absolute;
        top: .8rem;
        left: calc(50% - 2rem);
        width: 4rem;
        height: .4rem;
        background-color: $home-base-4;
    }

    .headerTitle {
        @include old-p2;

        color: $home-accent-5;

        &.strong {
            @include old-h4;

            color: $home-accent-1;
        }
    }

    .headerClose {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        margin-left: auto;
        border-radius: 50%;
        background-color: $line-200;
        color: #000;

        svg {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
</style>
