<template>
    <ModalContainer
        :class="$style.container"
        :visible="visible"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <div :class="$style.body">
            <div :class="$style.header">
                <h3
                    :class="[$style.title, 'h2 _base']"
                    v-html="card.title"
                >
                </h3>

                <!--MediaInfo-->
                <MediaInfo :card="card"/>
                <!--MediaInteractive-->
                <MediaInteractive
                    v-if="Object.keys(card).includes('likesCount') && !isPromo"
                    :class="$style.interactive"
                    :likes-count="card.likesCount"
                    :post-id="card.id"
                    @click="$modal.open('CommentModal', {postId: card.id})"
                />
            </div>
            <!--video-->
            <video
                v-if="card.category === 'video'"
                ref="videoPlayer"
                :class="$style.video"
                :src="card.video"
                controls
                autoplay
            ></video>
            <!--photos-->
            <GalleryWithThumbs
                v-if="card.category === 'gallery'"
                :class="$style.gallery"
                :slides="card.postimageSet"
            />
            <!--news-->
            <div
                v-if="card.category === 'text' || card.category === 'promotion' || card.category === 'mortgageBanner'"
                :class="$style.text"
            >
                <div :class="$style.inner">
                    <div
                        v-if="card.intro"
                        :class="[$style.topText, 'text']"
                        v-html="card.intro"
                    >
                    </div>

                    <div
                        v-if="card.textImage"
                        :class="$style.image"
                    >
                        <ImageLazy :origin="card.textImage"/>
                    </div>

                    <div
                        v-if="card.text"
                        :class="[$style.topText, 'text']"
                        v-html="card.text"
                    >
                    </div>

                    <MediaSlider
                        v-if="card.postimageSet && card.postimageSet.length"
                        :class="$style.slider"
                        :slides="card.postimageSet"
                    />
                </div>
            </div>
        </div>
    </ModalContainer>
</template>

<script>
import ModalContainer from './ModalContainer';
import MediaInfo from '../../center/card/MediaInfo';
import MediaInteractive from '../../center/card/MediaInteractive';
import GalleryWithThumbs from '../GalleryWithThumbs';
import ImageLazy from '../ImageLazy';
import MediaSlider from '~/components/center/MediaSlider.vue';

export default {
    name: 'MediaModal',

    components: {
        MediaSlider,
        ImageLazy,
        GalleryWithThumbs,
        MediaInteractive,
        MediaInfo,
        ModalContainer,
    },

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },

    data() {
        return {
            videoPlayer: null,
        };
    },

    computed: {
        card() {
            return this.data.card;
        },

        isPromo() {
            return this.card.category === 'promotion';
        },
    },

    mounted() {
        if (this.$refs.videoPlayer) {
            this.videoPlayer = this.$refs.videoPlayer;
        }
    },

    beforeDestroy() {
        if (this.videoPlayer) {
            this.videoPlayer.pause();
        }
    },
};
</script>

<style lang="scss" module>
    .container {
        width: 96rem;

        @include respond-to(sm) {
            width: 100%;
        }
    }

    .body {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @include respond-to(xs) {
            padding-bottom: 22px;
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 4rem;

        @include respond-to(xs) {
            margin-bottom: 0;
        }
    }

    .interactive {
        @include respond-to(xs) {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .title {
        width: 85%;
        margin-bottom: .8rem;
    }

    .video {
        width: 100%;
    }

    .gallery {
        @include respond-to(xs) {
            margin: auto 0;
        }
    }

    .text {
        position: relative;

        @include respond-to(xs) {
            overflow: hidden;
            height: 100%;
            padding: 32px 0;
        }
    }

    .inner {
        @include respond-to(xs) {
            position: relative;
            z-index: 1;
            overflow-y: auto;
            height: 100%;
        }
    }

    .topText {
        margin-bottom: 1.14em;

        a {
            text-decoration: underline;

            &:hover {
                opacity: .7;
            }
        }
    }

    .image {
        overflow: hidden;
        margin: 2.4rem 0;
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        @include aspect-ratio(746, 457);

        @include respond-to(xs) {
            @include aspect-ratio(288, 176);
        }
    }

    .slider {
        overflow: hidden;
        margin-top: 4.8rem;
        transform: translateZ(0);

        @include respond-to(xs) {
            margin-top: 3.2rem;
        }
    }
</style>
