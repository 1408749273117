// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheModalMobileSlideUp_e8-bf{position:fixed;top:0;left:0;z-index:99;overflow:hidden;width:100%;height:100%;background-color:rgba(24,25,28,.88)}.TheModalMobileSlideUp_e8-bf .wrap_diEQQ{position:absolute;top:100%;left:50%;width:100%;background-color:#fff;-webkit-transform:translateX(-50%);transform:translateX(-50%);-webkit-transition:-webkit-transform .3s ease;transition:-webkit-transform .3s ease;transition:transform .3s ease;transition:transform .3s ease,-webkit-transform .3s ease}.TheModalMobileSlideUp_e8-bf .wrap_diEQQ._visible_6ThTW{-webkit-transform:translateX(-50%) translateY(-100%);transform:translateX(-50%) translateY(-100%)}.TheModalMobileSlideUp_e8-bf .header_VT5G\\+{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;padding:2.8rem 2.4rem 2.4rem;border-bottom:1px solid #e9eaec}.TheModalMobileSlideUp_e8-bf .bar_Rpk7C{position:absolute;top:.8rem;left:50%;width:4rem;height:.4rem;background-clip:padding-box;background-color:#e9eaec;-webkit-transform:translateX(-50%);transform:translateX(-50%)}.TheModalMobileSlideUp_e8-bf .bar_Rpk7C:before{content:\"\";position:absolute;top:50%;left:50%;display:block;width:8rem;height:4rem;border-radius:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.TheModalMobileSlideUp_e8-bf .title_\\+m2Mo{font-size:1.6rem;font-weight:700;line-height:1.6rem;letter-spacing:-.064rem;color:#bebec5}@media(max-width:767.98px){.TheModalMobileSlideUp_e8-bf{background-color:rgba(24,25,28,.64)}.TheModalMobileSlideUp_e8-bf .header_VT5G\\+{padding:2.8rem 1.6rem 2.4rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TheModalMobileSlideUp": "TheModalMobileSlideUp_e8-bf",
	"wrap": "wrap_diEQQ",
	"_visible": "_visible_6ThTW",
	"header": "header_VT5G+",
	"bar": "bar_Rpk7C",
	"title": "title_+m2Mo"
};
module.exports = ___CSS_LOADER_EXPORT___;
