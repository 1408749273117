<template>
    <symbol
        id="icon-booked"
        viewBox="0 0 24 24"
        fill="none"
    >
        <rect
            x="4.75"
            y="8.75"
            width="14.5"
            height="11.5"
            rx="1.25"
            stroke="white"
            stroke-width="1.5"
        />
        <path d="M12.5 12C12.5 11.7239 12.2761 11.5 12 11.5C11.7239 11.5 11.5 11.7239 11.5 12L12.5 12ZM11.5 15C11.5 15.2761 11.7239 15.5 12 15.5C12.2761 15.5 12.5 15.2761 12.5 15L11.5 15ZM11.5 12L11.5 15L12.5 15L12.5 12L11.5 12Z" fill="white"/>
        <path
            d="M16.4446 9V6C16.4446 4.34315 15.1014 3 13.4446 3H10.5557C8.89881 3 7.55566 4.34315 7.55566 6V9"
            stroke="white"
            stroke-width="1.5"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconBooked',
};
</script>
