<template>
    <div :class="$style.TheFormSuccess">
        <h3
            :class="[$style.title, 'h2 _base']"
            v-html="title"
        >
        </h3>
        <div
            :class="$style.description"
            v-html="description"
        >
        </div>

        <UiLink
            v-if="closeBtn"
            :class="$style.link"
            type="button"
            @click="$emit('click')"
        >
            {{ label }}
        </UiLink>

        <div :class="$style.success">
            <SuccessIcon/>
        </div>
    </div>
</template>

<script>
import UiLink from '../../ui/UiLink';
import SuccessIcon from './SuccessIcon';

export default {
    name: 'TheFormSuccess',
    components: { SuccessIcon, UiLink },
    props: {
        title: {
            type: String,
            default: 'Комментарий отправлен',
        },

        description: {
            type: String,
            default: 'Спасибо за обращение, ответ придет в ближайшее время на Ваш e-mail.',
        },

        label: {
            type: String,
            default: 'Закрыть',
        },

        closeBtn: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" module>
    .TheFormSuccess {
        background-color: $base-inverse;
    }

    .title {
        margin-bottom: 2rem;
    }

    .description {
        margin-bottom: 4rem;
    }

    .link {
        margin-bottom: 4rem;
    }

    .success {
        text-align: center;
    }
</style>
