// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiInput_L517s{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.UiInput_L517s._old_4LIL6 .input_vXjxA{border:1px solid #dbdbdb;background-color:#fff}.UiInput_L517s._white_ZX53Z .input_vXjxA{border:none;background-color:#fff}.UiInput_L517s._medium_-FXum .input_vXjxA{height:5.6rem}.UiInput_L517s._small_i7M04 .input_vXjxA{height:4rem}.input_vXjxA{font-size:1.4rem;font-weight:700;line-height:1;letter-spacing:normal;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;height:5.6rem;padding:0 2rem;background-color:#f3f3f3;text-transform:uppercase;font-size:1.2rem;color:#2f2f2f;-webkit-transition:.3s ease;transition:.3s ease}.input_vXjxA::-webkit-input-placeholder{text-transform:uppercase;color:#a0a0a0}.input_vXjxA::-moz-placeholder{text-transform:uppercase;color:#a0a0a0}.input_vXjxA:-ms-input-placeholder{text-transform:uppercase;color:#a0a0a0}.input_vXjxA::-ms-input-placeholder{text-transform:uppercase;color:#a0a0a0}.input_vXjxA::placeholder{text-transform:uppercase;color:#a0a0a0}.input_vXjxA:disabled{opacity:.56;pointer-events:none}.input_vXjxA._error_Vwy3P{color:#e04b4b}.input_vXjxA._old_4LIL6{background-color:#f3f3f3;text-transform:uppercase}@media(max-width:991.98px){.input_vXjxA{text-transform:uppercase;font-size:16px}}.error_zTJtf{position:absolute;top:5rem;font-size:1.2rem;line-height:1.5;letter-spacing:normal;margin-top:.4rem;color:#e04b4b}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiInput": "UiInput_L517s",
	"_old": "_old_4LIL6",
	"input": "input_vXjxA",
	"_white": "_white_ZX53Z",
	"_medium": "_medium_-FXum",
	"_small": "_small_i7M04",
	"_error": "_error_Vwy3P",
	"error": "error_zTJtf"
};
module.exports = ___CSS_LOADER_EXPORT___;
