<template>
    <symbol id="icon-loop-flat" viewBox="0 0 24 24">
        <path d="M17.4527 16.1779L21.314 20.0383L20.0383 21.314L16.1779 17.4527C14.7415 18.6042 12.9549 19.2304 11.1139 19.2278C6.63503 19.2278 3 15.5928 3 11.1139C3 6.63503 6.63503 3 11.1139 3C15.5928 3 19.2278 6.63503 19.2278 11.1139C19.2304 12.9549 18.6042 14.7415 17.4527 16.1779ZM15.6442 15.5089C16.7883 14.3323 17.4273 12.7551 17.4247 11.1139C17.4247 7.62673 14.6002 4.80309 11.1139 4.80309C7.62673 4.80309 4.80309 7.62673 4.80309 11.1139C4.80309 14.6002 7.62673 17.4247 11.1139 17.4247C12.7551 17.4273 14.3323 16.7883 15.5089 15.6442L15.6442 15.5089ZM10.2124 10.2124V7.50773H12.0155V10.2124H14.7201V12.0155H12.0155V14.7201H10.2124V12.0155H7.50773V10.2124H10.2124Z" />
    </symbol>
</template>

<script>
export default {
    name: 'IconLoopFlat',
};
</script>
