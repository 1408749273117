<template>
    <div :class="$style.MediaInteractive">
        <!--LikeButton-->
        <LikeButton
            :likes-count="likesCount"
            :post-id="postId"
        />
        <!--CommentButton-->
        <!--        <CommentButton :class="$style.comment"-->
        <!--                       @click="$emit('click')"/>-->
    </div>
</template>

<script>
import LikeButton from '../../ui/buttons/LikeButton';
// import CommentButton from '../../ui/buttons/CommentButton';

export default {
    name: 'MediaInteractive',

    components: {
        // CommentButton,
        LikeButton,
    },

    props: {
        likesCount: {
            type: Number,
            default: 0,
        },

        postId: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" module>
    .MediaInteractive {
        display: flex;
    }

    .comment {
        margin-left: 1.6rem;
    }
</style>
