<template>
    <ValidationProvider
        v-slot="{ errors }"
        ref="provider"
        tag="div"
        :name="field"
        :rules="rules"
        :custom-messages="customMessages"
        :vid="vid"
        :class="[$style.UiInput, classList]"
    >
        <input
            v-mask="mask"
            :value="value"
            :name="name"
            :placeholder="placeholder"
            :type="type"
            :disabled="disabled"
            :class="classes"
            @input="onInput"
        >
        <ExpandBlock>
            <div
                v-if="errors && errors.length || propError"
                :class="$style.error"
            >
                {{ errors[0] || propError }}
            </div>
        </ExpandBlock>
    </ValidationProvider>
</template>

<script>
import ExpandBlock from '~/components/common/ExpandBlock.vue';
import { kebabToCamelCase } from '~/components/ui/utils/helpers';

export default {
    name: 'UiInput',
    components: { ExpandBlock },

    props: {
        type: {
            type: String,
            default: 'text',
        },

        value: {
            type: [String, Number],
            default: '',
        },

        color: {
            type: String,
            default: 'default',
            validator: value => ['default', 'old', 'white'].includes(value),
        },

        size: {
            type: String,
            default: 'medium',
            validator: value => ['small', 'medium', 'large'].includes(value),
        },

        placeholder: {
            type: String,
            default: '',
        },

        field: {
            type: String,
            description: 'Имя поля обязательно для валидации',
            required: true,
        },

        vid: {
            type: String,
            required: false,
            default: () => null,
        },

        name: {
            type: String,
            default: '',
        },

        rules: {
            type: [String, Array, Object],
            description: 'Правила валидации vee-validate',
            default: '',
        },

        isPhone: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        propError: {
            type: String,
            default: '',
        },

        customMessages: {
            type: Object,
            default: () => ({}),
        },

        maskPattern: {
            type: String,
            default: undefined,
        },
    },

    data() {
        return {
            phoneMask: '+7 (###) ###-##-##',
        };
    },

    computed: {
        mask() {
            if (this.maskPattern) {
                return this.maskPattern;
            }

            if (this.isPhone) {
                return this.phoneMask;
            }

            return '';
        },

        classes() {
            return {
                [this.$style.input]: true,
                [this.$style._old]: this.color === 'old',
                [this.$style._error]: this.errors && this.errors.length || this.propError,
            };
        },

        classList() {
            return {
                [this.$style[`_${kebabToCamelCase(this.color)}`]]: this.color,
                [this.$style[`_${kebabToCamelCase(this.size)}`]]: this.size,
            };
        },
    },

    methods: {
        onInput(event) {
            this.$emit('input', event.target.value);
        },

        validate() {
            return this.$refs.provider.validate();
        },
    },
};
</script>

<style lang="scss" module>
    .UiInput {
        position: relative;
        display: flex;
        flex-direction: column;

        &._old {
            .input {
                border: 1px solid $base-200;
                background-color: $base-0;
            }
        }

        &._white {
            .input {
                border: none;
                background-color: $base-0;
            }
        }

        &._medium {
            .input {
                height: 5.6rem;
            }
        }

        &._small {
            .input {
                height: 4rem;
            }
        }
    }

    .input {
        @include old-p4;

        display: flex;
        align-items: center;
        width: 100%;
        height: 5.6rem;
        padding: 0 2rem;
        background-color: $base-50;
        text-transform: uppercase;
        font-size: 1.2rem;
        color: $base-900;
        transition: $homeTransition;

        &::placeholder {
            text-transform: uppercase;
            color: $base-400;
        }

        &:disabled {
            opacity: .56;
            pointer-events: none;
        }

        &._error {
            color: $danger;
        }

        //&._old {
        //    border: 1px solid $base-200;
        //    background-color: $base-0;
        //}

        &._old {
            background-color: $base-50;
            text-transform: uppercase;
        }

        @include respond-to(sm) {
            text-transform: uppercase;
            font-size: 16px;
        }
    }

    .error {
        position: absolute;
        top: 5rem;

        @include old-p6;

        margin-top: .4rem;
        color: $danger;
    }
</style>
