// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalHomeAdvantagesGallery_VEfdL{width:100vw;height:100vh}.headerWrapper_S2ynb{border-bottom:.1rem solid #292a2c;background-color:#18191c}.header_x0pMt{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;height:7.2rem;padding-top:1.6rem;padding-bottom:1.6rem}.headerTitle_VTq1t{font-size:2rem;font-weight:700;line-height:2.6rem;letter-spacing:normal;width:10rem;color:#fff}.pagination_c45bc{font-size:1.8rem;line-height:1.32;letter-spacing:normal;color:#4e4e51}.headerClose_e9r8u{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;width:10rem}.body_aOc05{position:relative;width:100%;height:calc(100vh - 7.3rem);background-color:#18191c}.slide_73n2o,.slider_vXddW{width:100%;height:100%}.buttons_6R0mu{top:50%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:calc(100% - 12.8rem);-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.buttons_6R0mu,.footer_PXtmC{position:absolute;left:50%}.footer_PXtmC{bottom:2.4rem;-webkit-transform:translateX(-50%);transform:translateX(-50%)}.select_aCi2E{font-size:2rem;font-weight:700;line-height:2.6rem;letter-spacing:normal;position:relative;padding-right:32px;color:#fff}.selectArrow_GxYJN{position:absolute;top:calc(50% - 8px);right:0;width:16px;height:16px;color:#fff}@media(max-width:991.98px){.headerClose_e9r8u,.headerTitle_VTq1t{width:auto}.buttons_6R0mu{width:calc(100% - 64px)}.pagination_c45bc{font-size:1.6rem;line-height:1.5;letter-spacing:normal;color:#fff}}@media(max-width:991.98px){.buttons_6R0mu{width:calc(100% - 48px)}.footer_PXtmC{bottom:8px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalHomeAdvantagesGallery": "ModalHomeAdvantagesGallery_VEfdL",
	"headerWrapper": "headerWrapper_S2ynb",
	"header": "header_x0pMt",
	"headerTitle": "headerTitle_VTq1t",
	"pagination": "pagination_c45bc",
	"headerClose": "headerClose_e9r8u",
	"body": "body_aOc05",
	"slide": "slide_73n2o",
	"slider": "slider_vXddW",
	"buttons": "buttons_6R0mu",
	"footer": "footer_PXtmC",
	"select": "select_aCi2E",
	"selectArrow": "selectArrow_GxYJN"
};
module.exports = ___CSS_LOADER_EXPORT___;
