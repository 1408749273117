<template>
    <ModalContainer
        :class="[$style.container, {[$style._learn]: isLearnPage}]"
        :visible="visible"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <div :class="$style.body">
            <div :class="$style.header">
                <h3
                    :class="$style.title"
                    v-html="card.title"
                >
                </h3>

            </div>

            <div :class="$style.text">
                <div :class="$style.inner">
                    <div
                        :class="[$style.topText, 'text']"
                        v-html="card.category === 'text'? card.intro + card.text : card.description"
                    >
                    </div>
                </div>
            </div>
        </div>
    </ModalContainer>
</template>

<script>
import ModalContainer from './ModalContainer';

export default {
    name: 'TextModal',

    components: {
        ModalContainer,
    },

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },

    computed: {
        card() {
            return this.data.card;
        },

        isPromo() {
            return this.card.category === 'promotion';
        },

        isLearnPage() {
            return this.data.isLearnPage;
        },
    },
};
</script>

<style lang="scss" module>
    .container {
        width: 96rem;

        @include respond-to(sm) {
            width: 100%;
        }

        &._learn {
            .header {
                margin-bottom: 1.4rem;

                @include respond-to(xs) {
                    margin-bottom: 0;
                }
            }

            .title {
                font-size: 4rem;

                @include respond-to(xs) {
                    text-align: center;
                    font-size: 2.7rem;
                }
            }

            .text {
                @include respond-to(xs) {
                    padding: 12px 0;
                }
            }

            .topText {
                @include respond-to(xs) {
                    line-height: 1.5;
                }
            }
        }
    }

    .body {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @include respond-to(xs) {
            padding-bottom: 22px;
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 6.2rem;

        @include respond-to(xs) {
            margin-bottom: 0;
        }
    }

    .title {
        width: 85%;
        font-size: 2rem;
        font-weight: 700;
    }

    .text {
        position: relative;

        @include respond-to(xs) {
            overflow: hidden;
            height: 100%;
            padding: 32px 0;
        }
    }

    .inner {
        @include respond-to(xs) {
            position: relative;
            z-index: 1;
            overflow-y: auto;
            height: 100%;
        }
    }

    .topText {
        a {
            text-decoration: underline;

            &:hover {
                opacity: .7;
            }
        }

        ul {
            padding-left: 1.6rem;
        }

        li {
            list-style: disc;
        }
    }
</style>
