<template>
    <div
        :class="[$style.UiLabel, classList]"
        @click="$emit('click')"
    >
        <div
            v-if="iconBefore"
            :class="$style.UiLabelIconBefore"
        >
            <slot name="iconBefore"></slot>
        </div>

        <slot></slot>

        <div
            v-if="iconAfter"
            :class="$style.UiLabelIconAfter"
        >
            <slot name="iconAfter"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiLabel',

    props: {
        iconBefore: {
            type: Boolean,
            default: false,
        },

        iconAfter: {
            type: Boolean,
            default: false,
        },

        color: {
            type: String,
            default: 'default',
            validator: value => ['default', 'default-transparent', 'dark', 'dark-transparent'].includes(value),
        },
    },

    computed: {
        classList() {
            return {
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style['_withIcon']]: this.iconAfter,
            };
        },
    },
};
</script>

<style lang="scss" module>
    .UiLabel {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 1.2rem;
        background-color: $base-50;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1;
        color: $home-base-1;

        &._default {
            //
        }

        &._dark {
            background-color: $home-base-1;
            color: $inversed;
        }

        &._dark-transparent {
            background-color: #757d88;
            color: $inversed;
        }

        &._default-transparent {
            background-color: rgba(255, 255, 255, .24);
            color: $inversed;
        }
    }

    .UiLabelIconAfter {
        width: 1.2rem;
        height: 1.2rem;
        margin-left: .4rem;
    }

    .UiLabelIconBefore {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: .4rem;
    }
</style>
