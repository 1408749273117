<template>
    <ModalContainer
        :class="$style.container"
        :visible="visible"
        is-main-promo-success
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <div :class="$style.body">
            <h3
                :class="$style.title"
                v-html="data.title"
            >
            </h3>
            <div
                v-if="data.description"
                :class="$style.subtitle"
                v-html="data.description"
            />
            <a
                v-if="data.hasTelegramLink"
                :class="$style.button"
                target="_blank"
                href="https://t.me/asterus_alia"
            >
                <IconTelegram :class="$style.icon"/>
                <span>Новостной бот ALIA</span>
            </a>

            <div v-if="$device.isMobileOrTablet" :class="$style.timer">Окно автоматически закроется через <span :class="$style.timerBold">0{{ closeTimer }} сек</span> </div>

            <UiButton
                v-if="!$device.isMobileOrTablet && data.isError"
                :class="$style.formErrorButton"
                color="black"
                size="big"
                @click="$emit('close')"
            >
                Повторить попытку
            </UiButton>
        </div>
    </ModalContainer>
</template>

<script>
// utils
import FormMixin from '@/assets/js/mixins/FormMixin';
// components
import ModalContainer from '~/components/common/modals/ModalContainer';
import IconTelegram from '@/assets/icons/home/telegram-logo.svg?inline';
import UiButton from '~/components/ui/buttons/UiButton';

export default {
    name: 'ModalHomeSaleFormSuccess',

    components: {
        ModalContainer,
        IconTelegram,
        UiButton,
    },

    mixins: [FormMixin],


    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },

    data() {
        return {
            closeTimer: 9,
            interval: null,
        };
    },

    watch: {
        closeTimer(value) {
            if (value < 1) {
                this.stopTimer();
            }
        },
    },

    mounted() {
        if (this.$device.isMobileOrTablet) {
            this.startTimer();
        }
    },

    methods: {
        startTimer() {
            this.interval = setInterval(() => {
                this.closeTimer--;
            }, 1000);
        },

        stopTimer() {
            this.$emit('close');
            clearInterval(this.interval);
            setTimeout(() => {
                this.closeTimer = 9;
            }, 1000);
        },

    },

};
</script>

<style lang="scss" module>
    .container {
        min-width: 60rem;
        max-width: max-content;
        margin-right: auto;
        margin-left: auto;
        padding-right: 2.4rem;
        padding-left: 2.4rem;

        @include respond-to(xs) {
            width: 100vw;
            min-width: unset;
            max-width: unset;
            height: 100%;
            padding: 0;
        }
    }

    .body {
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        text-align: center;

        @include respond-to(xs) {
            overflow-y: auto;
            height: 100%;
        }
    }

    .title {
        margin-bottom: 3.6rem;
        padding: 0 1.6rem;
        text-transform: uppercase;
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 1.32;
        color: $base-900;

        @include respond-to(xs) {
            font-size: 2.4rem;
        }
    }

    .subtitle {
        color: $base-400;
    }

    .button {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-top: 3.6rem;
        padding: 1.6rem 2rem;
        border: none;
        background-clip: padding-box;
        background-color: $base-900;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1;
        color: $base-0;
        transition: all .3s ease;

        &:hover,
        &:active {
            background-color: $gray-3;
            color: $base;
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }
    }

    .icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }

    .timer {
        position: absolute;
        bottom: 24px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 152%;
        color: rgba(255, 255, 255, .66);
    }

    .timerBold {
        color: rgba(255, 255, 255, 1);
    }

    .formErrorButton {
        width: 50%;
        margin-top: 4.8rem;

        @include respond-to(sm) {
            margin-top: 3.2rem;
        }

        @include respond-to(xs) {
            position: absolute;
            right: 6rem;
            bottom: 3.2rem;
            left: 6rem;
            width: auto;
            margin-top: 0;
        }
    }
</style>
