export const state = () => ({
    webpSupported: null,
    acceptFormats: '',
    isIos: null,
    isOsx: null,
    isTouch: null,
});

export const getters = {
    getIsWebpSupported(state) {
        return state.webpSupported;
    },

    getIsIpadPro(state) {
        return state.isTouch && state.isOsx && !state.isIos;
    },

    getIsIos(state) {
        return state.isIos;
    },

    getIsTouch(state) {
        return state.isTouch;
    },
};

export const actions = {
    init({ dispatch }, context) {
        const accept = context?.req?.headers['accept'];
        const userAgent = context?.req?.headers['user-agent'];

        if (accept) {
            dispatch('changeAcceptFormats', accept.split('/'));
            dispatch('changeIsWebpSupported', /\b(webp)\b/i.test(accept));
        }

        if (userAgent) {
            dispatch('changeIsIos', /(iPhone|iPad)/i.test(userAgent));
            dispatch('changeIsOsx', /(Mac)/i.test(userAgent));
        }
    },

    changeAcceptFormats({ commit }, payload) {
        commit('SET_ACCEPT_FORMATS', payload);
    },

    changeIsWebpSupported({ commit }, payload) {
        commit('SET_WEBP_SUPPORTED', payload);
    },

    changeIsTouch({ commit }, payload) {
        commit('SET_IS_TOUCH', payload);
    },

    changeIsOsx({ commit }, payload) {
        commit('SET_IS_OSX', payload);
    },

    changeIsIos({ commit }, payload) {
        commit('SET_IS_IOS', payload);
    },
};

export const mutations = {
    SET_ACCEPT_FORMATS(state, accept) {
        state.acceptFormats = accept;
    },

    SET_WEBP_SUPPORTED(state, support) {
        state.webpSupported = support;
    },

    SET_IS_TOUCH(state, payload) {
        state.isTouch = payload;
    },

    SET_IS_OSX(state, payload) {
        state.isOsx = payload;
    },

    SET_IS_IOS(state, payload) {
        state.isIos = payload;
    },
};
