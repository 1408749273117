<template>
    <symbol id="icon-fully-lock" viewBox="0 0 18 18">
        <path fill="none" d="M0 0h24v24H0z" />
        <path
            d="M13.8333 7.33464H14.6667C14.8877 7.33464 15.0996 7.42243 15.2559 7.57871C15.4122 7.73499 15.5 7.94696 15.5 8.16797V16.5013C15.5 16.7223 15.4122 16.9343 15.2559 17.0906C15.0996 17.2468 14.8877 17.3346 14.6667 17.3346H1.33333C1.11232 17.3346 0.900358 17.2468 0.744078 17.0906C0.587797 16.9343 0.5 16.7223 0.5 16.5013V8.16797C0.5 7.94696 0.587797 7.73499 0.744078 7.57871C0.900358 7.42243 1.11232 7.33464 1.33333 7.33464H2.16667V6.5013C2.16667 5.73526 2.31755 4.97672 2.6107 4.26898C2.90386 3.56125 3.33354 2.91819 3.87521 2.37651C4.41689 1.83484 5.05995 1.40516 5.76768 1.112C6.47541 0.818852 7.23396 0.667969 8 0.667969C8.76604 0.667969 9.52459 0.818852 10.2323 1.112C10.9401 1.40516 11.5831 1.83484 12.1248 2.37651C12.6665 2.91819 13.0961 3.56125 13.3893 4.26898C13.6825 4.97672 13.8333 5.73526 13.8333 6.5013V7.33464ZM12.1667 7.33464V6.5013C12.1667 5.39623 11.7277 4.33643 10.9463 3.55502C10.1649 2.77362 9.10507 2.33464 8 2.33464C6.89493 2.33464 5.83512 2.77362 5.05372 3.55502C4.27232 4.33643 3.83333 5.39623 3.83333 6.5013V7.33464H12.1667ZM7.16667 10.668V14.0013H8.83333V10.668H7.16667Z"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconFullyLock',
};
</script>
