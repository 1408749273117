<template>
    <div :class="[$style.MenuCard, classList]">
        <div>
            <div
                v-if="icon"
                v-lazy:background-image="icon"
                :class="[$style.icon, $style.iconImage]"
            />

            <div v-else :class="$style.icon">
                <slot name="icon" />
            </div>
        </div>

        <div :class="$style.content">
            <div
                v-if="title"
                :class="$style.title"
                v-html="title"
            >
            </div>

            <div
                v-if="text"
                :class="$style.text"
                v-html="text"
            >
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MenuCard',

    props: {
        color: {
            type: String,
            default: 'default', // default or primary
        },

        title: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },

        icon: {
            type: String,
            default: '',
        },

        iconComponent: {
            type: Object,
            default: () => ({}),
        },

        textSize: {
            type: String,
            default: 'normal',
            validator: value => ['big', 'normal', 'small'].includes(value),
        },
    },

    computed: {
        classList() {
            return {
                [this.$style[`_${this.textSize}`]]: this.textSize,
            };
        },
    },
};
</script>

<style lang="scss" module>
    .MenuCard {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 2.6rem;
        background-color: $base-50;
        cursor: pointer;
        flex-direction: column;

        @include hover {
            &:hover {
                .title {
                    color: $base-900;
                }

                .icon {
                    color: $base-900;
                }

                .iconImage {
                    filter: contrast(1);
                }
            }
        }

        .link {
            position: absolute;
            right: 2.4rem;
            bottom: 2.4rem;
        }

        &._big {
            .title {
                font-size: 4rem;
            }
        }

        &._normal {
            .title {
                font-size: 3.2rem;
            }
        }

        &._small {
            .title {
                font-size: 2.8rem;
            }
        }
    }

    .title {
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.24;
        color: $base-500;
        transition: color .3s;
    }

    .text {
        margin-top: 1.6rem;
        font-weight: 300;
        line-height: 1.32;
        color: $base-500;
    }

    .content {
        margin-top: 4rem;
    }

    .iconImage,
    .icon {
        width: 3.2rem;
        height: 3.2rem;
        color: $base-500;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .iconImage {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.2rem;
        height: 3.2rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        filter: contrast(0);
        transition: filter .3s ease-in-out;
    }
</style>
