export const sortingData = [
    // {
    //     value: 'price',
    //     label: 'По возрастанию цены',
    // },

    {
        value: '-price',
        label: 'По убыванию цены',
    },

    {
        value: 'area',
        label: 'По возрастанию площади',
    },

    {
        value: '-area',
        label: 'По убыванию площади',
    },

    // {
    //     value: 'sale_percent',
    //     label: 'По возрастанию скидки',
    // },

    {
        value: '-sale_percent',
        label: 'По убыванию скидки',
    },

    // Убрал чёрную пятницу
    // {
    //     value: '-is_black_friday',
    //     label: 'Черная пятница',
    // },
];
