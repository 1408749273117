// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Gallery_Eb-cB{position:relative}.Gallery_Eb-cB:before{content:\"\";display:block;width:100%;padding-top:53.125%}.Gallery_Eb-cB>.aspect-content_vk2hA{position:absolute;top:0;left:0;width:100%;height:100%}@media(max-width:767.98px){.Gallery_Eb-cB{margin-right:-16px;margin-left:-16px;position:relative}.Gallery_Eb-cB:before{content:\"\";display:block;width:100%;padding-top:69.4444444444%}.Gallery_Eb-cB>.aspect-content_vk2hA{position:absolute;top:0;left:0;width:100%;height:100%}}.Gallery_Eb-cB._flatGallery_fr53Z .container_5cc0P{margin:0}.Gallery_Eb-cB._flatGallery_fr53Z .btn_c-W5z._prev_Zb6\\+4{left:3rem}.Gallery_Eb-cB._flatGallery_fr53Z .btn_c-W5z._next_qIdSd{right:3rem}.slider_IGV6J{position:absolute;top:0;left:0;display:-webkit-box;display:-ms-flexbox;display:flex}.container_5cc0P,.slider_IGV6J{width:100%;height:100%}.container_5cc0P{margin:0 5.5rem}@media(max-width:767.98px){.container_5cc0P{margin:0}}.slide_-1kA0{overflow:hidden;height:100%}@media(max-width:767.98px){.slide_-1kA0{width:100%}}.image_IXWCU{height:100%}.btn_c-W5z{position:absolute;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}@media(max-width:767.98px){.btn_c-W5z{display:none}}.btn_c-W5z._next_qIdSd{right:0}.btn_c-W5z._prev_Zb6\\+4{left:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Gallery": "Gallery_Eb-cB",
	"aspect-content": "aspect-content_vk2hA",
	"_flatGallery": "_flatGallery_fr53Z",
	"container": "container_5cc0P",
	"btn": "btn_c-W5z",
	"_prev": "_prev_Zb6+4",
	"_next": "_next_qIdSd",
	"slider": "slider_IGV6J",
	"slide": "slide_-1kA0",
	"image": "image_IXWCU"
};
module.exports = ___CSS_LOADER_EXPORT___;
