// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiModalTouchHeader_X4z9r{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;height:9.6rem;padding:2.8rem 2.4rem 2.4rem;border-bottom:1px solid #dfe0e2}.headerBar_gNT-y{position:absolute;top:.8rem;left:calc(50% - 2rem);width:4rem;height:.4rem;background-color:#dfe0e2}.headerTitle_qybwl{font-size:1.6rem;font-weight:700;line-height:1.6rem;letter-spacing:-.064rem;color:#bebec5}.headerTitle_qybwl.strong_U-iCA{font-size:2rem;font-weight:700;line-height:2.6rem;letter-spacing:normal;color:#131415}.headerClose_DBNVS{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:4rem;height:4rem;margin-left:auto;border-radius:50%;background-color:#e9eaec;color:#000}.headerClose_DBNVS svg{width:1.6rem;height:1.6rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiModalTouchHeader": "UiModalTouchHeader_X4z9r",
	"headerBar": "headerBar_gNT-y",
	"headerTitle": "headerTitle_qybwl",
	"strong": "strong_U-iCA",
	"headerClose": "headerClose_DBNVS"
};
module.exports = ___CSS_LOADER_EXPORT___;
