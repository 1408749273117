<template>
    <li :class="$style.UiTab">
        <button
            class="btn-reset"
            :class="[$style.item, classList]"
            type="button"
            @click.prevent="$emit('click')"
        >
            <span>
                <slot></slot>
            </span>
        </button>
    </li>
</template>

<script>
export default {
    name: 'UiTab',

    props: {
        active: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return {
                _active: this.active,
                _disabled: this.disabled,
                _base: this.$parent.base,
                _inverse: this.$parent.inverse,
                _vertical: this.$parent.vertical,
                _huge: this.$parent.huge,
            };
        },
    },
};
</script>

<style lang="scss" module>
    .UiTab {
        //
    }

    .item {
        position: relative;
        padding-top: 1.2rem;
        font-size: 1.4rem;
        line-height: 1.88;
        transition: padding-left .3s ease-out;

        @include hover {
            &:hover {
                span {
                    opacity: .64;
                }
            }
        }

        &:global(._base) {
            color: $base;
        }

        &:global(._inverse) {
            color: $base-inverse;
        }

        &:global(._vertical) {
            padding-top: 0;
            text-align: left;

            &:after {
                top: .8rem;
                left: 0;
            }
        }

        &:global(._huge) {
            font-size: 2rem;
            line-height: 1.4;

            &:global(._vertical) {
                &:after {
                    top: .9rem;
                }
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: .8rem;
            height: 1rem;
            background-color: currentColor;
            opacity: 0;
            transform: translate(-50%, -5px) skewX(-20deg);
            transition: opacity .3s ease-in, transform .3s ease-in;

            @include respond-to(xs) {
                top: 4px;
                width: 6px;
                height: 8px;
            }
        }

        span {
            display: inline-block;
            transition: opacity .3s ease-in, transform .3s ease-in;
        }

        &:global(._active) {
            span {
                opacity: .64;
            }

            &:after {
                opacity: 1;
                transform: translate(-50%, 0) skewX(-20deg);
            }

            &:global(._vertical) {
                span {
                    transform: translateX(2.4rem);
                }
            }
        }

        &:global(._disabled) {
            pointer-events: none;

            span {
                opacity: .3;
            }
        }
    }
</style>
