<template>
    <div
        v-show="visible"
        :class="[$style.RateModalAdaptive, {[$style._success]: step === 2}, {[$style._error]: step === 2 && isError === true}]"
    >
        <div :class="[$style.firstRow, {[$style._flexEnd]: step === 2}]">
            <div
                v-if="currentStep === 0"
                :class="$style.title"
            >
                {{ data.title }}
            </div>
            <div
                v-else-if="currentStep === 1"
                :class="$style.title"
            >
                {{ formTitle }}
            </div>
            <div :class="$style.btn">
                <UiCloseButton
                    :color="'primary'"
                    @click="onCloseModal"
                    @after-leave="$emit('after-leave')"
                />
            </div>
        </div>
        <div
            v-if="step === 0"
            :class="$style.rateFirstStep"
        >
            <div
                :class="[$style.text, $style.textFirst]"
                v-html="templateText.rateFormTitle"
            >
            </div>
            <div :class="$style.starsContainer">
                <div
                    v-for="star in stars"
                    :key="star.id"
                    :class="[$style.star, {[$style._active]: star.colored}, {[$style._disabled]: star.disabled}]"
                    @click="addBackground(star.id)"
                >
                    <FooterStar
                        v-if="!star.colored"
                    />
                    <FooterStarActive
                        v-if="star.colored"
                    />
                </div>

            </div>
            <UiButton
                color="primary"
                :disabled="isRated"
                :class="$style.rateBtn"
                @click="onNextStep"
            >
                {{ templateText.rateButton }}
            </UiButton>
        </div>


        <div
            v-if="step === 1"
            :class="$style.rateForm"
        >

            <ValidationObserver
                v-slot="{ handleSubmit }"
                tag="div"
            >

                <form
                    ref="form"
                    action="#"
                    method="post"
                    @submit.prevent="handleSubmit(() => onFormSubmit($event))"
                >

                    <input
                        type="hidden"
                        name="category"
                        value="rating"
                    >

                    <input
                        type="hidden"
                        name="rating"
                        :value="rating"
                    >

                    <UiInputNew
                        v-model="name"
                        field="имя"
                        name="name"
                        placeholder="Имя"
                        rules="required|cyrillic|min:2|alpha_spaces"
                        :class="$style.input"
                    />

                    <UiInputNew
                        v-model="phone"
                        is-phone
                        field="телефон"
                        type="tel"
                        name="phone"
                        placeholder="Телефон"
                        :rules="`${isCallback ? 'required|' : ''}phone`"
                        :class="$style.input"
                    />

                    <UiTextarea
                        v-model="text"
                        field="Комментарий"
                        type="textarea"
                        name="text"
                        :placeholder="formPlaceholder"
                        rules="required|min:2"
                        :custom-messages="{ required: 'Поле обязательно для заполнения'}"
                        :class="$style.formInput"
                    >

                    </UiTextarea>


                    <UiButton
                        type="submit"
                        color="primary"
                        :disabled="isRated"
                        :class="$style.submitBtn"
                        @click="onNextStep"
                    >
                        {{ templateText.submitButton }}
                    </UiButton>


                    <div :class="$style.policy">
                        <FormPolicy
                            v-model="checkboxes"

                            :callback="true"
                            :class="$style.policy"
                            @checked="changeCallback"
                        />
                    </div>
                </form>
            </ValidationObserver>


        </div>

        <div
            v-if="step === 2"
            :class="$style.rateForm"
        >
            <div :class="$style.secondTitle">{{ formSecondTitle }}</div>
            <div
                :class="[$style.secondDescription, {[$style._error]: !isSent}]"
                v-html="formMessage"
            >

            </div>
            <div
                v-if="isSent"
                :class="$style.timer"
            >
                {{ templateText.timerText }}
                <span :class="$style.timerHighlight">{{ timer }} сек</span>
            </div>
            <UiButton
                v-if="!isSent"
                :class="$style.tryBtn"
                :color="'primary'"
                @click="onResetStep"
            >
                {{ templateText.retryButton }}
            </UiButton>
        </div>
    </div>
</template>

<script>
import FooterStar from '@/assets/icons/footer-star.svg?inline';
import FormMixin from '~/assets/js/mixins/FormMixin';
import FooterStarActive from '@/assets/icons/footer-star-active.svg?inline';
import UiButton from '~/components/ui/home/UiButton';
// import UiInput from '~/components/ui/input/UiInput';
import UiInputNew from '~/components/ui/home/UiInput';
import UiCloseButton from '~/components/ui/home/UiCloseButton';
import FormPolicy from '~/components/common/form/FormPolicy.vue';
import UiTextarea from '~/components/ui/home/UiTextarea.vue';

const BAD_RATING = 3;

const TEMPLATE_RATE_BUTTON_TEXT = 'Оценить';
const TEMPLATE_SUBMIT_BUTTON_TEXT = 'Отправить';
const TEMPLATE_BAD_RATING_TEXT = 'что следует улучшить?';
const TEMPLATE_GOOD_RATING_TEXT = 'что вам понравилось?';
const TEMPLATE_PLACEHOLDER_BAD_RATING_TEXT = 'расскажите, что нам следует исправить, чтобы сайт стал еще удобнее';
const TEMPLATE_PLACEHOLDER_GOOD_RATING_TEXT = 'расскажите, что вам понравилось';
const TEMPLATE_COMFORTABLE_WEBSITE_TEXT = 'Удобно ли вам <br> пользоваться сайтом?';
const TEMPLATE_TIMER_TEXT = 'Окно автоматически закроется через';
const TEMPLATE_RETRY_FORM_TEXT = 'Попробуйте повторить попытку снова или<br> перезагрузите страницу';
const TEMPLATE_SUCCESS_FORM_TEXT = 'Ваше мнение очень важно для наc,<br> благодаря ему мы становимся лучше';
const TEMPLATE_RETRY_FORM_SECOND_TITLE = 'Произошла ошибка';
const TEMPLATE_SUCCESS_FORM_SECOND_TITLE = 'Спасибо!';
const TEMPLATE_RETRY_BUTTON_TEXT = 'Повторить попытку';

export default {
    name: 'RateModalAdaptive',

    components: {
        UiTextarea,
        FormPolicy,
        UiCloseButton,
        // UiInput,
        FooterStarActive,
        FooterStar,
        UiButton,
        UiInputNew,
    },

    mixins: [
        FormMixin,
    ],

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            visible: true,
            isRated: true,
            rating: 0,
            step: 0,
            isSent: false,
            timer: 10,
            name: '',
            phone: '',
            text: '',
            isCallback: false,
            templateBadRating: BAD_RATING,
            templateText: {
                rateButton: TEMPLATE_RATE_BUTTON_TEXT,
                submitButton: TEMPLATE_SUBMIT_BUTTON_TEXT,
                goodRating: TEMPLATE_GOOD_RATING_TEXT,
                badRating: TEMPLATE_BAD_RATING_TEXT,
                placeholderBadRating: TEMPLATE_PLACEHOLDER_BAD_RATING_TEXT,
                placeholderGoodRating: TEMPLATE_PLACEHOLDER_GOOD_RATING_TEXT,
                rateFormTitle: TEMPLATE_COMFORTABLE_WEBSITE_TEXT,
                timerText: TEMPLATE_TIMER_TEXT,
                retryButton: TEMPLATE_RETRY_BUTTON_TEXT,
            },

            checkboxes: {
                policy: false,
                callback: true,
                marketing: true,
            },

            stars: [
                {
                    id: '1',
                    colored: false,
                    disabled: false,
                },

                {
                    id: '2',
                    colored: false,
                    disabled: false,
                },

                {
                    id: '3',
                    colored: false,
                    disabled: false,
                },

                {
                    id: '4',
                    colored: false,
                    disabled: false,
                },

                {
                    id: '5',
                    colored: false,
                    disabled: false,
                },
            ],
        };
    },

    computed: {
        formTitle() {
            return this.currentRating <= this.templateBadRating ? this.templateText.badRating : this.templateText.goodRating;
        },

        formPlaceholder() {
            return this.currentRating <= this.templateBadRating ? this.templateText.placeholderBadRating : this.templateText.placeholderGoodRating;
        },

        currentStep() {
            return this.step;
        },

        currentRating() {
            return this.rating;
        },

        formSecondTitle() {
            return !this.isSent ? TEMPLATE_RETRY_FORM_SECOND_TITLE : TEMPLATE_SUCCESS_FORM_SECOND_TITLE;
        },

        formMessage() {
            return !this.isSent ? TEMPLATE_RETRY_FORM_TEXT : TEMPLATE_SUCCESS_FORM_TEXT;
        },
    },

    watch: {
        timer() {
            if (this.timer === 0) {
                this.$emit('after-leave');
                this.$emit('close');
                this.visible = false;
            }
        },

        formSuccess(value) {
            if (value) {
                this.$refs.form.reset();
                this.isSent = value;

                if (this.isSent) {
                    this.step = 2;
                    this.handleTimerInterval();
                }
            }
        },

        formFailure(value) {
            this.isSent = !value;
            this.step = 2;
        },
    },

    methods: {
        addBackground(id) {
            this.stars.forEach(el => {
                el.colored = false;
                el.disabled = false;
            });

            this.stars.forEach(el => {
                if (Number(el.id) <= Number(id)) {
                    el.colored = true;
                    this.rating = Number(el.id);
                }
                Number(el.id) <= Number(id)? el.colored = true : el.disabled =true;
            });

            this.isRated = false;
        },

        onCloseModal() {
            this.$emit('after-leave');
            this.$emit('close');
            this.visible = false;
        },

        onNextStep() {
            if (this.step === 0) {
                this.step += 1;
            }
        },

        handleTimerInterval() {
            if (this.step === 2 && this.isSent) {
                setInterval(() => {
                    this.timer -= 1;
                }, 1000);
            }
        },

        changeCallback(value) {
            this.isCallback = value;
        },

        onResetStep() {
            this.step = 0;
        },
    },
};
</script>

<style lang="scss" module>
    .RateModalAdaptive {
        position: absolute;
        top: calc(50% - 19.9rem);
        left: calc(50% - 36.3rem);
        width: 72.6rem;
        height: 67.4rem;
        padding: 2.4rem;
        background-color: $base-0;
        color: $base;

        &._success {
            //background-color: transparent;
            //background-image: url("/images/pages/home/rate-success.jpg");
            background-size: cover;
        }

        &._error {
            //background-color: transparent;
            //background-image: url("/images/pages/home/rate-success.jpg");
            background-size: cover;
        }

        @include respond-to(xs) {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .firstRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &._flexEnd {
            justify-content: flex-end;
            text-align: center;
        }
    }

    .title {
        margin-bottom: 1.6rem;
        text-transform: uppercase;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 1;
        letter-spacing: -.04em;
        color: $about-black;

        @include respond-to(xs) {
            margin-bottom: 0;
            font-size: 1.6rem;
        }
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        justify-self: flex-end;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background-color: $home-accent-6;

        .icon {
            width: 2rem;
            height: 2rem;

            :global(path) {
                stroke: $base-900;
            }
        }
    }

    .rateFirstStep,
    .rateForm {
        display: flex;
        flex-direction: column;

        .text {
            font-size: 1.4rem;
            line-height: 140%;
            color: $home-base-3;

            @include respond-to(xs) {
                margin-bottom: 4rem;
            }
        }

        .textFirst {
            margin-bottom: 15.1rem;

            @include respond-to(xs) {
                margin-bottom: 17.8rem;
            }
        }

        .starsContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1.6rem;
            margin-bottom: 26.1rem;

            @include respond-to(xs) {
                margin-bottom: 21.3rem;
            }

            @include respond-to(xxs) {
                margin-bottom: 12.3rem;
            }

            .star {
                width: 5.4rem;
                height: 5.4rem;

                &._disabled {
                    :global(path) {
                        fill: $home-base-4;
                    }
                }
            }
        }
    }

    .submitBtn {
        width: 100%;
        margin-top: 3.4rem;

        @include respond-to-min(xs) {
            height: 5.6rem;
        }
    }

    .formInput {
        &:last-of-type {
            height: 26.2rem;
        }

        :global(textarea::placeholder) {
            text-transform: uppercase;
            font-size: 16px;
            line-height: 19.88px;
            color: $base-400;
        }

        :global(textarea) {
            margin-top: 1.6rem;
            padding: 2rem;
            border: none;
            background-color: $base-50;
            text-transform: uppercase;
            font-size: 16px;
        }
    }

    .secondTitle {
        margin-top: 21.75rem;
        margin-bottom: 2.4rem;
        text-align: center;
        text-transform: uppercase;
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 3.6rem;
        letter-spacing: -.04em;
        color: $base;

        @include respond-to(xs) {
            margin-top: calc((258.5 / 812) * 100vh);
            margin-bottom: calc((40 / 812) * 100vh);
            font-size: 2rem;
        }
    }

    .secondDescription {
        margin-bottom: 24.25rem;
        text-align: center;
        font-size: 1.4rem;
        line-height: 140%;
        color: $base-400;

        @include respond-to(xs) {
            margin-bottom: calc((289.5 / 812) * 100vh);
        }

        &._error {
            margin-bottom: 16.2rem;

            @include respond-to(xs) {
                margin-bottom: 19rem;
            }
        }
    }

    .timer {
        text-align: center;
        font-size: 1.4rem;
        line-height: 152%;
        color: $base-400;

        .timerHighlight {
            color: $base;
        }
    }

    .policy {
        margin-top: 2.4rem;

        @include respond-to(xxs) {
            margin-top: 1.4rem;
        }
    }

    .input {
        &:not(:first-child) {
            margin-top: 1.6rem;
        }
    }

    .tryBtn {
        height: 5.6rem;
    }

    .rateBtn {
        height: 5.6rem;
        margin-top: 2.4rem;
    }
</style>
