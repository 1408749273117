<template>
    <ModalContainer
        :visible="visible"
        class="modal-container"
        :class="{
            'modal-container_tablet': isTablet,
            'modal-container_mobile': isMobile,
        }"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <div class="modal-container__modal">
            <div class="modal-container__img">
                <img
                    src="/images/pages/landing/icons/close.svg"
                    alt="Ошибка"
                    class="modal-container__icon"
                >
            </div>

            <div class="modal-container__title">
                Ошибка!
            </div>

            <div class="modal-container__text">
                Попробуйте еще раз
            </div>

            <TheButton
                label="Повторить"
                @click="$modal.open('CallbackModal');"
            />
        </div>
    </ModalContainer>
</template>

<script>
import ModalContainer from './ModalContainer';
import TheButton from '~/components/landing/components/ui/Button';

export default {
    name: 'CallbackModal',

    components: {
        ModalContainer,
        TheButton,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isDesktop() {
            return this.$device.isDesktop;
        },

        isTablet() {
            return !this.$device.isDesktop;
        },

        isMobile() {
            return this.$device.isMobile;
        },
    },
};
</script>

<style lang="scss" scoped>
    .modal-container {
        &__modal {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-width: 60rem;
            min-height: 65rem;
            padding: 10rem 12rem;
        }

        &__img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 7.2rem;
            height: 7.2rem;
            margin-bottom: 2.4rem;
        }

        &__icon {
            width: 4.8rem;
        }

        &__title {
            margin-bottom: .8rem;
            text-transform: uppercase;
            font-family: 'Aeroport', sans-serif;
            font-size: 2.4rem;
            font-weight: 700;
            line-height: 3rem;
            color: #2a2c2b;
        }

        &__text {
            margin-bottom: 3.2rem;
            font-family: 'Graphik LG', sans-serif;
            font-size: 1.2rem;
            line-height: 1;
            color: #2a2c2b;
        }
    }

    .modal-container_tablet {
        .modal-container {
            &__modal {
                min-width: 510px;
                min-height: 670px;
                padding: 60px;
            }

            &__img {
                width: 72px;
                height: 72px;
                margin-bottom: 24px;
            }

            &__icon {
                width: 48px;
            }

            &__title {
                margin-bottom: 8px;
                font-size: 24px;
                line-height: 30px;
            }

            &__text {
                margin-bottom: 32px;
                font-size: 12px;
            }
        }
    }

    .modal-container_mobile {
        .modal-container {
            &__modal {
                min-width: 100vw;
                min-height: 100vh;
                padding: 40px 20px;
            }
        }
    }
</style>
