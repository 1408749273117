import { splitThousands } from '~/assets/js/utils.js';
import { sortingData } from '~/assets/js/mock/filters.js';

export const FLAT_STATUSES = {
    /* eslint-disable */
    'Продана': 0,
    'В продаже': 1,
    'Бронь': 2,
    'Устная бронь': 5,
    'Оценка': 3,
    'Страт. резерв': 4,
    'Архив': 6
    /* eslint-enable */
};

function sortObjectsDesktopCards({ flats, otherHouses, banners, flatsPerPage }) {
    const otherHousesPosition = [2, 7, 16, 21];
    let promoCards = [
        { card: banners[0], position: 5 },
        { card: banners[1] || banners[0], position: 18 },
    ];
    let dinamicPromo = false;

    // если это последние квартиры
    if (flats.length !== flatsPerPage) {
        // и их нечетное количество
        if (flats.length % 2) {
            // оставляем только одно промо
            promoCards = [{ card: banners[0], position: 5 }];
            // если квартир мало, позиция промо будет после последней квартиры
            if (flats.length <= 3) {
                dinamicPromo = true;
            }
        } else {
            // если квартир четное количетво, то промо не будет
            promoCards = [];
        }
    }

    const totalCount = flats.length + otherHouses.length + promoCards.length;
    const result = [];
    let f = 0;
    let c = 0;
    let p = 0;

    if (promoCards.length) {
        // если промо есть
        if (dinamicPromo) {
            // если промо динамическое
            for (let i = 0; i < totalCount; i++) {
                if (otherHousesPosition[c] === i && otherHouses[c]) {
                    result.push(otherHouses[c]);
                    c++;
                } else if (flats[f]) {
                    result.push(flats[f]);
                    f++;

                    if (!flats[f] && promoCards[p]) {
                        result.push(promoCards[p].card);
                        p++;
                    }
                } else if (otherHouses[c]) {
                    result.push(otherHouses[c]);
                    c++;
                }
            }
        } else {
            // если промо не динамическое
            for (let i = 0; i < totalCount; i++) {
                if (promoCards[p] && promoCards[p].position === i) {
                    result.push(promoCards[p].card);
                    p++;
                } else if (otherHousesPosition[c] === i && otherHouses[c]) {
                    result.push(otherHouses[c]);
                    c++;
                } else if (flats[f]) {
                    result.push(flats[f]);
                    f++;
                } else if (otherHouses[c]) {
                    result.push(otherHouses[c]);
                    c++;
                }
            }
        }
    } else {
        // если промо нет
        for (let i = 0; i < totalCount; i++) {
            if (otherHousesPosition[c] === i && otherHouses[c]) {
                result.push(otherHouses[c]);
                c++;
            } else if (flats[f]) {
                result.push(flats[f]);
                f++;
            } else if (otherHouses[c]) {
                result.push(otherHouses[c]);
                c++;
            }
        }
    }

    return result;
}

function sortObjectsDesktopRows({ flats, otherHouses }) {
    const otherHousesPosition = [2, 7, 16, 21];
    const totalCount = flats.length + otherHouses.length;
    const result = [];
    let f = 0;
    let c = 0;

    for (let i = 0; i < totalCount; i++) {
        if (otherHousesPosition[c] === i && otherHouses[c]) {
            result.push(otherHouses[c]);
            c++;
        } else if (flats[f]) {
            result.push(flats[f]);
            f++;
        } else if (otherHouses[c]) {
            result.push(otherHouses[c]);
            c++;
        }
    }

    return result;
}

function sortOtherHousesDesktopCards({ otherHouses }) {
    return otherHouses;
}

function sortOtherHousesDesktopRows({ otherHouses }) {
    return otherHouses;
}

function sortFlatsDesktopCards({ flats, banners }) {
    const promoCards = [
        { card: banners[0], position: 3 },
        { card: banners[1] || banners[0], position: 16 },
    ];
    const totalCount = flats.length + promoCards.length;
    const result = [];
    let p = 0;
    let f = 0;

    for (let i = 0; i < totalCount; i++) {
        if (promoCards[p] && promoCards[p].position === i) {
            result.push(promoCards[p].card);
            p++;
        } else if (flats[f]) {
            result.push(flats[f]);
            f++;
        }
    }

    return result;
}

function sortFlatsDesktopRows({ flats }) {
    return flats;
}

function sortObjectsMobileCards({ flats, otherHouses, banners, flatsPerPage }) {
    const otherHousesPosition = [2, 7, 16, 21];
    let promoCards = [
        { card: banners[0], position: 5 },
        { card: banners[1] || banners[0], position: 18 },
    ];
    let dinamicPromo = false;

    // если это последние квартиры
    if (flats.length !== flatsPerPage) {
        // и их нечетное количество
        if (flats.length % 2) {
            // оставляем только одно промо
            promoCards = [{ card: banners[0], position: 5 }];
            // если квартир мало, позиция промо будет после последней квартиры
            if (flats.length <= 3) {
                dinamicPromo = true;
            }
        } else {
            // если квартир четное количетво, то промо не будет
            promoCards = [];
        }
    }

    const totalCount = flats.length + otherHouses.length + promoCards.length;
    const result = [];
    let f = 0;
    let c = 0;
    let p = 0;

    if (promoCards.length) {
        // если промо есть
        if (dinamicPromo) {
            // если промо динамическое
            for (let i = 0; i < totalCount; i++) {
                if (otherHousesPosition[c] === i && otherHouses[c]) {
                    result.push(otherHouses[c]);
                    c++;
                } else if (flats[f]) {
                    result.push(flats[f]);
                    f++;

                    if (!flats[f] && promoCards[p]) {
                        result.push(promoCards[p].card);
                        p++;
                    }
                } else if (otherHouses[c]) {
                    result.push(otherHouses[c]);
                    c++;
                }
            }
        } else {
            // если промо не динамическое
            for (let i = 0; i < totalCount; i++) {
                if (promoCards[p] && promoCards[p].position === i) {
                    result.push(promoCards[p].card);
                    p++;
                } else if (otherHousesPosition[c] === i && otherHouses[c]) {
                    result.push(otherHouses[c]);
                    c++;
                } else if (flats[f]) {
                    result.push(flats[f]);
                    f++;
                } else if (otherHouses[c]) {
                    result.push(otherHouses[c]);
                    c++;
                }
            }
        }
    } else {
        // если промо нет
        for (let i = 0; i < totalCount; i++) {
            if (otherHousesPosition[c] === i && otherHouses[c]) {
                result.push(otherHouses[c]);
                c++;
            } else if (flats[f]) {
                result.push(flats[f]);
                f++;
            } else if (otherHouses[c]) {
                result.push(otherHouses[c]);
                c++;
            }
        }
    }

    return result;
}

function sortObjectsMobileRows({ flats, otherHouses, banners }) {
    const otherHousesPosition = [2, 7, 16, 21];
    const promoCards = [
        { card: banners[0], position: 5 },
        { card: banners[1] || banners[0], position: 18 },
    ];
    const totalCount = flats.length + otherHouses.length + promoCards.length;
    const result = [];
    let f = 0;
    let c = 0;
    let p = 0;

    for (let i = 0; i < totalCount; i++) {
        if (promoCards[p] && promoCards[p].position === i) {
            result.push(promoCards[p].card);
            p++;
        } else if (otherHousesPosition[c] === i && otherHouses[c]) {
            result.push(otherHouses[c]);
            c++;
        } else if (flats[f]) {
            result.push(flats[f]);
            f++;
        } else if (otherHouses[c]) {
            result.push(otherHouses[c]);
            c++;
        }
    }

    return result;
}

function sortOtherHousesMobileCards({ otherHouses }) {
    return otherHouses;
}

function sortOtherHousesMobileRows({ otherHouses }) {
    return otherHouses;
}

function sortFlatsMobileCards({ flats, banners }) {
    const promoCards = [
        { card: banners[0], position: 3 },
        { card: banners[1] || banners[0], position: 16 },
    ];
    const totalCount = flats.length + promoCards.length;
    const result = [];
    let p = 0;
    let f = 0;

    for (let i = 0; i < totalCount; i++) {
        if (promoCards[p] && promoCards[p].position === i) {
            result.push(promoCards[p].card);
            p++;
        } else if (flats[f]) {
            result.push(flats[f]);
            f++;
        }
    }

    return result;
}

function sortFlatsMobileRows({ flats, banners }) {
    const promoCards = [
        { card: banners[0], position: 3 },
        { card: banners[1] || banners[0], position: 16 },
    ];
    const totalCount = flats.length + promoCards.length;
    const result = [];
    let p = 0;
    let f = 0;

    for (let i = 0; i < totalCount; i++) {
        if (promoCards[p] && promoCards[p].position === i) {
            result.push(promoCards[p].card);
            p++;
        } else if (flats[f]) {
            result.push(flats[f]);
            f++;
        }
    }

    return result;
}

// функция из массивов [0, 0, 0] [1, 1, 1, 1] [2, 2, 2, 2, 2] делает [0, 1, 2, 0, 1, 2, 0, 1, 2, 1, 2, 2]
export function sortOtherTypes(...d) {
    const c = d.length;
    let i = -1;
    let v;
    const r = [];
    const s = Math.max(...d.map(x => x.length))*c;

    while (s-i++) {
        /* eslint-disable */
        (v = d[i%c][~~(i/c)]) != undefined ? r.push(v):0;
        /* eslint-enable */
    }

    return r;
}

// сортировка объектов в зависимости от типа, устройства, сетки
// разные функции могут дублироваться, это сделано для удобства в возможном дальнейшем добавлении баннеров
export function doSort({ flats, cityHouses, pentHouses, townHouses, cityUnits, banners, flatType, isDesktop, gridView, flatsPerPage }) {
    let otherHouses = [];
    if (cityHouses || pentHouses || townHouses || cityUnits) {
        const arr = [];
        if (pentHouses) {
            arr.push(...pentHouses);
        }
        if (townHouses) {
            arr.push(...townHouses);
        }
        if (cityHouses) {
            arr.push(...cityHouses);
        }
        if (cityUnits) {
            arr.push(...cityUnits);
        }
        otherHouses = arr;
    }

    if (flatType.length === 1 && flatType[0] === '209') {
        flatType = 'flats';
    } else if (flatType.length && !flatType.includes('209')) {
        flatType = 'other';
    } else {
        flatType = null;
    }

    if (!flatType && isDesktop && gridView === 'cards') {
        return sortObjectsDesktopCards({ flats, otherHouses, banners, flatsPerPage });
    }

    if (!flatType && isDesktop && gridView === 'rows') {
        return sortObjectsDesktopRows({ flats, otherHouses });
    }

    if (flatType === 'other' && isDesktop && gridView === 'cards') {
        return sortOtherHousesDesktopCards({ otherHouses });
    }

    if (flatType === 'other' && isDesktop && gridView === 'rows') {
        return sortOtherHousesDesktopRows({ otherHouses });
    }

    if (flatType === 'flats' && isDesktop && gridView === 'cards') {
        return sortFlatsDesktopCards({ flats, banners });
    }

    if (flatType === 'flats' && isDesktop && gridView === 'rows') {
        return sortFlatsDesktopRows({ flats });
    }

    if (!flatType && !isDesktop && gridView === 'cards') {
        return sortObjectsMobileCards({ flats, otherHouses, banners, flatsPerPage });
    }

    if (!flatType && !isDesktop && gridView === 'rows') {
        return sortObjectsMobileRows({ flats, otherHouses, banners });
    }

    if (flatType === 'other' && !isDesktop && gridView === 'cards') {
        return sortOtherHousesMobileCards({ otherHouses });
    }

    if (flatType === 'other' && !isDesktop && gridView === 'rows') {
        return sortOtherHousesMobileRows({ otherHouses });
    }

    if (flatType === 'flats' && !isDesktop && gridView === 'cards') {
        return sortFlatsMobileCards({ flats, banners });
    }

    if (flatType === 'flats' && !isDesktop && gridView === 'rows') {
        return sortFlatsMobileRows({ flats, banners });
    }
}

export function arrayLabelsFromFilters(filters, specs, forLastSearch = false) {
    const labels = [];

    if (filters.scenario) {
        labels.push({
            name: 'scenario',
            label: specs.scenario.find(scenario => scenario.value === filters.scenario)?.label,
        });
    }

    if (filters.newTypeFlat?.length) {
        filters.newTypeFlat.forEach(value => {
            const newTypeFlat = specs.new_type_flat.find(newTypeFlat => newTypeFlat.value === value);

            labels.push({
                name: 'newTypeFlat',
                value: value,
                label: newTypeFlat?.label,
            });
        });
    }

    if (filters.layout?.length) {
        filters.layout.forEach(value => {
            const layout = specs.layout.find(layout => layout.value === value);

            labels.push({
                name: 'layout',
                value: value,
                label: layout?.label,
            });
        });
    }

    if (filters.priceMin || filters.priceMax) {
        const priceLabel = [];

        if (filters.priceMin) {
            priceLabel.push(`от ${splitThousands(filters.priceMin)} млн ₽`);
        }

        if (filters.priceMax) {
            priceLabel.push(`до ${splitThousands(filters.priceMax)} млн ₽`);
        }

        labels.push({
            name: 'priceMin,priceMax',
            label: priceLabel.join(' '),
        });
    }

    if (filters.areaMin || filters.areaMax) {
        const areaLabel = [];

        if (filters.areaMin) {
            areaLabel.push(`от ${filters.areaMin}м²`);
        }

        if (filters.areaMax) {
            areaLabel.push(`до ${filters.areaMax}м²`);
        }

        labels.push({
            name: 'areaMin,areaMax',
            label: areaLabel.join(' '),
        });
    }

    if (filters.urbanBlock?.length) {
        filters.urbanBlock.forEach(value => {
            const urbanBlock = specs.urban_block.find(urbanBlock => urbanBlock.value === value);

            labels.push({
                name: 'urbanBlock',
                value: value,
                label: urbanBlock?.label,
            });
        });
    }

    if (filters.building?.length) {
        filters.building.forEach(value => {
            const building = specs.building.find(building => building.value === value);

            labels.push({
                name: 'building',
                value: value,
                label: building?.label,
            });
        });
    }

    if (filters.section?.length) {
        filters.section.forEach(value => {
            const section = specs.section.find(section => section.value === value);

            labels.push({
                name: 'section',
                value: value,
                label: section?.label,
            });
        });
    }

    if (filters.window_view?.length) {
        filters.window_view.forEach(value => {
            const window_view = specs.window_view.find(window_view => window_view.value === value);

            labels.push({
                name: 'window_view',
                value: value,
                label: window_view?.label,
            });
        });
    }

    if (filters.floorMin || filters.floorMax) {
        const floorLabel = ['этаж'];

        if (filters.floorMin) {
            floorLabel.push(`с ${filters.floorMin}`);
        }

        if (filters.floorMax) {
            floorLabel.push(`до ${filters.floorMax}`);
        }

        labels.push({
            name: 'floorMin,floorMax',
            label: floorLabel.join(' '),
        });
    }

    if (filters.key_distribution?.length) {
        filters.key_distribution.forEach(value => {
            const key_distribution = specs.key_distribution.find(key_distribution => key_distribution.value === value);

            labels.push({
                name: 'key_distribution',
                value: value,
                label: key_distribution?.label,
            });
        });
    }

    if (filters.decoration?.length) {
        filters.decoration.forEach(value => {
            const decoration = specs.decoration.find(decoration => decoration.value === value);

            labels.push({
                name: 'decoration',
                value: value,
                label: decoration?.label,
            });
        });
    }

    if (filters.uspLayout?.length) {
        filters.uspLayout.forEach(value => {
            const uspLayout = specs.usp_layout.find(uspLayout => uspLayout.value === value);

            labels.push({
                name: 'uspLayout',
                value: value,
                label: uspLayout?.label,
            });
        });
    }

    if (filters.uspBalcony?.length) {
        filters.uspBalcony.forEach(value => {
            const uspBalcony = specs.usp_balcony.find(uspBalcony => uspBalcony.value === value);

            labels.push({
                name: 'uspBalcony',
                value: value,
                label: uspBalcony?.label,
            });
        });
    }

    if (filters.uspLayoutFeatures?.length) {
        filters.uspLayoutFeatures.forEach(value => {
            const uspLayoutFeatures = specs.usp_layout_features.find(uspLayoutFeatures => uspLayoutFeatures.value === value);

            labels.push({
                name: 'uspLayoutFeatures',
                value: value,
                label: uspLayoutFeatures?.label,
            });
        });
    }

    if (forLastSearch) {
        if (filters.isReady === true) {
            labels.push({
                name: 'isReady',
                label: 'Квартиры с ключами',
            });
        }

        if (filters.isReady === false) {
            labels.push({
                name: 'isReady',
                label: 'Строящиеся квартиры',
            });
        }

        if (filters.sale) {
            labels.push({
                name: 'sale',
                label: 'Со скидкой',
            });
        }

        if (filters.status) {
            labels.push({
                name: 'status',
                label: 'Без забронированных',
            });
        }

        if (filters.orderBy) {
            const orderBy = sortingData.find(item => item.value === filters.orderBy);

            labels.push({
                name: 'orderBy',
                label: `Сортировка ${orderBy?.label?.toLowerCase() || ''}`,
            });
        }

        if (filters.isSalesStart) {
            labels.push({
                name: 'isSalesStart',
                label: 'Старт продаж',
            });
        }
    }

    return labels;
}
