<template>
    <div :class="$style.UiNavLinkCard" @click="onClick(to)">
        <NuxtLink
            :key="label"
            :to="to"
            :prefetch="prefetch"
            :class="$style.link"
        >
            <div
                :class="$style.card"
            >
                <div
                    v-lazy:background-image="image"
                    :class="$style.bg"
                />

                <component
                    :is="icon"
                    :class="$style.icon"    
                />
                
                <div
                    :class="$style.label"
                    v-html="label"
                />
            </div>
        </NuxtLink>
    </div>

</template>

<script>
import ImageLazy from '../common/ImageLazy';

export default {
    name: 'UiNavLinkCard',

    components: { ImageLazy },

    props: {
        to: {
            type: String,
            default: '',
        },
        
        icon: {
            type: Object,
            default: () => ({}),
        },

        label: {
            type: String,
            default: '',
        },

        image: {
            type: String,
            default: '',
        },

        prefetch: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onClick(to) {
            this.$emit('link-click', to);
        },
    },
};
</script>

<style lang="scss" module>
    .UiNavLinkCard {
        .link {
            display: block;
            height: 100%;
            user-select: none;
        }

        .card {
            position: relative;
            z-index: 2;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding: 2.8rem;
            color: $base-0;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: #0006;
                transition: background-color .3s ease;
            }

            .bg {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
                transition: transform .3s ease-in-out;
            }

            &:hover {
                .bg {
                    transform: scale(1.05);
                }

                &:after {
                    background-color: transparent;
                }
            }

            .label {
                position: relative;
                z-index: 4;
                text-transform: uppercase;
                font-size: 2.4rem;
                font-weight: 700;
                line-height: 1.32;
            }

            .icon {
                position: relative;
                z-index: 4;
                width: 3.2rem;
                height: 3.2rem;
            }
        }
    }
</style>
