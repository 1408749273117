/**
 * Полезные фильтры для разработки. Раскомментировать в случае необходимости
 */

import Vue from 'vue';
import {
    splitThousands,
    roundToMillions,
    plural,
    prettyPhone,
    // bytesToSize,
    // monthByNumber,
} from '../assets/js/utils';

Vue.filter('splitThousands', splitThousands);
Vue.filter('roundToMillions', roundToMillions);
Vue.filter('plural', plural);
Vue.filter('prettyPhone', prettyPhone);
// Vue.filter('bytesToSize', bytesToSize);
// Vue.filter('monthByNumber', monthByNumber);
