<template>
    <symbol id="icon-telegram" viewBox="-7 -7 32 32">
        <path d="M9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18ZM6.201 10.053L6.2127 10.0467L6.9957 12.6297C7.0965 12.9096 7.2351 12.96 7.4034 12.9366C7.5726 12.9141 7.6617 12.8232 7.7724 12.717L8.8416 11.6838L11.1366 13.383C11.556 13.6143 11.8575 13.4946 11.9619 12.9942L13.4532 5.9544C13.6179 5.2992 13.3299 5.0364 12.8214 5.2452L4.0617 8.6292C3.4641 8.8686 3.4677 9.2034 3.9537 9.3519L6.201 10.053Z" />
    </symbol>

</template>

<script>
export default {
    name: 'IconTelegramPdf',
};
</script>
