<template>
    <symbol id="icon-arrow-fill" viewBox="0 0 17 16">
        <path d="M8.5 5.33337L12.5 9.33337L4.5 9.33337L8.5 5.33337Z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconArrowFill',
};
</script>
