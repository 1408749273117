<template>
    <symbol id="icon-settings" viewBox="0 0 18 18">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.76269 7.5C7.76269 8.32843 7.09112 9 6.26269 9C5.43427 9 4.76269 8.32843 4.76269 7.5C4.76269 6.67157 5.43427 6 6.26269 6C7.09112 6 7.76269 6.67157 7.76269 7.5ZM6.2627 9.98453C6.51941 9.98453 6.76703 9.9456 6.99997 9.87331L6.99998 16L5.59998 16L5.59997 9.89516C5.81096 9.95341 6.03319 9.98453 6.2627 9.98453ZM6.99997 2L6.99997 5.12669C6.76703 5.05441 6.51941 5.01547 6.2627 5.01547C6.03319 5.01547 5.81096 5.04659 5.59997 5.10484L5.59997 2L6.99997 2ZM11.3297 13.0483C11.562 13.0483 11.7868 13.0164 12 12.9568L12 16L10.6 16L10.6 12.9394C10.8307 13.0102 11.0758 13.0483 11.3297 13.0483ZM12 2L12 8.17068C11.7868 8.11109 11.562 8.07922 11.3297 8.07922C11.0758 8.07922 10.8307 8.11732 10.6 8.18812L10.6 2L12 2ZM11.3297 12.0638C12.1581 12.0638 12.8297 11.3922 12.8297 10.5638C12.8297 9.73532 12.1581 9.06375 11.3297 9.06375C10.5013 9.06375 9.82971 9.73532 9.82971 10.5638C9.82971 11.3922 10.5013 12.0638 11.3297 12.0638Z"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconSettings',
};
</script>
