<template>
    <symbol id="icon-school" viewBox="0 0 16 16">
        <path d="M10.0698 4.61819H13.39V5.73512L13.3952 9.37672M10.0698 4.61819V3.07278V2.53722H6.58117L5.98444 2.53711C5.98444 2.53711 5.98444 4.28156 5.98444 4.61819M10.0698 4.61819H5.98444M5.98444 4.61819H2.61826L2.60485 9.37672M13.3952 9.37672L13.39 13.462H12.1201H2.61826V12.3604L2.60485 9.37672M13.3952 9.37672C13.3952 9.37672 12.5516 9.37672 11.7069 9.37672C10.8623 9.37672 7.23072 9.37672 4.36255 9.37672C3.67613 9.37672 2.60485 9.37672 2.60485 9.37672" stroke="currentColor"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconSchool',
};
</script>
