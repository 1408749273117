<template>
    <ModalContainer
        :class="[$style.ModalContainer, {
            [$style._aeroModal]: isAero,
            [$style._fullscreen]: isFullscreen
        }]"
        :visible="visible"
        :is-aero-modal="isAero"
        :is-full-screen="isFullscreen"
        :without-padding="withoutPadding"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <template v-if="!data.hidden">
            <div
                :class="[$style.videoWrap, {
                    [$style._aeroModal]: isAero,
                    [$style._p0]: withoutPadding,
                    [$style._fullscreen]: isFullscreen
                }]"
            >
                <!--<video ref="video"-->
                <!--:class="$style.video"-->
                <!--:poster="data.preview"-->
                <!--:src="data.src"-->
                <!--playsinline-->
                <!--muted-->
                <!--autoplay>-->
                <!--<source :src="data.src" type="video/youtube">-->
                <!--</video>-->
                <template v-for="link in links">
                    <iframe
                        v-if="!isVirtualLand"
                        :key="link"
                        :class="[$style.video, {
                            [$style._h100]: withoutPadding,
                            [$style._active]: activeLink === link,
                        }]"
                        :src="link"
                        allow="autoplay; encrypted-media"
                        v-bind="{loading: isLazy ? 'lazy' : null}"
                        allowfullscreen
                    >
                    </iframe>
                    <VirtualLandWrapper
                        v-else
                        :key="link"
                        :class="$style.landWrapper"
                        @change-fullscreen="onChangeFullscreen"
                    >
                        <iframe
                            :key="link"
                            :class="[$style.video, {[$style._h100]: withoutPadding}, {[$style._active]: activeLink === link}]"
                            :src="link"
                            allow="autoplay; encrypted-media"
                            v-bind="{loading: isLazy ? 'lazy' : null}"
                            allowfullscreen
                        >
                        </iframe>
                    </VirtualLandWrapper>

                </template>
            </div>
            <div
                v-if="links.length > 1"
                :class="$style.tabs"
            >
                <UiLink
                    v-for="(link, idx) in links"
                    :key="idx"
                    :class="[$style.tab, {[$style._active]: activeLink === link}, '_center']"
                    @click="activeLink = link"
                >
                    Камера {{ idx + 1 }}
                </UiLink>
            </div>
        </template>

        <div
            v-else
            :class="$style.hidden"
        >
            Уважаемые клиенты, в связи с плановыми техническими работами с 25 по 28 октября приостановлена работа камеры на территории строительства. Мы уведомим вас, как только работы будут выполнены.<br><br>Приносим извинения за предоставленные неудобства.
        </div>
    </ModalContainer>
</template>

<script>

import ModalContainer from './ModalContainer';
import UiLink from '@/components/ui/UiLink';
import VirtualLandWrapper from '~/components/common/VirtualLandWrapper.vue';

export default {
    name: 'VideoModal',

    components: {
        VirtualLandWrapper,
        UiLink,
        ModalContainer,
    },

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },

    data() {
        return {
            activeLink: '',
            isFullscreen: false,
        };
    },

    computed: {
        links() {
            return this.data?.src?.length ? this.data.src : [];
        },

        isAero() {
            return this.data?.aeroModal;
        },

        withoutPadding() {
            return this.data?.withoutPadding || false;
        },

        isLazy() {
            return this.data?.lazy || false;
        },

        isVirtualLand() {
            return this.data?.isVirtualLand || false;
        },
    },

    mounted() {
        this.activeLink = this.links?.length ? this.links[0] : '';
    },

    methods: {
        onChangeFullscreen(val) {
            this.isFullscreen = val;
        },
    },

    // mounted() {
    //     this.loadVideo();
    // },
    //
    // methods: {
    //     loadVideo() {
    //         if (!this.$refs.video) return false;
    //         this.$refs.video.oncanplay = () => {
    //             this.$refs.video.play();
    //         };
    //     },
    // }
};
</script>

<style lang="scss" module>
    .ModalContainer {
        width: $container-w;

        @include respond-to(sm) {
            width: 100vw;
        }

        &._aeroModal {
            width: 90%;

            @include respond-to(sm) {
                width: 100vw;
            }
        }

        &._fullscreen {
            width: 100%;

            @include respond-to(sm) {
                width: 100%;
            }
        }

        .landWrapper {
            width: 100%;
            height: 100%;
        }
    }

    .videoWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 62vh;

        @include respond-to(lg) {
            height: 70vh;
        }

        @include respond-to(sm) {
            flex-grow: 1;
            height: 70vh;
        }

        &._aeroModal {
            height: calc(90vh - 17rem);

            @include respond-to(lg) {
                height: 70vh;
            }

            @include respond-to(sm) {
                flex-grow: 1;
                height: 100%;
            }
        }

        &._p0 {
            height: calc(90vh - 4.2rem);

            @include respond-to(lg) {
                height: calc(70vh + 12.8rem);
            }

            @include respond-to(sm) {
                flex-grow: 1;
                height: calc(100% + 12.8rem);
            }

            @include respond-to(xs) {
                height: 100%;
            }
        }

        &._fullscreen {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .video {
        display: none;
        width: 100%;
        height: 100%;
        border: none;

        @include respond-to(sm) {
            height: 60vh;

            &._h100 {
                height: calc(60vh + 12.8rem);
            }
        }

        @include respond-to(xs) {
            &._h100 {
                height: 100%;
                border-radius: 0;
            }
        }

        &._active {
            display: block;
        }
    }

    .tabs {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 4.4rem;

        @include respond-to(sm) {
            position: absolute;
            bottom: 24px;
            margin-top: 0;
            margin-bottom: 16px;
            flex-direction: column;
        }
    }

    .tab {
        width: auto;
        min-width: 9.6rem;
        margin-right: 2.6rem;
        opacity: .5;
        transition: opacity .3s ease-out;

        @include respond-to(xs) {
            margin-right: 0;
            margin-bottom: 16px;
        }

        &:last-of-type {
            margin-right: 0;

            @include respond-to(xs) {
                margin-bottom: 0;
            }
        }

        @include hover {
            &:hover {
                opacity: 1;
            }
        }

        &._active {
            opacity: 1;
        }
    }

    .hidden {
        font-size: 18px;
        line-height: 1.5;
    }
</style>
