<template>
    <symbol
        id="icon-arrow-down"
        viewBox="0 0 18 11"
        fill="none"
    >
        <path
            d="M1 1L9 9L17 1"
            stroke-width="2"
            fill="currentColor"
        />
    </symbol>
    
</template>

<script>
export default {
    name: 'IconArrowDown',
};
</script>
