<template>
    <ValidationObserver
        v-slot="{ handleSubmit }"
        tag="div"
        :class="$style.OfficeForm"
    >
        <div :class="$style.formHeader">
            <div v-if="data.isHomeForm" :class="$style.title">
                Офис продаж
            </div>
            <div v-else :class="$style.title">
                Записаться на встречу
            </div>

            <a
                v-if="data.isHomeForm"
                href="https://yandex.ru/maps/213/moscow/?indoorLevel=1&ll=37.432218%2C55.824237&mode=poi&poi%5Bpoint%5D=37.432152%2C55.824357&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D48831688027&z=20"
                target="_blank"
                rel="nofollow"
                :class="$style.mapLink"
            >
                <div :class="$style.text">
                    На карте
                </div>

                <div :class="$style.pin">
                    <MapPin />
                </div>
            </a>

            <div
                v-if="data.isModal"
                :class="$style.formHeaderClose"
            >
                <UiCloseButton
                    color="primary"
                    @click="$emit('close')"
                />
            </div>

        </div>

        <div :class="$style.address">
            Москва,<br>
            Волоколамское шоссе, влд 73А<br>
            Пн-Вс с 9:00 до 21:00
        </div>

        <div
            v-if="data.isHomeForm && $device.isMobileOrTablet"
            v-lazy:background-image="'/images/pages/home/home-form-background.webp'"
            :class="$style.imageBlock"
        />

        <div
            v-show="isError || isSuccess"
            :class="$style.response"
        >
            <div :class="$style.responseTitle">
                {{ isError ? 'Произошла ошибка' : 'Спасибо за обращение' }}
            </div>

            <div :class="$style.responseText">
                {{ isError ? 'Повторите попытку или перезагрузите страницу' : 'Менеджер свяжется с вами в течение часа' }}
            </div>
        </div>

        <form
            ref="HomeOfficeForm"
            action=""
            :class="$style.formInputs"
            @submit.prevent="handleSubmit(onSubmit)"
        >
            <UiInput
                v-show="!isError && !isSuccess"
                v-model="name"
                field="имя"
                name="name"
                placeholder="Имя"
                rules="required|cyrillic|min:2|alpha_spaces"
                :class="$style.formInput"
            />

            <UiInput
                v-show="!isError && !isSuccess"
                v-model="phone"
                is-phone
                field="телефон"
                type="tel"
                name="phone"
                placeholder="Номер телефона"
                rules="required|phone"
                :class="$style.formInput"
            />

            <div :class="$style.buttonContainer">
                <UiButton
                    v-show="!isError"
                    :class="$style.btn"
                    :color="$device.isDesktop ? 'primary' : 'default'"
                    type="submit"
                    :disabled="isSuccess"
                    :size="data.isHomeForm ? '' : 'big'"
                >
                    Записаться на встречу
                </UiButton>

                <UiButton
                    v-show="isError"
                    :class="$style.btn"
                    :color="$device.isDesktop ? 'primary' : 'default'"
                    :size="data.isHomeForm ? '' : 'big'"
                    @click="tryAgain"
                >
                    Повторить попытку
                </UiButton>

                <UiButton
                    v-if="data.isHomeForm"
                    :class="$style.btn"
                    :color="$device.isDesktop ? 'bordered' : 'default'"
                    @click="openVideoModal"
                >
                    <template #iconBefore>
                        <IconOffice3D />
                    </template>

                    <template #default>
                        3D–тур по офису
                    </template>
                </UiButton>
            </div>
        </form>

        <div :class="$style.policy">
            <p v-if="$device.isDesktop">
                Нажимая кнопку «Записаться на встречу» вы подтверждаете свое согласие на обработку
                <a
                    :class="$style.policyLink"
                    href="/policy"
                    target="_blank"
                >персональных данных</a>.
            </p>

            <p v-if="$device.isMobileOrTablet">
                Нажимая кнопку вы подтверждаете согласие на обработку
                <a
                    :class="$style.policyLink"
                    href="/policy"
                    target="_blank"
                >персональных данных</a>.
            </p>
        </div>
    </ValidationObserver>
</template>

<script>
import UiInput from '~/components/ui/home/UiInput';
import UiButton from '~/components/ui/home/UiButton';
import UiCloseButton from '~/components/ui/home/UiCloseButton';

import MapPin from '~/assets/icons/hero-form-map-pin.svg?inline';
import IconOffice3D from '~/assets/icons/office-form-3d.svg?inline';

export default {
    name: 'HomeOffice',

    components: {
        UiButton,
        MapPin,
        UiInput,
        IconOffice3D,
        UiCloseButton,
    },

    props: {
        data: {
            type: Object,
            default: () => ({
                isHomeForm: true,
                isModal: false,
            }),
        },
    },

    data() {
        return {
            name: '',
            phone: '',
            isError: false,
            isSuccess: false,
        };
    },

    methods: {
        async onSubmit() {
            try {
                const payload = {
                    name: this.name,
                    phone: this.phone.replace(/[^+\d]+/g, ''),
                    request_type: 'office',
                };

                await this.$axios.$post('/api/request/', payload);

                window.mindbox('async', {
                    operation: 'website.mainPageOfficeModalSub',
                    data: {
                        customer: {
                            mobilePhone: this.phone,
                            firstName: this.name,
                        },
                    },
                    onSuccess: function() {
                        console.log('%c Mindbox: All good, заявка отправлена', 'background: #01630b; color: #add7ff');
                    },
                    onError: function(error) {
                        console.log('%c Mindbox: error! Ошибка в отправке заявки', 'background: #ffd400; color: #ff0000', error);
                    },
                });

                this.resetForm();
                this.isSuccess = true;
            } catch (e) {
                console.warn('[HomeOfficeForm] onSubmit:', e);
                this.isError = true;
            }
        },

        tryAgain() {
            this.isError = false;
        },

        resetForm() {
            this.$refs.HomeOfficeForm.reset();
        },

        openVideoModal() {
            this.$modal.open('VideoModal', {
                src: ['https://www.novostroy-m.ru/files/novos/virtual_tour/jiloy_rayon_alia_office/'],
            });
        },
    },
};
</script>

<style lang="scss" module>
    .OfficeForm {
        z-index: 2;
        display: flex;
        flex-direction: column;
        width: 51rem;
        padding: 2.8rem;
        background-color: $inversed;
        pointer-events: all;

        @include respond-to(sm) {
            width: 100%;
            padding: 2.4rem;
        }

        .formHeader {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 6rem;
            margin-top: .8rem;
            margin-bottom: 3.6rem;
            border-bottom: 1px solid $home-base-4;

            @include respond-to(sm) {
                height: 4.2rem;
                margin-top: initial;
                margin-bottom: 2.4rem;
            }
        }

        .formHeaderClose {
            margin-top: -.8rem;
        }

        .title {
            @include old-h4;

            color: $home-accent-1;
        }

        .mapLink {
            display: flex;
            margin-top: .2rem;

            .text {
                @include old-p5;

                margin-top: .2rem;
                color: $home-accent-2;
            }

            .pin {
                width: 1.6rem;
                height: 2rem;
                margin-left: 1.2rem;

                path {
                    fill: $home-accent-2;
                }
            }
        }

        .address {
            @include old-p5;

            margin-bottom: 11.6rem;
            color: $home-accent-5;

            @include respond-to(sm) {
                margin-bottom: 2.4rem;
            }
        }

        .imageBlock {
            width: 100%;
            height: 25.5rem;
            margin-bottom: 2.4rem;
            border-radius: .4rem;
            background-size: cover;

            @include respond-to(xs) {
                height: 13rem;
            }
        }

        .formInputs {
            width: 100%;
            margin-bottom: 24px;

            @include respond-to(sm) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin-bottom: 1.6rem;
                column-gap: 1.2rem;
            }

            @include respond-to(xs) {
                grid-template-columns: 1fr;
            }

            .formInput {
                margin-bottom: 1.6rem;

                @include respond-to(sm) {
                    margin-bottom: 2.4rem;
                }

                @include respond-to(xs) {
                    margin-bottom: 1.2rem;
                }
            }
        }

        .buttonContainer {
            display: flex;
            justify-content: space-between;
            gap: 1.6rem;
            margin-top: 5.6rem;

            .btn {
                flex-grow: 1;
            }

            @include respond-to(sm) {
                grid-gap: 1.2rem;
                margin-top: initial;
            }

            @include respond-to(xs) {
                margin-top: 1.2rem;
            }
        }

        .policy {
            @include old-p6;

            color: $home-accent-5;
        }

        .policyLink {
            color: $home-accent-2;
        }

        .response {
            width: 100%;
            padding: 1.6rem;
            border-radius: .4rem;
            background:
                linear-gradient(rgba(255, 255, 255, 0) 6.24%, rgba(255, 255, 255, .1083) 67%),
                linear-gradient(0deg, rgba(0, 0, 0, .43), rgba(0, 0, 0, .43)),
                linear-gradient(0deg, rgba(0, 0, 0, .56), rgba(0, 0, 0, .56)),
                #0d1322 url('/images/pages/home/home-office-bg.svg');
            background-blend-mode: normal, color, normal, normal;
            background-position: center right;
            background-repeat: no-repeat;
            background-size: cover;

            @include respond-to(sm) {
                margin-bottom: 2.4rem;
            }

            @include respond-to(sm) {
                margin-bottom: 1.2rem;
            }
        }

        .responseTitle {
            @include old-h4;

            margin-bottom: .8rem;
            color: $inversed;

            @include respond-to(sm) {
                @include old-p2;
            }
        }

        .responseText {
            @include old-p5;

            color: $inversed;
            opacity: .66;
        }
    }
</style>
