<template>
    <ModalContainer
        :class="$style.container"
        :visible="visible"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <div :class="$style.body">

            <transition name="fade">
                <TheFormSuccess
                    :close-btn="false"
                    :class="$style.formSuccess"
                    :title="data.title"
                    :description="data.description"
                />
            </transition>
        </div>
    </ModalContainer>
</template>

<script>
// components
import ModalContainer from './ModalContainer';
import TheFormSuccess from '../form/TheFormSuccess';

export default {
    name: 'SuccessModal',

    components: {
        TheFormSuccess,
        ModalContainer,
    },


    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },

    data() {
        return {
        };
    },
};
</script>

<style lang="scss" module>
    .container {
        width: 64rem;

        @include respond-to(xs) {
            width: 100vw;
        }
    }

    .body {
        position: relative;
        z-index: 1;
        width: 100%;

        @include respond-to(xs) {
            overflow-y: auto;
            height: 100%;
        }

        &._success {
            min-height: 54rem;

            @include respond-to(xs) {
                min-height: 100%;
            }

            & > form {
                visibility: hidden;
            }
        }
    }

    .policy {
        margin-top: 4rem;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.64;
        color: $base-gray;

        & > a {
            text-decoration: underline;
        }
    }

    .formSuccess {
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    .title {
        margin-bottom: 4rem;
    }

    .consultation {
        display: flex;
        flex-direction: column;

        &__radios {
            display: flex;
        }

        &__radio {
            margin-right: 3.6rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .timepicker {
        display: flex;
        align-items: center;
        margin-top: 2rem;

        &__wrap {
            width: 130px;
        }

        &__input {
            border: none !important; //stylelint-disable-line declaration-no-important
            text-align: center;
        }
    }
</style>
