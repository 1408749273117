import { getCookieFromString } from '../assets/js/utils';

export const state = () => ({
    similar_top: 0,
    favoritesParking: [],
    favorites_current: [],
});

export const actions = {
    setSimilarTop({ commit }, payload) {
        commit('SET_SIMILAR_TOP', payload);
    },

    readFavoritesFromCookies({ state, commit }, { req }) {
        try {
            const savedFavorites = getCookieFromString(req?.headers.cookie, 'favoritesParking', false);
            if (savedFavorites) {
                commit('ADD_FAVORITES', JSON.parse(decodeURIComponent(savedFavorites)));
            }
        } catch (e) {
            console.log('[Vuex] Parking/readFavoritesFromCookies: Не удалось загрузить', e);
        }
    },

    changeFavoriteState({ state, commit }, parkingId) {
        if (!state.favoritesParking.includes(parkingId)) {
            commit('ADD_TO_FAVORITES', parkingId);
        } else {
            commit('REMOVE_FROM_FAVORITES', parkingId);
        }

        const encodedFavorites = encodeURIComponent(JSON.stringify(state.favoritesParking));
        document.cookie = `favoritesParking=${encodedFavorites};path=/;`;
    },

    removeCurrentFavorites({ commit }) {
        commit('REMOVE_FAVORITES_CURRENT');
    },
};

export const mutations = {
    SET_SIMILAR_TOP(state, payload) {
        state.similar_top = payload;
    },

    ADD_FAVORITES(state, payload) {
        state.favoritesParking = payload;
    },

    ADD_TO_FAVORITES(state, payload) {
        state.favoritesParking.push(payload);
        state.favorites_current.push(payload);
    },

    REMOVE_FROM_FAVORITES(state, payload) {
        state.favoritesParking.splice(state.favoritesParking.indexOf(payload), 1);
        state.favorites_current.splice(state.favorites_current.indexOf(payload), 1);
    },

    REMOVE_FAVORITES_CURRENT(state) {
        state.favorites_current = [];
    },
};
