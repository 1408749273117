// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".burger_HQrmG{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:2.4rem;height:2.4rem;cursor:pointer}.burger_opened_RXC\\+p .burger__line_w9uzT:first-child{top:-20%}.burger_opened_RXC\\+p .burger__line_w9uzT:nth-child(2){background-color:#303236;-webkit-transform:rotate(45deg);transform:rotate(45deg)}.burger_opened_RXC\\+p .burger__line_w9uzT:nth-child(3){background-color:#303236;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}.burger_opened_RXC\\+p .burger__line_w9uzT:nth-child(4){bottom:-20%}.burger__wrap_3zWoU{position:relative;overflow:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:1.8rem;height:1.8rem}.burger__line_w9uzT{position:absolute;left:0;width:100%;height:.15rem;border-radius:.075rem;background-color:#000;-webkit-transition:.3s ease;transition:.3s ease}.burger__line_w9uzT:first-child{top:20%}.burger__line_w9uzT:nth-child(4){bottom:20%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"burger": "burger_HQrmG",
	"burger_opened": "burger_opened_RXC+p",
	"burger__line": "burger__line_w9uzT",
	"burger__wrap": "burger__wrap_3zWoU"
};
module.exports = ___CSS_LOADER_EXPORT___;
