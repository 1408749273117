<template>
    <svg id="icon-mail-pdf" viewBox="0 0 18 18">
        <path d="M9.42535 10.8563C9.2865 10.943 9.13045 10.9777 8.99159 10.9777C8.85288 10.9777 8.69668 10.943 8.55783 10.8563L1.65332 6.64062V12.2441C1.65332 13.4411 2.62482 14.4127 3.82184 14.4127H9.00026H14.1787C15.3756 14.4127 16.3472 13.4411 16.3472 12.2441V9.44245V6.64062L9.42535 10.8563Z"/>
        <path d="M14.1767 3.58691H8.99826H3.81983C2.79628 3.58691 1.92889 4.31552 1.7207 5.28702L9.00693 9.7282L16.2758 5.28702C16.0676 4.31552 15.2002 3.58691 14.1767 3.58691Z"/>
    </svg>
</template>

<script>
export default {
    name: 'IconMailPdf',
};
</script>
