<template>
    <div :class="[$style.Gallery, {[$style._flatGallery]: isFlatModal}]">
        <div :class="$style.slider">
            <div
                ref="slider"
                :class="$style.container"
                class="swiper-container"
            >
                <div
                    :class="$style.wrapper"
                    class="swiper-wrapper"
                >
                    <div
                        v-for="(slide, i) in slides"
                        :key="i"
                        :class="$style.slide"
                        class="swiper-slide"
                    >
                        <div :class="$style.image">
                            <ImageLazy
                                :origin="slide"
                                :absolute="false"
                                contain-image
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div :class="[$style.btn, $style._prev]">
                <SliderButton
                    ref="prev"
                    :color="isFlatModal ? '_base' : '_inverse'"
                    direction="_prev"
                />
            </div>

            <div :class="[$style.btn, $style._next]">
                <SliderButton
                    ref="next"
                    :color="isFlatModal ? '_base' : '_inverse'"
                    direction="_next"
                />
            </div>

        </div>
    </div>
</template>

<script>
// utils
import Swiper from 'swiper/js/swiper.min.js';

// components
import ImageLazy from './ImageLazy';
import SliderButton from './slider/SliderButton';

export default {
    name: 'SimpleGallery',

    components: {
        SliderButton,
        ImageLazy,
    },

    props: {
        slides: {
            type: Array,
            default: () => [],
        },

        isFlatModal: {
            type: Boolean,
            default: false,
        },

    },

    data() {
        return {
            sliderApp: undefined,
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.initApp();
            setTimeout(() => {
                if (this.sliderApp) {
                    this.sliderApp.update();
                }
            }, 600);
        });
    },

    beforeDestroy() {
        if (this.sliderApp) {
            this.sliderApp.destroy();
        }
    },

    methods: {

        initApp() {
            const options = {
                speed: 500,
                slideActiveClass: '_active',
                slidesPerView: 1,
                spaceBetween: 8,
                navigation: {
                    prevEl: this.$refs.prev.$el,
                    nextEl: this.$refs.next.$el,
                    hiddenClass: '_hidden',
                    disabledClass: '_disabled',
                },
            };

            this.sliderApp = new Swiper(this.$refs.slider, options);
        },
    },
};
</script>

<style lang="scss" module>
    .Gallery {
        @include aspect-ratio(1280, 680);

        @include respond-to(xs) {
            margin-right: -16px;
            margin-left: -16px;

            @include aspect-ratio(288, 200);
        }

        &._flatGallery {
            .container {
                margin: 0;
            }

            .btn {
                &._prev {
                    left: 3rem;
                }

                &._next {
                    right: 3rem;
                }
            }
        }
    }

    .slider {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
    }

    .container {
        width: 100%;
        height: 100%;
        margin: 0 5.5rem;

        @include respond-to(xs) {
            margin: 0;
        }
    }

    .slide {
        overflow: hidden;
        height: 100%;

        @include respond-to(xs) {
            width: 100%;
        }
    }

    .image {
        height: 100%;
    }

    .btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include respond-to(xs) {
            display: none;
        }

        &._next {
            right: 0;
        }

        &._prev {
            left: 0;
        }
    }
</style>
