// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".link_W-mbz{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:100%;padding:0 1.1rem;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.link_W-mbz:hover .link__label_oRm9G,.link_active_fs9P5 .link__label_oRm9G{color:#2f2f2f}.link_disabled_R0EzU{pointer-events:none}.link__icon_-OYmG{position:relative;width:1.6rem;min-width:1.6rem;height:1.6rem;margin-right:.8rem;color:#2f2f2f}.link__icon_-OYmG svg{position:absolute;top:50%;left:50%;display:block;width:1.6rem;height:auto;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.link__label_oRm9G{font-size:1.4rem;font-weight:700;line-height:1;letter-spacing:normal;text-transform:uppercase;font-size:1.2rem;color:#717171;-webkit-transition:.3s ease;transition:.3s ease}.link__gray_C6uAd{padding:1.3rem 2rem;background-color:#f3f3f3;color:#2f2f2f}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "link_W-mbz",
	"link__label": "link__label_oRm9G",
	"link_active": "link_active_fs9P5",
	"link_disabled": "link_disabled_R0EzU",
	"link__icon": "link__icon_-OYmG",
	"link__gray": "link__gray_C6uAd"
};
module.exports = ___CSS_LOADER_EXPORT___;
