<template>
    <ul :class="[$style.UiTabs, classList]">
        <slot></slot>
    </ul>
</template>

<script>
export default {
    name: 'UiTabs',
    props: {
        base: {
            type: Boolean,
            default: false,
        },

        inverse: {
            type: Boolean,
            default: false,
        },

        vertical: {
            type: Boolean,
            default: false,
        },

        huge: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return {
                _vertical: this.vertical,
            };
        },
    },
};
</script>

<style lang="scss" module>
    .UiTabs {
        display: flex;

        &:global(._vertical) {
            flex-direction: column;
        }
    }
</style>
