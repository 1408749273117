<template>
    <symbol id="icon-vimeo" viewBox="0 0 32 32">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.8 24.6a8.8 8.8 0 100-17.6 8.8 8.8 0 000 17.6zm-4.466-9.832l.928-.291c.258 1.502 1.114 4.71 2.475 5.523 1.805-.92 5.384-3.488 5.26-6.395-.124-2.907-2.63-1.211-3.867 0 .154.145.65.436 1.392.436-.206 1.066-.897 3.168-2.011 3.052-.258-.484-.774-1.773-.774-3.052 0-1.599-.618-1.89-1.546-1.453-.929.436-2.997 1.453-1.857 2.18z"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconVimeo',
};
</script>
