<template>
    <div
        id="app"
        :class="[
            $style.Default,
            {[$style._tablet]: !$device.isDesktop},
            {[$style._mobile]: $device.isMobile},
        ]"
    >
        <div
            :class="[
                $style.overlay,
                {[$style._shown]: isMenuOpened || isOverlayShown},
            ]"
            @click="onOveylayClick"
        />

        <client-only>
            <portal-target name="header-slot" />
        </client-only>

        <HeaderBanner
            :class="[
                $style.banner,
                {[$style._shown]: isBannerShown},
                {[$style._hidden]: isBannerHidden,
                 'HeaderBanner__hidden': isBannerHidden},
            ]"
        />

        <TheHeader
            v-model="isMenuOpened"
            data-scroll-lock-fill-gap
            :is-banner-hidden="isBannerHidden"
            :menu-banners="menuBanners"
            :class="[
                $style.header,
                {[$style._bottom]: isBannerShown},
                {[$style._top]: isBannerHidden},
            ]"
        />

        <main @click="closeModals">
            <nuxt />
        </main>

        <span ref="visibleWidgetPoint"></span>

        <NewFooter
            @open="openModal"
            @open-adaptive="openModalAdaptive"
        />

        <TheSprite />
        <TheModal />
        <TheModalNew />
        <TheOverlay />
        <TheModalMobileSlideUp />
        <UniversalModal />
        <FullScreenModal />
        <PageTransition />
        <TheWidgetButtons
            v-show="widgetOptions.isShow"
            ref="widgetButtons"
            :class="[$style.widget, widgetOptions.classList]"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { addHideMetrics, calcVhDestroy, calcVhInit, debounce } from '~/assets/js/utils.js';
import { checkMindbox, runMindboxOperations } from '~/assets/js/utils/mindbox/helpers';

import allMenuBanners from '~/queries/menu/allMenuBanners.graphql';

import HeaderBanner from '~/components/layout/header/HeaderBanner.vue';
import TheHeader from '~/components/layout/header/TheHeader.vue';
import NewFooter from '~/components/layout/NewFooter.vue';
import TheSprite from '~/components/layout/TheSprite.vue';
import TheModal from '~/components/layout/TheModal.vue';
import TheModalNew from '~/components/layout/TheModalNew.vue';
import TheOverlay from '~/components/layout/TheOverlay.vue';
import TheModalMobileSlideUp from '~/components/layout/TheModalMobileSlideUp.vue';
import UniversalModal from '~/components/common/modals/universal-modal/UniversalModal.vue';
import FullScreenModal from '~/components/common/modals/FullScreenModal.vue';
import PageTransition from '~/components/common/PageTransition.vue';
import TheWidgetButtons from '~/components/layout/TheWidgetButtons.vue';

import { MENU_BANNER_TYPES } from '~/assets/js/constants';

export default {
    name: 'DefaultNew',

    components: {
        HeaderBanner,
        TheHeader,
        NewFooter,
        TheSprite,
        TheModal,
        TheModalNew,
        TheOverlay,
        TheModalMobileSlideUp,
        UniversalModal,
        FullScreenModal,
        PageTransition,
        TheWidgetButtons,
    },

    data() {
        return {
            isBannerHidden: false, // При скролле скрываем баннер
            isBannerShown: false, // Первоначальное выезжание баннера
            isMenuOpened: false,
            isOverlayShown: false,
            debounceScroll: null,
            menuBanners: null,
            mindboxOperationsInterval: null,
        };
    },

    async fetch() {
        try {
            const [allMenuBannersRes] = await Promise.all([
                this.$axios.$post('/graphql/', {
                    query: allMenuBanners.loc.source.body,
                }),
            ]);

            const menuBanners = {};
            const menuTypes = MENU_BANNER_TYPES;
            const notFount =
                !allMenuBannersRes?.data ||
                !allMenuBannersRes?.data?.allMenuBanners ||
                !allMenuBannersRes?.data?.allMenuBanners?.edges ||
                !allMenuBannersRes?.data?.allMenuBanners?.edges?.length;

            if (notFount) {
                throw new Error('allMenuBannersRes not found:', allMenuBannersRes);
            }

            Object.keys(menuTypes).forEach(key => {
                allMenuBannersRes.data.allMenuBanners.edges.forEach(item => {
                    const { node } = item;

                    if (!menuBanners[key]) {
                        menuBanners[key] = [];
                    }

                    if (key === node.category && !node.disable) {
                        node.textSize = 'small';
                        node.color = 'default';
                        menuBanners[key].push(node);
                    }
                });
            });

            this.menuBanners = menuBanners;
        } catch (e) {
            console.log('[DefaultLayout / asyncFetch]', e);
        }
    },

    head() {
        return {
            script: [
                {
                    src: 'https://mod.calltouch.ru/init.js?id=fx3i2gmg',
                    async: true,
                },
                {
                    src: 'https://api.mindbox.ru/scripts/v1/tracker.js',
                    async: true,
                },
                {
                    innerHTML: addHideMetrics(`
                        mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
                        mindbox.queue = mindbox.queue || [];
                        mindbox('create');
                        `),
                },
                {
                    innerHTML: addHideMetrics(`
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('event', 'conversion', {
                                'allow_custom_scripts': true,
                                'send_to': 'DC-9737526/invmedia/2019e0+unique'
                            });
                        `),
                },
                {
                    innerHTML: addHideMetrics(`
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-KWBH4H8');
                        `),
                },
                {
                    src: '//smartcallback.ru/api/SmartCallBack.js?t=aMQNr9gGQRHsiNULVjS9',
                    async: true,
                },
            ],
        };
    },

    computed: {
        widgetOptions() {
            // скрываем на странице коммерции, вынес обращение к глобальным стилям
            // изменение позиционирования на страницы лота
            const routeName = this.$route.name;
            let isShow = true;
            let classList = '';

            if (routeName === 'commercial') {
                isShow = false;
            }

            if (routeName === 'flats-slug') {
                classList = this.$style._flatsPage;
            }

            return {
                isShow,
                classList,
            };
        },
    },

    mounted() {
        this.$root.$on('showLayoutOverlay', this.showOverlay);
        this.$root.$on('hideLayoutOverlay', this.hideOverlay);

        window.addEventListener('scroll', this.onScroll);
        this.isBannerShown = true;

        this.$nextTick(() => {
            calcVhInit();
            const touchSupported = 'ontouchstart' in window ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0;

            this.setTouchSupport(touchSupported);
        });

        this.debounceScroll = debounce(this.setWidgetVisibility, 100);
        window.addEventListener('scroll', this.debounceScroll);

        this.watchMindbox();
    },

    beforeDestroy() {
        this.$root.$off('showLayoutOverlay', this.showOverlay);
        this.$root.$off('hideLayoutOverlay', this.hideOverlay);

        calcVhDestroy();
        window.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('scroll', this.debounceScroll);
    },

    methods: {
        ...mapActions({
            setTouchSupport: 'device/changeIsTouch',
        }),

        showOverlay() {
            this.isOverlayShown = true;
        },

        hideOverlay() {
            this.isOverlayShown = false;
        },

        onOveylayClick() {
            this.$root.$emit('onLayoutOverlayClick');
        },

        onScroll() {
            if (window.scrollY >= 100) {
                this.isBannerHidden = true;
            } else {
                this.isBannerHidden = false;
            }
        },

        closeModals() {
            this.$refs.widgetButtons.closeButtons();
        },

        openModal(id) {
            if (id > 3) {
                const data = { title: 'расскажите, что вам понравилось', rating: id };

                this.$modal.open('SatisfactionModal', data);
            }

            if (id <= 3) {
                const data = { title: 'расскажите, что нам следует исправить, чтобы сайт стал еще удобнее', rating: id };

                this.$modal.open('SatisfactionModal', data);
            }
        },

        openModalAdaptive() {
            this.$modal.open('RateModalAdaptive', { title: 'Оценка' });
        },

        setWidgetVisibility() {
            const windowHeight = window.innerHeight;
            const widget = this.$refs.widgetButtons;
            const visibletPoint = this.$refs.visibleWidgetPoint;


            if (windowHeight && visibletPoint && widget) {
                const pointDOMRect = visibletPoint.getBoundingClientRect();
                const isScrolled = pointDOMRect.top - windowHeight < 0;


                if (isScrolled) {
                    widget.$el.classList.add(this.$style._hidden);
                } else {
                    widget.$el.classList.remove(this.$style._hidden);
                }
            }
        },

        watchMindbox() {
            this.mindboxOperationsInterval = setInterval(() => {
                if (checkMindbox()) {
                    runMindboxOperations();
                    clearInterval(this.mindboxOperationsInterval);
                }
            }, 1000);
        },
    },
};
</script>

<style lang="scss" module>
    .Default {
        position: relative;
        padding-top: calc($headerFullHeight + $headerBannerHeight);

        &._tablet {
            padding-top: calc($headerFullHeightTablet + $headerBannerHeight);
        }

        &._mobile {
            padding-top: calc($headerFullHeightMobile + $headerBannerHeight);
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 16;
            visibility: hidden;
            width: 100%;
            height: 100%;
            background-color: $home-base-1;
            opacity: 0;
            transition: 1s ease;

            &._shown {
                visibility: visible;
                opacity: .64;
            }
        }

        .banner {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 20;
            visibility: hidden;
            transform: translateY(-100%);
            transition: $homeTransition;

            &._shown {
                visibility: visible;
                transform: translateY(0);
            }

            &._hidden {
                transform: translateY(-100%);
            }
        }

        .header {
            position: fixed;
            top: $headerBannerHeight;
            left: 0;
            z-index: 19;
            transform: translateY(-$headerBannerHeight);
            transition: transform $transition;

            &._bottom {
                transform: translateY(0);
            }

            &._top {
                transform: translateY(-$headerBannerHeight);
            }
        }

        .widget {
            &._flatsPage {
                @include respond-to(sm) {
                    bottom: 8.6rem !important; //stylelint-disable-line declaration-no-important
                }
            }

            &._hidden {
                display: none;
            }
        }
    }
</style>
