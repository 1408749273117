// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu_ET00w{position:absolute;top:-48rem;overflow:hidden;width:100%;height:48rem;padding:2.8rem 0;background-color:#fff;-webkit-transition:1s ease;transition:1s ease}.menu__wrap_S8R9l{height:100%}.menu_opened_5SZxV{top:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "menu_ET00w",
	"menu__wrap": "menu__wrap_S8R9l",
	"menu_opened": "menu_opened_5SZxV"
};
module.exports = ___CSS_LOADER_EXPORT___;
