<template>
    <symbol id="icon-vk" viewBox="0 0 32 32">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 24.8a8.8 8.8 0 100-17.6 8.8 8.8 0 000 17.6zm4.243-10.15c.27-.454.425-.78.463-.98.077-.294-.038-.44-.348-.44h-1.14c-.23 0-.392.112-.482.34l-.174.38c-.116.266-.29.6-.521 1a6.5 6.5 0 01-.715 1.02c-.232.24-.412.36-.54.36-.117 0-.175-.114-.175-.34v-2.32c0-.294-.103-.44-.309-.44h-1.796a.333.333 0 00-.155.04.353.353 0 00-.096.1.253.253 0 00-.039.14c0 .053.02.113.058.18l.155.2a.866.866 0 01.154.28c.052.133.084.293.097.48v1.74c0 .172-.02.293-.058.36-.026.052-.077.08-.155.08-.206 0-.508-.32-.907-.96a10.799 10.799 0 01-.986-2.18c-.051-.174-.115-.294-.193-.36-.064-.068-.167-.1-.309-.1h-1.14c-.257 0-.386.112-.386.34 0 .133.046.36.136.68.09.32.277.786.56 1.4a13.3 13.3 0 001.1 1.82c.464.693 1.005 1.226 1.623 1.6.618.372 1.23.56 1.835.56.348 0 .573-.034.676-.1.103-.08.155-.2.155-.36a8.92 8.92 0 00-.02-.52c0-.44.013-.707.039-.8.026-.094.116-.14.27-.14.245 0 .625.252 1.14.76a11.965 11.965 0 01.676.76c.064.08.135.152.212.22a.816.816 0 00.213.14c.064.026.142.04.232.04h1.14c.347 0 .476-.168.386-.5a2.116 2.116 0 00-.213-.4 2.73 2.73 0 00-.328-.46 6.322 6.322 0 00-.387-.48c-.141-.16-.27-.3-.386-.42l-.309-.32-.116-.14c-.077-.134-.116-.227-.116-.28 0-.054.039-.14.116-.26.013-.014.129-.188.348-.52.219-.348.457-.748.715-1.2z"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconVk',
};
</script>
