// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VirtualLandWrapper_RpoBe{position:relative}.button_4GHOp{position:absolute;right:30px;bottom:30px;z-index:2;width:50px;height:50px;border:none;background-color:#131110;outline:none;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.button_4GHOp:hover{background-color:#2f2f2f}.icon_Z6JSY{position:absolute;top:50%;left:50%;display:block;width:42%;height:42%;color:#fff;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"VirtualLandWrapper": "VirtualLandWrapper_RpoBe",
	"button": "button_4GHOp",
	"icon": "icon_Z6JSY"
};
module.exports = ___CSS_LOADER_EXPORT___;
