<template>
    <ModalContainer
        ref="SatisfactionModal"
        is-custom
        :visible="visible"
        :class="$style.SatisfactionModal"
        @after-leave="$emit('after-leave')"
        @close="handleClose"
    >
        <div v-click-outside="handleClose" :class="$style.container">
            <div :class="[ $style.header, { 'flex-end': step === 2 } ]">

                <h2 v-if="step ===1" :class="[$style.title, 'h2']">{{ formTitle }}</h2>
                <UiCloseButton
                    color="light"
                    :class="$style.close"
                    @click="handleClose"
                />
            </div>
            <ValidationObserver
                v-if="step === 1"
                ref="observer"
                v-slot="{ handleSubmit }"
                tag="div"
                :class="$style.observer"
            >
                <div
                    v-if="data.description"
                    :class="$style.description"
                    v-html="data.description"
                />

                <form
                    ref="form"
                    action="#"
                    method="post"
                    @submit.prevent="handleSubmit(() => onFormSubmit($event))"
                >
                    <div :class="$style.inputsContainer">
                        <input
                            type="hidden"
                            name="category"
                            :value="'rating'"
                        >

                        <input
                            type="hidden"
                            name="rating"
                            :value="data.rating"
                        >

                        <UiInput
                            v-model="formData.name"
                            field="имя"
                            name="name"
                            placeholder="Имя"
                            rules="required|cyrillic|min:2|alpha_spaces"
                            :custom-messages="{ required: 'Поле обязательно для заполнения'}"
                            :class="$style.input"
                        />

                        <UiInput
                            v-model="formData.phone"
                            is-phone
                            field="телефон"
                            type="tel"
                            name="phone"
                            placeholder="Телефон"
                            :rules="`${isCallback ? 'required|' : ''}phone`"
                            :custom-messages="{ required: 'Поле обязательно для заполнения'}"
                            :class="$style.input"
                        />

                        <UiTextarea
                            v-model="formData.message"
                            field="Комментарий"
                            type="textarea"
                            name="text"
                            :placeholder="data.title"
                            rules="required|min:2"
                            :custom-messages="{ required: 'Поле обязательно для заполнения'}"
                            :class="$style.textarea"
                        />
                    </div>
                    <div :class="$style.bottom">
                        <UiButton
                            color="black"
                            type="submit"
                            size="big"
                            :loading="isLoading"
                            :class="$style.button"
                            @click="onNextStep"
                        >
                            {{ templateText.submitButton }}
                        </UiButton>
                        <FormPolicy
                            v-model="checkboxes"

                            :callback="true"
                            :class="$style.policy"
                            @checked="changeCallback"
                        />
                    </div>
                </form>
            </ValidationObserver>

            <div
                v-if="step === 2"
                :class="$style.rateForm"
            >
                <div :class="$style.secondTitle">{{ formSecondTitle }}</div>
                <div
                    :class="[$style.secondDescription, {[$style._error]: !isSent}]"
                    v-html="formMessage"
                >

                </div>
                <div
                    v-if="isSent"
                    :class="$style.timer"
                >
                    {{ templateText.timerText }}
                    <span :class="$style.timerHighlight">{{ timer }} сек</span>
                </div>
                <UiButton
                    v-if="!isSent"
                    :class="$style.tryBtn"
                    :color="'black'"
                    @click="onResetStep"
                >
                    {{ templateText.retryButton }}
                </UiButton>
            </div>

        </div>
    </ModalContainer>
</template>

<script>
// directives
import ClickOutside from 'vue-click-outside'; // components
import FormMixin from '~/assets/js/mixins/FormMixin';
import UiInput from '~/components/ui/home/UiInput.vue';
import UiButton from '~/components/ui/buttons/UiButton.vue';
import UiCloseButton from '~/components/ui/home/UiCloseButton';
import FormPolicy from '~/components/common/form/FormPolicy.vue';
import ModalContainer from '~/components/common/modals/ModalContainer';
import UiTextarea from '~/components/ui/home/UiTextarea.vue';

const DEFAULT_FORM_DATA = {
    name: '',
    phone: '',
    text: '',
};

const BAD_RATING = 3;

const TEMPLATE_SUBMIT_BUTTON_TEXT = 'Отправить';
const TEMPLATE_BAD_RATING_TEXT = 'что следует улучшить?';
const TEMPLATE_GOOD_RATING_TEXT = 'что вам понравилось?';
const TEMPLATE_RETRY_BUTTON_TEXT = 'Повторить попытку';
const TEMPLATE_TIMER_TEXT = 'Окно автоматически закроется через';
const TEMPLATE_RETRY_FORM_TEXT = 'Попробуйте повторить попытку снова или<br> перезагрузите страницу';
const TEMPLATE_SUCCESS_FORM_TEXT = 'Ваше мнение очень важно для наc,<br> благодаря ему мы становимся лучше';
const TEMPLATE_RETRY_FORM_SECOND_TITLE = 'Произошла ошибка';
const TEMPLATE_SUCCESS_FORM_SECOND_TITLE = 'Спасибо!';

export default {
    name: 'SatisfactionModal',
    components: {
        UiTextarea,
        UiInput,
        UiButton,
        UiCloseButton,
        FormPolicy,
        ModalContainer,
    },

    directives: {
        ClickOutside,
    },

    mixins: [FormMixin],

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },

    data() {
        return {
            formData: {
                name: '',
                phone: '',
                text: '',
            },

            checkboxes: {
                policy: false,
                offer: true,
                marketing: true,
            },

            templateBadRating: BAD_RATING,
            templateText: {
                submitButton: TEMPLATE_SUBMIT_BUTTON_TEXT,
                retryButton: TEMPLATE_RETRY_BUTTON_TEXT,
                goodRating: TEMPLATE_GOOD_RATING_TEXT,
                badRating: TEMPLATE_BAD_RATING_TEXT,
                timerText: TEMPLATE_TIMER_TEXT,
            },

            isSent: false,
            isVisible: true,

            timer: 10,
            step: 1,

            success: false,
            isLoading: false,
            isCallback: false,
        };
    },

    computed: {
        formTitle() {
            return this.data.rating <= this.templateBadRating ? this.templateText.badRating : this.templateText.goodRating;
        },

        formSecondTitle() {
            return !this.isSent ? TEMPLATE_RETRY_FORM_SECOND_TITLE : TEMPLATE_SUCCESS_FORM_SECOND_TITLE;
        },

        formMessage() {
            return !this.isSent ? TEMPLATE_RETRY_FORM_TEXT : TEMPLATE_SUCCESS_FORM_TEXT;
        },
    },

    watch: {
        timer() {
            if (this.timer === 0) {
                this.$emit('after-leave');
                this.$emit('close');
                this.isVisible = false;
            }
        },

        formSuccess(value) {
            if (value) {
                this.$refs.form.reset();
                this.isSent = value;

                if (this.isSent) {
                    this.step = 2;
                    this.handleTimerInterval();
                }
            }
        },

        formFailure(value) {
            this.isSent = !value;
            this.step = 2;
        },
    },

    methods: {

        resetForm() {
            this.$refs.SatisfactionForm.reset();
            this.formData = DEFAULT_FORM_DATA;
            this.$refs.observer.reset();
        },

        // openSuccessModal() {
        //     this.$modal.open('ModalHomeSaleFormSuccess', {
        //         title: 'Спасибо, заявка принята!',
        //         description: 'Вступайте в чат-бот и узнавайте новости первыми',
        //         type: 'main_promo',
        //         hasTelegramLink: true,
        //     });
        // },
        //
        // openFailureModal() {
        //     this.$modal.open('ModalHomeSaleFormSuccess', {
        //         title: 'Произошла ошибка',
        //         description: 'Попробуйте повторить попытку снова или перезагрузите страницу!',
        //         hasTelegramLink: false,
        //         isError: true,
        //     });
        // },

        onNextStep() {
            if (this.step === 0) {
                this.step += 1;
            }
        },

        handleTimerInterval() {
            if (this.step === 2 && this.isSent) {
                setInterval(() => {
                    this.timer -= 1;
                }, 1000);
            }
        },

        handleClose() {
            this.$emit('close');
        },

        changeCallback(value) {
            this.isCallback = value;
        },

        onResetStep() {
            this.step = 0;
        },

    },
};
</script>

<style lang="scss" module>
        .SatisfactionModal {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0;
            background: transparent;

            .header {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 2rem 2.8rem 1.3rem;
                //padding: 2.4rem 2.8rem;
            }

            .container {
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                max-width: 48rem;
                height: 100%;
                margin: 0 0 0 auto;
                background-color: $base-0;
            }

            .observer {
                height: calc(100% - 9.6rem);
                padding: 3.2rem 2.8rem 2.8rem;
            }

            .title {
                text-transform: uppercase;
                font-size: 2.4rem;
                font-weight: 700;
                line-height: 140%;
                color: $base-900;
            }

            .close {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 4rem;
                height: 4rem;
                margin-left: auto;
                padding: 1.2rem;
                color: $base-900;

                &:hover {
                    background-color: $base-100;
                }
            }

            .description {
                margin-bottom: auto;
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 124%;
                color: $base-400;
            }

            .form {
                display: flex;
                flex-direction: column;
                height: calc(100% - 9.6rem);
            }

            .inputsContainer {
                margin-bottom: 2.4rem;

                .input {
                    margin-bottom: 1.6rem;
                }
            }

            .bottom {
                align-self: flex-end;
                //margin-top: auto;
            }

            .button {
                z-index: 1;
                width: 100%;
                height: 5.6rem;
                //margin-top: 2.4rem;
                white-space: nowrap;

                @include respond-to(sm) {
                    grid-column: 1 / 3;
                }

                @include respond-to(xs) {
                    grid-column: auto;
                }
            }

            .policy {
                @include old-p6;

                margin: 2.4rem auto 0 auto;
                text-align: center;
                color: $home-accent-5;

                @include respond-to(sm) {
                    margin: 2.4rem auto 0 auto;
                }
            }

            @include respond-to(sm) {
                .container {
                    max-width: 61.2rem;
                }
            }

            @include respond-to(xxs) {
                .container {
                    width: 100vw;
                    margin: 0;
                }

                .header {
                    padding: 1.2rem 1.6rem;
                }

                .title {
                    font-size: 1.6rem;
                    font-weight: 700;
                    line-height: 132%;
                }

                .observer {
                    display: flex;
                    flex-direction: column;
                    height: calc(100% - 7rem);
                    padding: 3.2rem 1.6rem 2.9rem;
                }

                .form {
                    height: 100%;
                }

                .bottom {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }

        .textarea {
            textarea {
                height: 21.6rem;
                padding: 2rem;
                border: none;
                text-transform: uppercase;
                font-size: 1.2rem;
            }
        }

        .secondTitle {
            margin-top: 21.75rem;
            margin-bottom: 2.4rem;
            text-align: center;
            text-transform: uppercase;
            font-size: 2.4rem;
            font-weight: 600;
            line-height: 3.6rem;
            letter-spacing: -.04em;
            color: $base;

            @include respond-to(xs) {
                margin-top: calc((258.5 / 812) * 100vh);
                margin-bottom: calc((40 / 812) * 100vh);
                font-size: 2rem;
            }
        }

        .secondDescription {
            margin-bottom: 24.25rem;
            text-align: center;
            font-size: 1.4rem;
            line-height: 140%;
            color: $base-400;

            @include respond-to(xs) {
                margin-bottom: calc((289.5 / 812) * 100vh);
            }

            &._error {
                margin-bottom: 16.2rem;

                @include respond-to(xs) {
                    margin-bottom: 19rem;
                }
            }
        }

        .timer {
            text-align: center;
            font-size: 1.4rem;
            line-height: 152%;
            color: $base-400;

            .timerHighlight {
                color: $base;
            }
        }

        .rateForm {
            .tryBtn {
                width: 36rem;
                height: 5.6rem;
            }
        }
    </style>
