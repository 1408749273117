export const state = () => ({
    changing: false,
});

export const actions = {
    animate({ commit }) {
        commit('ANIMATE');
    },
};

export const mutations = {
    ANIMATE(state) {
        state.changing = !state.changing;
    },
};
