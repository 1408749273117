<template>
    <svg
        id="icon-new-info"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M7.99968 14.6666C4.31767 14.6666 1.33301 11.682 1.33301 7.99998C1.33301 4.31798 4.31767 1.33331 7.99968 1.33331C11.6817 1.33331 14.6663 4.31798 14.6663 7.99998C14.6663 11.682 11.6817 14.6666 7.99968 14.6666ZM7.99968 13.3333C9.41416 13.3333 10.7707 12.7714 11.7709 11.7712C12.7711 10.771 13.333 9.41447 13.333 7.99998C13.333 6.58549 12.7711 5.22894 11.7709 4.22874C10.7707 3.22855 9.41416 2.66665 7.99968 2.66665C6.58519 2.66665 5.22863 3.22855 4.22844 4.22874C3.22824 5.22894 2.66634 6.58549 2.66634 7.99998C2.66634 9.41447 3.22824 10.771 4.22844 11.7712C5.22863 12.7714 6.58519 13.3333 7.99968 13.3333ZM7.33301 9.99998H8.66634V11.3333H7.33301V9.99998ZM8.66634 8.90331V9.33331H7.33301V8.33331C7.33301 8.1565 7.40325 7.98693 7.52827 7.86191C7.6533 7.73689 7.82286 7.66665 7.99968 7.66665C8.18906 7.66664 8.37455 7.61284 8.53456 7.51153C8.69457 7.41022 8.82252 7.26556 8.90352 7.09437C8.98453 6.92318 9.01525 6.73251 8.99213 6.54454C8.96901 6.35657 8.89298 6.17904 8.7729 6.03259C8.65282 5.88614 8.49362 5.7768 8.31382 5.71729C8.13403 5.65779 7.94103 5.65056 7.75729 5.69645C7.57355 5.74234 7.40662 5.83947 7.27592 5.97652C7.14522 6.11358 7.05613 6.28493 7.01901 6.47065L5.71101 6.20865C5.7921 5.80337 5.97935 5.42689 6.25362 5.1177C6.5279 4.80851 6.87935 4.5777 7.27207 4.44886C7.66478 4.32002 8.08467 4.29777 8.4888 4.38439C8.89293 4.47101 9.26681 4.66339 9.57222 4.94186C9.87763 5.22034 10.1036 5.57492 10.2271 5.96936C10.3505 6.36379 10.3671 6.78394 10.2749 7.18685C10.1828 7.58976 9.98531 7.96098 9.70269 8.26256C9.42007 8.56413 9.06243 8.78525 8.66634 8.90331Z" fill="black"/>
    </svg>
</template>

<script>
export default {
    name: 'IconNewInfo',
};
</script>
