/**
 * Функция сравнения значения
 *
 * @param a
 * @param b
 * @returns {this is string[]|boolean}
 */
export function deepEqual(a, b) {
    if (a === b) {
        return true;
    }

    if (
        a instanceof Date &&
        b instanceof Date &&
        a.getTime() !== b.getTime()
    ) {
        // Если значения — Дата, сравните их как временные метки.
        return false;
    }

    if (a !== Object(a) || b !== Object(b)) {
        return false;
    }

    const props = Object.keys(a);

    if (props.length !== Object.keys(b).length) {
        return false;
    }

    return props.every(p => deepEqual(a[p], b[p]));
}

export function convertToUnit(str, unit = 'px') {
    // eslint-disable-next-line no-eq-null
    if (str == null || str === '') {
        return undefined;
    } else if (isNaN(Number(str))) {
        return String(str);
    } else {
        return `${Number(str)}${unit}`;
    }
}

export function kebabToCamelCase(str) {
    return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
}
