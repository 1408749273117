<template>
    <component
        :is="url ? 'n-link' : (link ? 'a' : (type === 'submit' ? 'button' : 'div'))"
        v-intersect="showButton"
        :to="url ? url : null"
        :href="link ? link : (url ? url : null)"
        :target="link ? '_blank' : null"
        :rel="link? 'nofollow' : null"
        :class="[$style.UiLink, color, {[$style._mounted]: isVisible}, {[$style._flatLink]: flatLink}]"
        :type="type ? type : null"
        class="btn-reset"
        @click="$emit('click')"
    >
        <div
            v-if="flatLink"
            :class="[$style.flatIconCnt, {[$style._mounted]: isVisible}]"
        >
            <svg>
                <use xlink:href="#icon-flats-link"/>
            </svg>
        </div>
        <div
            :class="[$style.wrap, {[$style._mounted]: isVisible}]"
            class="UiLink-wrap"
        >
            <span>
                <slot></slot>
            </span>
            <span v-if="!mobile">
                <slot></slot>
            </span>
        </div>
    </component>
</template>

<script>
export default {
    name: 'UiLink',

    props: {

        url: {
            type: [String, Object],
            default: '',
        },

        link: {
            type: String,
            default: '',
        },

        type: {
            type: String,
            default: '',
        },

        color: {
            type: String,
            default: '_base',
        },

        mobile: Boolean,

        flatLink: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isVisible: false,
        };
    },

    methods: {
        showButton(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.isVisible = true;
            }
        },
    },
};
</script>

<style lang="scss" module>
    .UiLink {
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        border-radius: 0;
        border: none;
        background-clip: padding-box;
        background-color: transparent;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1;
        transition: color .3s linear;
        -webkit-appearance: none;

        &._mounted {
            &:before {
                transform: skewX(-20deg) scaleX(1) translateX(0);
            }
        }

        &:before {
            content: "";
            display: block;
            width: .8rem;
            height: 1rem;
            margin-top: .1rem;
            margin-left: .2rem;
            background-color: currentColor;
            transform: skewX(-20deg) scaleX(1) translateX(-1.2rem);
            transform-origin: 0 50%;
            transition: all .3s linear;
        }

        &._flatLink {
            &:before {
                display: none;
            }
        }

        @include hover {
            &:hover,
            &:focus,
            &:active {
                &:before {
                    margin-right: 4px;
                    transform: skewX(-20deg) scaleX(1.5);
                }

                .wrap {
                    & > :nth-child(1) {
                        transform: translateY(-105%);
                    }

                    & > :nth-child(2) {
                        transform: translateY(0);
                    }
                }

                &:global(._base) {
                    color: $base-dark-gray;
                }

                &:global(._inverse) {
                    color: $base-light-gray;
                }
            }
        }

        &:global(._base) {
            color: $base;
        }

        &:global(._inverse) {
            color: $base-inverse;
        }

        &:global(._gray) {
            color: $base-gray;
        }

        &:global(._center) {
            justify-content: center;

            @include respond-to(sm) {
                justify-content: initial;
            }
        }
    }

    .flatIconCnt {
        width: 11px;
        height: 11px;
        transform: translateY(14px);
        transition: all .3s linear;

        &._mounted {
            transform: translateY(0);
        }

        svg {
            width: 100%;
            height: 100%;
            fill: currentColor;
        }
    }

    .wrap {
        position: relative;
        display: flex;
        margin-left: .8rem;
        transform: translateY(1.2rem);
        transition: all .3s linear;
        flex-direction: column;

        &._mounted {
            transform: translateY(0);
        }

        & > :nth-child(1),
        & > :nth-child(2) {
            transition: all .3s ease;
        }

        & > :nth-child(2) {
            position: absolute;
            transform: translateY(105%);
        }
    }
</style>
