<template>
    <symbol
        id="icon-chevron"
        viewBox="0 0 21 12"
        fill="none"
    >
        <path
            d="M19.5 1l-9 9-9-9"
            stroke="currentColor"
            stroke-width="2"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconChevron',
};
</script>
