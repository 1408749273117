<template>
    <symbol id="icon-project-logo-desc" viewBox="0 0 78 8">
        <path d="M4.3512 4.9553L1.63737 7.82145H0.325195L3.36706 4.6866L0.474306 1.96973H1.75666L4.38102 4.47761V1.96973H5.4248V4.47761L8.04915 1.96973H9.30169L6.40893 4.6866L9.42098 7.82145H8.13862L5.45462 4.9553V7.82145H4.41084V4.9553H4.3512Z"/>
        <path d="M15.266 1.99958H16.3396V7.85131H15.2958V3.25352L11.7469 7.82145H10.6733V1.96973H11.7171V6.53766L15.266 1.99958Z"/>
        <path d="M17.6819 7.01535C17.7415 7.0452 17.831 7.0452 17.9503 7.0452C18.1889 7.0452 18.4274 6.95563 18.5766 6.80635C18.8748 6.53765 18.9344 6.03011 18.9642 5.52256C18.9941 5.04487 19.0239 4.50746 19.0239 3.97006V1.96973H23.4674V7.82145H22.4236V2.77583H20.008V4.05963C20.008 4.62689 19.9782 5.40314 19.9186 5.91068C19.8887 6.32866 19.7993 6.98549 19.3519 7.43333C18.9642 7.82145 18.4871 7.88116 18.1292 7.88116C17.9503 7.88116 17.831 7.85131 17.6819 7.7916V7.01535Z"/>
        <path d="M30.8034 7.13422C30.0578 7.85076 29.1035 8.00003 28.3281 8.00003C27.5528 8.00003 26.6283 7.85076 25.8529 7.13422C25.1372 6.44754 24.988 5.61157 24.988 4.89504C24.988 4.20835 25.1372 3.34254 25.8529 2.65585C26.5984 1.93932 27.5528 1.79004 28.3281 1.79004C29.1035 1.79004 30.028 1.93932 30.8034 2.65585C31.5191 3.34254 31.6682 4.1785 31.6682 4.89504C31.698 5.61157 31.5489 6.44754 30.8034 7.13422ZM26.7476 3.19326C26.4195 3.52167 26.1213 4.08893 26.1213 4.92489C26.1213 5.76085 26.4195 6.32811 26.7476 6.65652C27.1352 7.01479 27.7019 7.22379 28.3579 7.22379C29.014 7.22379 29.5807 7.01479 29.9684 6.65652C30.2964 6.32811 30.5946 5.76085 30.5946 4.92489C30.5946 4.08893 30.2964 3.52167 29.9684 3.19326C29.5807 2.83499 29.014 2.626 28.3579 2.626C27.7019 2.626 27.1352 2.80513 26.7476 3.19326Z"/>
        <path d="M37.8419 1.99997H38.9155V7.85169H37.8419V3.25391L34.2931 7.82183H33.2195V1.97012H34.2633V6.53804L37.8419 1.99997ZM37.7823 0.35791C37.7823 0.746034 37.6332 0.984877 37.4542 1.13416C37.1262 1.46257 36.5596 1.49243 36.0824 1.49243C35.5754 1.49243 35.0386 1.46257 34.7106 1.13416C34.5615 0.984877 34.3825 0.746034 34.3825 0.35791H35.1579C35.1579 0.596756 35.2772 0.746034 35.3965 0.805746C35.486 0.865457 35.6649 0.925167 36.0824 0.925167C36.4999 0.925167 36.6789 0.865457 36.7683 0.805746C36.8876 0.746034 37.0069 0.596756 37.0069 0.35791H37.7823Z"/>
        <path d="M46.2812 1.99958C46.7285 1.99958 47.5039 1.99958 48.0705 2.41756C48.5775 2.80569 48.6968 3.31324 48.6968 3.79093C48.6968 4.17905 48.6371 4.6866 48.2494 5.07472C47.7424 5.58227 46.8776 5.61212 46.1917 5.61212H44.462V7.82145H43.4182V1.96973H46.2812V1.99958ZM44.462 4.80602H46.0724C46.6092 4.80602 46.9372 4.74631 47.146 4.62689C47.3548 4.50747 47.5933 4.23876 47.5933 3.76107C47.5933 3.28338 47.3548 3.07439 47.2056 2.98482C47.0267 2.8654 46.7285 2.77583 46.1022 2.77583H44.4918V4.80602H44.462Z"/>
        <path d="M50.0983 7.82145H48.9949L51.6491 1.96973H52.7525L55.4067 7.82145H54.3032L53.6173 6.32866H50.7544L50.0983 7.82145ZM53.3191 5.58227L52.1859 2.92511L51.0526 5.58227H53.3191Z"/>
        <path d="M61.2523 1.99997H62.3259V7.85169H61.2821V3.25391L57.7333 7.82183H56.6597V1.97012H57.7034V6.53804L61.2523 1.99997ZM61.1927 0.35791C61.1927 0.746034 61.0435 0.984877 60.8646 1.13416C60.5366 1.46257 59.9699 1.49243 59.4928 1.49243C58.9858 1.49243 58.449 1.46257 58.121 1.13416C57.9718 0.984877 57.7929 0.746034 57.7929 0.35791H58.5683C58.5683 0.596756 58.6876 0.746034 58.8069 0.805746C58.8963 0.865457 59.0753 0.925167 59.4928 0.925167C59.9103 0.925167 60.0892 0.865457 60.1787 0.805746C60.298 0.746034 60.4173 0.596756 60.4173 0.35791H61.1927Z"/>
        <path d="M69.6918 7.13422C68.9463 7.85076 67.9919 8.00003 67.2166 8.00003C66.4412 8.00003 65.5167 7.85076 64.7413 7.13422C64.0256 6.44754 63.8765 5.61157 63.8765 4.89504C63.8765 4.20835 64.0256 3.34254 64.7413 2.65585C65.4869 1.93932 66.4412 1.79004 67.2166 1.79004C67.9919 1.79004 68.9164 1.93932 69.6918 2.65585C70.4076 3.34254 70.5567 4.1785 70.5567 4.89504C70.5567 5.61157 70.4076 6.44754 69.6918 7.13422ZM65.6062 3.19326C65.2781 3.52167 64.9799 4.08893 64.9799 4.92489C64.9799 5.76085 65.2781 6.32811 65.6062 6.65652C65.9939 7.01479 66.5605 7.22379 67.2166 7.22379C67.8727 7.22379 68.4393 7.01479 68.827 6.65652C69.155 6.32811 69.4532 5.76085 69.4532 4.92489C69.4532 4.08893 69.155 3.52167 68.827 3.19326C68.4393 2.83499 67.8727 2.626 67.2166 2.626C66.5605 2.626 65.9939 2.80513 65.6062 3.19326Z"/>
        <path d="M73.1507 7.82145H72.1069V1.96973H73.1507V4.32833H76.5505V1.96973H77.5942V7.82145H76.5505V5.13444H73.1507V7.82145Z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconProjectLogoDesc',
};
</script>
