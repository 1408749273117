// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Subscribe_uelWx{position:relative;z-index:1;border-bottom:1px solid #e7e7e7;background-color:#fff;scroll-margin-top:7rem;scroll-margin-top:var(--headerHeight,7rem)}@media(max-width:991.98px){.Subscribe_uelWx{scroll-margin-top:6.4rem;scroll-margin-top:var(--headerHeightTablet,6.4rem)}}@media(max-width:767.98px){.Subscribe_uelWx{border-bottom:none;scroll-margin-top:5.4rem;scroll-margin-top:var(--headerHeightMobile,5.4rem)}}.SubscribeCaption_\\+o3Np{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;margin-right:15rem}@media(max-width:991.98px){.SubscribeCaption_\\+o3Np{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin-right:0}}.content_ov-pA{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;padding:5rem 0}@media(max-width:991.98px){.content_ov-pA{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:start;padding:4rem 2.4rem}}@media(max-width:767.98px){.content_ov-pA{padding:3.2rem 1.6rem}}.SubscribeCaption__image_un9G1{display:block;width:11.6rem;height:11.6rem;margin-top:-2rem;background-repeat:no-repeat;background-size:cover}@media(max-width:767.98px){.SubscribeCaption__image_un9G1{position:absolute;top:-1rem;right:0;width:9.3rem;height:9.3rem}}.title_Tz8b5{margin-bottom:1.6rem;text-transform:uppercase;font-size:2.4rem;font-weight:700;line-height:1.32}@media(max-width:767.98px){.title_Tz8b5{font-size:1.8rem}}.title__firstline_TbGOr{display:block}@media(max-width:991.98px){.title__firstline_TbGOr{display:inline-block}}@media(max-width:767.98px){.title__firstline_TbGOr{display:block}}.description_AtKHX{max-width:30rem;font-size:1.4rem;font-weight:300;line-height:124%;color:#717171}@media(max-width:991.98px){.description_AtKHX{max-width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Subscribe": "Subscribe_uelWx",
	"SubscribeCaption": "SubscribeCaption_+o3Np",
	"content": "content_ov-pA",
	"SubscribeCaption__image": "SubscribeCaption__image_un9G1",
	"title": "title_Tz8b5",
	"title__firstline": "title__firstline_TbGOr",
	"description": "description_AtKHX"
};
module.exports = ___CSS_LOADER_EXPORT___;
