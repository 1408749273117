<template>
    <div :class="[$style.Plan, ratioClass, {[$style._empty]: !plan}]">
        <img
            v-if="plan"
            :src="plan"
            :class="[$style.image, ratioClass]"
        >

        <div
            v-else
            :class="$style.placeholder"
        >
        </div>

        <div
            v-if="rooms.length"
            :class="$style.rooms"
        >

            <div
                v-for="room in rooms"
                :key="room.id"
                :class="$style.roomArea"
                :style="{left: room.point[0] + '%', top: room.point[1] + '%'}"
            >
                <span>{{ room.space }}</span>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    name: 'FlatPlan',

    props: {
        plan: {
            type: String,
            default: '',
        },

        rooms: {
            type: Array,
            default: () => [],
        },

        planWidth: {
            type: Number,
            default: 0,
        },

        planHeight: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        ratioClass() {
            return this.planWidth >= this.planHeight ? this.$style._horizontal : this.$style._vertical;
        },
    },
};
</script>

<style lang="scss" module>
    .Plan {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        //&._horizontal {
        //    width: 100%;
        //}

        //&._vertical {
        //    height: 100%;
        //}
    }

    .image {
        display: block;
        width: 100%;
        height: 100%;

        //&._horizontal {
        //    width: 100%;
        //}

        //&._vertical {
        //  height: 100%;
        //}

        //& > svg {
        //    width: 100%;
        //    height: 100%;
        //}
    }

    .placeholder {
        width: 100%;
        height: 100%;
        background-image: url("/assets/flats/plan-placeholder.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .rooms {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    .roomArea {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2px;
        font-size: 1rem;
        transform: translate(-50%, -50%);

        @include respond-to(xs) {
            font-size: 8px;
        }

        span:not(:first-child) {
            margin-top: 2px;
        }
    }
</style>
