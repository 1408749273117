<template>
    <symbol id="icon-list" viewBox="0 0 16 17">
        <path d="M4 9h8v3H4zM4 4h8v3H4z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconList',
};
</script>
