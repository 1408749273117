<template>
    <div
        :class="[$style.ImageLazy, previewClasses]"
        class="image-lazy"
        :style="previewStyles"
    >
        <div
            v-lazy:background-image="origin"
            :class="[$style.origin, originClasses]"
            :style="originStyles"
        >
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImageLazy',

    props: {
        preview: {
            type: String,
            default: '',
        },

        origin: {
            type: String,
            default: '',
        },

        absolute: {
            type: Boolean,
            default: true,
        },

        borderRadius: {
            type: [String, Number],
            default: '',
        },

        borderRadiusTop: {
            type: [String, Number],
            default: '',
        },

        positionImage: {
            type: String,
            default: '',
        },

        containImage: {
            type: Boolean,
            default: false,
        },

        coverImage: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        previewClasses() {
            return {
                [this.$style['_absolute']]: this.absolute,
                [this.$style['_contain']]: this.containImage,
                [this.$style['_cover']]: this.coverImage,
            };
        },

        previewStyles() {
            return {
                backgroundImage: `url(${this.preview})`,
                backgroundPosition: this.positionImage ? this.positionImage : null,
                borderRadius: this.borderRadius ? `${this.borderRadius}px` : null,
                borderTopLeftRadius: this.borderRadiusTop ? `${this.borderRadiusTop}px` : null,
                borderTopRightRadius: this.borderRadiusTop ? `${this.borderRadiusTop}px` : null,
            };
        },

        originClasses() {
            return {
                [this.$style['_contain']]: this.containImage,
            };
        },

        originStyles() {
            return {
                backgroundPosition: this.positionImage ? this.positionImage : null,
                borderRadius: this.borderRadius ? `${this.borderRadius}px` : null,
                borderTopLeftRadius: this.borderRadiusTop ? `${this.borderRadiusTop}px` : null,
                borderTopRightRadius: this.borderRadiusTop ? `${this.borderRadiusTop}px` : null,
            };
        },
    },
};
</script>

<style lang="scss" module>
    .ImageLazy {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;

        &._absolute {
            position: absolute;
        }

        &._contain {
            background-size: contain;
        }
    }

    .origin {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0;
        transform: translate3d(0, 0, 0);
        transition: opacity .26s ease;
        will-change: transform;

        &[lazy='loaded'] {
            opacity: 1;
        }

        &._contain {
            background-size: contain;
        }

        &._cover {
            background-size: cover;
        }
    }
</style>
