<template>
    <symbol id="icon-modal" viewBox="0 0 18 18">
        <path d="M9 14.6567H3.34315V8.99988L9 14.6567Z"/>
        <path d="M14.6569 9V3.34315H9.00001L14.6569 9Z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconModal',
};
</script>
