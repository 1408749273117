<template>
    <div>
        <nuxt></nuxt>
        <UniversalModal />
    </div>
</template>

<script>
import UniversalModal from '~/components/common/modals/universal-modal/UniversalModal.vue';

export default {
    name: 'EmptyLayout',
    components: { UniversalModal },
};
</script>
