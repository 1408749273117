<template>
    <p :class="[$style.inputBlock, classList]">
        <label
            :class="$style.label"
            :for="value"
            :style="labelStyle"
        >
            <input
                :id="value"
                ref="input"
                type="radio"
                :checked="checked"
                :value="inputValue"
                :class="$style.input"
                :name="name"
                :required="isRequire"
                @input="onInput"
                @change="onChange"
                @blur="onBlur"
                @focus="onFocus"
            >

            <span :class="$style.radioCheck">
            </span>

            <span :class="$style.labelText">
                {{ label }}
            </span>


            <template v-if="isError">
                <span
                    :class="$style.error"
                    v-html="error.messages[0]"
                ></span>
            </template>
            <slot></slot>
        </label>

    </p>
</template>

<script>

export default {
    name: 'UiRadio',

    props: {

        label: {
            type: String,
            default: null,
            require: true,
        },

        value: {
            type: [String, Number],
            default: null,
            require: true,
        },

        name: {
            type: String,
            default: '',
        },

        checked: {
            type: Boolean,
            default: false,
        },

        isRequire: {
            type: Boolean,
            default: false,
        },

        error: {
            type: Object,
            default: () => ({}),
        },

        labelStyle: {
            type: Object,
            default: () => ({}),
        },

        size: {
            type: String,
            default: 'small',
            validator: value => ['normal', 'small'].includes(value),
        },
    },

    data() {
        return {
            inputValue: '',
            isActive: false,
        };
    },

    computed: {
        isError() {
            return Boolean(this.error) && Boolean(this.error.messages) && Boolean(this.error.messages.length);
        },

        classList() {
            return {
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style._error]: this.isError,
                [this.$style._focus]: this.isActive,
            };
        },
    },

    watch: {
        value(val) {
            this.inputValue = val;
        },

        isFormSuccess() {
            if (this.isFormSuccess) {
                setTimeout(() => {
                    this.inputValue = '';
                    this.$refs.input.value = '';
                    this.isActive = false;
                }, 3000);
            }
        },
    },

    created() {
        if (this.value) {
            this.inputValue = this.value;
        }
    },

    methods: {
        onInput(evt) {
            if (!evt.target && !evt.target?.value) {
                return;
            }
            this.inputValue = evt.target.value;
            this.$emit('input', evt);
        },

        onChange(evt) {
            this.inputValue = evt.target.value;
            this.$emit('change', evt);
        },

        onBlur(evt) {
            if (!evt.target.value) {
                this.isActive = false;
            }
            this.$emit('blur', evt);
        },

        onFocus() {
            this.isActive = true;
        },
    },
};
</script>

<style lang="scss" module>
    .inputBlock {
        position: relative;
        font-family: inherit;
        font-size: 1.4rem;
        line-height: 1.88;
        color: $base;

        &._error {
            .input {
                border-color: $accent;
            }
        }

        &._small {
            .radioCheck {
                width: 1.2rem;
                min-width: 1.2rem;
                height: 1.2rem;
                margin-right: .8rem;

                &:after {
                    width: .8rem;
                    height: .8rem;
                }
            }
        }

        &._normal {
            .radioCheck {
                width: 2rem;
                min-width: 2rem;
                height: 2rem;
                margin-right: 1.2rem;

                &:after {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }
    }

    .radioCheck {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border: 1px solid $base-300;

        &:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            background-color: $base-dark-gray;
            opacity: 0;
            transition: .3s;
        }
    }

    .label {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .input {
        display: none;

        &:checked + .radioCheck:after {
            opacity: 1;
        }
    }

    .error {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 1.1rem;
        line-height: 1.6;
        color: $accent;
    }
</style>
