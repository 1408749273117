<template>
    <symbol id="icon-phone-calltouch" viewBox="0 0 21 21">
        <path d="M17.6614 13.137C16.9025 12.3877 15.955 12.3877 15.2008 13.137C14.6256 13.7074 14.0503 14.2778 13.4847 14.8579C13.33 15.0174 13.1995 15.0513 13.011 14.9449C12.6388 14.7419 12.2424 14.5775 11.8846 14.3552C10.2169 13.3062 8.81979 11.9575 7.58225 10.4396C6.96832 9.68553 6.42206 8.87825 6.04016 7.96946C5.96282 7.78576 5.97732 7.66491 6.12718 7.51506C6.70244 6.95915 7.2632 6.38874 7.82879 5.81833C8.61676 5.02555 8.61676 4.09742 7.82396 3.29981C7.37438 2.84542 6.92481 2.40069 6.47523 1.94629C6.01116 1.48223 5.55192 1.01333 5.08301 0.554099C4.32405 -0.185503 3.37656 -0.185503 2.62243 0.558933C2.04234 1.12934 1.48641 1.71426 0.896647 2.275C0.350391 2.79224 0.074845 3.4255 0.0168355 4.1651C-0.075013 5.36876 0.219869 6.50475 0.635604 7.61174C1.48641 9.90306 2.78196 11.9382 4.35305 13.8041C6.47524 16.3274 9.00832 18.3239 11.9716 19.7644C13.3059 20.4122 14.6884 20.9101 16.1918 20.9923C17.2263 21.0503 18.1255 20.7892 18.8458 19.9819C19.3389 19.4309 19.8948 18.9281 20.4169 18.4012C21.1903 17.6181 21.1952 16.6707 20.4265 15.8972C19.5081 14.9739 18.5847 14.0555 17.6614 13.137Z" />    
    </symbol>
</template>

<script>
export default {
    name: 'IconPhoneCallTouch',
};
</script>
