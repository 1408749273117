<template>
    <symbol id="icon-arrow-only-round" viewBox="0 0 20 20">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.95801 5.74969L16.5521 12.3438L15.35 13.5459L9.96585 8.16171L4.65519 13.5419L3.44531 12.3477L9.95801 5.74969Z"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconArrowOnlyRound',
};
</script>
