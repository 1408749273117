<template>
    <transition
        name="overlay-appear"
        @enter="handleOverlayEnter"
        @after-leave="handleOverlayLeave"
    >
        <div
            v-show="isOverlayVisible"
            :class="[$style.overlay, overlayClassList]"
            @click.self="onClose"
        >
            <component
                :is="component"
                :data="options"
                :visible="isContentVisible"
                @close="onClose"
                @after-leave="handleContentLeave"
            />
        </div>
    </transition>
</template>

<script>
import { lockBody, unlockBody } from '@/assets/js/utils';

// import TextModalNew from '@/components/common/modals/TextModalNew';
// import VideoModalNew from '@/components/common/modals/VideoModalNew';
// import FlatHeroModalNew from '@/components/common/modals/FlatHeroModalNew';
// import FlatsSortModal from '@/components/common/modals/FlatsSortModal';
// import FlatsFilterMobileModalNew from '@/components/common/modals/FlatsFilterMobileModalNew';
import ModalHomeAdvantagesGallery from '~/components/common/modals/home/ModalHomeAdvantagesGallery';
import ModalHomeAdvantagesVideo from '~/components/common/modals/home/ModalHomeAdvantagesVideo';
import ModalHomeAdvantages from '~/components/common/modals/home/ModalHomeAdvantages';
import ModalHomeAdvantagesCategory from '~/components/common/modals/home/ModalHomeAdvantagesCategory';
import ModalHomeAdvantagesGalleryAlbums from '~/components/common/modals/home/ModalHomeAdvantagesGalleryAlbums';
import UiModalTouch from '@/components/ui/UiModalTouch.vue';
import ModalOfficeForm from '@/components/common/modals/ModalOfficeForm';
import BookingModal from '@/components/common/modals/BookingModal';
import CommonForm from '@/components/common/modals/CommonForm';
import SendPdfModal from '@/components/common/modals/SendPdfModal';

export default {
    name: 'TheModalNew',

    components: {
        // TextModalNew,
        // VideoModalNew,
        // FlatHeroModalNew,
        // FlatsSortModal,
        // FlatsFilterMobileModalNew,
        ModalHomeAdvantagesGallery,
        ModalHomeAdvantagesVideo,
        ModalHomeAdvantages,
        ModalHomeAdvantagesCategory,
        ModalHomeAdvantagesGalleryAlbums,
        UiModalTouch,
        ModalOfficeForm,
        CommonForm,
        BookingModal,
        SendPdfModal,
    },

    data() {
        return {
            component: null,
            options: null,
            newComponent: null,
            newOptions: null,
            isOverlayVisible: false,
            isContentVisible: false,
        };
    },

    computed: {
        overlayClassList() {
            return this.data?.classList ? this.data.classList : '';
        },
    },

    watch: {
        $route() {
            this.onClose();
        },
    },

    beforeMount() {
        this.$modalNew.event.$on('open', this.onOpen);
        this.$modalNew.event.$on('close', this.onClose);
    },

    beforeDestroy() {
        this.$modalNew.event.$off('open', this.onOpen);
        this.$modalNew.event.$off('close', this.onClose);
    },

    methods: {
        handleOverlayEnter() {
            setTimeout(() => {
                this.isContentVisible = true;
            }, 500);
        },

        handleOverlayLeave() {
            unlockBody();
        },

        onOpen(component, options) {
            if (this.isOverlayVisible) {
                this.newComponent = component;
                this.newOptions = options || null;
                this.isContentVisible = false;
            } else {
                lockBody();
                this.isOverlayVisible = true;
                this.component = component;
                if (options) {
                    this.options = options;
                }
            }
        },

        onClose() {
            this.isContentVisible = false;
        },

        handleContentLeave() {
            if (this.newComponent) {
                this.component = this.newComponent;
                this.options = this.newOptions;
                this.newComponent = null;
                this.newOptions = null;

                this.$nextTick(() => {
                    this.isContentVisible = true;
                });
            } else {
                this.component = null;
                this.options = null;
                this.isOverlayVisible = false;
            }
        },
    },
};
</script>

<style lang="scss" module>
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 98;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: rgba(20, 21, 22, .48);
        // -webkit-overflow-scrolling: touch;

        @include respond-to(sm) {
            background-color: rgba(125, 125, 134, .7);
        }
    }
</style>
