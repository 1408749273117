<template>
    <symbol
        id="icon-chevron-small"
        viewBox="0 0 10 11"
        fill="none"
    >
        <path
            d="M10 8L5 3 0 8"
            stroke="currentColor"
            stroke-width="2"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconChevronSmall',
};
</script>
