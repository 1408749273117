<template>
    <component
        :is="is"
        v-bind="attrs"
        :class="[$style.HeaderLogo, {
            [$style._simplified]: simplified,
        }]"
    >
        <LogoIcon :class="$style.img" />
    </component>
</template>

<script>
import LogoIcon from '~/assets/icons/home/logos/logo-beam.svg?inline';

export default {
    name: 'HeaderLogo',

    components: {
        LogoIcon,
    },

    props: {
        simplified: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        is() {
            return this.$route.path === '/' ? 'a' : 'nuxt-link';
        },

        attrs() {
            return this.$route.path === '/' ? { href: '/' } : { to: '/' };
        },
    },
};
</script>

<style lang="scss" module>
    .HeaderLogo {
        position: relative;
        height: 4.8rem;

        @include respond-to(xs) {
            height: 4rem;
        }

        &._simplified {
            //
        }
    }

    .img {
        display: block;
        width: auto;
        height: 100%;
    }
</style>
