// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DigitalLayout_3zpLL{position:relative;min-height:100vh;min-height:100dvh}.main_PSBZG{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;width:100%}.overlay_hCsii{position:absolute;top:0;left:0;z-index:16;visibility:hidden;width:100%;height:100%;background-color:#18191c;opacity:0;-webkit-transition:1s ease;transition:1s ease}.overlay_hCsii._shown_z2CKp{visibility:visible;opacity:.64}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DigitalLayout": "DigitalLayout_3zpLL",
	"main": "main_PSBZG",
	"overlay": "overlay_hCsii",
	"_shown": "_shown_z2CKp"
};
module.exports = ___CSS_LOADER_EXPORT___;
