<template>
    <symbol id="icon-user" viewBox="0 0 16 16">
        <path d="M13 14.9988L3 14.9988C2.44772 14.9988 2 14.5511 2 13.9988C2 12.3447 3.0262 10.8642 4.57508 10.2836L4.8826 10.1684C6.8926 9.41505 9.1074 9.41505 11.1174 10.1684L11.4249 10.2836C12.9738 10.8642 14 12.3447 14 13.9988C14 14.5511 13.5523 14.9988 13 14.9988Z"/>
        <path d="M6.47536 8.21683C7.43235 8.70841 8.56765 8.70841 9.52464 8.21683C10.4153 7.75932 11.0534 6.92605 11.2629 5.94694L11.3188 5.68563C11.5331 4.68439 11.3042 3.6394 10.6911 2.81934L10.6013 2.69925C9.98814 1.87908 9.02406 1.39612 8 1.39612C6.97594 1.39612 6.01186 1.87908 5.39867 2.69926L5.30889 2.81934C4.69579 3.6394 4.46694 4.68439 4.68119 5.68563L4.7371 5.94694C4.94661 6.92605 5.58473 7.75932 6.47536 8.21683Z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconUser',
};
</script>
