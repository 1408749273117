// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuCard_YZfM9{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%;height:100%;padding:2.6rem;background-color:#f3f3f3;cursor:pointer;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.MenuCard_YZfM9:hover .icon_I4KoI,.MenuCard_YZfM9:hover .title_o4y37{color:#2f2f2f}.MenuCard_YZfM9:hover .iconImage_V5kYc{-webkit-filter:contrast(1);filter:contrast(1)}}.MenuCard_YZfM9 .link_1LZEb{position:absolute;right:2.4rem;bottom:2.4rem}.MenuCard_YZfM9._big_XUhA0 .title_o4y37{font-size:4rem}.MenuCard_YZfM9._normal_ul3op .title_o4y37{font-size:3.2rem}.MenuCard_YZfM9._small_g7cb2 .title_o4y37{font-size:2.8rem}.title_o4y37{text-transform:uppercase;font-weight:700;line-height:1.24;-webkit-transition:color .3s;transition:color .3s}.text_-gAyR,.title_o4y37{color:#717171}.text_-gAyR{margin-top:1.6rem;font-weight:300;line-height:1.32}.content_VcIKv{margin-top:4rem}.icon_I4KoI,.iconImage_V5kYc{width:3.2rem;height:3.2rem;color:#717171}.iconImage_V5kYc svg,.icon_I4KoI svg{display:block;width:100%;height:100%}.iconImage_V5kYc{overflow:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:3.2rem;height:3.2rem;background-position:50%;background-repeat:no-repeat;background-size:contain;-webkit-filter:contrast(0);filter:contrast(0);-webkit-transition:-webkit-filter .3s ease-in-out;transition:-webkit-filter .3s ease-in-out;transition:filter .3s ease-in-out;transition:filter .3s ease-in-out,-webkit-filter .3s ease-in-out}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MenuCard": "MenuCard_YZfM9",
	"icon": "icon_I4KoI",
	"title": "title_o4y37",
	"iconImage": "iconImage_V5kYc",
	"link": "link_1LZEb",
	"_big": "_big_XUhA0",
	"_normal": "_normal_ul3op",
	"_small": "_small_g7cb2",
	"text": "text_-gAyR",
	"content": "content_VcIKv"
};
module.exports = ___CSS_LOADER_EXPORT___;
