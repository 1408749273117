<template>
    <ModalContainer
        :class="[$style.container, $style.MortgageITModal]"
        :visible="visible"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <div :class="$style.body">
            <div :class="$style.header">
                <h3 :class="[$style.title, 'h2 _base']">
                    Ипотека для IT-специалистов от 4,3%
                </h3>
            </div>

            <div :class="$style.text">
                <div :class="$style.inner">
                    <div :class="[$style.topText, 'text']">
                        <p>Специалисты из IT-сферы могут оформить льготную ипотеку со ставкой от 4,3%* на покупку квартиры в жилом районе ÁLIA.</p>
                        <p>На сайте также доступна услуга бронирования.</p>
                    </div>

                    <div :class="[$style.topText, 'text']">
                        <p>Все подробности и условия предложения можно узнать в нашем офисе продаж.</p>
                        <p>&nbsp;</p>
                        <p>
                            *Ставка 4,3% годовых устанавливается по ипотечному кредиту (далее – кредит),
                            соответствующему постановлению Правительства РФ от 30.04.2022 № 805, на
                            приобретение у Застройщика квартиры по договору купли-продажи
                            <a
                                href="https://mail.yandex.ru/?uid=1130000057182435#6de2ea969c6ba35c_ftn1"
                                name="49ddc102e1fa1b79_ftnref1"
                                rel="noopener noreferrer nofollow"
                                target="_blank"
                            >[1]</a>,
                            договору участия в долевом строительстве (далее — ДДУ) при одновременном
                            соблюдении следующих условий: 1) заключение кредитного договора&nbsp; -
                            не позднее 31.12.2024 (вкл.);&nbsp;2) заемщики – граждане РФ, не более 4
                            человек&nbsp;в возрасте от 22 лет до 65 лет (вкл.) на момент полного
                            погашения кредита, хотя бы один из которых соответствует одновременно
                            следующим требованиям: 2.1) возраст на дату заключения кредитного договора –
                            от 22 до 44 лет (вкл.); 2.2) получает заработную плату на счет в АО «Банк ДОМ.РФ»
                            (далее – Банк) в последние 3 месяца; 2.3) основное место работы -&nbsp;&nbsp;организация,
                            осуществляющая деятельность в области информационных технологий, аккредитованной
                            Министерством цифрового развития, связи и массовых коммуникаций РФ
                            (далее – Организация);&nbsp;2.4)&nbsp;средняя заработная плата заемщика
                            в Организации в течение не менее чем 3 календарных месяцев, предшествовавших
                            дате заключения кредитного договора (с учетом налога на доходы физических лиц),&nbsp;-&nbsp;от
                            150 тыс. рублей при нахождении Организации в субъекте РФ с численностью населения
                            (по данным Росстата на начало календарного года, предшествовавшего году заключения кредитного договора)
                            от 1 млн. человек (далее – Субъекты № 1); от 100 тыс. рублей&nbsp; - для Организаций, находящихся в иных субъектах РФ (Субъекты № 2).
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Первоначальный взнос: от 15% - при приобретении квартиры. Сумма кредита:
                            от 500 тыс. руб. до 18 млн. руб. (вкл.) (недвижимость находится в&nbsp;Субъекте № 1).
                            Срок кредита: от 3 до 30 лет (с учетом п. 2) выше). Погашение: ежемесячными равными платежами.
                            Обеспечение: залог прав требований по ДДУ (на этапе строительства), залог кредитуемого объекта
                            недвижимости с оформлением закладной. При отсутствии личного страхования заемщика процентная
                            ставка увеличивается на 0,5 п.п. относительно ставки 4,3% на условиях кредитного договора.
                            С первого числа календарного месяца, следующего за месяцем, в котором: а) Банку стало
                            известно о факте истечения 3 месяцев после прекращения заемщиком работы в Организации,
                            и при отсутствии у Банка до истечения указанного срока сведений о трудоустройстве
                            заемщика в эту же или иную Организацию, или б)&nbsp;Банк уведомил заемщика об отказе
                            Банку в возмещении недополученных доходов по кредитному договору, заключенному с
                            данным заемщиком,&nbsp;ставка определяется как ключевая ставка Банка России
                            на дату заключения кредитного договора, увеличенная на 2,3 п.п. Расходы:
                            премия по договорам страхования, проведение оценки залога, регистрационные
                            действия, расчеты с контрагентом, иные расходы в связи с приобретением
                            недвижимости и оформлением ипотеки.
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Кредит предоставляется АО «Банк ДОМ.РФ», универсальная лицензия Банка
                            России № 2312 от 19.12.2018. Подробные условия предоставления, использования
                            и возврата кредитов размещены на официальном сайте банка:&nbsp;
                            <a
                                data-link-id="1"
                                href="http://domrfbank.ru/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >domrfbank.ru</a>.
                            Информация актуальна по состоянию на 12.05.2022. Предложение носит информационный характер и не является публичной офертой.
                        </p>

                        <p>&nbsp;</p>

                        <p>Застройщик – ООО «СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК «РЕЗИДЕНС». Проектная декларация на сайте наш.дом.рф.</p>

                        <p>&nbsp;</p>

                        <hr
                            align="left"
                            size="1"
                            width="33%"
                        >
                        <p>
                            <a
                                href="https://mail.yandex.ru/?uid=1130000057182435#49ddc102e1fa1b79_ftnref1"
                                name="6de2ea969c6ba35c_ftn1"
                                rel="noopener noreferrer nofollow"
                                target="_blank"
                            >[1]</a>&nbsp;
                            Продавец — Застройщик, являющийся первым собственником жилого помещения,
                            зарегистрировавший право собственности на недвижимое имущество на основании
                            разрешения на ввод объекта недвижимости в эксплуатацию (без указания ДДУ/договора
                            уступки прав требования по ДДУ в качестве правоустанавливающего документа).
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </ModalContainer>
</template>

<script>
import ModalContainer from './ModalContainer';

export default {
    name: 'MortgageITModal',

    components: {
        ModalContainer,
    },

    props: {
        visible: Boolean,
    },
};
</script>

<style lang="scss" module>
    .MortgageITModal {
        //
    }

    .container {
        width: 96rem;

        @include respond-to(sm) {
            width: 100%;
        }
    }

    .body {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @include respond-to(xs) {
            padding-bottom: 22px;
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 4rem;

        @include respond-to(xs) {
            margin-bottom: 0;
        }
    }

    .interactive {
        @include respond-to(xs) {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .title {
        width: 85%;
        margin-bottom: .8rem;
    }

    .video {
        width: 100%;
    }

    .gallery {
        @include respond-to(xs) {
            margin: auto 0;
        }
    }

    .text {
        position: relative;

        @include respond-to(xs) {
            overflow: hidden;
            height: 100%;
            padding: 32px 0;
        }
    }

    .inner {
        @include respond-to(xs) {
            position: relative;
            z-index: 1;
            overflow-y: auto;
            height: 100%;
        }

        .topText {
            p {
                padding-bottom: 0;
            }
        }
    }

    .topText {
        margin-bottom: 1.14em;

        a {
            text-decoration: underline;

            &:hover {
                opacity: .7;
            }
        }
    }

    .image {
        overflow: hidden;
        margin: 2.4rem 0;
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        @include aspect-ratio(746, 457);

        @include respond-to(xs) {
            @include aspect-ratio(288, 176);
        }
    }
</style>
