<template>
    <symbol id="icon-settings-horizontal" viewBox="0 0 16 16">
        <path
            d="M4.114 12a2 2 0 013.773 0h6.78v1.334h-6.78a2 2 0 01-3.773 0h-2.78V12h2.78zm4-4.666a2 2 0 013.773 0h2.78v1.333h-2.78a2 2 0 01-3.774 0h-6.78V7.334h6.78zm-4-4.667a2 2 0 013.773 0h6.78V4h-6.78a2 2 0 01-3.773 0h-2.78V2.667h2.78z"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconSettingsHorizontal',
};
</script>
