// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_GaWOA{width:96rem}@media(max-width:991.98px){.container_GaWOA{width:100%}}.container_GaWOA._learn_hClAU .header_\\+-eM2{margin-bottom:1.4rem}@media(max-width:767.98px){.container_GaWOA._learn_hClAU .header_\\+-eM2{margin-bottom:0}}.container_GaWOA._learn_hClAU .title_cAtoV{font-size:4rem}@media(max-width:767.98px){.container_GaWOA._learn_hClAU .title_cAtoV{text-align:center;font-size:2.7rem}}@media(max-width:767.98px){.container_GaWOA._learn_hClAU .text_q5KUz{padding:12px 0}}@media(max-width:767.98px){.container_GaWOA._learn_hClAU .topText_ROpXp{line-height:1.5}}.body_z11Ad{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;height:100%}@media(max-width:767.98px){.body_z11Ad{padding-bottom:22px}}.header_\\+-eM2{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-bottom:6.2rem}@media(max-width:767.98px){.header_\\+-eM2{margin-bottom:0}}.title_cAtoV{width:85%;font-size:2rem;font-weight:700}.text_q5KUz{position:relative}@media(max-width:767.98px){.text_q5KUz{overflow:hidden;height:100%;padding:32px 0}}@media(max-width:767.98px){.inner_q2Cv7{position:relative;z-index:1;overflow-y:auto;height:100%}}.topText_ROpXp a{text-decoration:underline}.topText_ROpXp a:hover{opacity:.7}.topText_ROpXp ul{padding-left:1.6rem}.topText_ROpXp li{list-style:disc}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_GaWOA",
	"_learn": "_learn_hClAU",
	"header": "header_+-eM2",
	"title": "title_cAtoV",
	"text": "text_q5KUz",
	"topText": "topText_ROpXp",
	"body": "body_z11Ad",
	"inner": "inner_q2Cv7"
};
module.exports = ___CSS_LOADER_EXPORT___;
