import { getCookieFromString } from '@/assets/js/utils';
import bookingInfoModalQuery from '@/queries/flat/bookingInfoModal.graphql';
import flatQuery from '@/queries/flat/flat.graphql';

export const state = () => ({
    similar_top: 0,
    isGalleryScrolled: false,
    favorites: [],
    favorites_current: [],
    bookingInfoModal: {},
    tabs: [],
    activeTab: null,
    flat: {},
});

export const actions = {
    setSimilarTop({ commit }, payload) {
        commit('SET_SIMILAR_TOP', payload);
    },

    checkIsGalleryScrolled({ commit }, payload) {
        commit('SET_IS_GALLERY_SCROLLED', payload);
    },

    readFavoritesFromCookies({ state, commit }, { req }) {
        try {
            const savedFavorites = getCookieFromString(req?.headers.cookie, 'favorites', false);
            if (savedFavorites) {
                commit('ADD_FAVORITES', JSON.parse(decodeURIComponent(savedFavorites)));
            }
        } catch (e) {
            console.log('[Vuex] flat/readFavoritesFromCookies: Не удалось загрузить', e);
        }
    },

    changeFavoriteState({ state, commit }, flatId) {
        if (!state.favorites.includes(flatId)) {
            commit('ADD_TO_FAVORITES', flatId);
        } else {
            commit('REMOVE_FROM_FAVORITES', flatId);
        }

        const encodedFavorites = encodeURIComponent(JSON.stringify(state.favorites));
        document.cookie = `favorites=${encodedFavorites};path=/;`;
    },

    removeCurrentFavorites({ commit }) {
        commit('REMOVE_FAVORITES_CURRENT');
    },

    async getContentBookingInfoModal({ commit }) {
        try {
            const bookingInfoModalRes = await this.$axios.$post('/graphql/', {
                query: bookingInfoModalQuery.loc.source.body,
            });
            commit('SET_CONTENT_BOOKING_MODAL', bookingInfoModalRes.data.bookingPopup);
        } catch (error) {
            console.warn(`Booking Info Modal/VUEX/error: ${error.response}`);
        }
    },

    setTabs({ commit }, value) {
        commit('SET_TABS', value);
    },

    setActiveTab({ commit }, value) {
        commit('SET_ACTIVE_TAB', value);
    },

    async fetchFlat(context, { slug, sale2 }) {
        try {
            const { data } = await this.$axios.$post('/graphql/', {
                query: flatQuery.loc.source.body,
                variables: {
                    slug,
                    sale2,
                },
            });

            return data.flat;
        } catch (e) {
            console.warn('[Vuex] flat/fetchFlat: ', e);
        }
    },

    setFlat({ commit }, payload) {
        commit('SET_FLAT', payload);
    },
};

export const mutations = {
    SET_SIMILAR_TOP(state, payload) {
        state.similar_top = payload;
    },

    SET_IS_GALLERY_SCROLLED(state, payload) {
        state.isGalleryScrolled = payload;
    },

    ADD_FAVORITES(state, payload) {
        state.favorites = payload;
    },

    ADD_TO_FAVORITES(state, payload) {
        state.favorites.push(payload);
        state.favorites_current.push(payload);
    },

    REMOVE_FROM_FAVORITES(state, payload) {
        state.favorites.splice(state.favorites.indexOf(payload), 1);
        state.favorites_current.splice(state.favorites_current.indexOf(payload), 1);
    },

    REMOVE_FAVORITES_CURRENT(state) {
        state.favorites_current = [];
    },

    SET_CONTENT_BOOKING_MODAL(state, payload) {
        state.bookingInfoModal = payload;
    },

    SET_TABS(state, payload) {
        state.tabs = payload;
    },

    SET_ACTIVE_TAB(state, payload) {
        state.activeTab = payload;
    },

    SET_FLAT(state, payload) {
        state.flat = payload;
    },
};
