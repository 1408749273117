<template>
    <transition
        :class="$style.ModalHomeAdvantagesCategory"
        name="content-slide-up"
        @after-enter="handleAfterEnter"
        @after-leave="$emit('after-leave')"
        @before-leave="handleBeforeLeave"
    >
        <div
            v-show="visible"
            :class="[$style.wrapper, wrapperClassList]"
        >
            <div :class="$style.header">
                <div :class="$style.headerBar" />

                <div :class="$style.headerTitle">
                    Преимущества
                </div>

                <div :class="$style.headerClose">
                    <UiCloseButton
                        color="light"
                        :is-rounded="false"
                        @click="$emit('close')"
                    />
                </div>
            </div>

            <div :class="$style.body">
                <div :class="$style.options">
                    <div
                        v-for="option in tabs"
                        :key="option.value"
                        :class="[$style.option, {[$style._active]: option.value === activeTab}]"
                        @click="handleClick(option.value)"
                        v-html="option.label"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import UiCloseButton from '~/components/ui/home/UiCloseButton';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'ModalHomeAdvantagesCategory',

    components: {
        UiCloseButton,
    },

    props: {

        visible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            active: false,
        };
    },

    computed: {
        ...mapState('home/gallery', [
            'tabs',
            'activeTab',
        ]),

        wrapperClassList() {
            return {
                [this.$style._active]: this.active,
            };
        },
    },

    methods: {
        handleClick(value) {
            this.setActiveTab(value);
            this.$emit('close');
        },

        ...mapActions('home/gallery', {
            setActiveTab: 'setActiveTab',
        }),

        handleAfterEnter() {
            this.active = true;
        },

        handleBeforeLeave() {
            this.active = false;
        },
    },
};
</script>

<style lang="scss" module>
    .ModalHomeAdvantagesCategory {
        //
    }

    .wrapper {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 100%;
        max-width: 584px;
        background-color: #fff;
        transform: translateX(-50%);
        transition: transform $homeTransition;

        &._active {
            transform: translateX(-50%) translateY(-100%);
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.8rem 2.4rem 2.4rem;
        border-bottom: 1px solid $home-base-4;
    }

    .headerBar {
        position: absolute;
        top: 8px;
        left: calc(50% - 20px);
        width: 40px;
        height: 4px;
        background-color: $home-base-4;
    }

    .headerTitle {
        padding-right: 1.6rem;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 132%;
        color: $base-900;
    }

    .headerClose {
        //
    }

    .body {
        padding: 1.2rem .8rem 2.4rem;
    }

    .options {
        //
    }

    .option {
        padding: 1.6rem 2rem;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 100%;
        color: $base;
        transition: all $homeTransition;

        &._active {
            background-color: $base-50;
        }

        &:not(:last-child) {
            margin-bottom: .8rem;
        }
    }

    @include respond-to(xxs) {
        .header {
            padding: 2.8rem 1.6rem 2.4rem;
        }
    }
</style>
