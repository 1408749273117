<template>
    <button
        :class="[$style.LikeButton, {[$style._active]: isPostLiked}]"
        class="btn-reset"
        type="button"
        @click.stop="addLikeToPost"
    >
        <svg :class="$style.icon">
            <use xlink:href="#icon-heart"/>
        </svg>
        <span>
            {{ formatLike }} понравилось
        </span>
    </button>
</template>

<script>
import createPostLike from '../../../queries/center/createPostLike.graphql';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'LikeButton',

    props: {
        postId: {
            type: String,
            default: '',
        },

        likesCount: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        ...mapState('center', [
            'likes_ids',
            'current_session_likes',
        ]),

        formatLike() {
            let count = this.likesCount;

            if (this.current_session_likes.includes(this.postId)) {
                count += 1;
            }

            if (count < 1000) {
                return count;
            }
            let result = Number(this.likesCount);
            let postfix;
            while (result > 1000) {
                result /= 1000;
                postfix = postfix ? 'М' : 'К';
            }

            return `${parseFloat(result.toFixed(1)).toLocaleString()}${postfix}`;
        },

        isPostLiked() {
            return this.likes_ids.includes(this.postId);
        },
    },

    methods: {
        ...mapActions('center', [
            'addLike',
            'setLikesCookies',
        ]),

        async addLikeToPost() {
            if (!this.isPostLiked) {
                this.addLike(this.postId);

                try {
                    await this.$axios.$post('/graphql/', {
                        query: createPostLike.loc.source.body,
                        variables: {
                            input: {
                                post: this.postId,
                            },
                        },
                    }, { progress: false });

                    this.setLikesCookies();
                } catch (e) {
                    console.log('[MediacenterPage / addLikeToPost]', e);
                }
            }
        },
    },
};
</script>

<style lang="scss" module>
    .LikeButton {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        font-size: 1.2rem;
        line-height: 1.88;
        color: $accent;
        transition: opacity .3s linear;

        @include hover {
            &:hover {
                opacity: .64;
            }
        }

        &._active {
            .icon {
                fill: $accent;

                @include heartbeat;
            }
        }
    }

    .icon {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 1rem;
        fill: transparent;
        stroke: $accent;
        stroke-width: 2;
        will-change: transform;
        transition: fill .3s linear;
    }
</style>
