<template>
    <symbol id="icon-mail" viewBox="0 0 14 14">
        <path d="M1.7513 1.75H12.2513C12.406 1.75 12.5544 1.81146 12.6638 1.92085C12.7732 2.03025 12.8346 2.17862 12.8346 2.33333V11.6667C12.8346 11.8214 12.7732 11.9697 12.6638 12.0791C12.5544 12.1885 12.406 12.25 12.2513 12.25H1.7513C1.59659 12.25 1.44822 12.1885 1.33882 12.0791C1.22943 11.9697 1.16797 11.8214 1.16797 11.6667V2.33333C1.16797 2.17862 1.22943 2.03025 1.33882 1.92085C1.44822 1.81146 1.59659 1.75 1.7513 1.75ZM7.0363 6.81508L3.29597 3.63883L2.54055 4.52783L7.04389 8.35158L11.4661 4.52492L10.7031 3.64233L7.03689 6.81508H7.0363Z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconMail',
};
</script>
