<template>
    <div :class="$style.live">
        <MenuRight :cards="cards" responsive />
    </div>
</template>

<script>
import MenuRight from './MenuRight';
import LiveIcon from '~/assets/icons/live.svg?inline';
import HotAirBallorIcon from '~/assets/icons/hot-air-ballor.svg?inline';
import FileIcon from '~/assets/icons/file.svg?inline';
import { BUILDING_STREAM_LINKS } from '~/assets/js/constants';

export default {
    name: 'MenuLive',

    components: {
        MenuRight,
    },

    data() {
        return {
            cards: [{
                title: 'Онлайн-камеры',
                callback: () => {
                    this.$modal.open('VideoModal', {
                        src: BUILDING_STREAM_LINKS,
                        aeroModal: false,
                        lazy: true,
                    });
                },

                iconComponent: LiveIcon,
            }, {
                title: 'Панорама проекта',
                callback: () => {
                    this.$modal.open('VideoModal', {
                        src: ['https://tour.virtualland.ru/svl/alia/'],
                        aeroModal: true,
                        lazy: false,
                        withoutPadding: true,
                        isVirtualLand: true,
                    });
                },

                iconComponent: HotAirBallorIcon,
            }, {
                title: 'Отчёт по ходу строительства',
                link: '/center/',
                iconComponent: FileIcon,
            }],
        };
    },
};
</script>

<style lang="scss" module>
    .live {
        width: 100%;
        height: 100%;
    }
</style>
