import Vue from 'vue';

const ModalPlugin = {
    install(Vue, options = {}) {
        Vue.prototype.$modal = {
            event: new Vue(),

            open(component, data) {
                this.event.$emit('open', component, data);
            },

            close() {
                this.event.$emit('close');
            },
        };
    },
};

const ModalPluginNew = {
    install(Vue, options = {}) {
        Vue.prototype.$modalNew = {
            event: new Vue(),

            open(component, data) {
                this.event.$emit('open', component, data);
            },

            close() {
                this.event.$emit('close');
            },
        };
    },
};

const Overlay = {
    install(Vue, options = {}) {
        Vue.prototype.$overlay = {
            event: new Vue(),

            open() {
                this.event.$emit('open');
            },

            close() {
                this.event.$emit('close');
            },
        };
    },
};

const ModalPluginSlideUp = {
    install(Vue, options = {}) {
        Vue.prototype.$modalMobileSlideUp = {
            event: new Vue(),

            open(data) {
                this.event.$emit('open', data);
            },

            close() {
                this.event.$emit('close');
            },
        };
    },
};

const universalModal = {
    install(Vue) {
        Vue.prototype.$universalModal = {
            event: new Vue(),

            isOpenModal: false,

            open(data) {
                this.event.$emit('open', data);
                this.isOpenModal = true;
            },

            close() {
                this.event.$emit('close');
                this.isOpenModal = false;
            },

            changeData(data) {
                this.event.$emit('changeData', data);
            },
        };
    },
};

const fullScreenModal = {
    install(Vue) {
        Vue.prototype.$fullScreenModal = {
            event: new Vue(),

            open(data) {
                this.event.$emit('open', data);
            },

            close() {
                this.event.$emit('close');
            },

            changeData(data) {
                this.event.$emit('changeData', data);
            },
        };
    },
};

Vue.use(ModalPlugin);
Vue.use(ModalPluginNew);
Vue.use(ModalPluginSlideUp);
Vue.use(Overlay);

// новая универсальная модалка
Vue.use(universalModal);

// новая модалка во весь экран
Vue.use(fullScreenModal);
