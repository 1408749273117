<template>
    <ModalContainer
        :class="[$style.container, containerClassList]"
        :visible="visible"
        :is-flat-modal="data.isFlatModal ? data.isFlatModal : false"
        :is-full-screen="data.isFullScreen ? data.isFullScreen : false"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >

        <div :class="[$style.body, {[$style._flatGallery]: data.isFlatModal}]">

            <div
                v-for="tab in data.tabsList"
                :key="tab.label"
                :class="[$style.item, {[$style._active]: activeTab === tab.value}]"
            >

                <!-- Если указана площадь комнат -->
                <template v-if="tab.rooms && tab.rooms.length">
                    <FlatPlan
                        :plan="tab.images[0]"
                        :plan-height="tab.planHeight"
                        :plan-width="tab.planWidth"
                        :rooms="tab.rooms"
                    />
                </template>

                <div v-else-if="tab?.virtualLand" :class="$style.virtualLandWrapper">
                    <iframe
                        :class="$style.virtualLand"
                        :src="tab.virtualLand"
                        allow="autoplay; encrypted-media"
                        allowfullscreen
                    >
                    </iframe>
                </div>

                <template v-else>
                    <!-- Если это не SVG, а ссылка на картинку -->
                    <template v-if="!tab.inlineSVG">

                        <!-- Если картинка одна -->
                        <div
                            v-if="tab.images.length === 1"
                            :class="$style.image"
                            :style="{backgroundImage: `url(${tab.images[0]})`}"
                        >
                        </div>

                        <!-- Если картинок много то нужен слайдер -->
                        <div
                            v-if="tab.images.length > 1"
                            :class="$style.image"
                        >
                            <SimpleGallery
                                :class="$style.gallery"
                                :slides="tab.images"
                                :is-flat-modal="data.isFlatModal ? data.isFlatModal : false"
                            />
                        </div>

                    </template>

                    <!-- Если это SVG -->
                    <div
                        v-else
                        :class="$style.planSet"
                        v-html="tab.images[0]"
                    >
                    </div>

                </template>
            </div>
            <div v-if="data.tabsList?.length > 1" :class="$style.wrapper">
                <UiTabs :class="$style.tabs">
                    <UiTab
                        v-for="(tab, idx) in data.tabsList"
                        :key="idx"
                        :active="activeTab === tab.value"
                        :class="$style.tab"
                        @click="onTabClick(tab.value)"
                    >
                        {{ tab.label }}
                    </UiTab>
                </UiTabs>
            </div>
        </div>
    </ModalContainer>
</template>

<script>
import ModalContainer from './ModalContainer';
import UiTabs from '../../ui/tabs/UiTabs';
import UiTab from '../../ui/tabs/UiTab';
import SimpleGallery from '../SimpleGallery';
import FlatPlan from '../../flats/detail/hero/FlatPlan';

export default {
    name: 'ImagesModal',

    components: {
        FlatPlan,
        SimpleGallery,
        UiTab,
        UiTabs,
        ModalContainer,
    },

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },

    data() {
        return {
            activeTab: '',
        };
    },

    computed: {
        containerClassList() {
            return {
                [this.$style._fullScreen]: this.data.isFullScreen ? this.data.isFullScreen : false,
            };
        },
    },

    created() {
        this.activeTab = this.data.activeTab;
    },

    methods: {
        onTabClick(value) {
            this.activeTab = value;
        },
    },
};
</script>

<style lang="scss" module>
    .container {
        width: 96rem;

        @include respond-to(sm) {
            width: 100%;
        }

        &._fullScreen {
            display: flex;
            flex-direction: column;

            .body {
                flex-grow: 1;

                .item {
                    flex-grow: 1;
                }

                @include respond-to(xxs) {
                    width: 100%;
                    margin-left: 0;
                }
            }

            .virtualLandWrapper {
                overflow: hidden;
                transform: translateZ(0);

                @include respond-to(sm) {
                    left: -3.2rem;
                    width: calc(100% + 6.4rem);
                    border-radius: 0;
                }

                @include respond-to(xxs) {
                    left: -3.2rem;
                    width: calc(100% + 6.4rem);
                }
            }
        }
    }

    .body {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @include respond-to(xxs) {
            width: calc(100% + 8rem);
            margin-left: -4rem;
        }

        &._flatGallery {
            .gallery {
                width: 100%;
            }
        }
    }

    .item {
        position: absolute;
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 54rem;
        opacity: 0;
        transition: .5s opacity ease-in-out;

        @include respond-to(xs) {
            height: 90%;
        }

        &._active {
            position: relative;
            visibility: visible;
            opacity: 1;
        }
    }

    .image {
        display: flex;
        justify-content: center;
        //align-items: center;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .planSet {
        text-align: center;

        & > svg {
            height: 54rem;

            @include respond-to(sm) {
                height: 40vh;
            }
        }
    }

    .gallery {
        width: 70rem;

        :global(.swiper-slide) {
            width: 100%;
        }

        @include respond-to(xs) {
            width: 100%;
        }
    }

    .wrapper {
        display: flex;
        padding-top: 3.2rem;

        @include scroll;

        @include respond-to(xs) {
            display: block;
            text-align: center;
        }
    }

    .tabs {
        justify-content: center;
        width: 100%;
        user-select: none;

        @include respond-to(xs) {
            display: inline-flex;
            justify-content: flex-start;
            width: auto;
            padding: 0 16px;
        }
    }

    .tab {
        margin-right: 4rem;

        @include respond-to(xs) {
            margin-right: 16px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .virtualLand {
        width: 100%;
        height: 100%;
        border: none;
    }

    .virtualLandWrapper {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;

        @include respond-to(xxs) {
            left: -2.4rem;
            width: calc(100% + 4.8rem);
        }
    }
</style>
