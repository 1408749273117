<template>
    <symbol id="icon-cross-calltouch" viewBox="0 0 20 20">
        <path d="M18.5826 0.417373C19.1391 0.97387 19.1391 1.87613 18.5826 2.43263L2.43263 18.5826C1.87613 19.1391 0.97387 19.1391 0.417373 18.5826C-0.139124 18.0261 -0.139124 17.1239 0.417373 16.5674L16.5674 0.417373C17.1239 -0.139124 18.0261 -0.139124 18.5826 0.417373Z" />
        <path d="M0.417373 0.417373C0.97387 -0.139124 1.87613 -0.139124 2.43263 0.417373L18.5826 16.5674C19.1391 17.1239 19.1391 18.0261 18.5826 18.5826C18.0261 19.1391 17.1239 19.1391 16.5674 18.5826L0.417373 2.43263C-0.139124 1.87613 -0.139124 0.97387 0.417373 0.417373Z" />
    </symbol>
</template>

<script>
export default {
    name: 'IconCrossCallTouch',
};
</script>
