var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{attrs:{"tag":"div","name":_vm.name,"mode":_vm.mode,"rules":_vm.rules,"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{class:[
            _vm.$style.inputBlock,
            {
                [_vm.$style._error]: (errors && errors.length || _vm.propError),
                [_vm.$style._focus]: _vm.isActive,
                [_vm.$style._checked]: _vm.checked,
                [_vm.$style._disabled]: _vm.disabled
            }]},[_c('label',{class:_vm.$style.label,style:(_vm.labelStyle),attrs:{"for":_vm.internalId},on:{"click":function($event){$event.preventDefault();return _vm.onClick.apply(null, arguments)}}},[_c('input',{ref:"input",class:_vm.$style.input,attrs:{"id":_vm.internalId,"type":"checkbox","name":_vm.name,"disabled":_vm.disabled},domProps:{"checked":_vm.checked,"value":_vm.inputValue},on:{"input":_vm.onInput,"change":_vm.onChange,"blur":_vm.onBlur,"focus":_vm.onFocus}}),_vm._v(" "),_c('span',{class:_vm.$style.radioCheck},[_c('svg',{class:_vm.$style.icon,attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"currentColor","viewBox":"0 0 8 8"}},[_c('path',{attrs:{"d":"M7.703 2.14a.77.77 0 1 0-1.252-.895L3.132 5.891 1.467 4.226A.77.77 0 0 0 .379 5.313L2.687 7.62a.77.77 0 0 0 1.17-.097L7.702 2.14Z"}})])]),_vm._v(" "),_vm._t("default",function(){return [_c('span',{class:_vm.$style.labelText},[_vm._v("\n                    "+_vm._s(_vm.label)+"\n                ")])]})],2),_vm._v(" "),_c('ExpandBlock',[(errors && errors.length || _vm.propError)?_c('div',{class:_vm.$style.error},[_vm._v("\n                "+_vm._s(errors[0] || _vm.propError)+"\n            ")]):_vm._e()])],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }