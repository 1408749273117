// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".overlay_Rit\\+u{position:fixed;top:0;left:0;z-index:99;overflow:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;height:100%;background-color:rgba(0,0,0,.64);-webkit-overflow-scrolling:touch}.overlay_Rit\\+u._opened_01SoI,.overlay_Rit\\+u.is-article{overflow-y:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "overlay_Rit+u",
	"_opened": "_opened_01SoI"
};
module.exports = ___CSS_LOADER_EXPORT___;
