// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SuccessIcon_3ErZ6{width:38rem;height:34.8rem;-webkit-transform-origin:center;transform-origin:center;-webkit-animation:appear_B6NfR .5s linear forwards;animation:appear_B6NfR .5s linear forwards}@media(max-width:767.98px){.SuccessIcon_3ErZ6{width:254px;height:232px}}@-webkit-keyframes appear_B6NfR{0%{-webkit-transform:scale(0);transform:scale(0)}50%{-webkit-transform:scale(1.2);transform:scale(1.2)}to{-webkit-transform:scale(1);transform:scale(1)}}@keyframes appear_B6NfR{0%{-webkit-transform:scale(0);transform:scale(0)}50%{-webkit-transform:scale(1.2);transform:scale(1.2)}to{-webkit-transform:scale(1);transform:scale(1)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SuccessIcon": "SuccessIcon_3ErZ6",
	"appear": "appear_B6NfR"
};
module.exports = ___CSS_LOADER_EXPORT___;
