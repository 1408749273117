<template>
    <symbol id="icon-flats-link" viewBox="0 0 11 11">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 0H10.5V10.75H9V1.5H1.5V10.75H0V0Z"
        />
        <path d="M4.125 2.75H6.125V4.75H4.125V2.75Z" />
        <path d="M4.125 5.75H6.125V7.75H4.125V5.75Z" />
        <path d="M4.125 8.75H6.125V10.75H4.125V8.75Z" />
    </symbol>
</template>

<script>
export default {
    name: 'IconFlatsLink',
};
</script>
