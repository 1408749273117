export function renderFraction(currentClass, totalClass) {
    return `<div class="swiper-fraction-wrapper">
                <span class="${currentClass}"></span>
                <span>/</span>
                <span class="${totalClass}"></span>
            </div>`;
}

export function formatFraction(num) {
    return num < 10 ? `0${num}` : num;
}
