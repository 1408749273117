<template>
    <symbol id="icon-youtube" viewBox="0 0 32 32">
        <path d="M25.543 10.498C26 12.28 26 16 26 16s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C21.896 24 16 24 16 24s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C6 19.72 6 16 6 16s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C10.107 8 16 8 16 8s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM14 19.5l6-3.5-6-3.5v7z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconYoutube',
};
</script>
