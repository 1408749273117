<template>
    <symbol id="icon-project" viewBox="0 0 24 32">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.627 6.055L12.753.68h4.438l-2.127 5.375h-4.438zM8.908 10.29h6.423l8.427 21.03h-4.52L12.12 13.486 8.09 23.584h6.995l-1.431 3.522H6.679L5 31.32H.481l8.427-21.03z"
        />
    </symbol>
</template>

<script>
export default {
    name: 'IconProject',
};
</script>
