<template>
    <div :class="[$style.UiTabs, classList]">
        <button
            v-for="tab in tabs"
            :key="tab.value"
            :class="[
                $style.tab,
                { [$style._active]: tab.value === value },
            ]"
            :style="styleList"
            type="button"
            @click="onClick(tab.value)"
        >
            {{ tab.label }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'UiTabs',

    props: {
        tabs: {
            type: Array,
            default: () => [],
        },

        value: {
            type: [String, Number],
            default: '',
        },

        tabWidth: {
            type: String,
            default: '',
        },

        autoSelect: {
            type: Boolean,
            default: false,
        },

        dark: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return {
                [this.$style['_dark']]: this.dark,
            };
        },

        styleList() {
            const styles = {};
            if (this.tabWidth) {
                styles.minWidth = this.tabWidth;
            }

            return styles;
        },
    },

    mounted() {
        if (this.autoSelect) {
            this.$emit('input', this.tabs[0].value);
        }
    },

    methods: {
        onClick(value) {
            if (value === this.value) {
                this.$emit('reset');
            } else {
                this.$emit('input', value);
            }
        },
    },
};
</script>

<style lang="scss" module>
    .UiTabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: .8rem;
        padding: .8rem;
        background-color: $base-50;

        &._dark {
            background-color: $home-base-2;

            .tab {
                color: $inversed;

                &:hover {
                    background-color: rgba(255, 255, 255, .16);
                    color: $inversed;
                }

                &._active,
                &:active {
                    background-color: #fff;
                    color: $base;
                }
            }
        }
    }

    .tab {
        position: relative;
        padding: 1.2rem 1.6rem 1.2rem;
        border: none;
        background-clip: padding-box;
        background-color: transparent;
        text-transform: uppercase;
        white-space: nowrap;
        font-size: 1.2rem;
        font-weight: 700;
        color: $home-base-3;
        opacity: .64;
        transition: all $homeTransition;
        cursor: pointer;

        &:hover {
            background-color: rgba(255, 255, 255, .66);
            color: $base;
            opacity: 1;
        }

        &._active,
        &:active {
            background-color: #fff;
            color: $base;
            opacity: 1;
        }

        &:focus {
            outline: none;
        }
    }
</style>
