// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_zBeRV{width:96rem}@media(max-width:991.98px){.container_zBeRV{width:100%}}.body_6I-ef{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;height:100%}@media(max-width:767.98px){.body_6I-ef{padding-bottom:22px}}.header_RaLP\\+{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-bottom:4rem}@media(max-width:767.98px){.header_RaLP\\+{margin-bottom:0}}@media(max-width:767.98px){.interactive_Fctc1{position:absolute;bottom:0;left:0}}.title_UjtVH{width:85%;margin-bottom:.8rem}.video_42L4C{width:100%}@media(max-width:767.98px){.gallery_w\\+QJ\\+{margin:auto 0}}.text_KGRX5{position:relative}@media(max-width:767.98px){.text_KGRX5{overflow:hidden;height:100%;padding:32px 0}}@media(max-width:767.98px){.inner_5chP-{position:relative;z-index:1;overflow-y:auto;height:100%}}.inner_5chP- .topText_M3z7W p{padding-bottom:0}.topText_M3z7W{margin-bottom:1.14em}.topText_M3z7W a{text-decoration:underline}.topText_M3z7W a:hover{opacity:.7}.image_o3ZPo{overflow:hidden;margin:2.4rem 0;-webkit-transform:translateZ(0);transform:translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden;position:relative}.image_o3ZPo:before{content:\"\";display:block;width:100%;padding-top:61.2600536193%}.image_o3ZPo>.aspect-content_r2DaJ{position:absolute;top:0;left:0;width:100%;height:100%}@media(max-width:767.98px){.image_o3ZPo{position:relative}.image_o3ZPo:before{content:\"\";display:block;width:100%;padding-top:61.1111111111%}.image_o3ZPo>.aspect-content_r2DaJ{position:absolute;top:0;left:0;width:100%;height:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_zBeRV",
	"body": "body_6I-ef",
	"header": "header_RaLP+",
	"interactive": "interactive_Fctc1",
	"title": "title_UjtVH",
	"video": "video_42L4C",
	"gallery": "gallery_w+QJ+",
	"text": "text_KGRX5",
	"inner": "inner_5chP-",
	"topText": "topText_M3z7W",
	"image": "image_o3ZPo",
	"aspect-content": "aspect-content_r2DaJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
