<template>
    <div :class="$style.MediaSlider">
        <div
            ref="slider"
            class="swiper-container"
            :class="$style.slider"
        >
            <div class="swiper-wrapper">
                <div
                    v-for="slide in slides"
                    :key="slide.id"
                    class="swiper-slide"
                    :class="$style.slide"
                >
                    <div
                        :class="['swiper-lazy', $style.image]"
                        :data-background="slide.fileDisplay"
                        :style="{ backgroundImage: `url('${slide.filePreview}')` }"
                    >
                    </div>
                </div>
            </div>

            <UiCircleButton
                v-show="slides.length > 1 && $device.isDesktop"
                ref="prev"
                :class="[$style.btn, $style._prev]"
                color="white"
            >
                <ArrowLeft />
            </UiCircleButton>

            <UiCircleButton
                v-show="slides.length > 1 && $device.isDesktop"
                ref="next"
                :class="[$style.btn, $style._next, $style._reverse]"
                color="white"
            >
                <ArrowLeft/>
            </UiCircleButton>

            <div
                v-if="slides.length > 1"
                ref="pagination"
                class="swiper-pagination"
                :class="[$style.pagination]"
            >
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import UiCircleButton from '~/components/ui/buttons/UiCircleButton.vue';

// icons
import ArrowLeft from '~/assets/icons/arrow-left.svg?inline';

export default {
    name: 'MediaSlider',
    components: { UiCircleButton, ArrowLeft },
    props: {
        slides: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            sliderApp: null,
        };
    },

    mounted() {
        if (this.sliderApp) {
            this.sliderApp.destroy();
        }

        setTimeout(() => {
            this.initSlider();
        }, 800);
    },

    beforeDestroy() {
        if (this.sliderApp) {
            this.sliderApp.destroy();
        }
    },

    methods: {
        initSlider() {
            const options = {
                slidesPerView: 1,
                allowTouchMove: true,
                loop: true,
                speed: 800,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 1,
                },

                navigation: {
                    prevEl: this.$refs.prev ? this.$refs.prev.$el : null,
                    nextEl: this.$refs.next ? this.$refs.next.$el : null,
                },

                pagination: {
                    el: this.$refs.pagination,
                    type: 'bullets',
                    bulletClass: this.$style.dot,
                    bulletActiveClass: this.$style._active,
                    clickable: false,
                },
            };

            this.sliderApp = new Swiper(this.$refs.slider, options);
        },
    },
};
</script>

<style lang="scss" module>
    .MediaSlider {
        position: relative;
    }

    .btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &._prev {
            left: 2.4rem;
        }

        &._next {
            right: 2.4rem;
        }

        &._reverse {
            transform: translateY(-50%) rotate(180deg);
        }
    }

    .slide,
    .slider {
        width: 100%;
        height: 100%;
    }

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(#000, .1);
        }
    }

    .slide {
        position: relative;

        @include aspect-ratio(756, 450);
    }

    .pagination {
        position: absolute;
        bottom: 2rem;
        left: 0;
        z-index: 2;
        width: 100%;
        text-align: center;
    }

    .dot {
        display: inline-block;
        width: .4rem;
        height: .4rem;
        margin-right: .4rem;
        margin-left: .4rem;
        border-radius: 50%;
        background-color: #fff;
        opacity: .44;
        transition: all .3s;

        &._active {
            opacity: 1;
        }
    }
</style>
