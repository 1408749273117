<template>
    <ModalContainer
        :class="$style.container"
        :visible="visible"
        @after-leave="$emit('after-leave')"
        @close="$emit('close')"
    >
        <InfraItemInfo
            :title="infra.title"
            :subtitle="infra.subTitle"
            :description="infra.description"
            :image="infra.imageDisplay"
            :preview="infra.imagePreview"
        />
    </ModalContainer>
</template>

<script>
import ModalContainer from './ModalContainer';
import InfraItemInfo from '../../play/InfraItemInfo';

export default {
    name: 'InfraModal',

    components: {
        InfraItemInfo,
        ModalContainer,
    },

    props: {
        data: {
            type: Object,
            default: () => ({}),
        },

        visible: Boolean,
    },

    computed: {
        infra() {
            return this.data.infra;
        },
    },
};
</script>

<style lang="scss" module>
    .container {
        width: 100%;
    }
</style>
