<template>
    <div :class="$style.DigitalHeader" class="DigitalHeader">
        <header
            :class="$style.header"
            class="header"
        >
            <div :class="$style.wrapper">
                <div
                    class="container-home"
                    :class="$style.container"
                >
                    <NuxtLink
                        to="/digital-maket/"
                        prefetch
                        :class="$style.logoLink"
                    >
                        <LogoIcon />
                    </NuxtLink>

                    <DigitalMenu
                        :navs="navs"
                        :class="$style.menu"
                    />
                </div>
            </div>

            <component
                :is="slot"
                v-if="slot"
            />
        </header>
    </div>
</template>

<script>
// components
import DigitalMenu from './DigitalMenu';

// icons
import LogoIcon from '~/assets/icons/home/logos/logo-beam.svg?inline';
import MapPinIcon from '~/assets/icons/map-pin.svg?inline';
import MapIcon from '~/assets/icons/map.svg?inline';
import BuildingsIcon from '~/assets/icons/buildings.svg?inline';
import AirBallorIcon from '~/assets/icons/hot-air-ballor.svg?inline';
import GridListIcon from '~/assets/icons/grid-list.svg?inline';
import ColumnsIcon from '~/assets/icons/columns.svg?inline';

export default {
    name: 'DigitalHeader',

    components: {
        LogoIcon,
        DigitalMenu,
    },

    data() {
        return {
            slot: null,
        };
    },

    computed: {
        navs() {
            return [
                {
                    to: '/digital-maket/?tab=genplan',
                    icon: MapPinIcon,
                    label: 'генплан',
                    image: '/images/pages/digital/navs/genplan.jpg',
                },

                {
                    to: '/digital-maket/project/',
                    icon: BuildingsIcon,
                    label: 'о проекте',
                    image: '/images/pages/digital/navs/project.jpg',
                },

                {
                    to: '/digital-maket/about/',
                    icon: ColumnsIcon,
                    label: 'о компании',
                    image: '/images/pages/digital/navs/about.jpg',
                },

                {
                    to: '/digital-maket/flats/',
                    icon: GridListIcon,
                    label: 'выбрать квартиру',
                    image: '/images/pages/digital/navs/flats.jpg',
                },

                {
                    to: '/digital-maket/virtual-tour/',
                    icon: AirBallorIcon,
                    label: 'панорамы',
                    image: '/images/pages/digital/navs/virtual-tour.jpg',
                },

                {
                    to: '/digital-maket/infrastructure/',
                    icon: MapIcon,
                    label: 'окружение',
                    image: '/images/pages/digital/navs/infrastructure.jpg',
                },
            ];
        },
    },

    mounted() {
        this.$root.$on('createHeaderSlot', this.createSlot);
        this.$root.$on('destroyHeaderSlot', this.destroySlot);
    },

    beforeDestroy() {
        this.$root.$off('createHeaderSlot', this.createSlot);
        this.$root.$off('destroyHeaderSlot', this.destroySlot);
    },

    methods: {
        createSlot(component) {
            this.slot = component;
        },

        destroySlot() {
            this.slot = null;
        },
    },
};
</script>

<style lang="scss" module>
    .DigitalHeader {
        position: relative;
        height: $digital-header-height;

        nav {
            display: flex;
            align-items: center;

            a {
                &:not(:first-child) {
                    margin-left: 1.8rem;
                }
            }
        }
    }

    .header {
        position: fixed;
        z-index: 20;
        width: 100%;
        height: $digital-header-height;
        background-color: $base-0;
    }

    .wrapper {
        position: relative;
        z-index: 2;
        height: 100%;
        border-bottom: .1rem solid $base-100;
        background-color: $base-0;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
    }

    .logoLink {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
