<template>
    <div :class="$style.GalleryWithThumbs">
        <div :class="$style.slider">
            <div
                ref="slider"
                :class="$style.container"
                class="swiper-container"
            >
                <div class="swiper-wrapper">
                    <div
                        v-for="(slide, i) in sortedSlides"
                        :key="i"
                        :class="$style.slide"
                        class="swiper-slide"
                    >
                        <div
                            :class="$style.image"
                            class="parallax-image"
                        >
                            <ImageLazy
                                :origin="slide.fileDisplay"
                                :preview="slide.filePreview"
                                :absolute="false"
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div :class="[$style.btn, $style._prev]">
                <SliderButton
                    ref="prev"
                    :class="$style.SliderButton"
                    direction="_prev"
                    color="_inverse"
                />
            </div>

            <div :class="[$style.btn, $style._next]">

                <SliderButton
                    ref="next"
                    :class="$style.SliderButton"
                    direction="_next"
                    color="_inverse"
                />
            </div>
        </div>
        <div :class="$style.footer">
            <!--thumbs-->
            <div
                ref="thumbs"
                :class="$style.thumbs"
                class="swiper-container"
            >
                <div
                    :class="$style.wrapper"
                    class="swiper-wrapper"
                >
                    <div
                        v-for="(slide, i) in sortedSlides"
                        :key="i"
                        :class="$style.thumbsSlide"
                        class="swiper-slide"
                    >
                        <ImageLazy
                            :origin="slide.fileDisplay"
                            :preview="slide.filePreview"
                            :absolute="false"
                        />
                    </div>
                </div>
            </div>
            <!--pagination-->
            <div
                ref="pagination"
                :class="$style.paginaton"
            >
            </div>
        </div>
    </div>
</template>

<script>
// utils
import Swiper from 'swiper/js/swiper.min.js';
import { renderFraction } from '../../assets/js/utils/sliderUtils';
import GalleryMixin from '@/assets/js/mixins/GalleryMixin';
// components
import ImageLazy from './ImageLazy';
import SliderButton from './slider/SliderButton';

export default {
    name: 'GalleryWithThumbs',

    components: {
        SliderButton,
        ImageLazy,
    },

    mixins: [GalleryMixin],

    props: {
        slides: {
            type: Array,
            default: () => [],
        },

    },

    data() {
        return {
            sliderApp: null,
            sliderThumbs: null,
        };
    },

    computed: {
        sortedSlides() {
            const sorted = this.slides.slice();
            return sorted.sort((a, b) => a.order - b.order);
        },
    },

    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.initApp();
            }, 500);
        });
    },

    beforeDestroy() {
        if (this.sliderApp) {
            this.sliderApp.destroy();
            this.sliderThumbs.destroy();
        }
    },

    methods: {
        initApp() {
            const thumbsOptions = {
                speed: 700,
                slidesPerView: 'auto',
                spaceBetween: 8,
                slideActiveClass: '_active',
                centeredSlides: true,
                slideToClickedSlide: true,
            };
            this.sliderThumbs = new Swiper(this.$refs.thumbs, thumbsOptions);

            const mainOptions = {
                speed: 1000,
                slidesPerView: 1,
                slideActiveClass: '_active',
                watchSlidesProgress: true,

                direction: 'horizontal',
                navigation: {
                    prevEl: this.$refs.prev.$el,
                    nextEl: this.$refs.next.$el,
                    hiddenClass: '_hidden',
                    disabledClass: '_disabled',
                },
            };

            if (this.$refs.pagination) {
                mainOptions.pagination = {
                    el: this.$refs.pagination,
                    type: 'fraction',
                    renderFraction: renderFraction,
                };
            }

            this.sliderApp = new Swiper(this.$refs.slider, mainOptions);

            this.sliderApp.controller.control = this.sliderThumbs;
            this.sliderThumbs.controller.control = this.sliderApp;

            this.sliderApp.on('progress', this.checkProgress);
            this.sliderApp.on('setTransition', this.setTransition);
        },
    },
};
</script>

<style lang="scss" module>
    .GalleryWithThumbs {
        //
    }

    .slider {
        @include aspect-ratio(745, 453);

        @include respond-to(xs) {
            @include aspect-ratio(288, 176);
        }
    }

    .container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .slide {
        overflow: hidden;
        width: 100%;
        height: 100%;
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .image {
        height: 100%;
    }

    .footer {
        position: relative;
        margin: 2rem -10.7rem 0;

        @include respond-to(xs) {
            margin: 16px -16px 0;
        }
    }

    .thumbs {
        padding: 0 10.7rem;

        @include respond-to(xs) {
            padding: 0 $mobile-padding;
        }
    }

    .thumbsSlide {
        overflow: hidden;
        width: 8.6rem;
        height: 5.2rem;
        transform: translate3d(0, 0, 0);
        cursor: pointer;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        @include overlay($opacity: 0);

        &:global(._active) {
            &:before {
                opacity: 1;
            }
        }
    }

    .btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include respond-to(xs) {
            display: none;
        }

        &._next {
            right: 3.2rem;
        }

        &._prev {
            left: 3.2rem;
        }
    }

    .paginaton {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.46;
        color: $base-inverse;
        transform: translate(-50%, -50%);
    }
</style>
