export const state = () => ({
    isFixedHeaderVisible: false,
    isFixedFooterVisible: false,
    isLoadingSimilarFlats: false,
});

export const actions = {
    setFixedHeaderVisibility({ commit }, payload) {
        commit('SET_FIXED_HEADER_VISIBILITY', payload);
    },

    setFixedFooterVisibility({ commit }, payload) {
        commit('SET_FIXED_FOOTER_VISIBILITY', payload);
    },

    setLoadingSimilarFlats({ commit }, payload) {
        commit('SET_LOADING_SIMILAR_FLATS', payload);
    },
};

export const mutations = {
    SET_FIXED_HEADER_VISIBILITY(state, payload) {
        state.isFixedHeaderVisible = payload;
    },

    SET_FIXED_FOOTER_VISIBILITY(state, payload) {
        state.isFixedFooterVisible = payload;
    },

    SET_LOADING_SIMILAR_FLATS(state, payload) {
        state.isLoadingSimilarFlats = payload;
    },
};
