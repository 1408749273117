// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_cJ-f6{width:64rem}@media(max-width:767.98px){.container_cJ-f6{width:100vw}}.body_u5OY9{position:relative;z-index:1;width:100%}@media(max-width:767.98px){.body_u5OY9{overflow-y:auto;height:100%}}.body_u5OY9._success_ZE0ay>form{visibility:hidden}.formSuccess_QEV7u{position:absolute;top:0;left:0;z-index:2;width:100%;height:100%}.title_EBPEF{margin-bottom:4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_cJ-f6",
	"body": "body_u5OY9",
	"_success": "_success_ZE0ay",
	"formSuccess": "formSuccess_QEV7u",
	"title": "title_EBPEF"
};
module.exports = ___CSS_LOADER_EXPORT___;
