<template>
    <button :class="[$style.SliderButton, direction, color, 'btn-reset']">
        <svg>
            <use xlink:href="#icon-arrow-next"/>
        </svg>
    </button>
</template>

<script>
export default {
    name: 'SliderButton',

    props: {
        direction: {
            type: String,
            default: '_next',
        },

        color: {
            type: String,
            default: '_base',
        },
    },
};
</script>

<style lang="scss" module>
    .SliderButton {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.8rem;
        height: 2.8rem;
        will-change: transform, opacity;
        transition: opacity .3s ease-in, transform .3s linear;

        @include respond-to(xs) {
            width: 18px;
            height: 18px;
        }

        @include hover {
            &:focus,
            &:active {
                outline: none;
            }

            &:hover {
                transform: scale(1.2);
            }
        }

        &:global(._disabled) {
            opacity: .3;
            pointer-events: none;
        }

        svg {
            display: block;
            width: 100%;
            height: 1.9rem;
            transition: transform .3s linear;
        }

        &:global(._inverse) {
            svg {
                fill: $base-inverse;
            }
        }

        &:global(._base) {
            svg {
                fill: $base;
            }
        }

        &:global(._prev) {
            svg {
                transform: rotate(180deg);
            }
        }
    }
</style>
