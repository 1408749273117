<template>
    <div>
        <nuxt></nuxt>
    </div>
</template>

<script>
export default {
    head() {
        return {
            __dangerouslyDisableSanitizers: ['script'],
            script: [
                {
                    src: 'https://api.mindbox.ru/scripts/v1/tracker.js',
                },
                {
                    innerHTML: `
                        mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
                        mindbox.queue = mindbox.queue || [];
                        mindbox('create');
                        `,
                },
            ],
        };
    },
};
</script>

<style>
    body {
        -webkit-print-color-adjust: exact;
    }
</style>
