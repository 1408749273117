<template>
    <symbol id="icon-pen" viewBox="0 0 24 24">
        <path d="M12.9 6.85802L17.142 11.101L7.242 21H3V16.757L12.9 6.85702V6.85802ZM14.314 5.44402L16.435 3.32202C16.6225 3.13455 16.8768 3.02924 17.142 3.02924C17.4072 3.02924 17.6615 3.13455 17.849 3.32202L20.678 6.15102C20.8655 6.33855 20.9708 6.59286 20.9708 6.85802C20.9708 7.12319 20.8655 7.37749 20.678 7.56502L18.556 9.68602L14.314 5.44402Z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconPen',
};
</script>
