// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menuRight_2rdSV{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:stretch;-ms-flex-align:stretch;align-items:stretch;height:100%}.menuRight_2rdSV._responsive_1qgTw .card_C7sEJ{-webkit-box-flex:1;-ms-flex:1;flex:1;width:auto}.card_C7sEJ{width:32rem}.card_C7sEJ:not(:first-child){margin-left:2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuRight": "menuRight_2rdSV",
	"_responsive": "_responsive_1qgTw",
	"card": "card_C7sEJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
