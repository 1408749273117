import officePage from '../queries/pages/officePage.graphql';

export const state = () => ({
    projectData: {},
});

export const getters = {
    getPhone: state => state.projectData.phone,
};

export const actions = {
    async loadData({ commit }) {
        try {
            const { data } = await this.$axios.$post('/graphql/', {
                query: officePage.loc.source.body,
            });

            if (!data) {
                return;
            }

            commit('SET_PROJECT_DATA', data.officePage);
        } catch (e) {
            console.log('[Vuex] project/loadData: Не удалось загрузить', e);
        }
    },
};

export const mutations = {
    SET_PROJECT_DATA(state, payload) {
        state.projectData = payload;
    },
};
