<template>
    <symbol id="icon-kindergarten" viewBox="0 0 16 16">
        <path d="M2.95538 11.3423L2.29965 11.1658C1.95497 11.0649 1.20509 11.0296 0.96297 11.6954C0.853682 11.9812 0.796516 12.6487 1.44216 13.0321C2.41735 13.4104 4.57433 14.1002 5.3261 14.3066C6.24198 14.5581 8.95226 14.6719 10.2693 14.394C11.2151 14.1945 14.1533 13.4104 14.6072 13.0321C15.0047 12.7008 15.1621 12.4773 15.0864 11.8972C15.0276 11.4463 14.4811 11.0649 14.0019 11.1658L13.2958 11.3423M2.95538 11.3423C3.43856 11.5607 4.32585 11.8997 5.3261 12.1594M2.95538 11.3423C3.19918 10.2582 3.69182 7.9537 3.712 7.40894C3.73217 6.86418 3.44983 6.74768 3.18237 6.60189L1.97179 5.9966C1.16077 5.36487 1.81456 4.40571 2.22399 4.10404C2.63342 3.80237 4.9303 2.95471 5.0739 2.74218C5.2175 2.52966 5.0739 1.83519 5.0739 1.83519C5.05139 1.62305 5.2186 1.48282 5.39514 1.53368C6.15608 1.75291 6.1836 1.88541 6.5619 2.18729C6.90106 2.45793 7.64638 3.27176 7.82293 3.72573C7.99947 4.1797 8.02469 4.98675 8.02469 5.51638C8.02469 5.94008 8.34415 6.06283 8.50388 6.07123C8.92422 6.08805 9.97171 6.11159 10.7989 6.07123C11.6262 6.03088 12.3374 7.06324 12.5896 7.58446L13.2958 11.3423M13.2958 11.3423C12.7345 11.5484 11.7584 11.862 10.6981 12.1149M5.3261 12.1594C6.13583 12.3695 7.01959 12.5277 7.82293 12.5277C8.68202 12.5277 9.72546 12.3468 10.6981 12.1149M5.3261 12.1594C5.43539 11.7189 5.73467 10.7623 6.0575 10.4596C6.46102 10.0813 7.04109 9.60214 8.02469 9.57691C8.81157 9.55674 9.63039 10.157 9.94144 10.4596C10.2105 10.7897 10.7384 11.5828 10.6981 12.1149" stroke="currentColor"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconKindergarten',
};
</script>
