<template>
    <div :class="$style.MediaInfo">
        <div v-if="isPromos && endPromoDate">
            Действует до {{ endPromoDate }}
        </div>
        <div v-else-if="!isPromos">
            {{ publicationDate }}
        </div>
        <span v-if="isGallery || card.duration" :class="$style.icon"></span>
        <div v-if="isGallery || card.duration" :class="$style.adds">
            <template v-if="isGallery">
                {{ imageCount }} фото
            </template>
            <template v-else-if="card.duration">
                <svg :class="$style.svg">
                    <use xlink:href="#icon-clock"/>
                </svg>
                <span>{{ card.duration }} мин.</span>
            </template>
        </div>
    </div>
</template>

<script>

import { monthByNumber } from '../../../assets/js/utils';

export default {
    name: 'MediaInfo',

    props: {
        card: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        imageCount() {
            return this.card?.postimageSet.length ? this.card?.postimageSet.length : 0;
        },

        publicationDate() {
            const date = new Date(this.card.publicationDate);
            return `${date.getDate()} ${monthByNumber(date.getMonth(), 'case')} ${date.getFullYear()}`;
        },
                        
        endPromoDate() {
            if (!this.card.end) {
                return;
            }

            const date = new Date(this.card.end);
            return `${date.getDate()} ${monthByNumber(date.getMonth(), 'case')} ${date.getFullYear()}`;
        },

        isPromos() {
            return this.card.category === 'promotion';
        },

        isGallery() {
            return this.card.category === 'gallery';
        },
    },
};
</script>

<style lang="scss" module>
    .MediaInfo {
        display: flex;
        align-items: center;
        min-width: 16%;
        font-size: 1.2rem;
        line-height: 1.64;
        color: $base-gray;
    }

    .icon {
        display: inline-block;
        width: .4rem;
        min-width: 4px;
        height: .4rem;
        min-height: 4px;
        margin: 0 .8rem;
        border-radius: 50%;
        background-color: $base-ease-gray;
        vertical-align: middle;
    }

    .adds {
        display: flex;
        align-items: center;
    }

    .svg {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: .6rem;
        fill: $base-soft-gray;
    }
</style>
