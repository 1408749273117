<template>
    <symbol
        id="icon-download"
        viewBox="0 0 20 20"
    >
        <path d="M3.33332 15.8334H16.6667V11.6667H18.3333V16.6667C18.3333 16.8878 18.2455 17.0997 18.0892 17.256C17.933 17.4123 17.721 17.5001 17.5 17.5001H2.49999C2.27898 17.5001 2.06701 17.4123 1.91073 17.256C1.75445 17.0997 1.66666 16.8878 1.66666 16.6667V11.6667H3.33332V15.8334Z"/>
        <path d="M14.1257 7.43484L10.8341 10.7265L10.8341 1.4165L9.16741 1.4165L9.16741 10.7265L5.87574 7.43484L4.69741 8.61317L10.0007 13.9165L15.3041 8.61317L14.1257 7.43484Z"/>
    </symbol>
</template>

<script>
export default {
    name: 'IconDownload',
};
</script>
