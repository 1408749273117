// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_u3Uva{position:absolute;top:100%;left:50%;width:100%;max-width:58.4rem;background-color:#fff;-webkit-transform:translateX(-50%);transform:translateX(-50%);-webkit-transition:-webkit-transform .3s ease;transition:-webkit-transform .3s ease;transition:transform .3s ease;transition:transform .3s ease,-webkit-transform .3s ease}.wrapper_u3Uva._active_qJm3B{-webkit-transform:translateX(-50%) translateY(-100%);transform:translateX(-50%) translateY(-100%)}.wrapper_u3Uva._about_EVui5{max-width:60.8rem;height:calc(100% - 6.1rem)}@media(max-width:767.98px){.wrapper_u3Uva._about_EVui5{height:calc(100% - 1.6rem)}}.option_VKCAF{font-size:1.4rem;font-weight:700;line-height:1;letter-spacing:normal;padding:2rem 3.2rem;color:#000;-webkit-transition:all .3s ease;transition:all .3s ease}.option_VKCAF._active_qJm3B{background-color:#2f2f2f;color:#fff}@media(max-width:767.98px){.header_0nUnd{padding:2.8rem 1.6rem 2.4rem}}.imageBanner_FkVNZ{width:100%;height:29.4rem;margin:2.4rem 0;padding:0 2.4rem}@media(max-width:767.98px){.imageBanner_FkVNZ{height:25rem;margin:1.6rem 0;padding:0 1.6rem}}.imageBanner_FkVNZ .image_RCyoX{overflow:hidden}.textBlock_dDvjO{margin:2.4rem 0;padding:0 2.4rem}.title_RdAse{font-size:2.4rem;font-weight:700;line-height:2.4rem;letter-spacing:-.08rem;margin-bottom:6.4rem}.description_cL-gC{font-size:1.4rem;line-height:1.4;letter-spacing:normal}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_u3Uva",
	"_active": "_active_qJm3B",
	"_about": "_about_EVui5",
	"option": "option_VKCAF",
	"header": "header_0nUnd",
	"imageBanner": "imageBanner_FkVNZ",
	"image": "image_RCyoX",
	"textBlock": "textBlock_dDvjO",
	"title": "title_RdAse",
	"description": "description_cL-gC"
};
module.exports = ___CSS_LOADER_EXPORT___;
