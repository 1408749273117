// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".overlay_w8PAZ{position:fixed;top:0;left:0;z-index:98;overflow:hidden;width:100%;height:100%;background-color:rgba(20,21,22,.48)}@media(max-width:991.98px){.overlay_w8PAZ{background-color:rgba(125,125,134,.7)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "overlay_w8PAZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
